import React, { Component } from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SearchIcon from "@material-ui/icons/Search";
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@mui/material/Avatar';
import {   FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'

const Search = styled("div")(({ theme }) => ({
    position: "relative",

    borderRadius: "4px",
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(2),

    width: "10%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "15em",
    },
  }));


  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "1em",

      [theme.breakpoints.up("md")]: {
        width: "65ch",
      },
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({

    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));




export default class superadminattendance extends Component {
  render() {
    return (
      <div>
        <Box style={{display:"flex"}}>
     <Typography style={{fontSize:"20px",fontweight:600}}>
     Attendance Over View
     </Typography>
     <label style={{ marginTop: 50 }}>  </label>
                <select style={{
                  background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                  height: 38,
                  color: "#000000",
                  marginLeft: "60%",
                  marginTop: 0,
                  width: "180px",
                  border: "1px solid grey",
                  borderRadius: 4,


                }}
                 
                  name=""
                  // value={this.state.eventName}
                  select

                >
                  <option value=''>
                    Select Month
                  </option>
                  <option value='1st Shift-9 to 14'>
                  Jan 2024-Dec 2024
                  </option>
                  <option value='  2nd Shift-14 to 19'>
                  Jan 2024-Dec 2024
                  </option>
                  <option value=' Overtime- 19 to 21'>
                  Jan 2024-Dec 2024
                  </option>
                </select>
     </Box>
         <Grid className='attendanceDate'>
                    <div className='btncom'>
                        <Button className='btn-attendance'>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Feb<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>
                    <div className='btncom'>
                        <Button sx={{ color: "#000" }}>Jan<br />2024</Button>

                    </div>

                </Grid>




                <Box style={{display:"flex",marginTop:"2%"}}>
                <label style={{ marginTop: 50 }}>  </label>
                <select style={{
                  background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                  height: 38,
                  color: "#000000",
                  marginLeft: "0%",
                  marginTop: 0,
                  width: "180px",
                  border: "1px solid grey",
                  borderRadius: 4,


                }}
                 
                  name=""
                  // value={this.state.eventName}
                  select

                >
                  <option value=''>
                    Select Shift
                  </option>
                  <option value='1st Shift-9 to 14'>
                  Jan 2024-Dec 2024
                  </option>
                  <option value='  2nd Shift-14 to 19'>
                  Jan 2024-Dec 2024
                  </option>
                  <option value=' Overtime- 19 to 21'>
                  Jan 2024-Dec 2024
                  </option>
                </select>
                <label style={{ marginTop: 50 }}>  </label>
                <select style={{
                  background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                  height: 38,
                  color: "#000000",
                  marginLeft: "5%",
                  marginTop: 0,
                  width: "180px",
                  border: "1px solid grey",
                  borderRadius: 4,


                }}
                 
                  name=""
                  // value={this.state.eventName}
                  select

                >
                  <option value=''>
                    Select TL
                  </option>
                  <option value='1st Shift-9 to 14'>
                  Jan 2024-Dec 2024
                  </option>
                  <option value='  2nd Shift-14 to 19'>
                  Jan 2024-Dec 2024
                  </option>
                  <option value=' Overtime- 19 to 21'>
                  Jan 2024-Dec 2024
                  </option>
                </select>
                <label style={{ marginTop: 50 }}>  </label>
                <select style={{
                  background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                  height: 38,
                  color: "#000000",
                  marginLeft: "5%",
                  marginTop: 0,
                  width: "180px",
                  border: "1px solid grey",
                  borderRadius: 4,


                }}
                 
                  name=""
                  // value={this.state.eventName}
                  select

                >
                  <option value=''>
                    Select Month
                  </option>
                  <option value='1st Shift-9 to 14'>
                  Jan 2024-Dec 2024
                  </option>
                  <option value='  2nd Shift-14 to 19'>
                  Jan 2024-Dec 2024
                  </option>
                  <option value=' Overtime- 19 to 21'>
                  Jan 2024-Dec 2024
                  </option>
                </select>

          
               
                <Box sx={{ minWidth: 200, height: 5, marginRight: 5 }}>
                        <FormControl >

                            <TextField
                               sx={{
                                '& > :not(style)': {  width: '25ch',height:"4ch",marginTop:"-.2%",marginLeft:"5%" },
                              }}
                                label="Search"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                        </FormControl>
                    </Box>
            

                
                </Box>


                <TableContainer component={Paper} style={{width:"90%",marginTop:"2%",backgroundColor:"linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)"}}>
      <Table sx={{ minWidth: 500 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Employees</TableCell>
            <TableCell align="right">1</TableCell>
            <TableCell align="right">2</TableCell>
            <TableCell align="right">3</TableCell>
            <TableCell align="right">4</TableCell>
            <TableCell align="right">5</TableCell>
            <TableCell align="right">6</TableCell>
            <TableCell align="right">7</TableCell>
            <TableCell align="right">8</TableCell>
            <TableCell align="right">9</TableCell>
            <TableCell align="right">10</TableCell>
            <TableCell align="right">11</TableCell>
            <TableCell align="right">12</TableCell>
            <TableCell align="right">13</TableCell>
            <TableCell align="right">14</TableCell>
            <TableCell align="right">15</TableCell>
            <TableCell align="right">16</TableCell>
            <TableCell align="right">17</TableCell>
            <TableCell align="right">18</TableCell>
            <TableCell align="right">19</TableCell>
            <TableCell align="right">20</TableCell>
            <TableCell align="right">21</TableCell>
            <TableCell align="right">22</TableCell>
            <TableCell align="right">23</TableCell>
            <TableCell align="right">25</TableCell>
            <TableCell align="right">26</TableCell>
            <TableCell align="right">27</TableCell>
            <TableCell align="right">28</TableCell>
            <TableCell align="right">29</TableCell>
            <TableCell align="right">30</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
         <TableRow
         
           sx={{ '&:last-child td, &:last-child th': { borderLeft:1,borderColor:"rgba(209, 209, 209, 1)" } }}
         >
           <TableCell component="th" scope="row">
           <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />Aman Dey

           </TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
         
         </TableRow>

         
   
     </TableBody>
       
      </Table>
    </TableContainer>


      </div>
    )
  }
}
