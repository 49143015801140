import React, { Component } from "react";
import { connect } from "react-redux";
import Users from "../components/home";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  onLogout
} from "../../../../auth/actions";
import {
  view_all_user,
  deleteUser,
  update_profile_pic,
  add_upload_profile,
  view_user_profile
} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <Users {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    user: store.user,
    one_user: store.one_user,
    login: store.login,
    snackbar: store.snackbar
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    view_all_user: (admin_id) => {
      dispatch(view_all_user())
    },
    view_user_profile: (id) => {
      dispatch(view_user_profile(id))
    },
    add_upload_profile: (Emp_Id, name, mobile, email, dob, gender, profile_pic, p_address, pr_address, password) => {
      dispatch(add_upload_profile(Emp_Id, name, mobile, email, dob, gender, profile_pic, p_address, pr_address, password))
    },
    update_profile_pic: (id,name,mobile,email,dob,gender,profile_pic,profile_pic_old,p_address,pr_address,password) => {
      dispatch(update_profile_pic(id,name,mobile,email,dob,gender,profile_pic,profile_pic_old,p_address,pr_address,password))
    },
    deleteUser: (Emp_Id) => {
      dispatch(deleteUser(Emp_Id))
    },
    onLogout: () => {
      dispatch(onLogout());
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);