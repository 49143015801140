import {
    Grid, CardContent, Typography, Box, IconButton, TextField,
    DialogActions, Tooltip
} from "@mui/material";
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import TablePagination from '@mui/material/TablePagination';
// import { Close } from '@material-ui/icons';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import { Navigate } from "react-router-dom";
// import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
// import AddIcon from '@mui/icons-material/Add';
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import "../../style/order.css"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Link } from "react-router-dom";
import Snackbar from "../../../../common/snackbar/components/snackbar";
import TablePagination from "@mui/material/TablePagination";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            add_event: false,
            edit: false,
            upload_image_dialog: false,
            delete: false,
            // token: "",
            // filterValue: this.props.speakers.all_speakers,
            page: 0,
            setPage: 0,
            rowsPerPage: 20,
            setRowsPerPage: "",
        };
    }
    // handleFileChange(e) {
    //     // if (e.target.files) {
    //     // console.log
    //     // this.props.upload_profile(e.target.files[0],this.props.login.user_id);
    //     this.props.add_upload_profile(this.props.login.user_id, e.target.files[0], this.props.one_user.p_address, this.props.one_user.pr_address, this.props.one_user.password);

    //     // }
    // };
    componentDidMount() {
        this.props.viewAllCustomer(this.props.login.user_id, this.state.page);
    }
    render() {
        const {
            all_customer,
            snackbar,
            close_snack_bar,
            login,
        } = this.props;
        const handleChangePage = (event, newPage) => {
            // console.log(newPage)
            // this.props.viewAlltheClients(this.props.login.user_id,newPage);
            this.props.viewAllCustomer(this.props.login.user_id, newPage);

            // this.props.view_all_participants(this.props.login.user_token, newPage * this.state.rowsPerPage, this.state.rowsPerPage, this.state.status, this.state.event_id, this.state.department_id,this.state.sort);
            // this.props.view_all_participants()
            this.setState({
                page: newPage,
            });
        };


        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='ordername'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Customer</Button>
                        </center>
                    </Box>

                    <center style={{ borderRadius: 0 }}>
                        <Link to="/addcustomer" style={{ textDecoration: "none", }}>

                            <Button className='orderadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                })
                            }}>Add Customer</Button>
                        </Link>
                    </center>

                </Grid>
                {/* <Grid container direction="row" justifyContent="space-between" display="flex"
                    style={{ paddingLeft: "3.3%", paddingRight: "5.5%" }}>
                    <Grid item lg={4}>
                        <Search
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 52,
                                width: "300px",
                                border: "2px solid #000000"
                            }}
                            direction="row"
                        >
                            <SearchIconWrapper>
                                <SearchIcon style={{ color: "black" }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search By mobile No."
                                color="black"
                                inputProps={{ "aria-label": "search" }}
                                style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                            // onChange={handleChange}
                            />
                        </Search>
                    </Grid>

                </Grid> */}
                <Grid
                    // container
                    // direction="row"
                    alignItems="end"
                    // justifyContent="space-between"
                    mr={10}
                    style={{}}
                >
                    <TablePagination
                        rowsPerPageOptions={[20,]}
                        component="div"
                        count={all_customer.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={handleChangePage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>


                <CardContent display="flex" style={{ paddingLeft: "3.9%", paddingRight: "7%" }}>
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: 600 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                            <TableHead className="orderhead">
                                <TableRow >
                                    <TableCell>Name</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Address</TableCell>
                                    {/* <TableCell>Address</TableCell> */}
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="orderbody">
                                {all_customer.all_customer.map((allcustomer) => {
                                    return (
                                        <TableRow
                                            // key={row.name}
                                            display="flex"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell>{allcustomer.cust_name}</TableCell>
                                            <TableCell>{allcustomer.cust_mobile}</TableCell>
                                            <TableCell>{allcustomer.cust_email}</TableCell>
                                            <TableCell>{allcustomer.cust_address}</TableCell>
                                            <TableCell>


                                                <Tooltip title="Edit">

                                                    <Link to="/editcustomer" style={{ textDecoration: "none" }}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.props.viewCustomerId(allcustomer._id)
                                                            }}
                                                            style={{ marginLeft: "-15px" }}
                                                        >
                                                            <EditOutlinedIcon style={{ color: 'green' }} />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                                <Tooltip title="View">
                                                    <IconButton color="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                view: true,
                                                                cust_name: allcustomer.cust_name,
                                                                cust_mobile: allcustomer.cust_mobile,
                                                                cust_email: allcustomer.cust_email,
                                                                cust_address: allcustomer.cust_address,
                                                                cust_aadhar: allcustomer.cust_aadhar,
                                                                cust_pan: allcustomer.cust_pan,
                                                                cust_gst_cert: allcustomer.cust_gst_cert,
                                                                cust_profile_pic: allcustomer.cust_profile_pic
                                                            })
                                                        }}
                                                    >
                                                        <RemoveRedEyeOutlinedIcon style={{ color: 'blue', fontWeight: "bolder" }} />


                                                    </IconButton>
                                                </Tooltip>
                                                {/* 
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            edit: true,
                                                            id: allorder._id,
                                                            customer_id: allorder.customer_id,
                                                            model_id: allorder.model_id,
                                                            category_id: allorder.category_id,
                                                            variant_id: allorder.variant_id,
                                                            quanty: allorder.quanty,
                                                            order_color: allorder.order_color,
                                                            will_registration: allorder.will_registration,
                                                            applied: allorder.applied,
                                                            received: allorder.received,
                                                            length: allorder.length,
                                                            width: allorder.width,
                                                            height: allorder.height,
                                                            insurance: allorder.insurance,
                                                            registration: allorder.registration,
                                                            transportation: allorder.transportation,
                                                            booking_date: allorder.booking_date,
                                                            estimated_delivery_date: allorder.estimated_delivery_date,
                                                            delivery_date: allorder.delivery_date,
                                                            order_status: allorder.order_status,
                                                            comment: allorder.comment
                                                        });

                                                    }}
                                                >
                                                    <EditOutlinedIcon style={{ color: 'green' }} />

                                                </IconButton> */}
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            delete: true,
                                                            id: allcustomer._id,
                                                        })

                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />

                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </CardContent>














                {/* 
                <Dialog
                    open={this.state.view}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography style={{ color: "blue", fontSize: 25 }}>
                            Orders Deatils

                        </Typography>

                        <IconButton color="primary"
                            style={{ marginLeft: "93%", marginTop: "-15%" }}
                            onClick={() => {
                                this.setState({
                                    view: false,
                                    // _id: c._id,
                                    // color_name: c.color_name,
                                    // color_code: c.color_code,
                                })
                            }}
                        >
                            <CloseIcon style={{ color: 'blue' }}
                            />
                        </IconButton>


                    </DialogTitle>



                    <Grid lg={8} style={{ display: "flex", flexDirection: "row", width: "600px", }}>
                         <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "25px" }}>
                            <img
                                src={this.state.profile_pic}
                                style={{ width: 140, height: 150 }} />

                        </Grid> 


                        <Grid style={{ marginLeft: "25px", marginTop: "-30px" }}>
                            <Grid item style={{ display: "flex", width: 100 }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Address:</Typography>
                                <Typography style={{ fontSize: 12 }}>&nbsp;{this.state.pr_address}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 100 }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Addhar No:</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.dob}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Pan No :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.will_registration}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  GST No  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.p_address}</Typography>
                            </Grid>
                           
                        </Grid>

                    </Grid>

                </Dialog>

 */}
                <Dialog
                    open={this.state.view}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ marginLeft: "0%", width: "100%" }}
                >
                    <Grid className="view">


                        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box className='details'>
                                <center>

                                    <Typography style={{ color: "white ", marginLeft: "0px", fontSize: 16, marginTop: 5 }}>   Customer Details</Typography>
                                </center>




                            </Box>
                            <IconButton
                                // color=""
                                style={{ marginLeft: "0%", marginTop: "-0%" }}
                                onClick={() => {
                                    this.setState({
                                        view: false,
                                        // _id: c._id,
                                        // color_name: c.color_name,
                                        // color_code: c.color_code,
                                    })
                                }}
                            // size={18}
                            >
                                <CloseIcon style={{ color: 'black', fontSize: 48 }}
                                />
                            </IconButton>



                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            {/* <Grid item style={{ display: "flex", width: 200 ,marginTop:"50px",marginLeft:"50px"}} >
                            <Typography style={{ fontWeight: 600, fontSize: 15, }}>Warranty:</Typography>
                        </Grid> */}
                            <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "20px" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 18, marginTop: 2, width: 300 }}>CUSTOMER PROFILE:</Typography>
                                <img
                                    src={this.state.cust_profile_pic}
                                    style={{ width: 140, height: 150 }} />

                            </Grid>


                        </Grid>
                        <Grid item style={{ display: "flex", width: 400, marginTop: "20px", marginLeft: "20px" }} >
                            {/* <Typography style={{ fontWeight: 600, fontSize: 15, }}>Warranty:</Typography> */}
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginTop: 2, width: 800 }}>CUSTOMER DOCUMENTS:</Typography>
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "20px" }}>
                                <img
                                    src={this.state.cust_pan}
                                    style={{ width: 140, height: 150 }} />
                                <Typography style={{ fontWeight: 400, fontSize: 18, marginTop: 2, width: 100 }}>(PAN)</Typography>

                            </Grid>

                            <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "20px" }}>
                                <img
                                    src={this.state.cust_aadhar}
                                    style={{ width: 140, height: 150 }} />
                                <Typography style={{ fontWeight: 400, fontSize: 18, marginTop: 2, width: 100 }}>(AADHAR)</Typography>
                            </Grid>
                            <Grid style={{ marginTop: "0px", marginBottom: 50, marginLeft: "30px", marginRight: "30px" }}>
                                <img
                                    src={this.state.cust_gst_cert}
                                    style={{ width: 140, height: 150 }} />
                                <Typography style={{ fontWeight: 400, fontSize: 18, marginTop: 2, width: 100 }}>(GST)</Typography>
                            </Grid>



                        </Grid>
                        {/* <Grid item style={{ display: "flex", }} >
                        <Grid item style={{ display: "flex", width: 300 ,marginTop:"20px",marginLeft:"50px"}} >
                            <Typography style={{ fontWeight: 600, fontSize: 15, }}>Name:</Typography>
                            <Typography style={{ fontWeight: 400, fontSize: 14, marginTop:2 }}>&nbsp;&nbsp;&nbsp;{this.state.cust_name}</Typography>
                        </Grid>
                        <Grid item style={{ display: "flex", width: 300 ,marginTop:"20px",marginLeft:"0px"}} >
                            <Typography style={{ fontWeight: 600, fontSize: 15, }}>Mobile :</Typography>
                            <Typography style={{fontWeight: 400, fontSize: 14, marginTop:2}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.cust_mobile}</Typography>
                        </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                        <Grid item style={{ display: "flex", width: 200 ,marginTop:"20px",marginLeft:"50PX"}} >
                            <Typography style={{ fontWeight: 600, fontSize: 15, }}> Email:</Typography>
                            <Typography style={{ fontWeight: 400, fontSize: 14, marginTop:2}}>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.cust_email}</Typography>
                        </Grid>
                        <Grid item style={{ display: "flex", width: 300 ,marginTop:"20px",marginLeft:"90px"}} >
                            <Typography style={{ fontWeight: 600, fontSize: 15, }}>Address:</Typography>
                            <Typography style={{ fontWeight: 400, fontSize: 14, marginTop:2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.cust_address}</Typography>
                        </Grid>
                        </Grid> */}




                    </Grid>
                </Dialog>

                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                    > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained" onClick={() => { this.props.deleteCustomer(this.state.id, this.props.login.user_id); this.setState({ delete: false }) }}>

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.add}
                    fullScreen
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid style={{ backgroundColor: "#fff", height: "700px", minWidth: "700px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                {/* <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Customer "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // value={this.state.category_name}
                                    onChange={(e) => { this.setState({ customer_id: e.target.value }) }}
                                >
                                    {all_customer.all_customer.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.cust_name}
                                        </MenuItem>
                                    ))}
                                </TextField> */}
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search>


                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="EmployeeId"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => {
                                        this.setState({ Emp_Id: e.target.value })
                                    }}
                                >

                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Name"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => {
                                        this.setState({ name: e.target.value });
                                    }}
                                />

                            </div>
                            <form>
                                <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                    <TextField
                                        required={true}
                                        id="phone number"
                                        label="Mobile Number"
                                        //   variant='number'
                                        type="text" pattern="[0-9]{10}" placeholder="mobile No"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "280px" }}
                                        value={this.state.cust_mobile}
                                        min={0}
                                        // onKeyDown="if(this.value.length==2 && event.keyCode!=8) return false;"
                                        maxLength={10}
                                        onChange={(e) => {
                                            this.setState({ mobile: e.target.value });
                                        }}
                                    />

                                </div></form>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Email"
                                    type={"email"}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value });
                                    }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-required"
                                    label="Address"
                                    multiline
                                    rows={4}
                                    style={{ height: "60px", width: "280px" }}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    onChange={(e) => {
                                        this.setState({ address: e.target.value });
                                    }}
                                />

                            </div>
                            {/* <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    type=""
                                    id="outlined-required"
                                    label="Will Register"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    // style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ will_registration: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div> */}

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Will Register</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={this.state.will_registration} onChange={(e) => { this.setState({ will_registration: e.target.value }) }} name="Yes" />
                                            }
                                            label="yes"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={this.state.will_registration} onChange={(e) => { this.setState({ will_registration: e.target.value }) }} name="No" />
                                            }
                                            label="no"
                                        />

                                    </FormGroup>

                                </FormControl>
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="standard-multiline-flexible"
                                    label="Pan"
                                    multiline
                                    maxRows={4}
                                    variant="standard"
                                    style={{ height: "60px", width: "230px" }}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    required
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Height"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ height: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="exshowroom price"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ insurance: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Insurance Price"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ insurance: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Registation Price"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ registration: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Transporation Price"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ transportation: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Estimate Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ estimated_delivery_date: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="Status"
                                    label="Status"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ order_status: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Comment"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ comment: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>

                            <Button
                                onClick={() => {
                                    this.props.createOrder(this.props.login.user_id, this.state.customer_id, this.state.model_id, this.state.category_id, this.state.variant_id, this.state.quanty, this.state.order_color, this.state.will_registration, this.state.applied, this.state.received, this.state.length, this.state.width, this.state.height, this.state.insurance, this.state.registration, this.state.transportation, this.state.booking_date, this.state.estimated_delivery_date, this.state.delivery_date, this.state.order_status, this.state.comment);
                                    this.setState({ add: false });
                                }}
                                style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Add</Button>
                            <Button onClick={() => {
                                this.setState({ add: false });
                            }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Close</Button>


                        </Grid>

                    </Grid>
                </Dialog>


                <Dialog
                    open={this.state.edit}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid style={{ backgroundColor: "#fff", height: "500px", minWidth: "360px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="EmployeeId"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ Emp_Id: e.target.value }) }}
                                />

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Name"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.cust_name}
                                    onChange={(e) => {
                                        this.setState({ cust_name: e.target.value });
                                    }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required={true}
                                    id="outlined-required"
                                    disabled={this.state.mobile === 0}
                                    label="Mobile Number"
                                    type="number" pattern="[a-zA-Z0-9]{6}" name="formName" placeholder="mobile No"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.cust_mobile}

                                    min={0}
                                    // onKeyDown="if(this.value.length==2 && event.keyCode!=8) return false;"
                                    maxLength={10}
                                    onChange={(e) => {
                                        this.setState({ cust_mobile: e.target.value });
                                    }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Email"
                                    type={"email"}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.cust_email}
                                    onChange={(e) => {
                                        this.setState({ cust_email: e.target.value });
                                    }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Quantity"
                                    value={this.state.quanty}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ quanty: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-required"
                                    label="Address"
                                    multiline
                                    rows={4}
                                    style={{ height: "60px", width: "280px" }}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    value={this.state.cust_address}
                                    onChange={(e) => {
                                        this.setState({ cust_address: e.target.value });
                                    }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Will Register"
                                    value={this.state.will_registration}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ will_registration: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Applied"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ applied: e.target.value }) }}
                                    value={this.state.applied}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Received"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.received}
                                    onChange={(e) => { this.setState({ received: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Length"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ length: e.target.value }) }}
                                    value={this.state.length}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Width"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.width}
                                    onChange={(e) => { this.setState({ width: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Height"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ height: e.target.value }) }}
                                    value={this.state.height}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Insurance"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    value={this.state.insurance}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ insurance: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    value={this.state.registration}
                                    label="Registation"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ registration: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Transporation"
                                    value={this.state.transportation}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ transportation: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Booking Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ booking_date: e.target.value }) }}
                                    value={this.state.booking_date}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Estimate Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ estimated_delivery_date: e.target.value }) }}
                                    value={this.state.estimated_delivery_date}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Delivery Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.delivery_date}
                                    onChange={(e) => { this.setState({ delivery_date: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="Status"
                                    label="Status"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ order_status: e.target.value }) }}

                                    value={this.state.order_status}
                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Comment"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ comment: e.target.value }) }}

                                    value={this.state.comment}
                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>

                            <Button
                                onClick={() => {
                                    this.props.createOrder(this.props.login.user_id, this.state.customer_id, this.state.model_id, this.state.category_id, this.state.variant_id, this.state.quanty, this.state.order_color, this.state.will_registration, this.state.applied, this.state.received, this.state.length, this.state.width, this.state.height, this.state.insurance, this.state.registration, this.state.transportation, this.state.booking_date, this.state.estimated_delivery_date, this.state.delivery_date, this.state.order_status, this.state.comment);
                                    this.setState({ edit: false });
                                }}
                                style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Update</Button>
                            <Button onClick={() => {
                                this.setState({ edit: false });
                            }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Close</Button>


                        </Grid>

                    </Grid>
                </Dialog>
                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />

            </Grid>
            //     <Grid>
            //         <br />
            //         <br />
            //         <Grid justifyContent={"center"}>
            //             <Box>
            //                 <Card
            //                     variant="elevation"
            //                     style={{ width: "90%", borderRadius: 10, marginLeft: "60px" }}
            //                 >
            //                     <Grid container justifyContent="space-between" direction="row">
            //                         {/* <CardHeader title="All Speakers" /> */}
            //                         <Typography
            //                             style={{
            //                                 fontSize: 20,
            //                                 color: "blue",
            //                                 marginLeft: "20px",
            //                                 fontWeight: "bold",
            //                                 width: "20%",
            //                                 marginTop: "10px",
            //                             }}
            //                         >
            //                             ALL CUSTOMER
            //                         </Typography>

            //                         {/* <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
            //                             <Button style={{ marginLeft: "500%", backgroundColor: "ButtonFace   ", marginTop: "10px" }} onClick={() => {
            //                                 this.setState({
            //                                     add_event: true,
            //                                     // id: allusers._id,
            //                                 })
            //                             }}>ADD

            //                                 <AddIcon style={{ marginTop: 0, color: "blue" }} />
            //                             </Button>
            //                         </Grid> */}

            //                         <Grid>
            //                             <Grid
            //                                 style={{
            //                                     display: "flex",
            //                                     flexDirection: "row",
            //                                     justifyContent: "",
            //                                 }}
            //                             >
            //                                 <Button
            //                                     style={{
            //                                         backgroundColor: "ButtonFace   ",
            //                                         marginRight: "10px",
            //                                         marginTop: "10px",
            //                                     }}
            //                                     onClick={() => {
            //                                         this.setState({
            //                                             add: true,
            //                                             // id: allusers._id,
            //                                         });
            //                                     }}
            //                                 >
            //                                     ADD
            //                                     <AddIcon style={{ marginTop: 0, color: "blue" }} />
            //                                 </Button>
            //                             </Grid>
            //                             <IconButton
            //                                 style={{ paddingRight: 14 }}
            //                                 onClick={() => {
            //                                     this.setState({ add_event: true });
            //                                 }}
            //                             >
            //                                 {/* <Typography style={{ fontWeight: "600", color: "black", fontSize: 13 }}>Add Speakers</Typography>
            //                                 <Icon sx={{ color: green[500] }} >add_circle</Icon> */}
            //                             </IconButton>
            //                             {/* <DownloadTableExcel

            //                                 filename="speaker table"
            //                                 sheet="speaker"
            //                                 currentTableRef={this.tableRef.current}
            //                             > */}

            //                             <IconButton
            //                             //  onClick={()=>export_all_speakar()}
            //                             >
            //                                 {/* <Typography
            //                                         style={{ fontWeight: "600", color: "black", fontSize: 13 }}

            //                                     >
            //                                         Export Speaker
            //                                     </Typography>
            //                                     <Icon sx={{ color: green[500] }}>download</Icon> */}
            //                             </IconButton>

            //                             {/* </DownloadTableExcel> */}
            //                         </Grid>
            //                     </Grid>
            //                     <Grid
            //                         container
            //                         direction="row"
            //                         justifyContent="space-between"
            //                         style={{ paddingLeft: 9, paddingRight: 14 }}
            //                     >
            //                         <Grid item lg={4}>
            //                             <Search
            //                                 style={{
            //                                     backgroundColor: "#d9d9d9",
            //                                     height: 32,
            //                                     marginRight: 19,
            //                                     marginTop: 12,
            //                                 }}
            //                                 direction="row"
            //                             >
            //                                 <SearchIconWrapper>
            //                                     <SearchIcon style={{ color: "black" }} />
            //                                 </SearchIconWrapper>
            //                                 <StyledInputBase
            //                                     placeholder="Search by name ..."
            //                                     color="black"
            //                                     inputProps={{ "aria-label": "search" }}
            //                                     style={{
            //                                         textAlign: "center",
            //                                         justifyItems: "center",
            //                                         marginTop: -4,
            //                                     }}
            //                                 // onChange={handleChange}
            //                                 />
            //                             </Search>
            //                         </Grid>

            //                         <Grid
            //                             class="register-form-custom-select"
            //                             style={{ marginTop: 19 }}
            //                         >
            //                             <select
            //                                 style={{ width: "200px", marginLeft: "30px" }}
            //                                 name=""
            //                                 // value={this.state.eventName}
            //                                 select
            //                             // onChange={handleChangeDepartment}
            //                             >
            //                                 <option value="">DEMO</option>
            //                                 {/* {departments.all_departments.map((row) => (

            //                                     <>

            //                                         <option value={row.department_id}>{row.department_name}</option>
            //                                     </>

            //                                 ))} */}
            //                             </select>
            //                             <select
            //                                 style={{ width: "200px", marginLeft: "10px" }}
            //                                 name="participant type"
            //                             // value={this.state.eventName}
            //                             // onChange={handleChangeEvent}
            //                             >
            //                                 <option value="">DEMO</option>
            //                                 {/* {events.all_events.map((row) => (

            //                                     <>
            //                                         <option value={row.event_id}>{row.event_name}</option>
            //                                     </>

            //                                 ))} */}
            //                             </select>
            //                         </Grid>
            //                     </Grid>

            //                     <CardContent>
            //                         <TableContainer component={Paper}>
            //                             <Table
            //                                 sx={{ minWidth: 650 }}
            //                                 size="small"
            //                                 aria-label="a dense table"
            //                                 ref={this.tableRef}
            //                             >
            //                                 <TableHead>
            //                                     <TableRow>
            //                                         <TableCell>Name</TableCell>
            //                                         <TableCell>Mobile</TableCell>
            //                                         <TableCell>Email</TableCell>
            //                                         <TableCell>Address</TableCell>
            //                                         {/* <TableCell>Address</TableCell> */}
            //                                         <TableCell>Action</TableCell>
            //                                     </TableRow>
            //                                 </TableHead>
            //                                 <TableBody>
            //                                     {all_customer.all_customer.map((allcustomer) => {
            //                                         return (
            //                                             <TableRow
            //                                                 // key={row.name}
            //                                                 sx={{
            //                                                     "&:last-child td, &:last-child th": { border: 0 },
            //                                                 }}
            //                                             >

            //                                                 <TableCell>{allcustomer.cust_name}</TableCell>
            //                                                 <TableCell>{allcustomer.cust_mobile}</TableCell>
            //                                                 <TableCell>{allcustomer.cust_email}</TableCell>
            //                                                 <TableCell>{allcustomer.cust_address}</TableCell>
            //                                                 <TableCell>
            //                                                     <IconButton
            //                                                         onClick={() => {
            //                                                             this.setState({
            //                                                                 view: true,
            //                                                                 cust_name: allcustomer.cust_name,
            //                                                                 cust_mobile: allcustomer.cust_mobile,
            //                                                                 cust_email: allcustomer.cust_email,
            //                                                                 cust_address: allcustomer.cust_address,
            //                                                                 cust_aadhar: allcustomer.cust_aadhar,
            //                                                                 cust_pan: allcustomer.cust_pan,
            //                                                                 cust_gst_cert: allcustomer.cust_gst_cert,
            //                                                                 cust_profile_pic: allcustomer.cust_profile_pic
            //                                                             });
            //                                                         }}
            //                                                     >
            //                                                         <Icon style={{ color: "blue" }}>
            //                                                             visibility
            //                                                         </Icon>
            //                                                     </IconButton>
            //                                                     {/* <IconButton color="primary" aria-label="upload picture" component="label"
            //                                                         onClick={() => {
            //                                                             this.setState({
            //                                                                 upload_image_dialog: true,
            //                                                                 // speaker_id: row.speaker_id, speaker_photo: row.speaker_photo
            //                                                             })
            //                                                         }}
            //                                                     >

            //                                                     </IconButton> */}
            //                                                     <IconButton
            //                                                         onClick={() => {
            //                                                             this.setState({
            //                                                                 edit: true,
            //                                                                 id: allcustomer._id,
            //                                                                 cust_name: allcustomer.cust_name,
            //                                                                 cust_mobile: allcustomer.cust_mobile,
            //                                                                 cust_email: allcustomer.cust_email,
            //                                                                 cust_address: allcustomer.cust_address,
            //                                                                 cust_aadhar: allcustomer.cust_aadhar,
            //                                                                 cust_pan: allcustomer.cust_pan,
            //                                                                 cust_gst_cert: allcustomer.cust_gst_cert,
            //                                                                 cust_profile_pic: allcustomer.cust_profile_pic
            //                                                             });
            //                                                         }}
            //                                                     >
            //                                                         <Icon style={{ color: "green" }}>edit</Icon>
            //                                                     </IconButton>
            //                                                     <IconButton
            //                                                         onClick={() => {
            //                                                             this.setState({
            //                                                                 delete: true,
            //                                                                 id: allcustomer._id,
            //                                                             });
            //                                                         }}
            //                                                     >
            //                                                         <Icon style={{ color: "red" }}>delete</Icon>
            //                                                     </IconButton>
            //                                                 </TableCell>
            //                                             </TableRow>
            //                                         );
            //                                     })}
            //                                 </TableBody>
            //                             </Table>
            //                         </TableContainer>
            //                         <TablePagination
            //                             rowsPerPageOptions={[10, 25, 100]}
            //                             component="div"
            //                         // count={speakers.filterValue.length}
            //                         // rowsPerPage={this.state.rowsPerPage}
            //                         // page={this.state.page}
            //                         // onPageChange={handleChangePage}
            //                         // onRowsPerPageChange={handleChangeRowsPerPage}
            //                         />
            //                     </CardContent>
            //                 </Card>
            //             </Box>
            //         </Grid>

            //         <Dialog
            //             open={this.state.view}
            //             // onClose={() => { this.setState({ view: false }) }}
            //             aria-labelledby="alert-dialog-title"
            //             aria-describedby="alert-dialog-description"
            //             style={{
            //                 // top: "50%",
            //                 // left: "10%",
            //                 // right: "auto",
            //                 // bottom: "auto",
            //                 // marginRight: "-50%",
            //                 // transform: "translate(-50%, -50%)",
            //                 // width: "1000px",
            //                 // marginLeft: "500px",
            //                 // paddingTop: "40px",
            //                 // paddingLeft: "25px",
            //                 // paddingRight: "25px",
            //                 // borderRadius: "0px",
            //                 height: "700px",
            //                 // zIndex: "200",
            //                 // backgroundColor: "#fff"
            //             }}
            //         >
            //             <DialogTitle id="alert-dialog-title">
            //                 Customer Deatils
            //                 <IconButton
            //                     color="primary"
            //                     style={{ marginLeft: "63%", marginTop: "-2%" }}
            //                     onClick={() => {
            //                         this.setState({
            //                             view: false,
            //                         });
            //                     }}
            //                 >
            //                     <CloseIcon style={{ color: "red" }} />
            //                 </IconButton>
            //             </DialogTitle>
            //             {/* <DialogTitle id="alert-dialog-title" sx={{ marginLeft: "25px" }}>

            //             </DialogTitle> */}

            //             <Grid
            //                 lg={8}
            //                 style={{ display: "flex", flexDirection: "column", width: "600px" }}
            //             >
            //                 <Grid
            //                     style={{ marginTop: "0px", marginBottom: 10, marginLeft: "25px" }}
            //                 >
            //                     <img
            //                         src={this.state.cust_profile_pic}
            //                         style={{ width: 140, height: 150 }}
            //                     />
            //                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            //                     <img
            //                         src={this.state.cust_gst_cert}
            //                         style={{ width: 140, height: 150 }}
            //                     />
            //                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            //                 </Grid>

            //                 <Grid style={{ marginLeft: "25px" }}>
            //                     <Grid item style={{ display: "flex" }}>
            //                         <Typography style={{ fontWeight: 600, fontSize: 15 }}>
            //                             Address :
            //                         </Typography>
            //                         <Typography style={{ fontSize: 15 }}>
            //                             &nbsp;{this.state.pr_address}
            //                         </Typography>
            //                     </Grid>
            //                     <Grid item style={{ display: "flex", width: 100 }}>
            //                         <Typography style={{ fontWeight: 600, fontSize: 15 }}>
            //                             Addhar No:
            //                         </Typography>
            //                         <Typography style={{ fontSize: 12 }}>
            //                             &nbsp;{this.state.dob}
            //                         </Typography>
            //                     </Grid>
            //                     <Grid item style={{ display: "flex", width: 100 }}>
            //                         <Typography style={{ fontWeight: 600, fontSize: 15 }}>
            //                             Pan No:
            //                         </Typography>
            //                         <Typography style={{ fontSize: 15 }}>
            //                             &nbsp;{this.state.gender}
            //                         </Typography>
            //                     </Grid>
            //                     <Grid item style={{ display: "flex" }}>
            //                         <Typography style={{ fontWeight: 600, fontSize: 15 }}>
            //                             {" "}
            //                             GST No :
            //                         </Typography>
            //                         <Typography style={{ fontSize: 15 }}>
            //                             &nbsp;{this.state.p_address}
            //                         </Typography>
            //                     </Grid>
            //                     {/* <Grid item style={{ display: "flex" }}>
            //                         <Typography style={{ fontWeight: 600, fontSize: 15 }}> Permanate Address  :</Typography>
            //                         <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.pr_address}</Typography>
            //                     </Grid> */}
            //                 </Grid>
            //             </Grid>
            //         </Dialog>

            //         <Dialog
            //             open={this.state.delete}
            //             // onClose={() => {
            //             //     this.setState({ delete: false });
            //             // }}
            //             aria-labelledby="alert-dialog-title"
            //             aria-describedby="alert-dialog-description"
            //         >
            //             <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
            //             <DialogContent>
            //                 <DialogContentText id="alert-dialog-description">
            //                     Are you sure want to Delete ?
            //                 </DialogContentText>
            //             </DialogContent>
            //             <DialogActions>
            //                 <Button
            //                     onClick={() => {
            //                         this.setState({ delete: false });
            //                     }}
            //                 >
            //                     Cancel
            //                 </Button>
            //                 <Button
            //                     onClick={() => {
            //                         this.props.deleteCustomer(this.state.id);
            //                         this.setState({ delete: false });
            //                     }}
            //                 >
            //                     Confirm
            //                 </Button>
            //             </DialogActions>
            //         </Dialog>

            //         <Dialog
            //             open={this.state.add}
            //             // onClose={() => {
            //             //     this.setState({ add: false });
            //             // }}
            //             // style={{wi}}
            //             style={{
            //                 // top: "50%",
            //                 // left: "10%",
            //                 // right: "auto",
            //                 // bottom: "auto",
            //                 // marginRight: "-50%",
            //                 // transform: "translate(-50%, -50%)",
            //                 // width: "1000px",
            //                 // marginLeft: "500px",
            //                 // paddingTop: "40px",
            //                 // paddingLeft: "25px",
            //                 // paddingRight: "25px",
            //                 // borderRadius: "0px",
            //                 height: "700px",
            //                 // zIndex: "200",
            //                 // backgroundColor: "#fff"
            //             }}
            //         >
            //             <Grid
            //                 style={{
            //                     backgroundColor: "#fff",
            //                     height: "450px",
            //                     minWidth: "500px",
            //                     scrollBehavior: "smooth",
            //                     overflow: "revert-layer",
            //                     alignSelf: "center",
            //                     overflowX: "hidden",
            //                     flexDirection: "row",
            //                     display: "flex",
            //                 }}
            //             >
            //                 <Grid>
            //                     {/* <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

            //                         <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="EmployeeId"
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             onChange={(e) => { this.setState({ Emp_Id: e.target.value }) }}
            //                         />

            //                     </div> */}

            //                     <div
            //                         class="input-group mb-3"
            //                         style={{ marginLeft: "20px", marginTop: "20px" }}
            //                     >
            //                         <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="Name"
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             onChange={(e) => {
            //                                 this.setState({ name: e.target.value });
            //                             }}
            //                         />
            //                     </div>

            //                     <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                         <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="Mobile Number"
            //                            type="text" pattern="[a-zA-Z0-9]{6}" name="formName" placeholder="mobile No"
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             onChange={(e) => {
            //                                 this.setState({ mobile: e.target.value });
            //                             }}
            //                         />
            //                     </div>

            //                     <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                         <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="Email"
            //                             type={"email"}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             onChange={(e) => {
            //                                 this.setState({ email: e.target.value });
            //                             }}
            //                         />
            //                     </div>
            //                     <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                         <TextField
            //                             id="outlined-required"
            //                             label="Address"
            //                             multiline
            //                             rows={4}
            //                             style={{ height: "60px", width: "280px" }}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             onChange={(e) => {
            //                                 this.setState({ address: e.target.value });
            //                             }}
            //                         />
            //                     </div>
            //                     {/* <Box sx={{ minWidth: 120 }}> */}

            //                     {/* </Box> */}
            //                     <Stack
            //                         spacing={1}
            //                         style={{
            //                             textDecoration: "none",
            //                             // display: "inline-block",
            //                             padding: "0 30px",
            //                             marginTop: "80px",
            //                         }}
            //                         className="imagemedia"
            //                     >
            //                         <img
            //                             alt="Aadhar"
            //                             src={this.state.aadhar}
            //                             style={{ height: "114px", width: "114px" }}
            //                             variant={"square"}
            //                         />
            //                         {/* {imageUrl && (
            //                             <img
            //                                 src={imageUrl}
            //                                 alt={imageAlt}
            //                                 className="displayed-image"
            //                             />
            //                         )} */}
            //                         <Input
            //                             type="file"
            //                             onChange={(e) => this.setState({ aadhar: e.target.files[0] })}
            //                         />
            //                     </Stack>
            //                     <div
            //                         class="input-group mb-3"
            //                         style={{ marginLeft: "30px", marginTop: "20px" }}
            //                     >
            //                         <TextField
            //                             id="standard-multiline-flexible"
            //                             label="Addhar"
            //                             multiline
            //                             maxRows={4}
            //                             variant="standard"
            //                             style={{ height: "60px", width: "230px" }}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             required
            //                         />
            //                     </div>
            //                     <Stack
            //                         spacing={1}
            //                         style={{
            //                             textDecoration: "none",
            //                             // display: "inline-block",
            //                             padding: "0 30px",
            //                             // marginTop:"80px"
            //                         }}
            //                         className="imagemedia"
            //                     >
            //                         <img
            //                             alt="Pan"
            //                             src={this.state.pan}
            //                             style={{ height: "114px", width: "114px" }}
            //                             variant={"square"}
            //                         />
            //                         {/* {imageUrl && (
            //                             <img
            //                                 src={imageUrl}
            //                                 alt={imageAlt}
            //                                 className="displayed-image"
            //                             />
            //                         )} */}
            //                         <Input
            //                             type="file"
            //                             onChange={(e) => this.setState({ pan: e.target.files[0] })}
            //                         />
            //                     </Stack>
            //                     <div
            //                         class="input-group mb-3"
            //                         style={{ marginLeft: "30px", marginTop: "20px" }}
            //                     >
            //                         <TextField
            //                             id="standard-multiline-flexible"
            //                             label="Pan"
            //                             multiline
            //                             maxRows={4}
            //                             variant="standard"
            //                             style={{ height: "60px", width: "230px" }}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             required
            //                         />
            //                     </div>
            //                     <Stack
            //                         spacing={1}
            //                         style={{
            //                             textDecoration: "none",
            //                             // display: "inline-block",
            //                             padding: "0 30px",
            //                             // marginTop:"80px"
            //                         }}
            //                         className="imagemedia"
            //                     >
            //                         <img
            //                             alt="Gst Certificate"
            //                             src={this.state.gst_cert}
            //                             style={{ height: "114px", width: "114px" }}
            //                             variant={"square"}
            //                         />
            //                         {/* {imageUrl && (
            //                             <img
            //                                 src={imageUrl}
            //                                 alt={imageAlt}
            //                                 className="displayed-image"
            //                             />
            //                         )} */}
            //                         <Input
            //                             type="file"
            //                             onChange={(e) =>
            //                                 this.setState({ gst_cert: e.target.files[0] })
            //                             }
            //                         />
            //                     </Stack>
            //                     <div
            //                         class="input-group mb-3"
            //                         style={{ marginLeft: "30px", marginTop: "20px" }}
            //                     >
            //                         <TextField
            //                             id="standard-multiline-flexible"
            //                             label="GST No"
            //                             multiline
            //                             maxRows={4}
            //                             variant="standard"
            //                             style={{ height: "60px", width: "230px" }}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             required
            //                         />
            //                     </div>

            //                     <Button
            //                         onClick={() => {
            //                             this.props.createCustomer(
            //                                 this.state.name,
            //                                 this.state.mobile,
            //                                 this.state.email,
            //                                 this.state.address,
            //                                 this.state.aadhar,
            //                                 this.state.pan,
            //                                 this.state.gst_cert,
            //                                 this.state.profile_pic
            //                             );
            //                             this.setState({ add: false });
            //                         }}
            //                         style={{
            //                             marginLeft: "20px",
            //                             backgroundColor: "ButtonFace",
            //                             marginBottom: "30px",
            //                             marginTop: "20px",
            //                         }}
            //                     >
            //                         Add
            //                     </Button>
            //                     <Button
            //                         onClick={() => {
            //                             this.setState({ add: false });
            //                         }}
            //                         style={{
            //                             marginLeft: "20px",
            //                             backgroundColor: "ButtonFace",
            //                             marginBottom: "30px",
            //                             marginTop: "20px",
            //                         }}
            //                     >
            //                         Close
            //                     </Button>
            //                 </Grid>
            //                 <Grid style={{ marginTop: "10px" }}>
            //                     {/* <Grid style={{ backgroundColor: "#fff", height: "380px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex" }}> */}
            //                     {/* <Avatar alt="Remy Sharp" src="" /> */}
            //                     <Stack
            //                         spacing={1}
            //                         style={{
            //                             textDecoration: "none",
            //                             // display: "inline-block",
            //                             padding: "0 45px",
            //                         }}
            //                         className="imagemedia"
            //                     >
            //                         <Avatar
            //                             alt="Cindy Baker"
            //                             src={this.state.profile_pic}
            //                             style={{ height: "114px", width: "114px" }}
            //                         />
            //                         {/* {imageUrl && (
            //                             <img
            //                                 src={imageUrl}
            //                                 alt={imageAlt}
            //                                 className="displayed-image"
            //                             />
            //                         )} */}
            //                         <Input
            //                             type="file"
            //                             onChange={(e) => this.handleFileChange(e.target.files[0])}
            //                         />
            //                     </Stack>
            //                 </Grid>
            //             </Grid>
            //         </Dialog>

            //         <Dialog
            //             open={this.state.edit}
            //             // onClose={() => {
            //             //     this.setState({ add: false });
            //             // }}
            //             // style={{wi}}
            //             style={{
            //                 // top: "50%",
            //                 // left: "10%",
            //                 // right: "auto",
            //                 // bottom: "auto",
            //                 // marginRight: "-50%",
            //                 // transform: "translate(-50%, -50%)",
            //                 // width: "1000px",
            //                 // marginLeft: "500px",
            //                 // paddingTop: "40px",
            //                 // paddingLeft: "25px",
            //                 // paddingRight: "25px",
            //                 // borderRadius: "0px",
            //                 height: "700px",
            //                 // zIndex: "200",
            //                 // backgroundColor: "#fff"
            //             }}
            //         >
            //             <Grid
            //                 style={{
            //                     backgroundColor: "#fff",
            //                     height: "450px",
            //                     minWidth: "500px",
            //                     scrollBehavior: "smooth",
            //                     overflow: "revert-layer",
            //                     alignSelf: "center",
            //                     overflowX: "hidden",
            //                     flexDirection: "row",
            //                     display: "flex",
            //                 }}
            //             >
            //                 <Grid>
            //                     {/* <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

            //                         <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="EmployeeId"
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             onChange={(e) => { this.setState({ Emp_Id: e.target.value }) }}
            //                         />

            //                     </div> */}

            //                     <div
            //                         class="input-group mb-3"
            //                         style={{ marginLeft: "20px", marginTop: "20px" }}
            //                     >
            //                         <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="Name"
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             value={this.state.cust_name}
            //                             onChange={(e) => {
            //                                 this.setState({ cust_name: e.target.value });
            //                             }}
            //                         />
            //                     </div>

            //                     <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                         <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="Mobile Number"
            //                              type="text" pattern="[a-zA-Z0-9]{6}" name="formName" placeholder="mobile No"
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             value={this.state.cust_mobile}
            //                             onChange={(e) => {
            //                                 this.setState({ cust_mobile: e.target.value });
            //                             }}
            //                         />
            //                     </div>

            //                     <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                         <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="Email"
            //                             type={"email"}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             value={this.state.cust_email}
            //                             onChange={(e) => {
            //                                 this.setState({ cust_email: e.target.value });
            //                             }}
            //                         />
            //                     </div>
            //                     <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                         {/* <TextField
            //                             required
            //                             id="outlined-required"
            //                             label="Address"
            //                             // type={"password"}
            //                             // type={"text"}
            //                             // ty
            //                             rows={4}

            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             style={{ height: "50px", width: "280px" }}
            //                             onChange={(e) => { this.setState({ password: e.target.value }) }}
            //                         /> */}
            //                         <TextField
            //                             id="outlined-required"
            //                             label="Address"
            //                             multiline
            //                             rows={4}
            //                             style={{ height: "60px", width: "280px" }}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             value={this.state.cust_address}
            //                             onChange={(e) => {
            //                                 this.setState({ cust_address: e.target.value });
            //                             }}
            //                         />
            //                     </div>
            //                     {/* <Box sx={{ minWidth: 120 }}> */}

            //                     {/* </Box> */}
            //                     <Stack
            //                         spacing={1}
            //                         style={{
            //                             textDecoration: "none",
            //                             // display: "inline-block",
            //                             padding: "0 30px",
            //                             marginTop: "80px",
            //                         }}
            //                         className="imagemedia"
            //                     >
            //                         <img
            //                             alt="Addhar"
            //                             src={this.state.profile_pic}
            //                             style={{ height: "114px", width: "114px" }}
            //                             variant={"square"}
            //                         />
            //                         {/* {imageUrl && (
            //                             <img
            //                                 src={imageUrl}
            //                                 alt={imageAlt}
            //                                 className="displayed-image"
            //                             />
            //                         )} */}
            //                         <Input
            //                             type="file"
            //                             onChange={(e) =>
            //                                 this.setState({ profile_pic: e.target.files[0] })
            //                             }
            //                         />
            //                     </Stack>
            //                     <div
            //                         class="input-group mb-3"
            //                         style={{ marginLeft: "30px", marginTop: "20px" }}
            //                     >
            //                         <TextField
            //                             id="standard-multiline-flexible"
            //                             label="Addhar"
            //                             multiline
            //                             maxRows={4}
            //                             variant="standard"
            //                             style={{ height: "60px", width: "230px" }}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             required
            //                         />
            //                     </div>
            //                     <Stack
            //                         spacing={1}
            //                         style={{
            //                             textDecoration: "none",
            //                             // display: "inline-block",
            //                             padding: "0 30px",
            //                             // marginTop:"80px"
            //                         }}
            //                         className="imagemedia"
            //                     >
            //                         <img
            //                             alt="Pan"
            //                             src={this.state.profile_pic}
            //                             style={{ height: "114px", width: "114px" }}
            //                             variant={"square"}
            //                         />
            //                         {/* {imageUrl && (
            //                             <img
            //                                 src={imageUrl}
            //                                 alt={imageAlt}
            //                                 className="displayed-image"
            //                             />
            //                         )} */}
            //                         <Input
            //                             type="file"
            //                             onChange={(e) =>
            //                                 this.setState({ profile_pic: e.target.files[0] })
            //                             }
            //                         />
            //                     </Stack>
            //                     <div
            //                         class="input-group mb-3"
            //                         style={{ marginLeft: "30px", marginTop: "20px" }}
            //                     >
            //                         <TextField
            //                             id="standard-multiline-flexible"
            //                             label="Pan"
            //                             multiline
            //                             maxRows={4}
            //                             variant="standard"
            //                             style={{ height: "60px", width: "230px" }}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             required
            //                         />
            //                     </div>
            //                     <Stack
            //                         spacing={1}
            //                         style={{
            //                             textDecoration: "none",
            //                             // display: "inline-block",
            //                             padding: "0 30px",
            //                             // marginTop:"80px"
            //                         }}
            //                         className="imagemedia"
            //                     >
            //                         <img
            //                             alt="Gst Certificate"
            //                             src={this.state.profile_pic}
            //                             style={{ height: "114px", width: "114px" }}
            //                             variant={"square"}
            //                         />
            //                         {/* {imageUrl && (
            //                             <img
            //                                 src={imageUrl}
            //                                 alt={imageAlt}
            //                                 className="displayed-image"
            //                             />
            //                         )} */}
            //                         <Input
            //                             type="file"
            //                             onChange={(e) =>
            //                                 this.setState({ profile_pic: e.target.files[0] })
            //                             }
            //                         />
            //                     </Stack>
            //                     <div
            //                         class="input-group mb-3"
            //                         style={{ marginLeft: "30px", marginTop: "20px" }}
            //                     >
            //                         <TextField
            //                             id="standard-multiline-flexible"
            //                             label="GST No"
            //                             multiline
            //                             maxRows={4}
            //                             variant="standard"
            //                             style={{ height: "60px", width: "230px" }}
            //                             InputLabelProps={{ shrink: true, required: true }}
            //                             required
            //                         />
            //                     </div>

            //                     <Button
            //                         onClick={() => {
            //                             this.props.updateCustomer(
            //                                 this.state.id,
            //                                 this.state.cust_name,
            //                                 this.state.cust_mobile,
            //                                 this.state.cust_email,
            //                                 this.state.cust_address,
            //                                 this.state.cust_aadhar,
            //                                 this.state.cust_pan,
            //                                 this.state.cust_gst_cert,
            //                                 this.state.cust_profile_pic
            //                             );
            //                             this.setState({ edit: false });
            //                         }}
            //                         style={{
            //                             marginLeft: "20px",
            //                             backgroundColor: "ButtonFace",
            //                             marginBottom: "30px",
            //                             marginTop: "20px",
            //                         }}
            //                     >
            //                         Update
            //                     </Button>
            //                     <Button
            //                         onClick={() => {
            //                             this.setState({ edit: false });
            //                         }}
            //                         style={{
            //                             marginLeft: "20px",
            //                             backgroundColor: "ButtonFace",
            //                             marginBottom: "30px",
            //                             marginTop: "20px",
            //                         }}
            //                     >
            //                         Close
            //                     </Button>
            //                 </Grid>
            //                 <Grid style={{ marginTop: "10px" }}>
            //                     {/* <Grid style={{ backgroundColor: "#fff", height: "380px", minWidth: "500px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex" }}> */}
            //                     {/* <Avatar alt="Remy Sharp" src="" /> */}
            //                     <Stack
            //                         spacing={1}
            //                         style={{
            //                             textDecoration: "none",
            //                             // display: "inline-block",
            //                             padding: "0 45px",
            //                         }}
            //                         className="imagemedia"
            //                     >
            //                         <Avatar
            //                             alt="Cindy Baker"
            //                             src={this.state.profile_pic}
            //                             style={{ height: "114px", width: "114px" }}
            //                         />
            //                         {/* {imageUrl && (
            //                             <img
            //                                 src={imageUrl}
            //                                 alt={imageAlt}
            //                                 className="displayed-image"
            //                             />
            //                         )} */}

            //                         <Input
            //                             type="file"
            //                             placeholder="Addhar Upload"
            //                             title="Addhar Upload"
            //                             onChange={(e) =>
            //                                 this.setState({ profile_pic: e.target.files[0] })
            //                             }
            //                         />
            //                     </Stack>
            //                     {/* <div class="input-group mb-3" style={{ marginLeft: "20px",marginTop:"20px" }}>
            //                     <TextField
            //   id="standard-multiline-flexible"
            //   label="Addhar"
            //   multiline
            //   maxRows={4}
            //   variant="standard"
            //   style={{ height: "60px", width: "230px" }}
            //   InputLabelProps={{ shrink: true, required: true }}
            //   required
            // />

            //                     </div> */}
            //                 </Grid>
            //             </Grid>
            //         </Dialog>
            //         <LoaderCon />
            //     </Grid>
        );
    }
}
