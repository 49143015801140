import React, { Component } from 'react'
import Logo from "../../../image/logo.png"
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import Payment from "../../../image/paymentqr.jpeg";
import Upi from "../../../image/upi.png";
import Sign from "../../../image/sign.png";
import { Link } from 'react-router-dom';
import Snackbar from "../../../../common/snackbar/components/snackbar";
function round(num, decimalPlaces = 0) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
}
export default class Quotation_SRE extends Component {
    exportAsPicture = () => {

        var t0 = performance.now()

        var data = document.getElementById('test')

        // for (var i = 0; i < data.length; ++i) {
        html2canvas(data).then((canvas) => {
            var image = canvas.toDataURL('image/PNG', 1.0);
            console.log(image)
            this.props.uploadQuot(image, this.props.all_order.order_id)

            // window.open(pdf.output('bloburl', { filename: 'new-file.pdf' }), '_blank');
        })
        // }
    }
    componentDidMount(){
        if(this.props.all_order.order_id!==""){
            this.props.viewQuotationByOrderId(this.props.all_order.order_id);
        }
    }

    render() {
        const {
            all_order,
            snackbar,
            login,
            close_snack_bar
        } = this.props;
        console.log("shdfhgasfhjasg",all_order.order_id)
        return (
            <div>
                {/* <center> */}
                <div id="test">
                    <center>

                        <h6 style={{ marginLeft: "44%", fontWeight: "bold" }}>Quoation</h6>
                        {/* </center> */}
                        <table style={{ border: "2px solid #000", width: "90%" }} >
                            <tbody>
                                {/* <thead style={{}}> */}



                                <tr style={{ width: "100%" }}>
                                    <td style={{ width: "10%" }} ><div id="bottom" >  <img src={Logo} width="100" height="70" style={{ marginLeft: "0%" }} /></div>  </td>
                                    <td colsSpan="50" style={{ width: "50%" }} >
                                        <div style={{ marginLeft: "0%", }}>
                                            <h5 style={{ textAlign: "right", fontWeight: "bold" }}>SKYY RIDER ELECTRIC PRIVATE LIMITED</h5>
                                            <p style={{ fontSize: 12, textAlign: "right", fontWeight: "500" }}>
                                                PLOT NO 137/136, CENTURION UNIVERSITY, RAMCHANDRAPUR, JATNI, KHORDHA ODISHA, 752050<br />
                                                Phone no.: 9692722511 Email: office@skyyrider.com<br />
                                                GSTIN: 21ABECS8798M1ZK, State: 21-Odisha<br />
                                                CIN NUMBER: U34100OR2020PTC034758<br />
                                            </p>


                                        </div>
                                    </td>
                                </tr>
                                {/* <div style={{flexDirection:"row",justifyContent:"space-between",display:"flex",width:"100%"}}>
                <div>

            <img src={Logo} width="100" height="70" style={{ marginLeft: "15%" }} />
                </div>
               
                </div> */}

                                {/* </thead> */}
                                <tr style={{ border: "2px solid #000", width: "90%" }}>
                                    <td style={{ border: "2px solid #000", width: "50%" }}><div >
                                        <h5 style={{ backgroundColor: "#306EBA", color: "#fff" }}>Estimate For</h5>
                                        <p style={{ fontSize: 13 }}>
                                            <b>{all_order.customer_name}</b><br />
                                            {all_order.customer_mobile}<br />
                                            {all_order.customer_address}</p></div>  </td>
                                    <td colsSpan="50"> <div style={{ float: "right" }} ><p style={{ fontSize: 14 }}><br />
                                        <b>Estimate No. : {all_order.count_quotas}</b> <br />
                                        <b>Date :{new Date().toLocaleString('en-GB')}</b> </p></div></td>
                                </tr>

                            </tbody>
                        </table>
                        <table style={{ border: "2px solid #000", width: "90%" }}>



                            <tr style={{ border: "2px solid #000", width: "100%", backgroundColor: "#306EBA" }} >

                                <td style={{ border: "2px solid #000", width: "5%", color: "#fff" }} >SI NO</td>
                                <td style={{ border: "2px solid #000", width: "15%", color: "#fff" }} >Item Name </td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Specification</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >HSN/ SAC</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Quantity</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Unit</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Price/Unit</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >TaxableAmount</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >GST</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Amount</td>


                            </tr>
                            <tr style={{ border: "2px solid #000", width: "100%", height: "100px" }} >

                                <td style={{ border: "2px solid #000", width: "5%" }}>1</td>
                                <td style={{ border: "2px solid #000", width: "10%" }}>{all_order.model_name}<br />{all_order.variant_name}<br />({all_order.color_name})</td>
                                <td style={{ border: "2px solid #000", width: "26%" }} >Volt: {all_order.variant_volt}<br />Length: {all_order.variant_length}<br />Width: {all_order.variant_width}<br />Capacity: {all_order.variant_capacity}<br />Motor: {all_order.variant_motor}<br />Range: {all_order.variant_range}<br />Battery: {all_order.battery_name}<br />Battery Voltage: {all_order.battery_voltage}<br />Model Speed: {all_order.model_speed} </td>
                                <td style={{ border: "2px solid #000", width: "10%" }} >8713</td>
                                <td style={{ border: "2px solid #000", width: "5%" }} >{all_order.quantity}</td>
                                <td style={{ border: "2px solid #000", width: "10%" }} >Nos</td>
                                <td style={{ border: "2px solid #000", width: "10%" }} >{round(all_order.onroad_price / parseInt(all_order.quantity))}</td>
                                <td style={{ border: "2px solid #000", width: "10%" }} >{round(all_order.onroad_price)}</td>
                                <td style={{ border: "2px solid #000", width: "10%" }} >{round(all_order.onroad_price * 0.05)}(5%)</td>
                                <td style={{ border: "2px solid #000", width: "10%" }} >{round(all_order.total_price)}</td>


                            </tr>
                            <tr style={{ border: "2px solid #000", width: "100%", height: "50px" }} >

                                <td style={{ border: "2px solid #000", width: "5%" }} ></td>
                                <td style={{ border: "2px solid #000", width: "10%", }} ><b>Total</b></td>
                                <td style={{ border: "2px solid #000", width: "10%" }} ></td>
                                <td style={{ border: "2px solid #000", width: "10%" }} ></td>
                                <td style={{ border: "2px solid #000", width: "10%" }} ><b>{all_order.quantity}</b></td>
                                <td style={{ border: "2px solid #000", width: "10%" }} ></td>
                                <td style={{ border: "2px solid #000", width: "10%" }} ></td>
                                <td style={{ border: "2px solid #000", width: "10%" }} ><b>₹{round(all_order.onroad_price)}</b></td>
                                <td style={{ border: "2px solid #000", width: "10%" }} ><b>₹{round(all_order.onroad_price * 0.05)}</b></td>
                                <td style={{ border: "2px solid #000", width: "10%" }} ><b>₹{round(all_order.total_price)}</b></td>


                            </tr>


                        </table>
                        <table style={{ border: "2px solid #000", width: "90%", backgroundColor: "#306EBA" }}>
                            <tr style={{ width: "90%" }}>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Tax type</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Taxable amount</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Rate</td>
                                <td style={{ border: "2px solid #000", width: "10%", color: "#fff" }} >Tax amount</td>
                                <td style={{ border: "2px solid #000", width: "50%", color: "#fff" }} >Amount</td>

                                {/* <td style={{ border: "2px solid #000", width: "100%" }}>
                                <div style={{backgroundColor: "#306EBA"}}>
                                <div style={{display:"flex", width: "100%",justifyContent:"space-between",flexDirection:"row"}} >
                                <p style={{  color: "#fff",marginLeft:"0px" }}>Tax type</p>
                                <p style={{  color: "#fff",marginLeft:"-100px"  }}>Taxable amount</p>
                                <p style={{  color: "#fff", marginLeft:"-80px"}}>Rate</p>
                                <p style={{ color: "#fff" ,marginLeft:"-110px" }}></p>
                                <p style={{ color: "#fff" ,marginLeft:"-90px",marginRight:"400px" }}>Amounts:</p>
                                </div>
                                </div>  </td> */}

                            </tr>


                        </table>
                        <table style={{ border: "2px solid #000", width: "90%" }}>
                            <tr style={{ width: "90%", display: "flex" }}>
                                <td style={{ border: "2px solid #000", width: "49.5%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} rowSpan="10" >
                                    <p>GST</p>
                                    <p>₹{round(all_order.onroad_price)}</p>
                                    <p>5%</p>
                                    <p>₹{round(all_order.onroad_price * 0.05)}</p>
                                </td>
                                <td style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "10%" }} rowSpan="10" >
                                    {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                        <p>SubTotal</p>
                                        <p style={{ marginLeft: '405%' }}>₹{round(parseFloat(all_order.total_price)) + (parseFloat(all_order.discount_amount))}</p>
                                    </div> */}
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                        <p>Transportation</p>
                                        <p style={{ marginLeft: '360%' }}>₹{all_order.transportation_cost}</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                        <p>Registration</p>
                                        <p style={{ marginLeft: '380%',width:"100%" }}>₹{all_order.registration_fee}</p>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                        <p>Discount</p>
                                        <p style={{ marginLeft: '402%' }}>₹{all_order.discount_amount}</p>
                                    </div>
                                    <hr style={{ width: "200%", borderColor: "#000", float: "right", marginLeft: "417%" }} />
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                                        <p>Total</p>
                                        <p style={{ marginLeft: '430%' }}><b>₹{round(all_order.total_price)}</b></p>
                                    </div>


                                </td>
                                {/* <td style={{ border: "2px solid #000", width: "10%" }} >₹ 7,20,000.00</td>
                            <td style={{ border: "2px solid #000", width: "10%" }} >5%</td>
                            <td style={{ border: "2px solid #000", width: "10%" }} >Tax amount</td>
                            <td style={{ border: "2px solid #000", width: "10%" }} >Amount</td> */}

                                {/* <td style={{ border: "2px solid #000", width: "100%" }}>
                                <div style={{backgroundColor: "#306EBA"}}>
                                <div style={{display:"flex", width: "100%",justifyContent:"space-between",flexDirection:"row"}} >
                                <p style={{  color: "#fff",marginLeft:"0px" }}>Tax type</p>
                                <p style={{  color: "#fff",marginLeft:"-100px"  }}>Taxable amount</p>
                                <p style={{  color: "#fff", marginLeft:"-80px"}}>Rate</p>
                                <p style={{ color: "#fff" ,marginLeft:"-110px" }}></p>
                                <p style={{ color: "#fff" ,marginLeft:"-90px",marginRight:"400px" }}>Amounts:</p>
                                </div>
                                </div>  </td> */}

                            </tr>


                        </table>
                        <table style={{ border: "2px solid #000", width: "90%" }}>
                            <tr style={{ border: "2px solid #000", width: "90%" }}>
                                <td style={{ border: "2px solid #000", width: "30%" }}><div >
                                    <h5 style={{ backgroundColor: "#306EBA", color: "#fff" }}>Terms and Conditions
                                    </h5>
                                    <p style={{ fontSize: 13 }}>
                                        1. The order will be delivered after clearance of
                                        100% payment.<br></br>
                                        2. Any further modifications shall be chargeable
                                        accordingly.<br></br>
                                        3. All disputes are subject to Bhubaneswar
                                        jurisdiction only.<br></br>
                                        4. Warranty of 1 year will be provided on the
                                        components.<br></br>
                                        5. On-site service for a period of 1 year will be
                                        done free-of-cost</p>
                                    <h5 style={{ backgroundColor: "#306EBA", color: "#fff" }}>Bank details:</h5>
                                    <p>
                                        Bank Name : ICICI BANK LIMITED, CUTTACK<br></br>
                                        ROAD-BHUBANESHWAR, ORISSA<br></br>
                                        Bank Account No. : 150005004833<br></br>
                                        Bank IFSC code : ICIC0001500<br></br>
                                        Account holder's name : SKYY RIDER ELECTRIC<br></br>
                                        PRIVATE LIMITED
                                    </p>



                                </div>  </td>
                                <td colsSpan="50" style={{ width: "30%" }}> <div style={{ width: "50%", marginTop: "-30%" }} >
                                    <br />
                                    <img src={Payment} width={200} height={200} />
                                    <div>
                                        <img src={Upi} width={50} height={30} style={{ marginLeft: "10px" }} />
                                        <b style={{ marginLeft: "10px", backgroundColor: "green", color: "white" }}>Scan To Pay ✓</b>


                                    </div>

                                    <br />
                                </div></td>
                                <td colsSpan="50" style={{ border: "2px solid #000", width: "30%" }}> <div style={{}} ><p>
                                    For, SKYY RIDER ELECTRIC PRIVATE LIMITED<br /> <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <img src={Sign} alt='Nihar Ranjan Panda'></img>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    {/* <br /> */}
                                    {/* <br /> */}
                                    Authorized Signatory</p></div></td>
                            </tr>

                        </table>
                    </center>
                    <br />

                </div>

                {/* <br/> */}
                <center>
                    <Link to="/" style={{ textDecoration: "none", marginLeft: "0%" }}>
                        <Button style={{ marginTop: "40px", color: "white", backgroundColor: "ActiveBorder", marginBottom: "20px", borderRadius: 1 }} onClick={() => this.exportAsPicture()}>Save & Continue</Button>
                    </Link>

                </center>

                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />
            </div>

        )
    }
}
