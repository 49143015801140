import React, { Component } from "react";
import { connect } from "react-redux";
import Rto from "../component/registrationdash";
// import {
//   close_snack_bar
// } from "../../common/snackbar/action";
// import {
//   createCategory,
//   updateCategory,
//   deleteCategory,
//   // viewAllCategory
// } from "../action";


import {
  // view_vehicles_status,
  view_dashboard_statistitcs,
  viewAllvehicle,
  view_vehicles_delivery,
  view_vechicle_booking,
  view_all_manufactured,
  view_all_noplate,
  view_all_vechdash,
  dashConfirmvehicle,
  dash_Rtoreg,
  dashoutdeliveryvehicle,
  Noplatevehicle

  // view_pending_order
} from "../../vechicle/action"
 
import{
  viewAllOrder,
  view_rto_reg,
  view_pending_order,
  view_all_quotation,
  dashconvert_to_booking,
  viewDashManufacturing
}from"../../orders/action"

import{
  viewAllService,
  viewAllServiceAllotted,
  serviceCompleted
}from"../../service/action"

import{
  view_all_user,
  AllottedEmployee
}from"../../empolyee/actions"

export class Controller extends Component {
  render() {
    return (
      <Rto {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_vehicles: store.all_vehicles,
    all_order: store.all_order,
    all_service: store.all_service,
    employee: store.employee,
    // viewAllCategory: store.viewAllCategory,
    // viewAllColor: store.viewAllColor,
    // viewAllCustomer: store.viewAllCustomer,
    // view_user_profile: store.view_user_profile,
    // viewModel: store.viewModel,
    // viewAllService: store.viewAllService,
    // viewVarient: store.viewVarient,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    viewAllService: (admin_id) => {
      dispatch(viewAllService(admin_id))
    },
    view_all_user: (admin_id) => {
      dispatch(view_all_user(admin_id))
    },
    AllottedEmployee: (service_id, emp_id) => {
      dispatch(AllottedEmployee(service_id, emp_id))
    },
    viewAllServiceAllotted: (admin_id) => {
      dispatch(viewAllServiceAllotted(admin_id))
    },
    serviceCompleted: (id) => {
      dispatch(serviceCompleted(id))
    },
    viewAllvehicle: (admin_id) => {
      dispatch(viewAllvehicle(admin_id))
    },
    view_vehicles_delivery: (admin_id) => {
      dispatch(view_vehicles_delivery(admin_id))
    },
    viewAllOrder: (admin_id) => {
      dispatch(viewAllOrder(admin_id))
    },
    view_rto_reg: (admin_id) => {
      dispatch(view_rto_reg(admin_id))
    },
    view_pending_order: (admin_id) => {
      dispatch(view_pending_order(admin_id))
    },
    viewDashManufacturing: (admin_id) => {
      dispatch(viewDashManufacturing(admin_id))
    },
    view_vechicle_booking: (admin_id) => {
      dispatch(view_vechicle_booking(admin_id))
    },
    view_dashboard_statistitcs: (admin_id) => {
      dispatch(view_dashboard_statistitcs(admin_id))
    },
    view_all_quotation: (admin_id) => {
      dispatch(view_all_quotation(admin_id))
    },
    view_all_manufactured: (admin_id) => {
      dispatch(view_all_manufactured(admin_id))
    },
    view_all_noplate: (admin_id) => {
      dispatch(view_all_noplate(admin_id))
    },
    view_all_vechdash: (admin_id) => {
      dispatch(view_all_vechdash(admin_id))
    },
    dashConfirmvehicle: (_id,admin_id) => {
      dispatch(dashConfirmvehicle(_id,admin_id))
    },
    dashconvert_to_booking: (_id,admin_id,advance_amount,estimated_delivery_date,mode) => {
      dispatch(dashconvert_to_booking(_id,admin_id,advance_amount,estimated_delivery_date,mode))
    },
    dash_Rtoreg: (_id, challan_number, payment_slip,admin_id) => {
      dispatch(dash_Rtoreg(_id, challan_number, payment_slip,admin_id))
    },
    dashoutdeliveryvehicle: (_id,admin_id) => {
      dispatch(dashoutdeliveryvehicle(_id,admin_id))
    },
    Noplatevehicle: (_id,no_plate,admin_id) => {
      dispatch(Noplatevehicle(_id,no_plate,admin_id))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);