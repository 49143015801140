import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Controller from "../containers/controller_cont";
import Login from "../../auth/container/superadminCont";
import Dahboard from "../../pages/admin/dashboard/container/dashbordCont"
import Category from "../../pages/admin/category/container/categoryCont";
import Model from "../../pages/admin/model/container/modelCont";
import Variant from "../../pages/admin/variante/container/varianteCont";
import Color from "../../pages/admin/color/container/colorCont";
import Battery from "../../pages/admin/battery/container/batteryCont";
import Profile from "../../pages/user/container/profileCont"
import EditProfile from "../../pages/user/container/editProfilCont"
import Drawer_option_con from "../containers/drawer_cont";
import Employee from "../../pages/admin/empolyee/container/homeCont"
import Customer from "../../pages/admin/customer/container/customerCont";
import AddCustomer from "../../pages/admin/customer/container/addcustomerCont"
import EditCustomer from "../../pages/admin/customer/container/editcustomerCont"
import Order from "../../pages/admin/orders/container/orderCont";
import Service from "../../pages/admin/service/container/serviceCont";
import Vechicle from "../../pages/admin/vechicle/container/vechicleCont";
import Emporder from "../../pages/admin/orders/container/emporderCont";
import Empservice from "../../pages/admin/service/container/empserCont";
import AdminUser from "../../pages/admin/admin_user/container/admin_userCont";
import Attendance from "../../pages/admin/Attendance/component/attendance";
import AttendanceTL from "../../pages/admin/Attendance/component/attendenceTL";
import TaskManagement from "../../pages/admin/Taskmanagement/container/taskmanagementCont";
import Superadmintask from "../../pages/admin/Taskmanagement/container/superadminCont";
// import Drawer2 from "./Drawer2";
import Manufacturing from "../../pages/admin/dashboard/container/manufacturingdashconst";
import Orderdash from "../../pages/admin/dashboard/container/orderdashcont";
import Paymentdash from "../../pages/admin/dashboard/container/paymentdashcont";
import Registration from "../../pages/admin/dashboard/container/registrationdashcont";
import Numberplatedash from "../../pages/admin/dashboard/container/numberplatedashcont";
import Attendancedash from "../../pages/admin/dashboard/component/attendancedash";
import Vehicledash from "../../pages/admin/dashboard/container/vehicledashcont";
import Vehicleservicedash from "../../pages/admin/dashboard/container/vehicleservicedashconst";
import Allotedservice from "../../pages/admin/dashboard/container/allotedservicedashcont";
import Startpage from "../../pages/user/container/startpageCont";
import Addorder from "../../pages/admin/orders/container/addorderCont"
import Addservice from "../../pages/admin/service/container/addservicecont"
import Editservice from "../../pages/admin/service/component/editservice"
import Vehiclestatus from "../../pages/admin/dashboard/container/manufacturingdashconst"
// import Vehiclestatus from "../../pages/admin/dashboard/container/manufacturingdashconst";
import Addvarint from "../../pages/admin/variante/container/addvariantCont"
import Editvariant from "../../pages/admin/variante/container/editvariantcont"
import OrderStatus from "../../pages/user/components/orders";
import OrderStatusdown from "../../pages/user/components/ordersts";
import EditEmployee from "../../pages/admin/empolyee/container/editempCont";
import AddEmployee from "../../pages/admin/empolyee/container/addempCont";
import Bookingpdf from "../../pages/admin/orders/container/bookingpdfCont";
// import QUATION from "../../pages/admin/orders/container/quationpdfCont"
import Quation2 from "../../pages/admin/orders/container/qoutionCont"
// import Bookings from "../../pages/admin/orders/component/bookingpdf"
// import Dashvehicle from "../../pages/admin/vechicle/component/dashvehicle";
// import Editprofile from "../../pages/user/components/Edit_profile";
// import Myprofile from "../../pages/user/components/Profile";
import Leads from "../../pages/admin/leads/container/clientCont";
import AllLeads from "../../pages/admin/leads/container/TodaylclientsCont"
import SuperadminDashboard from "../../pages/admin/dashboard/component/dashboardnew"
import Teamlead from "../../pages/superadmin/container/teamleadCont"
import Department from "../../pages/superadmin/container/departmentCont"
import Addteamlead from "../../pages/superadmin/container/addteamleadCont"
import Editteamlead from "../../pages/superadmin/container/editteamleadCont"
import Superadminattendance from "../../pages/admin/Attendance/component/superadminattendance";
import Lead2 from "../../pages/admin/Leads2/Container/Leads2Cont"
import DelerUser from "../../pages/admin/Deler/container/delerUserCont"
import Deler from "../../pages/admin/Deler/container/delerCont"
import Dashboard from "../../pages/admin/dashboard/container/dashbordNewCont";
import Kiosk from "../../pages/admin/Kiosk/Container/KioskCont";
import TaskKiosk from "../../pages/admin/Kiosk/Container/Task_KioskCont";
import Policy from "../../pages/admin/Policy/Container/PolicyCont"


// import {} "react-express-graphql"
import Error from "../../404";

class Routers extends Component {
  render() {
    const { login, onLogout } = this.props;
    let body;

    if (login.isHome) {
      body = (
        <div>
          <main
            style={{
              flexGrow: 1,
            }}
          >
            <Controller />
            <Routes>
              {/* <Route exact path="/home" element={<Home />} /> */}

              <Route exact path="/" element={<Login />} />
              <Route exact path="/checkstatus" element={<OrderStatus />} />
              <Route exact path="/checkstatusdownload" element={< OrderStatusdown />} />
              <Route exact path="/Kiosk" element={<Kiosk />} />
              <Route exact path="/TaskKiosk" element={<TaskKiosk />} />

              <Route exact path="*" element={<Error />} />


            </Routes>

          </main>
        </div>
      );
    } else {
      if (login.type === "E") {
        body = (
          <div
            style={{
              display: "flex",
            }}
          >
            {/* <Drawer_option_con type={login.type} onLogout={onLogout} /> */}
            <main

            >
              <Controller />
              <Routes>

                {/* <Route path='/login' element={<Navigate to="/" />} /> */}
                {/* <Route exact path="/" element={<Login />} /> */}

                {/* <Route exact path="/" element={<Home />} /> */}
                {/* <Route path='/startpage' element={<Navigate to="/profile" />} /> */}
                <Route exact path="/" element={<Startpage />} />
                <Route exact path="/myprofile" element={<Profile />} />
                <Route exact path="/editProfile" element={<EditProfile />} />
                <Route exact path="/emporder" element={<Emporder />} />
                <Route exact path="/empservice" element={<Empservice />} />
                {/* <Route exact path="/" element={<Controller />} /> */}
                {/* <Route exact path='/myprofile' element={<Myprofile />} /> */}
                {/* <Route exact path="/" element={<Profile />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      else if (login.type === "A") {
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: 20,
                marginLeft: 250
              }}
            >
              <Controller />
              <Routes>
                <Route exact path="/" element={<Dahboard />} />
                <Route exact path="/category" element={<Category />} />
                <Route exact path="/Policy" element={<Policy />} />
                {/* <Route exact path="/Policy" element={<Policy />} /> */}


                
                <Route exact path="/model" element={<Model />} />
                <Route exact path="/variant" element={<Variant />} />
                <Route exact path="/color" element={<Color />} />
                <Route exact path="/battery" element={<Battery />} />
                <Route exact path="/employee" element={<Employee />} />
                <Route exact path="/customer" element={<Customer />} />
                <Route exact path="/order" element={<Order />} />
                {/* <Route exact path="/service" element={<Service />} /> */}
                <Route exact path="/vechicle" element={<Vechicle />} />
                {/* <Route exact path="/adminuser" element={<AdminUser />} /> */}
                <Route exact path="/manufacturingdash" element={<Manufacturing />} />
                <Route exact path="/orderdash" element={<Orderdash />} />
                <Route exact path="/paymentdash" element={<Paymentdash />} />
                <Route exact path="/registrationdash" element={<Registration />} />
                <Route exact path="/numberplatedash" element={<Numberplatedash />} />
                <Route exact path="/attendancedash" element={<Attendancedash />} />
                <Route exact path="/vehicledash" element={<Vehicledash />} />
                <Route exact path="/vehicleservicedash" element={<Vehicleservicedash />} />
                <Route exact path="/allotedservice" element={<Allotedservice />} />
                <Route exact path="/attendance" element={<Attendance />} />
                <Route exact path="/attendancetl" element={<AttendanceTL />} />
              
                <Route exact path="/addorder" element={<Addorder />} />
                <Route exact path="/addservice" element={<Addservice />} />
                <Route exact path="/editservice" element={<Editservice />} />
                <Route exact path="/manufacturingdash" element={<Manufacturing />} />
                <Route exact path="/orderdash" element={<Orderdash />} />
                <Route exact path="/paymentdash" element={<Paymentdash />} />
                {/* <Route exact path="/registration" element={<Registrationdash />} /> */}
                <Route exact path="/numberplatedash" element={<Numberplatedash />} />
                <Route exact path="/attendancedash" element={<Attendancedash />} />
                {/* <Route exact path="/vehicledash" element={<Vehicledash />} /> */}
                <Route exact path="/vehicleservicedash" element={<Vehicleservicedash />} />
                <Route exact path="/allotedservice" element={<Allotedservice />} />
                <Route exact path="/attendance" element={<Attendance />} />
                <Route exact path="/vehiclestatus" element={<Vehiclestatus />} />
                <Route exact path="/addvarint" element={<Addvarint />} />
                <Route exact path="/editvariant" element={<Editvariant />} />
                <Route exact path="/addcustomer" element={<AddCustomer />} />
                <Route exact path="/editcustomer" element={<EditCustomer />} />
                {/* <Route exact path="/addemployee" element={<AddEmployee />} />
                <Route exact path="/editemployee" element={<EditEmployee />} /> */}
                {/* <Route exact path="/quoation" element={< QUATION/>} /> */}
                <Route exact path="/quoation2" element={< Quation2 />} />
                <Route exact path="/bookings" element={< Bookingpdf />} />
                <Route exact path="/lead" element={< Leads />} />
                <Route exact path="/todayslead" element={< AllLeads />} />
                {/* <Route exact path="/superadmintask" element={<TaskManagement />} /> */}
                <Route exact path="/superadminattendance" element={<Superadminattendance />} />

                <Route exact path="/taskmanagement" element={<TaskManagement />} />
                {/* <Route exact path="/" element={<Vehicle/>} /> */}
                <Route exact path="/superadmintask" element={<Superadmintask/>} />

                {/* <Route path='/superadminlogin' element={<Navigate to="/" />} />
                <Route exact path="/department" element={<Department />} /> */}
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      else if (login.type === "SA") {
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
             style={{
              flexGrow: 1,
              marginTop: 20,
              marginLeft: 230
            }}
            >
              <Controller />
              <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/category" element={<Category />} />
                {/* <Route exact path="/Kiosk" element={<Kiosk />} /> */}

                <Route exact path="/model" element={<Model />} />
                <Route exact path="/variant" element={<Variant />} />
                <Route exact path="/editvariant" element={<Editvariant />} />
                <Route exact path="/addvarint" element={<Addvarint />} />
                <Route exact path="/color" element={<Color />} />
                <Route exact path="/Policy" element={<Policy />} />
                <Route exact path="/Kiosk" element={<Kiosk />} />
                <Route exact path="/TaskKiosk" element={<TaskKiosk />} />

                <Route exact path="/battery" element={<Battery />} />
                <Route exact path="/adminuser" element={<AdminUser />} />
                <Route exact path="/employee" element={<Employee />} />
                <Route exact path="/addemployee" element={<AddEmployee />} />
                <Route exact path="/editemployee" element={<EditEmployee />} />
                <Route exact path="/vechicle" element={<Vechicle />} />
                <Route exact path="/deler" element={<Deler />} />
                <Route exact path="/addteamlead" element={<Addteamlead />} />
                <Route exact path="/editteamlead" element={<Editteamlead />} />
                <Route exact path="/taskmanagement" element={<TaskManagement />} />
                {/* <Route exact path="/" element={<Vehicle/>} /> */}
                <Route exact path="/superadmintask" element={<Superadmintask/>} />
                <Route exact path="/attendance" element={<Attendance />} />
                <Route exact path="/attendancetl" element={<AttendanceTL />} />
                <Route exact path="/superadminattendance" element={<Superadminattendance />} />
                <Route exact path="/service" element={<Service />} />

              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      else if (login.type === "D") {
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
             style={{
              flexGrow: 1,
              marginTop: 20,
              marginLeft: 230
            }}
            >
              <Controller />
              <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/Kiosk" element={<Kiosk />} />
                <Route exact path="/TaskKiosk" element={<TaskKiosk />} />
                <Route exact path="/category" element={<Category />} />
                <Route exact path="/model" element={<Model />} />
                <Route exact path="/variant" element={<Variant />} />
                <Route exact path="/color" element={<Color />} />
                <Route exact path="/battery" element={<Battery />} />
                <Route exact path="/employee" element={<Employee />} />
                <Route exact path="/customer" element={<Customer />} />
                <Route exact path="/order" element={<Order />} />
                <Route exact path="/service" element={<Service />} />
                <Route exact path="/vechicle" element={<Vechicle />} />
                {/* <Route exact path="/adminuser" element={<AdminUser />} /> */}
                <Route exact path="/manufacturingdash" element={<Manufacturing />} />
                <Route exact path="/orderdash" element={<Orderdash />} />
                <Route exact path="/paymentdash" element={<Paymentdash />} />
                <Route exact path="/registrationdash" element={<Registration />} />
                <Route exact path="/numberplatedash" element={<Numberplatedash />} />
                <Route exact path="/attendancedash" element={<Attendancedash />} />
                <Route exact path="/vehicledash" element={<Vehicledash />} />
                <Route exact path="/vehicleservicedash" element={<Vehicleservicedash />} />
                <Route exact path="/allotedservice" element={<Allotedservice />} />
                <Route exact path="/attendance" element={<Attendance />} />
                <Route exact path="/attendancetl" element={<AttendanceTL />} />
              
                <Route exact path="/addorder" element={<Addorder />} />
                <Route exact path="/addservice" element={<Addservice />} />
                <Route exact path="/editservice" element={<Editservice />} />
                <Route exact path="/manufacturingdash" element={<Manufacturing />} />
                <Route exact path="/orderdash" element={<Orderdash />} />
                <Route exact path="/paymentdash" element={<Paymentdash />} />
                {/* <Route exact path="/registration" element={<Registrationdash />} /> */}
                <Route exact path="/numberplatedash" element={<Numberplatedash />} />
                <Route exact path="/attendancedash" element={<Attendancedash />} />
                {/* <Route exact path="/vehicledash" element={<Vehicledash />} /> */}
                <Route exact path="/vehicleservicedash" element={<Vehicleservicedash />} />
                <Route exact path="/allotedservice" element={<Allotedservice />} />
                <Route exact path="/attendance" element={<Attendance />} />
                <Route exact path="/vehiclestatus" element={<Vehiclestatus />} />
                <Route exact path="/addvarint" element={<Addvarint />} />
                <Route exact path="/editvariant" element={<Editvariant />} />
                <Route exact path="/addcustomer" element={<AddCustomer />} />
                <Route exact path="/editcustomer" element={<EditCustomer />} />
                {/* <Route exact path="/addemployee" element={<AddEmployee />} />
                <Route exact path="/editemployee" element={<EditEmployee />} /> */}
                {/* <Route exact path="/quoation" element={< QUATION/>} /> */}
                <Route exact path="/quoation2" element={< Quation2 />} />
                <Route exact path="/bookings" element={< Bookingpdf />} />
                <Route exact path="/lead" element={< Leads />} />
                <Route exact path="/todayslead" element={< AllLeads />} />
                <Route exact path="/delaruser" element={<DelerUser />} />
                <Route exact path="/superadminattendance" element={<Superadminattendance />} />

                <Route exact path="/taskmanagement" element={<TaskManagement />} />
                {/* <Route exact path="/" element={<Vehicle/>} /> */}
                <Route exact path="/superadmintask" element={<Superadmintask/>} />

              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      else if (login.type === "DU") {
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
             style={{
              flexGrow: 1,
              marginTop: 20,
              marginLeft: 230
            }}
            >
              <Controller />
              <Routes>
              <Route exact path="/" element={<Dahboard />} />
                <Route exact path="/category" element={<Category />} />
                <Route exact path="/model" element={<Model />} />
                <Route exact path="/variant" element={<Variant />} />
                <Route exact path="/color" element={<Color />} />
                <Route exact path="/battery" element={<Battery />} />
                <Route exact path="/employee" element={<Employee />} />
                <Route exact path="/customer" element={<Customer />} />
                <Route exact path="/order" element={<Order />} />
                <Route exact path="/service" element={<Service />} />
                <Route exact path="/vechicle" element={<Vechicle />} />
                {/* <Route exact path="/adminuser" element={<AdminUser />} /> */}
                <Route exact path="/manufacturingdash" element={<Manufacturing />} />
                <Route exact path="/orderdash" element={<Orderdash />} />
                <Route exact path="/paymentdash" element={<Paymentdash />} />
                <Route exact path="/registrationdash" element={<Registration />} />
                <Route exact path="/numberplatedash" element={<Numberplatedash />} />
                <Route exact path="/attendancedash" element={<Attendancedash />} />
                <Route exact path="/vehicledash" element={<Vehicledash />} />
                <Route exact path="/vehicleservicedash" element={<Vehicleservicedash />} />
                <Route exact path="/allotedservice" element={<Allotedservice />} />
                <Route exact path="/attendance" element={<Attendance />} />
                <Route exact path="/attendancetl" element={<AttendanceTL />} />
              
                <Route exact path="/addorder" element={<Addorder />} />
                <Route exact path="/addservice" element={<Addservice />} />
                <Route exact path="/editservice" element={<Editservice />} />
                <Route exact path="/manufacturingdash" element={<Manufacturing />} />
                <Route exact path="/orderdash" element={<Orderdash />} />
                <Route exact path="/paymentdash" element={<Paymentdash />} />
                {/* <Route exact path="/registration" element={<Registrationdash />} /> */}
                <Route exact path="/numberplatedash" element={<Numberplatedash />} />
                <Route exact path="/attendancedash" element={<Attendancedash />} />
                {/* <Route exact path="/vehicledash" element={<Vehicledash />} /> */}
                <Route exact path="/vehicleservicedash" element={<Vehicleservicedash />} />
                <Route exact path="/allotedservice" element={<Allotedservice />} />
                <Route exact path="/attendance" element={<Attendance />} />
                <Route exact path="/vehiclestatus" element={<Vehiclestatus />} />
                <Route exact path="/addvarint" element={<Addvarint />} />
                <Route exact path="/editvariant" element={<Editvariant />} />
                <Route exact path="/addcustomer" element={<AddCustomer />} />
                <Route exact path="/editcustomer" element={<EditCustomer />} />
                {/* <Route exact path="/addemployee" element={<AddEmployee />} />
                <Route exact path="/editemployee" element={<EditEmployee />} /> */}
                {/* <Route exact path="/quoation" element={< QUATION/>} /> */}
                <Route exact path="/quoation2" element={< Quation2 />} />
                <Route exact path="/bookings" element={< Bookingpdf />} />
                <Route exact path="/lead" element={< Leads />} />
                <Route exact path="/todayslead" element={< AllLeads />} />
                {/* <Route exact path="/superadmintask" element={<TaskManagement />} /> */}
                <Route exact path="/superadminattendance" element={<Superadminattendance />} />

                <Route exact path="/taskmanagement" element={<TaskManagement />} />
                {/* <Route exact path="/" element={<Vehicle/>} /> */}
                <Route exact path="/superadmintask" element={<Superadmintask/>} />

              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      else if (login.type === "DM") {
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <main
             style={{
              flexGrow: 1,
              marginTop: 20,
            }}
            >
              <Controller />
              <Routes>
              <Route exact path="/" element={<Lead2/>} />
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        );
      }
      // else if (login.type == "") {
      //   body = (
      //     <div>
      //       {/* <Drawer_option_con
      //                       type={login.type}
      //                       onLogout={onLogout} /> */}
      //       {/* <main
      //                   style={{
      //                       // flexGrow: 1
      //                   }}> */}
      //       <Loginnav />
      //       <Regis />
      //       <Footer />
      //       {/* <Routes> */}
      //       {/* <Route exact path="/login" component={Login} /> */}
      //       {/* <Route exact path="/login" component={Login} /> */}
      //       {/* <Route path="/" element={Controller} /> */}
      //       {/* ADMIN ROUTES */}
      //       {/* STUDENT ROUTES */}
      //       {/* <Route exact path="/category" component={Category}/> */}
      //       {/* <Route exact path="/request" component={Request}/> */}
      //       {/* <Route exact activeClassName="active" path="/" component={Dashboard}/> */}
      //       {/* <Route exact path="/" component={Users}/>
      //                   <Route exact path="/lead" component={Lead}/> */}
      //       {/* <Route exact path="/" component={AddLead}/> */}
      //       {/* </Routes> */}
      //       {/* </main> */}
      //       {/* <Footer /> */}
      //     </div>
      //   );
      // }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;
