import React, { Component } from "react";
import {
    Grid,
    // Card,
    CardContent,
    Typography,
    Box,
    IconButton,
    Icon,
    TextField,
    DialogActions,
    DialogTitle,
    Tooltip
} from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import "../../style/order.css";
import Snackbar from "../../../../common/snackbar/components/snackbar";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));
export default class model extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            model_name:"",
            speed:"",
            category_id:"",
            filterValue: this.props.all_model.all_model,
        };
    }
    componentDidMount() {
        this.props.viewModel();
        this.props.viewAllCategory();
    }
     handleChange = (e) => {
        // const el=e.target.value
        if (e.target.value === "") {
        //   this.setState({ filterValue: this.props.all_model.all_model })
          this.props.set_filtered_value(this.props.model.all_model);
        }
        else {
          const newArray = this.props.all_model.all_model.filter((el) => {
            return (el.model_name.toLowerCase().includes(e.target.value.toLowerCase()))
          })
          this.props.set_filtered_value(newArray);
        //   this.setState({ filterValue: newArray })
        }
        // console.log(this.state.filterValue)x
      }
    //   console.log(this.state.model_name);

    render() {
        const {
            all_model,
            all_category,
            snackbar,
            close_snack_bar,
            setFilter
        } = this.props;
        

        
      
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className="ordername">
                        <center>
                            <Button style={{ color: "white ", textAlign: "center" }}>
                                Model
                            </Button>
                        </center>
                    </Box>

                    <center style={{ borderRadius: 0 }}>
                        <Button
                            className="orderadd"
                            style={{ color: "white ", textAlign: "center" }}
                            onClick={() => {
                                this.setState({
                                    add: true,
                                });
                            }}
                        >
                            Add Model
                        </Button>
                    </center>
                </Grid>
                <Grid container direction="row" justifyContent="space-between"
                    style={{ paddingLeft: 48, paddingRight: 64 }}>
                    <Grid item lg={4}>
                        <Search
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 52,
                                width: "300px",
                                border: "2px solid #000000"
                            }}
                            direction="row"
                        >
                            <SearchIconWrapper>
                                <SearchIcon style={{ color: "black" }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search Model  "
                                color="black"
                                inputProps={{ "aria-label": "search" }}
                                style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                            onChange={(e)=>this.props.searchModel(all_model.all_model,e)}
                            />
                        </Search>
                    </Grid>
                    <Grid class="register-form-custom-select" style={{ marginTop: 5, }} >
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 48,
                                width: "200px",
                                border: "2px solid #000000"
                            }}
                            name=""
                            // value={this.state.eventName}
                            select
                        onChange={(e)=>{this.props.filter_category(all_model.all_model,e.target.value)}}
                        >

                            <option value="">All Category</option>
                            {all_category.all_category.map((option) => (
                                        <option key={option._id} value={option._id}>
                                            {option.category_name}
                                        </option>
                                    ))}
                           
                        </select>
                        


                    </Grid>
                </Grid>

                <CardContent style={{ marginLeft: "40px", marginRight: "65px" }}>
                    <TableContainer component={Paper}>
                        <Table
                            style={{ minWidth: 600 }}
                            size="small"
                            aria-label="a dense table"
                            ref={this.tableRef}
                        >
                            <TableHead className="orderhead">
                                <TableRow>
                                    {/* <TableCell>Category name</TableCell> */}
                                    <TableCell>Model Name</TableCell>
                                    <TableCell>Speed</TableCell>
                                    <TableCell style={{ marginLeft: "-80px" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="orderbody">
                                {all_model.filtered_model.map((allmodel) => {
                                    return (
                                        <TableRow
                                            // key={row.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            {/* <TableCell >{allmodel.category}</TableCell> */}
                                            <TableCell>{allmodel.model_name}</TableCell>
                                            <TableCell>{allmodel.speed}</TableCell>
                                            <TableCell>

                                            <Tooltip title="Edit">
                                                <IconButton
                                                    style={{ marginLeft: "-5px" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            edit: true,
                                                            id: allmodel._id,
                                                            category_id: allmodel.category_id,
                                                            model_name: allmodel.model_name,
                                                            speed: allmodel.speed,
                                                        });
                                                    }}
                                                >
                                                    <EditOutlinedIcon style={{ color: "green" }} />
                                                </IconButton>
                                                </Tooltip>
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            delete: true,
                                                            id: allmodel._id,
                                                        });
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon style={{ color: "red" }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>

                {/* <Dialog
          open={this.state.view}
          // onClose={() => { this.setState({ view: false }) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography style={{ color: "blue", fontSize: 25 }}>
              Category Deatils
            </Typography>

            <IconButton
              color="primary"
              style={{ marginLeft: "93%", marginTop: "-15%" }}
              onClick={() => {
                this.setState({
                  view: false,
                  // _id: c._id,
                  // color_name: c.color_name,
                  // color_code: c.color_code,
                });
              }}
            >
              <CloseIcon style={{ color: "blue" }} />
            </IconButton>
          </DialogTitle>

          <Grid
            lg={8}
            style={{ display: "flex", flexDirection: "row", width: "600px" }}
          >
            

            <Grid style={{ marginLeft: "25px", marginTop: "-30px" }}>
              <Grid item style={{ display: "flex", width: 200 }}>
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>
                  Category Name:
                </Typography>
                <Typography style={{ fontSize: 15 }}>
                  &nbsp;{this.state.category_name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Dialog> */}

                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                    > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained" onClick={() => { this.props.deleteModel(this.state.id); this.setState({ delete: false }) }}>

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.add}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    // style={{wi}}
                    style={
                        {
                            // top: "50%",
                            // left: "10%",
                            // right: "auto",
                            // bottom: "auto",
                            // marginRight: "-50%",
                            // transform: "translate(-50%, -50%)",
                            // width: "1000px",
                            // marginLeft: "500px",
                            // paddingTop: "40px",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            // borderRadius: "0px",
                            height: "700px",
                            // zIndex: "200",
                            // backgroundColor: "#fff"
                        }}
                >
                    <Grid className="orderbodys" style={{ backgroundColor: "#fff", height: "300px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid style={{ marginBottom: "60px" }}>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    id="outlined-name-input"
                                    countryCodeEditable={false}
                                    label="Category Name"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    style={{ height: "50px", width: "280px" }}
                                    required={true}
                                    // value={this.state.category_name}
                                    onChange={(e) => this.setState({ category_id: e.target.value })}
                                    select
                                >
                                    {all_category.all_category.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.category_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="MODEL NAME"
                                    // value={this.state.name}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ model_name: e.target.value }) }}
                                />

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="SPEED"
                                    // value={this.state.name}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ speed: e.target.value }) }}
                                />

                            </div>

                            <Button className="btns"
                                disabled={this.state.category_id === "" || this.state.model_name === "" || this.state.speed === ""}
                                onClick={() => {
                                    this.props.createModel(this.state.category_id, this.state.model_name, this.state.speed);
                                    this.setState({ add: false });
                                }}
                                style={{ marginLeft: "20px", color: "white", marginBottom: "30px", marginTop: "0px", }}>Add</Button>
                            <Button className="btns" onClick={() => {
                                this.setState({ add: false });
                            }} style={{ marginLeft: "20px", color: "white", marginBottom: "30px", marginTop: "0px" }}>Close</Button>

                        </Grid>

                    </Grid>

                </Dialog>
                <Dialog
                    open={this.state.edit}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    // style={{wi}}
                    style={
                        {
                            // top: "50%",
                            // left: "10%",
                            // right: "auto",
                            // bottom: "auto",
                            // marginRight: "-50%",
                            // transform: "translate(-50%, -50%)",
                            // width: "1000px",
                            // marginLeft: "500px",
                            // paddingTop: "40px",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            // borderRadius: "0px",
                            height: "700px",
                            // zIndex: "200",
                            // backgroundColor: "#fff"
                        }}
                >
                    <Grid className="orderbodys" style={{ backgroundColor: "#fff", height: "300px", minWidth: "320px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>
                                <TextField
                                    id="outlined-name-input"
                                    countryCodeEditable={false}
                                    label="Category Name"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={true}
                                    select
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.category_id}
                                    // value={this.state.category_id}
                                    defaultValue={this.state.category_id}
                                    onChange={(e) => this.setState({ category_id: e.target.value })}

                                >
                                    {/* <MenuItem key={""} value={""}>
                                        Select a Category
                                     </MenuItem> */}
                                    {all_category.all_category.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.category_name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Model "
                                    value={this.state.model_name}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ model_name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Speed "
                                    value={this.state.speed}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ speed: e.target.value }) }}
                                />

                            </div>

                            <Button className="btns"
                                disabled={this.state.category_id === "" || this.state.model_name === "" || this.state.speed === ""}

                                onClick={() => {

                                    this.props.updateMOdal(this.state.id, this.state.category_id, this.state.model_name, this.state.speed);

                                    this.setState({ edit: false });
                                }}
                                style={{ marginLeft: "20px", color: "white", marginBottom: "30px", marginTop: "0px" }}>Update</Button>
                            <Button className="btns" onClick={() => {
                                this.setState({ edit: false });
                            }} style={{ marginLeft: "20px", color: "white", marginBottom: "30px", marginTop: "0px" }}>Close</Button>

                        </Grid>

                    </Grid>
                </Dialog>



                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />
            </Grid>
            // <div>
            //     {/* model */}
            //     <Box
            //         marginLeft={'5%'}
            //         // marginTop={"%"}
            //         marginRight={"5%"}
            //     >
            //         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
            //             <Button style={{ marginLeft: 14, backgroundColor: "ButtonFace   " }} onClick={() => {
            //                 this.setState({
            //                     add: true,
            //                     // id: allusers._id,
            //                 })
            //             }}>ADD

            //                 <AddIcon style={{ marginTop: 0, color: "blue" }} />
            //             </Button>
            //         </Grid>

            //         <CardContent>
            //             <TableContainer
            //                 component={Paper}
            //             >
            //                 <Table
            //                     style={{ minWidth: '10%' }}
            //                     size="small"
            //                     aria-label="a dense table"
            //                     ref={this.tableRef}
            //                 >
            //                     <TableHead>
            //                         <TableRow>
            //                             <Typography style={{ fontSize: 20, color: "blue", marginLeft: "10px", fontWeight: "bold" }}>ALL MODEL</Typography>

            //                         </TableRow>
            //                         <TableRow>
            //                             {/* <TableCell>Model ID</TableCell> */}
            //                             {/* <TableCell>Category ID</TableCell> */}
            //                             <TableCell>Model Name</TableCell>
            //                             <TableCell>Speed</TableCell>
            //                             <TableCell>Action</TableCell>
            //                         </TableRow>

            //                     </TableHead>
            //                     {all_model.all_model.map((allmodel) => {
            //                         return (
            //                             <TableBody>
            //                                 <TableRow
            //                                     sx={{
            //                                         "&:last-child td, &:last-child th": { border: 0 },
            //                                     }}
            //                                 >
            //                                     {/* <TableCell >{allmodel._id}</TableCell> */}
            //                                     {/* <TableCell >{allmodel.category_id}</TableCell> */}
            //                                     <TableCell >{allmodel.model_name}</TableCell>
            //                                     <TableCell >{allmodel.speed}</TableCell>

            //                                     {/* <TableCell >{allusers.type}</TableCell> */}
            //                                     <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", }}>
            //                                         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
            //                                             {/* //edit */}
            //                                             <IconButton
            //                                                 onClick={() => {
            //                                                     this.setState({
            //                                                         edit: true,
            //                                                         id: allmodel._id,
            //                                                         category_id: allmodel.category_id,
            //                                                         model_name: allmodel.model_name,
            //                                                         speed: allmodel.speed,

            //                                                         // name: allusers.name,
            //                                                         // mobile: allusers.mobile,
            //                                                         // email: allusers.email,
            //                                                         // dob: allusers.dob,
            //                                                         // gender: allusers.gender,
            //                                                         // p_address: allusers.p_address,
            //                                                         // pr_address: allusers.pr_address,
            //                                                         // password: allusers.password,
            //                                                     })
            //                                                 }}
            //                                             >
            //                                                 <EditIcon style={{ color: 'green' }}
            //                                                 />
            //                                             </IconButton>

            //                                         </Grid>
            //                                         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 40 }}>
            //                                             <IconButton onClick={() => { this.setState({ delete: true }) }}>

            //                                                 <DeleteRoundedIcon style={{ color: 'red' }}
            //                                                     onClick={() => {
            //                                                         this.setState({
            //                                                             delete: true,
            //                                                             id: allmodel._id,
            //                                                         })
            //                                                     }}
            //                                                 />
            //                                             </IconButton>
            //                                         </Grid>
            //                                         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 40 }}>
            //                                             <IconButton onClick={() => {
            //                                                 this.setState({
            //                                                     view: true,

            //                                                 })
            //                                             }}
            //                                             >

            //                                                 {/* <VisibilityIcon
            //                                             style={{ color: "Blue", pointerEvents: "visiblePainted" }}
            //                                         // onClick={() => {
            //                                         //     this.setState({
            //                                         //         view: true,
            //                                         //         profile_pic: allusers.profile_pic,
            //                                         //         gender: allusers.gender,
            //                                         //         dob: allusers.dob,
            //                                         //         p_address: allusers.p_address,
            //                                         //         pr_address: allusers.pr_address
            //                                         //     })
            //                                         // }}
            //                                         /> */}
            //                                             </IconButton>
            //                                         </Grid>

            //                                     </Grid>

            //                                 </TableRow>

            //                             </TableBody>
            //                         );
            //                     })}
            //                 </Table>

            //             </TableContainer>
            //         </CardContent>
            //     </Box>

            //     
            //     <Dialog
            //         open={this.state.delete}
            //         onClose={() => {
            //             this.setState({ delete: false });
            //         }}
            //         // style={{wi}}
            //         style={
            //             {
            //                 // top: "50%",
            //                 // left: "50%",
            //                 // right: "auto",
            //                 // bottom: "auto",
            //                 // marginRight: "-50%",
            //                 // transform: "translate(-50%, -50%)",
            //                 // width: "470px",
            //                 // marginLeft: "500px",
            //                 // paddingTop: "40px",
            //                 // paddingLeft: "25px",
            //                 // paddingRight: "25px",
            //                 // borderRadius: "0px",
            //                 // height: "300px",
            //                 // zIndex: "200",
            //                 // backgroundColor: "#fff"
            //             }}

            //     >
            //         <Box style={{ backgroundColor: "#fff", height: "100px", width: "350px" }}>
            //             <Typography

            //                 style={{ fontSize: "16px", textAlign: 'center' }}
            //             >
            //                 Are You Sure ?
            //             </Typography>
            //             <Grid sx={{ marginLeft: "32%", marginTop: "8%" }}>
            //                 <Button
            //                     onClick={() => { this.props.deleteModel(this.state.id); this.setState({ delete: false }) }}
            //                 >Yes</Button>
            //                 <Button onClick={() => {
            //                     this.setState({ delete: false });
            //                 }}>No</Button>
            //             </Grid>

            //         </Box>

            //     </Dialog>
            //     <Dialog
            //         open={this.state.add}
            //         // onClose={() => {
            //         //     this.setState({ add: false });
            //         // }}
            //         // style={{wi}}
            //         style={
            //             {
            //                 // top: "50%",
            //                 // left: "10%",
            //                 // right: "auto",
            //                 // bottom: "auto",
            //                 // marginRight: "-50%",
            //                 // transform: "translate(-50%, -50%)",
            //                 // width: "1000px",
            //                 // marginLeft: "500px",
            //                 // paddingTop: "40px",
            //                 // paddingLeft: "25px",
            //                 // paddingRight: "25px",
            //                 // borderRadius: "0px",
            //                 height: "700px",
            //                 // zIndex: "200",
            //                 // backgroundColor: "#fff"
            //             }}
            //     >
            //         <Grid style={{ backgroundColor: "#fff", height: "300px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
            //             <Grid>
            //                 <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

            //                     <TextField
            //                         id="outlined-name-input"
            //                         countryCodeEditable={false}
            //                         label="Category Name"
            //                         type="text"
            //                         fullWidth={true}
            //                         margin="normal"
            //                         variant="outlined"
            //                         style={{ height: "50px", width: "280px" }}
            //                         required={false}
            //                         value={this.state.category_name}
            //                         onChange={(e) => this.setState({ category_id: e.target.value })}
            //                         select
            //                     >
            //                         {all_category.all_category.map((option) => (
            //                             <MenuItem key={option._id} value={option._id}>
            //                                 {option.category_name}
            //                             </MenuItem>
            //                         ))}
            //                     </TextField>
            //                 </div>

            //                 <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                     <TextField
            //                         required
            //                         id="outlined-required"
            //                         label="MODEL NAME"
            //                         // value={this.state.name}
            //                         InputLabelProps={{ shrink: true, required: true }}
            //                         style={{ height: "50px", width: "280px" }}
            //                         onChange={(e) => { this.setState({ model_name: e.target.value }) }}
            //                     />

            //                 </div>

            //                 <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                     <TextField
            //                         required
            //                         id="outlined-required"
            //                         label="SPEED"
            //                         // value={this.state.name}
            //                         InputLabelProps={{ shrink: true, required: true }}
            //                         style={{ height: "50px", width: "280px" }}
            //                         onChange={(e) => { this.setState({ speed: e.target.value }) }}
            //                     />

            //                 </div>

            //                 <Button
            //                     onClick={() => {
            //                         this.props.createModel(this.state.category_id, this.state.model_name, this.state.speed);
            //                         this.setState({ add: false });
            //                     }}
            //                     style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Add</Button>
            //                 <Button onClick={() => {
            //                     this.setState({ add: false });
            //                 }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Close</Button>

            //             </Grid>

            //         </Grid>

            //     </Dialog>
            //     <LoaderCon />

            // </div>
        );
    }
}
