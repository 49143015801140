import React, { Component } from "react";
import { connect } from "react-redux";
import Superadmin from "../Componenet/superadmintask";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";

import {
  createtask,
  viewAlltask,
  viewAlltask_by_type,
  updateTaskprogress,
  viewAllUser,
  viewAlltaskGivenBy,
  viewAlltaskGivenTo,
  update_task_status,
  update_task_response
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Superadmin {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    // user: store.user
    all_color: store.all_color,
    snackbar: store.snackbar,
    login: store.login,
    taskmanagement: store.taskmanagement,


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createtask: (given_by, given_to, date, desc, page_number, rowper_page) => {
      dispatch(createtask(given_by, given_to, date, desc, page_number, rowper_page))
    },
    viewAlltask: () => {
      dispatch(viewAlltask())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAlltaskGivenBy: (given_by, page_number, rowper_page) => {
      dispatch(viewAlltaskGivenBy(given_by, page_number, rowper_page))
    },
    viewAlltaskGivenTo: (given_to, page_number, rowper_page) => {
      dispatch(viewAlltaskGivenTo(given_to, page_number, rowper_page))
    },
    viewAlltask_by_type: (type) => {
      dispatch(viewAlltask_by_type(type))
    },
    updateTaskprogress: (id, process, status) => {
      dispatch(updateTaskprogress(id, process, status))
    },
    update_task_response: (id, response, given_by, page_number, rowper_page) => {
      dispatch(update_task_response(id, response, given_by, page_number, rowper_page))
    },
    update_task_status: (id, response, given_by, page_number, rowper_page) => {
      dispatch(update_task_status(id, response, given_by, page_number, rowper_page))
    },
    viewAllUser: () => {
      dispatch(viewAllUser())
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);