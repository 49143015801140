import {
    SET_CUSTOMER,
    SET_CUSTOMER_DETAIL,
    SET_CUSTOMERID,
    SET_PROFILEPIC,
    SET_CUSTOMERNAME,
    SET_CUSTOMERMOBILE,
    SET_CUSTOMERMAIL,
    SET_CUSTOMERADDRESS,
    SET_CUSTOMERGSTNO,
    SET_CUSTOMERAADHAR,
    SET_CUSTOMERPAN,
    SET_CUSTOMERGSTCERTIFICATE,
    SET_CUSTOMEROTHER1,
    SET_CUSTOMEROTHER2,
    SET_ALL_CUSTOMER
} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
// import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import 'firebase/storage';
import { set_snack_bar } from "../../../common/snackbar/action";
// import firebase from 'firebase/app'

export function add_upload_profile(admin_id, name, mobile, email, address, aadhar, pan, gst, profile_pic, cust_gst_no, other_doc1, other_doc2) {
    return dispatch => {
        // console.log(id)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/Profile/" + name + ".png")
        const uploadTask = uploadBytesResumable(storageRef, profile_pic);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                    console.log(profile_pic)
                    // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst_cert, downloadURL));
                    const storageRef = ref(getStorage(), "/Aadhar/" + name + ".png")
                    const uploadaadhar = uploadBytesResumable(storageRef, aadhar);
                    uploadaadhar.on(
                        "state_changed",
                        function (snapshot) {
                        },
                        function (error) {
                            console.log(error)
                            //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                            dispatch(setLoader(false));
                        },
                        function () {
                            getDownloadURL(uploadaadhar.snapshot.ref).then(function (aadhar) {
                                console.log(aadhar)
                                // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst_cert, downloadURL));
                                const storageRef = ref(getStorage(), "/Pan/" + name + ".png")
                                const uploadpan = uploadBytesResumable(storageRef, pan);
                                uploadpan.on(
                                    "state_changed",
                                    function (snapshot) {
                                    },
                                    function (error) {
                                        console.log(error)
                                        //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                        dispatch(setLoader(false));
                                    },
                                    function () {
                                        getDownloadURL(uploadpan.snapshot.ref).then(function (pan) {
                                            console.log(pan)
                                            // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst_cert, downloadURL));
                                            const storageRef = ref(getStorage(), "/Gst/" + name + ".pdf")
                                            const uploadgst = uploadBytesResumable(storageRef, gst);
                                            uploadgst.on(
                                                "state_changed",
                                                function (snapshot) {
                                                },
                                                function (error) {
                                                    console.log(error)
                                                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                                    dispatch(setLoader(false));
                                                },
                                                function () {
                                                    getDownloadURL(uploadgst.snapshot.ref).then(function (gst) {
                                                        console.log(other_doc1)
                                                        const storageRef = ref(getStorage(), "/other/" + name + ".pdf")
                                                        const uploadgst = uploadBytesResumable(storageRef, other_doc1);
                                                        uploadgst.on(
                                                            "state_changed",
                                                            function (snapshot) {
                                                            },
                                                            function (error) {
                                                                console.log(error)
                                                                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                                                dispatch(setLoader(false));
                                                            },
                                                            function () {
                                                                getDownloadURL(uploadgst.snapshot.ref).then(function (other_doc1) {
                                                                    console.log(other_doc2)
                                                                    const storageRef = ref(getStorage(), "/other/" + name + ".pdf")
                                                                    const uploadgst = uploadBytesResumable(storageRef, other_doc2);
                                                                    uploadgst.on(
                                                                        "state_changed",
                                                                        function (snapshot) {
                                                                        },
                                                                        function (error) {
                                                                            console.log(error)
                                                                            //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                                                            dispatch(setLoader(false));
                                                                        },
                                                                        function () {
                                                                            getDownloadURL(uploadgst.snapshot.ref).then(function (other_doc2) {
                                                                                console.log(gst)
                                                                                dispatch(createCustomer(admin_id, name, mobile, email, address, aadhar, pan, gst, profile_pic, cust_gst_no, other_doc1, other_doc2));
                                                                            });
                                                                        }
                                                                    );
                                                                    // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst, profile_pic));
                                                                });
                                                            }
                                                        );
                                                        // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst, profile_pic));
                                                    });
                                                }
                                            );
                                        });
                                    }
                                );
                            });
                        }
                    );
                });
            }
        );

    }
}

export function edit_upload_profile(_id,admin_id, name, mobile, email, address, gst_no, old_aadhar, aadhar, old_pan, pan, old_gst_cert, gst_cert, old_profile_pic, profile_pic, old_other_doc1, other_doc1, old_other_doc2, other_doc2) {
    return dispatch => {
        // console.log(id)
        dispatch(setLoader(true));
        if (profile_pic == null) {
            dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, gst_no, aadhar, pan, gst_cert, old_profile_pic, other_doc1, other_doc2));
        }
        else {
            const storageRef = ref(getStorage(), "/Profile/" + name + ".png")
            const uploadTask = uploadBytesResumable(storageRef, profile_pic);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                        console.log(profile_pic)
                        if (aadhar == null) {
                            dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, gst_no, old_aadhar, pan, gst_cert, profile_pic, other_doc1, other_doc2));
                        }
                        else {
                            // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst_cert, downloadURL));
                            const storageRef = ref(getStorage(), "/Aadhar/" + name + ".png")
                            const uploadaadhar = uploadBytesResumable(storageRef, aadhar);
                            uploadaadhar.on(
                                "state_changed",
                                function (snapshot) {
                                },
                                function (error) {
                                    console.log(error)
                                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                    dispatch(setLoader(false));
                                },
                                function () {
                                    getDownloadURL(uploadaadhar.snapshot.ref).then(function (aadhar) {
                                        console.log(aadhar)
                                        if (pan == null) {
                                            dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, gst_no, aadhar, old_pan, gst_cert, profile_pic, other_doc1, other_doc2));
                                        }
                                        else {
                                            // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst_cert, downloadURL));
                                            const storageRef = ref(getStorage(), "/Pan/" + name + ".png")
                                            const uploadpan = uploadBytesResumable(storageRef, pan);
                                            uploadpan.on(
                                                "state_changed",
                                                function (snapshot) {
                                                },
                                                function (error) {
                                                    console.log(error)
                                                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                                    dispatch(setLoader(false));
                                                },
                                                function () {
                                                    getDownloadURL(uploadpan.snapshot.ref).then(function (pan) {
                                                        console.log(pan)
                                                        if (gst_cert == null) {
                                                            dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, gst_no, aadhar, pan, old_gst_cert, profile_pic, other_doc1, other_doc2));
                                                        }
                                                        else {
                                                            // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst_cert, downloadURL));
                                                            const storageRef = ref(getStorage(), "/Gst/" + name + ".png")
                                                            const uploadgst = uploadBytesResumable(storageRef, gst_cert);
                                                            uploadgst.on(
                                                                "state_changed",
                                                                function (snapshot) {
                                                                },
                                                                function (error) {
                                                                    console.log(error)
                                                                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                                                    dispatch(setLoader(false));
                                                                },
                                                                function () {
                                                                    getDownloadURL(uploadgst.snapshot.ref).then(function (gst_cert) {
                                                                        console.log(gst_cert)
                                                                        if (other_doc1 == null) {
                                                                            dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, gst_no, aadhar, pan, gst_cert, profile_pic, old_other_doc1, other_doc2));
                                                                        }
                                                                        else {
                                                                            const storageRef = ref(getStorage(), "/other/" + name + ".png")
                                                                            const uploadgst = uploadBytesResumable(storageRef, other_doc1);
                                                                            uploadgst.on(
                                                                                "state_changed",
                                                                                function (snapshot) {
                                                                                },
                                                                                function (error) {
                                                                                    console.log(error)
                                                                                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                                                                    dispatch(setLoader(false));
                                                                                },
                                                                                function () {
                                                                                    getDownloadURL(uploadgst.snapshot.ref).then(function (other_doc1) {
                                                                                        console.log(other_doc2)
                                                                                        if (other_doc2 == null) {
                                                                                            dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, gst_no, aadhar, pan, gst_cert, profile_pic, other_doc1, old_other_doc2));
                                                                                        }
                                                                                        else {
                                                                                            const storageRef = ref(getStorage(), "/other/" + name + ".png")
                                                                                            const uploadgst = uploadBytesResumable(storageRef, other_doc2);
                                                                                            uploadgst.on(
                                                                                                "state_changed",
                                                                                                function (snapshot) {
                                                                                                },
                                                                                                function (error) {
                                                                                                    console.log(error)
                                                                                                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                                                                                                    dispatch(setLoader(false));
                                                                                                },
                                                                                                function () {
                                                                                                    getDownloadURL(uploadgst.snapshot.ref).then(function (other_doc2) {
                                                                                                        console.log(other_doc2)
                                                                                                        dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, gst_no, aadhar, pan, gst_cert, profile_pic, other_doc1, other_doc2));
                                                                                                    });
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                        // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst, profile_pic));
                                                                                    });
                                                                                }
                                                                            );
                                                                        }
                                                                        // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst, profile_pic));
                                                                    });
                                                                }
                                                            );
                                                        }
                                                    });
                                                }
                                            );
                                        }
                                    });
                                }
                            );
                        }
                    });
                }
            );
        }

    }
}

export function createCustomer(admin_id, name, mobile, email, address, aadhar, pan, gst, profile_pic, cust_gst_no, other_doc1, other_doc2) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_customer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id:admin_id,
                cust_name: name,
                cust_mobile: mobile,
                cust_email: email,
                cust_address: address,
                cust_aadhar: aadhar,
                cust_pan: pan,
                cust_gst_cert: gst,
                cust_profile_pic: profile_pic,
                cust_gst_no: cust_gst_no,
                other_doc1: other_doc1,
                other_doc2: other_doc2,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllCustomer(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateCustomer(_id,admin_id, name, mobile, email, address, gst_no, aadhar, pan, gst_cert, profile_pic, other_doc1, other_doc2) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_customer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: _id,
                cust_name: name,
                cust_mobile: mobile,
                cust_email: email,
                cust_address: address,
                cust_gst_no: gst_no,
                cust_aadhar: aadhar,
                cust_pan: pan,
                cust_gst_cert: gst_cert,
                cust_profile_pic: profile_pic,
                other_doc1: other_doc1,
                other_doc2: other_doc2,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllCustomer(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
                console.error(error);
            });
    };
}

export function deleteCustomer(_id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_customer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: _id,
          })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllCustomer(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
                console.error(error);
            });
    };
}

export function viewAllCustomer(admin_id,pagenumber) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_customer", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
                pagenumber: pagenumber,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson.result);
                    dispatch({ type: SET_CUSTOMER, payload: { data: responseJson.result, count: responseJson.length }});
                    console.log(responseJson.result);
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_CUSTOMER,payload: { data: [], count: 0 } })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewAllCustomerByAdmin(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_customers", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                admin_id: admin_id,

            }

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson.result);
                    dispatch({ type: SET_ALL_CUSTOMER, payload: responseJson.result});
                    console.log(responseJson.result);
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ALL_CUSTOMER,payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function viewCustomerId(_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_customer_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: _id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(setCustomerid(responseJson.result._id));
                    dispatch(setProfilepic(responseJson.result.cust_profile_pic));
                    dispatch(setCustomername(responseJson.result.cust_name));
                    dispatch(setCustomermobile(responseJson.result.cust_mobile));
                    dispatch(setCustomeremail(responseJson.result.cust_email));
                    dispatch(setCustomeraddress(responseJson.result.cust_address));
                    dispatch(setCustomergstno(responseJson.result.cust_gst_no));
                    dispatch(setCustomeraadhar(responseJson.result.cust_aadhar));
                    dispatch(setCustomerpan(responseJson.result.cust_pan));
                    dispatch(setCustomergstcertificate(responseJson.result.cust_gst_cert));
                    dispatch(setCustomerother1(responseJson.result.other_doc1));
                    dispatch(setCustomerother2(responseJson.result.other_doc2));

                    // dispatch(setcategoryname(responseJson.result.category_name));
                    // dispatch(setmodalname(responseJson.result.modal_name));
                    dispatch({ type: SET_CUSTOMER_DETAIL, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_CUSTOMER_DETAIL, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}




export function setCustomerid(payload) {
    return {
        type: SET_CUSTOMERID,
        payload: payload,
    };
}

export function setProfilepic(payload) {
    return {
        type: SET_PROFILEPIC,
        payload: payload,
    };
}

export function setCustomername(payload) {
    return {
        type: SET_CUSTOMERNAME,
        payload: payload,
    };
}

export function setCustomermobile(payload) {
    return {
        type: SET_CUSTOMERMOBILE,
        payload: payload,
    };
}
export function setCustomeremail(payload) {
    return {
        type: SET_CUSTOMERMAIL,
        payload: payload,
    };
}
export function setCustomeraddress(payload) {
    return {
        type: SET_CUSTOMERADDRESS,
        payload: payload,
    };
}
export function setCustomergstno(payload) {
    return {
        type: SET_CUSTOMERGSTNO,
        payload: payload,
    };
}
export function setCustomeraadhar(payload) {
    return {
        type: SET_CUSTOMERAADHAR,
        payload: payload,
    };
}
export function setCustomerpan(payload) {
    return {
        type: SET_CUSTOMERPAN,
        payload: payload,
    };
}
export function setCustomergstcertificate(payload) {
    return {
        type: SET_CUSTOMERGSTCERTIFICATE,
        payload: payload,
    };
}
export function setCustomerother1(payload) {
    return {
        type: SET_CUSTOMEROTHER1,
        payload: payload,
    };
}
export function setCustomerother2(payload) {
    return {
        type: SET_CUSTOMEROTHER2,
        payload: payload,
    };
}
