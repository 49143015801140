import React, { Component } from "react";
import { connect } from "react-redux";
import Policy from "../Component/Policy";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  onLogout
} from "../../../../auth/actions";
import {
    viewAllPolicy,
    updateCategory,
    deletePolicy,
    createPolicy
} from "../Action";
export class Controller extends Component {
  render() {
    return (
      <Policy {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    policy: store.policy,
    snackbar: store.snackbar,
    login: store.login,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // createCategory: (category_name) => {
    //   dispatch(createCategory(category_name))
    // },
    createPolicy: (policy_name,policy_url) => {
      dispatch(createPolicy(policy_name,policy_url))
    },


   
    deletePolicy: (policy_id) => {
      dispatch(deletePolicy(policy_id))
    },
    viewAllPolicy: () => {
      dispatch(viewAllPolicy())
    },
    onLogout: () => {
      dispatch(onLogout());
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);