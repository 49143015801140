import {
    PASSWORD_VERIFY,
    KIOSK_DETAILS,
    TASK_KIOSK_DETAILS
} from "./Constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
import { set_snack_bar } from "../../../common/snackbar/action";

// import { set_snack_bar } from "../../common/snackbar/action";
// import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import 'firebase/storage';
// import firebase from 'firebase/app'
export function view_kiosk_details() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_kiosk_details", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: KIOSK_DETAILS, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: KIOSK_DETAILS, payload: [] });
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_admins_task_details() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_admins_task_details", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch({ type: TASK_KIOSK_DETAILS, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: TASK_KIOSK_DETAILS, payload: [] });
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function passwordVerify(payload) {
    const password = "skyyrider@nihar365"
    var res;
    if (payload === password) {
        res = true
    }
    else {
        res = false
    }
    console.log(res)
    return {
        type: PASSWORD_VERIFY,
        payload: res
    }
}