import React, { Component } from "react";
import { connect } from "react-redux";
import EditProfile from "../components/Edit_profile";
// import {
//   close_snack_bar
// } from "../../common/snackbar/action";
import {
    updateUser,
    view_user_profile,
    setID,
    setName,
    setMobile,
    setEmail,
    setGender,
    setDob,
    setP_address,
    setPr_address,
    setProfile_pic,
    upload_profile,
    setPassword
} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <EditProfile {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    // user: store.user,
    one_user: store.one_user,
    login: store.login
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    view_user_profile: (id) => {
      dispatch(view_user_profile(id))
    },
    upload_profile: (id,name,mobile,email,dob,gender,profile_pic,p_address,pr_address,password) => {
      dispatch(upload_profile(id,name,mobile,email,dob,gender,profile_pic,p_address,pr_address,password))
    },
    updateUser: (id,name,mobile,email,dob,gender,profile_pic,p_address,pr_address,password) => {
      dispatch(updateUser(id,name,mobile,email,dob,gender,profile_pic,p_address,pr_address,password))
    },
    setID: (Emp_Id) => {
      dispatch(setID(Emp_Id))
    },
    setName: (name) => {
      dispatch(setName(name))
    },
    setMobile: (mobile) => {
      dispatch(setMobile(mobile))
    },
    setEmail: (email) => {
      dispatch(setEmail(email))
    },
    setGender: (gender) => {
      dispatch(setGender(gender))
    },
    setDob: (dob) => {
      dispatch(setDob(dob))
    },
    setP_address: (p_address) => {
      dispatch(setP_address(p_address))
    },
    setPr_address: (pr_address) => {
      dispatch(setPr_address(pr_address))
    },
    setProfile_pic: (profile_pic) => {
      dispatch(setProfile_pic(profile_pic))
    },
    setPassword: (password) => {
      dispatch(setPassword(password))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);