import React, { Component } from "react";
import { connect } from "react-redux";
import Editemployee from "../components/editemployee";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";

import {
    update_profile_pic,
    view_all_user,
    setName,
    setMobile,
    setEmail,
    setPassword,
    setGender,
    setDob,
    setPer_address,
    setPre_address,
    setProfile_pic,
    setTL,
    setdepartment,
    setbasicsalary,
    setDesignation,
    settime,
    setshift
} from "../actions";
import {
    viewAlldepartment,
    view_all_TL
} from "../../../superadmin/action";
export class Controller extends Component {
    render() {
        return (
            <Editemployee {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        employee: store.employee,
        snackbar: store.snackbar,
        superadmin: store.superadmin,

    };
};
export const mapDispatchToProps = dispatch => {
    return {
        update_profile_pic: (id,TL_Id,name, mobile, email, password, gender,profile_pic_old, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id) => {
            dispatch(update_profile_pic(id,TL_Id,name, mobile, email, password, gender,profile_pic_old, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id))
        },
        view_all_user: (admin_id) => {
            dispatch(view_all_user(admin_id))
        },
        setName: (name) => {
            dispatch(setName(name))
        },
        setMobile: (mobile) => {
            dispatch(setMobile(mobile))
        },
        setEmail: (email) => {
            dispatch(setEmail(email))
        },
        setPassword: (password) => {
            dispatch(setPassword(password))
        },
        setGender: (gender) => {
            dispatch(setGender(gender))
        },
        setProfile_pic: (profile_pic) => {
            dispatch(setProfile_pic(profile_pic))
        },
        setDob: (dob) => {
            dispatch(setDob(dob))
        },
        setPer_address: (per_address) => {
            dispatch(setPer_address(per_address))
        },
        setPre_address: (pre_address) => {
            dispatch(setPre_address(pre_address))
        },
        setTL: (pre_address) => {
            dispatch(setTL(pre_address))
        },
        setdepartment: (pre_address) => {
            dispatch(setdepartment(pre_address))
        },
        setbasicsalary: (pre_address) => {
            dispatch(setbasicsalary(pre_address))
        },
        setDesignation: (pre_address) => {
            dispatch(setDesignation(pre_address))
        },
        settime: (pre_address) => {
            dispatch(settime(pre_address))
        },
        setshift: (pre_address) => {
            dispatch(setshift(pre_address))
        },







        close_snack_bar: () => {
            dispatch(close_snack_bar());
          },
          viewAlldepartment: () => {
            dispatch(viewAlldepartment());
          },
          view_all_TL: () => {
            dispatch(view_all_TL());
          },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);