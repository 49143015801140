import {
  SET_CUSTOMER,
  SET_CUSTOMER_DETAIL,
  SET_CUSTOMERID,
  SET_PROFILEPIC,
  SET_CUSTOMERNAME,
  SET_CUSTOMERMOBILE,
  SET_CUSTOMERMAIL,
  SET_CUSTOMERADDRESS,
  SET_CUSTOMERGSTNO,
  SET_CUSTOMERAADHAR,
  SET_CUSTOMERPAN,
  SET_CUSTOMERGSTCERTIFICATE,
  SET_CUSTOMEROTHER1,
  SET_CUSTOMEROTHER2,
  SET_ALL_CUSTOMER,
} from "./constant";
const initial_state = {
  all_customer: [],
  customer_detail:[],
  customer_id:"",
  cust_profile_pic:"",
  cust_name:"",
  cust_mobile:"",
  cust_email:"",
  cust_address:"",
  cust_gst_no:"",
  cust_aadhar:"",
  cust_pan:"",
  customer_gstcertificate:"",
  customer_other1:"",
  customer_other2:"",
  length:0,
  customer:[],
  
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_CUSTOMER:
      return state = { ...state, all_customer:action.payload.data, length: action.payload.count };
    case SET_CUSTOMER_DETAIL:
      return state = { ...state, customer_detail: action.payload };
    case SET_CUSTOMERID:
      return state = { ...state, customer_id: action.payload };
    case SET_PROFILEPIC:
      return state = { ...state, cust_profile_pic: action.payload };
    case SET_CUSTOMERNAME:
      return state = { ...state, cust_name: action.payload };
    case SET_CUSTOMERMOBILE:
      return state = { ...state, cust_mobile: action.payload };
    case SET_CUSTOMERMAIL:
      return state = { ...state, cust_email: action.payload };
    case SET_CUSTOMERADDRESS:
      return state = { ...state, cust_address: action.payload };
    case SET_CUSTOMERGSTNO:
      return state = { ...state, cust_gst_no: action.payload };
    case SET_CUSTOMERAADHAR:
      return state = { ...state, cust_aadhar: action.payload };
    case SET_CUSTOMERPAN:
      return state = { ...state, cust_pan: action.payload };
    case SET_CUSTOMERGSTCERTIFICATE:
      return state = { ...state, customer_gstcertificate: action.payload };
    case SET_CUSTOMEROTHER1:
      return state = { ...state, customer_other1: action.payload };
    case SET_CUSTOMEROTHER2:
      return state = { ...state, customer_other2: action.payload };
    case SET_ALL_CUSTOMER:
      return state = { ...state, customer: action.payload };

    default:
      return state;
  }
}
