import React, { Component } from "react";
import { connect } from "react-redux";
import Policy from "../Component/Kiosk";
import {
    close_snack_bar
} from "../../../../common/snackbar/action";
import {
    onLogout
} from "../../../../auth/actions";
import {
    passwordVerify,
    view_kiosk_details
} from "../Action";
export class Controller extends Component {
    render() {
        return (
            <Policy {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        policy: store.policy,
        snackbar: store.snackbar,
        login: store.login,
        kiosk: store.kiosk,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        view_kiosk_details: () => {
            dispatch(view_kiosk_details())
        },
        passwordVerify: (pass) => {
            dispatch(passwordVerify(pass))
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);