import React, { Component } from "react";
import { connect } from "react-redux";
import Addorder from "../component/addorder";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  createQuotation,
  createOrder,
  // updateOrder,
  // deleteOrder,
  viewAllOrder,
  setorderid
} from "../action";
import {
  viewAllCategory
} from "../../category/action";
import {
  viewAllBattery,
  viewBatteryByid
} from "../../battery/action";
import {
  viewModel,
  viewCategortModel
} from "../../model/action";
import {
  viewVarient,
  viewModelVarient,
  viewVarientByid
} from "../../variante/action";
import {
  viewAllCustomer,
  viewAllCustomerByAdmin,
} from "../../customer/action"
import {
  viewAllColor
} from "../../color/action";
export class Controller extends Component {
  render() {
    return (
      <Addorder {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    // user: store.user
    all_order: store.all_order,
    all_category: store.all_category,
    all_model: store.all_model,
    all_varient: store.all_varient,
    all_customer: store.all_customer,
    all_color: store.all_color,
    all_battery: store.all_battery,
    snackbar: store.snackbar,
    login:store.login
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createQuotation: (customer_id,admin_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) => {
      dispatch(createQuotation(customer_id,admin_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment))
    },
    createOrder: (admin_id,customer_id, category_id, model_id, variant_id, color_id, battery_id, booking_invoice, ex_shoeroom_price, insurance_fee, registration_fee, transportation_cost, estimated_delivery_date, quanty, width, length, will_registration, will_transport, on_road_price, total_price, discount_amount, advance_amount, payment_mode, comment) => {
      dispatch(createOrder(admin_id,customer_id, category_id, model_id, variant_id, color_id, battery_id, booking_invoice, ex_shoeroom_price, insurance_fee, registration_fee, transportation_cost, estimated_delivery_date, quanty, width, length, will_registration, will_transport, on_road_price, total_price, discount_amount, advance_amount, payment_mode, comment))
    },
    // updateOrder: (_id, customer_id, category_id, model_id, variant_id, order_id, booking_invoice, ex_shoeroom_price, insurance_fee, registration_fee, transportation_cost, estimated_delivery_date, on_road_price, quanty, width, length, will_registration, will_transport, status, comment) => {
    //   dispatch(updateOrder(_id, customer_id, category_id, model_id, variant_id, order_id, booking_invoice, ex_shoeroom_price, insurance_fee, registration_fee, transportation_cost, estimated_delivery_date, on_road_price, quanty, width, length, will_registration, will_transport, status, comment))
    // },
    viewBatteryByid: (_id) => {
      dispatch(viewBatteryByid(_id))
    },
    viewAllOrder: (admin_id,status,order_status,page_number) => {
      dispatch(viewAllOrder(admin_id,status,order_status,page_number))
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    viewModel: () => {
      dispatch(viewModel())
    },
    viewVarient: () => {
      dispatch(viewVarient())
    },
    viewAllCustomer: (admin_id) => {
      dispatch(viewAllCustomer(admin_id))
    },
    viewAllColor: () => {
      dispatch(viewAllColor())
    },
    viewAllBattery: () => {
      dispatch(viewAllBattery())
    },
    viewCategortModel: (category_id) => {
      dispatch(viewCategortModel(category_id))
    },
    viewModelVarient: (model_id) => {
      dispatch(viewModelVarient(model_id))
    },
    viewVarientByid: (variant_id) => {
      dispatch(viewVarientByid(variant_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    setorderid: (payload) => {
      dispatch(setorderid(payload));
    },
    viewAllCustomerByAdmin: (admin_id) => {
      dispatch(viewAllCustomerByAdmin(admin_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);