import React, { Component } from "react";
import {
  Grid,
  // Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Icon,
  TextField,
  DialogActions,
  Tooltip
} from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

// import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import { Navigate } from "react-router-dom";
// import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";

import {
  // Link,
  Navigate,
} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import "../../style/order.css";
import Snackbar from "../../../../common/snackbar/components/snackbar";


export default class category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      delete: false,
      add: false,
      view: false,
      imageUrl: null,
      imageAlt: null,
      category_name:""
    };
  }
  componentDidMount() {
    this.props.viewAllCategory();
  }
  render() {
    const { 
      all_category, 
      snackbar,
      close_snack_bar
     } = this.props;
    if (this.state.redirect === true) {
      return <Navigate to="/" />;
    }
    return (
      <Grid>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Box className="ordername">
            <center>
              <Button style={{ color: "white ", textAlign: "center" }}>
                Category
              </Button>
            </center>
          </Box>
          <Box style={{ marginRight: "42%" }}>
            <center style={{ borderRadius: 0 }}>
              <Button
                className="orderadd"
                style={{ color: "white ", textAlign: "center" }}
                onClick={() => {
                  this.setState({
                    add: true,
                  });
                }}
              >
                Add Category
              </Button>
            </center>
          </Box>
        </Grid>

        <CardContent style={{ marginTop: ".5%", marginLeft: "3%", marginRight: "5%", width: "50%" }}>
          <TableContainer component={Paper} >
            <Table
              style={{ minWidth: 200 }}
              size="small"
              aria-label="a dense table"
              ref={this.tableRef}
            >
              <TableHead className="orderhead">
                <TableRow>
                  {/* <TableCell>Category ID</TableCell> */}
                  <TableCell  >Category Name</TableCell>
                  <TableCell style={{ marginRight: "2px" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="orderbody">
                {all_category.all_category.map((category) => {
                  return (
                    <TableRow
                      // key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {/* <TableCell >{category._id}</TableCell>  */}
                      <TableCell>{category.category_name}</TableCell>
                      <TableCell>
                        {/* <IconButton
                          onClick={() => {
                            this.setState({
                              view: true,
                              // id: allorder._id,
                              category_name: category.category_name,
                            });
                          }}
                        >
                          <Icon style={{ color: "blue" }}>visibility</Icon>
                        </IconButton> */}
                        <Tooltip title="Edit">


                        <IconButton
                          onClick={() => {
                            this.setState({
                              edit: true,
                              id: category._id,
                              category_name: category.category_name,
                            });
                          }}
                        >
                          <EditOutlinedIcon style={{ color: "green" }} />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">

                        <IconButton
                          onClick={() => {
                            this.setState({
                              delete: true,
                              id: category._id,
                            });
                          }}
                        >
                          <DeleteOutlineOutlinedIcon style={{ color: "red" }} />
                        </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        {/* <Dialog
          open={this.state.view}
          // onClose={() => { this.setState({ view: false }) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography style={{ color: "blue", fontSize: 25 }}>
              Category Deatils
            </Typography>

            <IconButton
              color="primary"
              style={{ marginLeft: "93%", marginTop: "-15%" }}
              onClick={() => {
                this.setState({
                  view: false,
                  // _id: c._id,
                  // color_name: c.color_name,
                  // color_code: c.color_code,
                });
              }}
            >
              <CloseIcon style={{ color: "blue" }} />
            </IconButton>
          </DialogTitle>

          <Grid
            lg={8}
            style={{ display: "flex", flexDirection: "row", width: "600px" }}
          >
            

            <Grid style={{ marginLeft: "25px", marginTop: "-30px" }}>
              <Grid item style={{ display: "flex", width: 200 }}>
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>
                  Category Name:
                </Typography>
                <Typography style={{ fontSize: 15 }}>
                  &nbsp;{this.state.category_name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Dialog> */}




        <Dialog
          open={this.state.delete}
          onClose={() => {
            this.setState({ delete: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ delete: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained" onClick={() => { this.props.deleteCategory(this.state.id); this.setState({ delete: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={this.state.add}
          // fullScreen
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "160px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  required
                  id="outlined-required"
                  label="Category Name"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  onChange={(e) => {
                    this.setState({ category_name: e.target.value });
                  }}
                />
              </div>

              <Button
                className="btns"
                disabled={this.state.category_name===""}
                onClick={() => {
                  this.props.createCategory(this.state.category_name);
                  this.setState({ add: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Add
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ add: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog
          open={this.state.edit}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "160px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  required
                  id="outlined-required"
                  label="Category Name"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ category_name: e.target.value });
                  }}
                />
              </div>

              <Button
                className="btns"
                disabled={this.state.category_name===""}
                onClick={() => {
                  this.props.updateCategory(
                    this.state.id,
                    this.state.category_name
                  );
                  this.setState({ edit: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Update
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ edit: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>
        <LoaderCon />
        <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
      </Grid>

      // <div>
      //   <Box
      //     marginLeft={'5%'}
      //     // marginTop={"%"}
      //     marginRight={"5%"}
      //   >
      //     <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
      //       <Button style={{ marginLeft: 14, backgroundColor: "ButtonFace   " }} onClick={() => {
      //         this.setState({
      //           add: true,
      //           // id: allusers._id,
      //         })
      //       }}>ADD

      //         <AddIcon style={{ marginTop: 0, color: "blue" }} />
      //       </Button>
      //     </Grid>

      //     <CardContent>
      //       <TableContainer
      //         component={Paper}
      //       >
      //         <Table
      //           style={{ minWidth: '10%' }}
      //           size="small"
      //           aria-label="a dense table"
      //           ref={this.tableRef}
      //         >
      //           <TableHead>
      //             <TableRow>
      //               <Typography style={{ fontSize: 20, color: "blue", marginLeft: "10px", fontWeight: "bold" }}>ALL CATEGORY</Typography>
      //             </TableRow>
      //             <TableRow>
      //               {/* <TableCell>Category ID</TableCell> */}
      //               <TableCell>Category Name</TableCell>
      //               <TableCell>Action</TableCell>
      //             </TableRow>

      //           </TableHead>
      //           {all_category.all_category.map((category) => {
      //             return (
      //               <TableBody>
      //                 <TableRow
      //                   sx={{
      //                     "&:last-child td, &:last-child th": { border: 0 },
      //                   }}
      //                 >
      //                   {/* <TableCell >{category._id}</TableCell> */}
      //                   <TableCell >{category.category_name}</TableCell>

      //                   <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", }}>
      //                     <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
      //                       <IconButton
      //                       style={{ color: 'green' }}onClick={() => {
      //                         this.setState({
      //                           edit: true,
      //                           _id: category._id,
      //                           category_name: category.category_name
      //                         })
      //                       }}>
      //                         <EditIcon
      //                         />
      //                       </IconButton>

      //                     </Grid>
      //                     <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 20 }}>
      //                       <IconButton onClick={() => { this.setState({ delete: true }) }}>

      //                         <DeleteRoundedIcon
      //                         style={{ color: 'red' }}onClick={() => {
      //                           this.setState({
      //                             delete: true,
      //                             _id: category._id,
      //                           })
      //                         }} />
      //                       </IconButton>
      //                     </Grid>
      //                     {/* <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 40 }}>
      //                       <IconButton onClick={() => {
      //                         this.setState({
      //                           view: true,

      //                         })
      //                       }}
      //                       >

      //                         <VisibilityIcon
      //                           style={{ Color: "Blue", pointerEvents: "visiblePainted" }}
      //                           onClick={() => {
      //                             this.setState({
      //                               view: true,
      //                               // profile_pic: allusers.profile_pic,
      //                               // gender: allusers.gender,
      //                               // dob: allusers.dob,
      //                               // p_address: allusers.p_address,
      //                               // pr_address: allusers.pr_address
      //                             })
      //                           }} />
      //                       </IconButton>
      //                     </Grid> */}

      //                   </Grid>

      //                 </TableRow>

      //               </TableBody>
      //             );
      //           })}
      //         </Table>

      //       </TableContainer>
      //     </CardContent>
      //   </Box>

      //   <Dialog
      //     open={this.state.edit}
      //     // onClose={() => {
      //     //     this.setState({ add: false });
      //     // }}
      //     // style={{wi}}
      //     style={
      //       {
      //         // top: "50%",
      //         // left: "10%",
      //         // right: "auto",
      //         // bottom: "auto",
      //         // marginRight: "-50%",
      //         // transform: "translate(-50%, -50%)",
      //         // width: "1000px",
      //         // marginLeft: "500px",
      //         // paddingTop: "40px",
      //         // paddingLeft: "25px",
      //         // paddingRight: "25px",
      //         // borderRadius: "0px",
      //         height: "700px",
      //         // zIndex: "200",
      //         // backgroundColor: "#fff"
      //       }}
      //   >
      //     <Grid style={{ backgroundColor: "#fff", height: "160px", minWidth: "323px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
      //       <Grid>
      //         <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Category Name"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             style={{ height: "50px", width: "280px" }}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </div>

      //         <Button onClick={() => {
      //           this.props.updateCategory(this.state._id, this.state.category_name);
      //           this.setState({ edit: false });
      //         }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Update</Button>
      //         <Button onClick={() => {
      //           this.setState({ edit: false });
      //         }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Close</Button>

      //       </Grid>

      //     </Grid>

      //   </Dialog>
      //   <Dialog
      //     open={this.state.delete}
      //     onClose={() => {
      //       this.setState({ delete: false });
      //     }}
      //     // style={{wi}}
      //     style={
      //       {
      //         // top: "50%",
      //         // left: "50%",
      //         // right: "auto",
      //         // bottom: "auto",
      //         // marginRight: "-50%",
      //         // transform: "translate(-50%, -50%)",
      //         width: "470px",
      //         marginLeft: "500px",
      //         // paddingTop: "40px",
      //         // paddingLeft: "25px",
      //         // paddingRight: "25px",
      //         borderRadius: "0px",
      //         // height: "300px",
      //         zIndex: "200",
      //         backgroundColor: "#fff"
      //       }}

      //   >
      //     <Box style={{ backgroundColor: "#fff", height: "100px", width: "350px" }}>
      //       <Typography

      //         style={{ fontSize: "16px", textAlign: 'center' }}
      //       >
      //         Are You Sure ?
      //       </Typography>
      //       <Grid sx={{ marginLeft: "32%", marginTop: "8%" }}>
      //         <Button onClick={() => { this.props.deleteCategory(this.state._id); this.setState({ delete: false }) }}>Yes</Button>
      //         <Button onClick={() => {
      //           this.setState({ delete: false });
      //         }}>No</Button>
      //       </Grid>
      //     </Box>

      //   </Dialog>
      //   <Dialog
      //     open={this.state.add}
      //     // onClose={() => {
      //     //     this.setState({ add: false });
      //     // }}
      //     // style={{wi}}
      //     style={
      //       {
      //         // top: "50%",
      //         // left: "10%",
      //         // right: "auto",
      //         // bottom: "auto",
      //         // marginRight: "-50%",
      //         // transform: "translate(-50%, -50%)",
      //         // width: "1000px",
      //         // marginLeft: "500px",
      //         // paddingTop: "40px",
      //         // paddingLeft: "25px",
      //         // paddingRight: "25px",
      //         // borderRadius: "0px",
      //         height: "700px",
      //         // zIndex: "200",
      //         // backgroundColor: "#fff"
      //       }}
      //   >
      //     <Grid style={{ backgroundColor: "#fff", height: "160px", minWidth: "323px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
      //       <Grid>
      //         <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Category Name"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             style={{ height: "50px", width: "280px" }}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </div>

      //         <Button onClick={() => {
      //           this.props.createCategory(this.state.category_name);
      //           this.setState({ add: false });
      //         }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Add</Button>
      //         <Button onClick={() => {
      //           this.setState({ add: false });
      //         }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Close</Button>

      //       </Grid>

      //     </Grid>

      //   </Dialog>
      //   <LoaderCon />
      // </div>
    );
  }
}
