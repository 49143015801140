import React, { Component } from "react";
import { connect } from "react-redux";
import Vehicle from "../component/vehicle";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";

import {
  viewAllvehicle,
  Confirmvehicle,
  Rtoreg,
  upload_payment,
  Noplatevehicle,
  outdeliveryvehicle
} from "../action";

export class Controller extends Component {
  render() {
    return (
      <Vehicle {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    all_vehicles: store.all_vehicles,
    snackbar: store.snackbar,
    login: store.login,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // createColor: (color_name,color_code) => {
    //   dispatch(createColor(color_name,color_code))
    // },
    // updateColor: (_id,color_name,color_code) => {
    //   dispatch(updateColor(_id,color_name,color_code))
    // },
    // deleteColor: (_id) => {
    //   dispatch(deleteColor(_id))
    // },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAllvehicle: (admin_id,pagenumber) => {
      dispatch(viewAllvehicle(admin_id,pagenumber))
    },
    outdeliveryvehicle: (_id,admin_id) => {
      dispatch(outdeliveryvehicle(_id,admin_id))
    },
    Confirmvehicle: (_id,admin_id) => {
      dispatch(Confirmvehicle(_id,admin_id))
    },
    Rtoreg: (_id,challan_number,payment_slip,admin_id) => {
      dispatch(Rtoreg(_id,challan_number,payment_slip,admin_id))
    },
    upload_payment: (_id,challan_number,payment_slip,admin_id) => {
      dispatch(upload_payment(_id,challan_number,payment_slip,admin_id))
    },
    Noplatevehicle: (_id,no_plate,admin_id) => {
      dispatch(Noplatevehicle(_id,no_plate,admin_id))
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);