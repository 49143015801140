import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Typography,
    Icon

} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {
    // Link,
    Navigate,
} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
// import Modal from "react-modal";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import EditIcon from '@mui/icons-material/Edit';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddIcon from '@mui/icons-material/Add';
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
import TablePagination from '@mui/material/TablePagination';
// import TableContainer from "@mui/material/TableContainer";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled, alpha } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { green } from "@mui/material/colors";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import Snackbar from "../../../../common/snackbar/components/snackbar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,

        }
    }
    // componentDidMount() {
    //     this.props.viewAllColor();

    // }
    componentDidMount() {
      this.props.viewAllService(this.props.login.user_id);
    }




    render() {
        



        const {
          all_service,
          snackbar,
          login,
          close_snack_bar
        } = this.props;
        const { imageUrl, imageAlt } = this.state;
        if (this.state.redirect === true) {
            return <Navigate to="/" />;
        }

        // const handleChange = (e) => {
        //     if (e.target.value === "") {
        //       this.setState({ filterValue: departments.all_departments })
        //       setFilter(departments.all_departments);
      
        //     }
        //     else {
        //       const newArray = departments.all_departments.filter((el) => {
        //         return (el.department_name.toLowerCase().includes(e.target.value.toLowerCase()))
        //       })
        //       setFilter(newArray)
        //       this.setState({ filterValue: newArray })
        //     }
        //     console.log(this.state.filterValue)
        //   }
        const handleChangePage = (event, newPage) => {
            this.setState({
              page: newPage
            })
          };
      
          const handleChangeRowsPerPage = (e) => {
            this.setState({
              rowsPerPage: e.target.value,
              page: 0
            })
          };
        return (
            <div>
                <Box marginLeft={4} marginTop={3}>
                <Link to="/"><ArrowBackIcon/></Link> </Box>
                <Box
                    marginLeft={'15%'}
                    // marginLeft={'15%'}
                    marginTop={"5%"}
                    marginRight={"0%"}
                    width={"100%"}
                    style={{backgroundColor:"",alignContent:"center",marginLeft:"20%",overflowX:"hidden",overflow:"hidden",overflowY:"hidden"}}
                >
                   





                    <CardContent>


                        <Grid style={{ display: "flex",overflowX:"hidden" }}>



                            <Typography style={{ fontSize: 20, color: "blue", marginLeft: "10px", fontWeight: "bold", width: "20%" }}>ALL SERVICE</Typography>
                            <Grid container direction="row" justifyContent="space-between" style={{}}>
                                <Grid item lg={4}>
                                    <Search
                                        style={{
                                            backgroundColor: "#d9d9d9",
                                            height: 32,
                                            marginRight: 105,
                                            // marginTop: 12,
                                            marginLeft:-5
                                        }}
                                        direction="row"
                                    >
                                        <SearchIconWrapper>
                                            <SearchIcon style={{ color: "black" }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search by Id ...."
                                            color="black"
                                            inputProps={{ "aria-label": "search" }}
                                            
                             
                                            style={{ textAlign: "center", justifyItems: "center",marginTop:-4 }}
                                        // onChange={handleChange}
                                        />
                                    </Search>
                                </Grid>
                            </Grid>
                            <Grid style={{marginLeft:"-70%"}}>

                            <TablePagination
                  rowsPerPageOptions={[20, 100, 1000]}
                //   labelDisplayedRows={20}
                  component="div"
                  style={{overflow:"hidden",overflowX:"hidden",overflowY:"hidden"}}
                //   count={departments.filterValue.length}
                defaultValue={20}
                defaultChecked={10}
                count={50}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

               
                        </Grid>
                        <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "",height:"40px" }} >
                        <Link 
                to="/"  style={{textDecoration:"none"}}>
                        <Button style={{ marginLeft: 70,  marginLeft: 80,color:"red",textDecoration:"none",borderWidth:4,borderColor:"red"}}
                        
                        
                        onClick={() => {
                            // localStorage.removeItem("user_id");
                            this.setState({ redirect: true });
                            this.props.onLogout();
                          }}>
                            LogOut

                            <LogoutIcon style={{ marginTop: 0, color: "red" }} />
                        </Button>
                        </Link>
                    </Grid>
                        </Grid>


                        <TableContainer
                        style={{marginTop:15}}
                            component={Paper}
                        >
                            <Table
                                style={{ minWidth: '10%' }}
                                size="small"
                                aria-label="a dense table"
                                ref={this.tableRef}
                            >
                                <TableHead>

                                    <TableRow>
                                    <TableCell>Customer Id</TableCell>
                                                <TableCell>Vechicle Id</TableCell>
                                                <TableCell>Booking Date</TableCell>
                                                <TableCell>Status</TableCell>
                                        <TableCell>Estimated_visit_date</TableCell>
                                        <TableCell>Visit_date</TableCell>
                                        <TableCell>Components</TableCell>
                                        {/* <TableCell>Recived</TableCell> */}
                                        {/* <TableCell>Height</TableCell> */}
                                        {/* <TableCell>Width</TableCell> */}
                                        {/* <TableCell>Length</TableCell> */}
                                        {/* <TableCell>Insurance</TableCell> */}
                                        <TableCell>Action</TableCell>
                                    </TableRow>

                                </TableHead>
                                {all_service.all_service.map((allservice) => {
                                                return (
                                        <TableBody>
                                            <TableRow
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell >{allservice.customer_id}</TableCell>
                                                        <TableCell >{allservice.vechicle_id}</TableCell>
                                                        <TableCell >{allservice.booking_date}</TableCell>
                                                        <TableCell >{allservice.status}</TableCell>
                                                        <TableCell >{allservice.estimated_visit_date}</TableCell>
                                                        <TableCell >{allservice.visit_date}</TableCell>
                                                        <TableCell >{allservice.components}</TableCell>
                                                {/* <TableCell>{allorder.received}</TableCell> */}
                                                {/* <TableCell>{allorder.height}</TableCell> */}
                                                {/* <TableCell>{allorder.width}</TableCell> */}
                                                {/* <TableCell>{allorder.customer_id}</TableCell> */}
                                                
                                                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", }}>
                                                   
                                                    
                                                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 20 }}>
                                                        <IconButton onClick={() => {
                                                            this.setState({
                                                                view: true,

                                                            })
                                                        }}
                                                        >

                                                            <VisibilityIcon
                                                                style={{ color: "Blue", pointerEvents: "visiblePainted" }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        view: true,
                                                                        
                                                                        address: allservice.address,
                                                                        // components: allservice.components,
                                                                        service_charges: allservice.service_charges,
                                                                        amount: allservice.amount,
                                                                        comment: allservice.comment
                                                                    })
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Grid>

                                                </Grid>

                                            </TableRow>

                                        </TableBody>
                                     );
                                })} 
                            </Table>

                        </TableContainer>
                    </CardContent>



          



            
                    <Dialog
                        open={this.state.view}
                        // onClose={() => { this.setState({ view: false }) }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"

                        style={
                            {
                                // top: "50%",
                                // left: "10%",
                                // right: "auto",
                                // bottom: "auto",
                                // marginRight: "-50%",
                                // transform: "translate(-50%, -50%)",
                                // width: "1000px",
                                // marginLeft: "500px",
                                // paddingTop: "40px",
                                // paddingLeft: "25px",
                                // paddingRight: "25px",
                                // borderRadius: "0px",
                                height: "700px",
                                // zIndex: "200",
                                // backgroundColor: "#fff"
                            }}
                    >

                        <DialogTitle id="alert-dialog-title">
                            <Typography style={{ color: "blue", fontSize: 25 }}>
                                Service Deatils

                            </Typography>




                            <IconButton color="primary"
                                style={{ marginLeft: "93%", marginTop: "-15%" }}
                                onClick={() => {
                                    this.setState({
                                        view: false,
                                        // _id: c._id,
                                        // color_name: c.color_name,
                                        // color_code: c.color_code,
                                    })
                                }}
                            >
                                <CloseIcon style={{ color: 'Blue' }}
                                />
                            </IconButton>


                        </DialogTitle>



                        <Grid lg={8} style={{ display: "flex", flexDirection: "row", width: "600px", }}>
                            {/* <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "25px" }}>
                            <img
                                src={this.state.profile_pic}
                                style={{ width: 140, height: 150 }} />

                        </Grid> */}


                            <Grid style={{ marginLeft: "25px", marginTop: "-30px" }}>
                            <Grid item style={{ display: "flex" }}>
                                    <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Address  :</Typography>
                                    <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.address}</Typography>
                                </Grid>
                                <Grid item style={{ display: "flex" }}>
                                    <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Service_charges  :</Typography>
                                    <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.service_charges}</Typography>
                                </Grid>
                                <Grid item style={{ display: "flex" }}>
                                    <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Amount  :</Typography>
                                    <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.amount}</Typography>
                                </Grid>
                                <Grid item style={{ display: "flex" }}>
                                    <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Comment  :</Typography>
                                    <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.comment}</Typography>
                                </Grid>
                               
                            </Grid>

                        </Grid>
                        {/* <Grid sx={{ marginLeft: "25px", marginTop: "0%" }}>
                        <Button onClick={() => {
                            this.setState({ view: false });
                        }}>Close</Button>
                    </Grid> */}
                    </Dialog>



                </Box>
                <LoaderCon />
                <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
            </div>
        )
    }
}

