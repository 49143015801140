import React, { Component } from "react";
import { connect } from "react-redux";
import Admin_user from "../component/delar";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  onLogout
} from "../../../../auth/actions";
import {
  createdealer,
  updatedealer,
  // deleteCategory,
  view_all_dealer,
  deletedealer
} from "../action";


// import{
//   deleteUser
// }from "../../empolyee/actions"
export class Controller extends Component {
  render() {
    return (
      <Admin_user {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    dealer: store.dealer,
    snackbar: store.snackbar

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createdealer: (admin_username, admin_useremail, admin_mobile, admin_userpassword, delar_name, page_number, rowper_page) => {
      dispatch(createdealer(admin_username, admin_useremail, admin_mobile, admin_userpassword, delar_name, page_number, rowper_page))
    },
    updatedealer: (id, admin_username, admin_useremail, admin_mobile, admin_userpassword, delar_name, page_number, rowper_page) => {
      dispatch(updatedealer(id, admin_username, admin_useremail, admin_mobile, admin_userpassword, delar_name, page_number, rowper_page))
    },
    deletedealer: (id, page_number, rowper_page) => {
      dispatch(deletedealer(id, page_number, rowper_page))
    },
    view_all_dealer: (page_number, rowper_page) => {
      dispatch(view_all_dealer(page_number, rowper_page))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    // onLogout: () => {
    //   dispatch(onLogout());
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);