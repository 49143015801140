import {
  SET_POLICY
  } from "./Constant";
  const initial_state = {
    all_policy: []
  };
  export default function reducer(state = initial_state, action) {
    switch (action.type) {
  
      case SET_POLICY:
        return state = { ...state, all_policy: action.payload };
      // case SET_ONE_USER:
      //   return state = { ...state, one_user: action.payload };
  
      default:
        return state;
    }
  }
  