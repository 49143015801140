import {
  SET_USER,
  SET_ONE_USER,
  SET_USER_ID,
  SET_USER_NAME,
  SET_USER_MOBILE,
  SET_USER_EMAIL,
  SET_USER_GENDER,
  SET_USER_DOB,
  SET_USER_PADDRESS,
  SET_USER_PRADDRESS,
  SET_USER_PROFILE_PIC,
  SET_USER_PASSWORD
} from "./constant";
const initial_state = {
  all_users: [],
  one_user: [],
  Emp_Id: "",
  name: "",
  mobile: "",
  email: "",
  gender: "",
  dob: "",
  profile_pic: "",
  p_address: "",
  pr_address: "",
  password:""
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_USER:
      return state = { ...state, all_users: action.payload };
    case SET_ONE_USER:
      return state = { ...state, one_user: action.payload };

    case SET_USER_ID:
      return state = { ...state, Emp_Id: action.payload };
    case SET_USER_NAME:
      return state = { ...state, name: action.payload };
    case SET_USER_MOBILE:
      return state = { ...state, mobile: action.payload };
    case SET_USER_EMAIL:
      return state = { ...state, email: action.payload };
    case SET_USER_GENDER:
      return state = { ...state, gender: action.payload };
    case SET_USER_DOB:
      return state = { ...state, dob: action.payload };
    case SET_USER_PADDRESS:
      return state = { ...state, p_address: action.payload };
    case SET_USER_PRADDRESS:
      return state = { ...state, pr_address: action.payload };
    case SET_USER_PROFILE_PIC:
      return state = { ...state, profile_pic: action.payload };
    case  SET_USER_PASSWORD:
      return state = { ...state, password: action.payload };
    default:
      return state;
  }
}
