export const SET_CUSTOMER="SET_CUSTOMER";
export const SET_CUSTOMER_DETAIL="SET_CUSTOMER_DETAIL";
export const SET_CUSTOMERID="SET_CUSTOMERID";
export const SET_PROFILEPIC="SET_PROFILEPIC";
export const SET_CUSTOMERNAME="SET_CUSTOMERNAME";
export const SET_CUSTOMERMOBILE="SET_CUSTOMERMOBILE";
export const SET_CUSTOMERMAIL="SET_CUSTOMERMAIL";
export const SET_CUSTOMERADDRESS="SET_CUSTOMERADDRESS";
export const SET_CUSTOMERGSTNO="SET_CUSTOMERGSTNO";
export const SET_CUSTOMERAADHAR="SET_CUSTOMERAADHAR";
export const SET_CUSTOMERPAN="SET_CUSTOMERPAN";
export const SET_CUSTOMERGSTCERTIFICATE="SET_CUSTOMERGSTCERTIFICATE";
export const SET_CUSTOMEROTHER1="SET_CUSTOMEROTHER1";
export const SET_CUSTOMEROTHER2="SET_CUSTOMEROTHER2";
export const SET_ALL_CUSTOMER="SET_ALL_CUSTOMER";
