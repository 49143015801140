import React, { Component } from "react";
import { connect } from "react-redux";
import EMPOrder from "../component/emporder";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  onLogout
} from "../../../../auth/actions";
import {
  createOrder,
  updateOrder,
  deleteOrder,
  viewAllOrder,
  viewVechileBooking
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <EMPOrder {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    // user: store.user
    // all_vehicles: store.all_vehicles,
    all_order: store.all_order,
    snackbar: store.snackbar,
    login: store.login,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createOrder: (admin_id,customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) => {
      dispatch(createOrder(admin_id,customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment))
    },
    updateOrder: (_id,admin_id, customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) => {
      dispatch(updateOrder(_id,admin_id, customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment))
    },
    deleteOrder: (_id,admin_id) => {
      dispatch(deleteOrder(_id,admin_id))
    },
    viewAllOrder: (admin_id) => {
      dispatch(viewAllOrder(admin_id))
    },
    viewVechileBooking: (admin_id) => {
      dispatch(viewVechileBooking(admin_id))
    },
    onLogout: () => {
      dispatch(onLogout());
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);