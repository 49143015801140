import React, { Component } from "react";
import { connect } from "react-redux";
import Battery from "../component/battery";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
    createBattery,
    viewAllBattery,
    updateBattery,
    deleteBattery
} from "../action";
export class Controller extends Component {
    render() {
        return (
            <Battery {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        // user: store.user
        all_battery: store.all_battery,
        snackbar: store.snackbar,
        login: store.login,

    };
};
export const mapDispatchToProps = dispatch => {
    return {
        createBattery: (battery_name, battery_price, battery_volt) => {
            dispatch(createBattery(battery_name, battery_price, battery_volt))
        },
        updateBattery: (_id, battery_name, battery_price, battery_volt) => {
          dispatch(updateBattery(_id, battery_name, battery_price, battery_volt))
        },
        deleteBattery: (_id) => {
          dispatch(deleteBattery(_id))
        },
        viewAllBattery: () => {
            dispatch(viewAllBattery())
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
          },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);