import React, { Component } from "react";
import { connect } from "react-redux";
// import vehicle from "../component/vehicledash";
import Manufacturingdash from "../../dashboard/component/manufacturingdash"
// import {
//   close_snack_bar
// } from "../../common/snackbar/action";
// import {
    // createCategory,
    // updateCategory,
    // deleteCategory,
//     viewAllmanufacturing
// } from "../action";



import {
  // view_dashboard_statistitcs,
  viewAllvehicle,
  dashConfirmvehicle,
  view_vehicles_delivery,
  // view_vechicle_booking,
  // view_all_manufactured,
  // view_all_noplate,
  // view_all_vechdash,
  // dash_Rtoreg,
  // dashoutdeliveryvehicle,
  // Noplatevehicle
}from "../../vechicle/action"
import{
  // viewAllOrder,
  // view_rto_reg,
  // view_pending_order,
  // view_all_quotation,
  // dashconvert_to_booking,
  viewDashManufacturing
}from"../../orders/action"

export class Controller extends Component {
  render() {
    return (
      < Manufacturingdash{...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_vehicles: store.all_vehicles,
    all_order: store.all_order,
    login: store.login
  };
};
export const mapDispatchToProps = dispatch => {
  return {

   

    // view_vehicles_status: () => {
    //   dispatch(view_vehicles_status())
    // },

   
    viewAllvehicle: (admin_id) => {
      dispatch(viewAllvehicle(admin_id))
    },
    view_vehicles_delivery: (admin_id) => {
      dispatch(view_vehicles_delivery(admin_id))
    },
    dashConfirmvehicle: (_id,admin_id) => {
      dispatch(dashConfirmvehicle(_id,admin_id))
    },
    // viewAllOrder: () => {
    //   dispatch(viewAllOrder())
    // },
    // view_rto_reg: () => {
    //   dispatch(view_rto_reg())
    // },
    // view_pending_order: () => {
    //   dispatch(view_pending_order())
    // },
    viewDashManufacturing: (admin_id) => {
      dispatch(viewDashManufacturing(admin_id))
    },
    // view_vechicle_booking: () => {
    //   dispatch(view_vechicle_booking())
    // },
    // view_dashboard_statistitcs: () => {
    //   dispatch(view_dashboard_statistitcs())
    // },
    // view_all_quotation: () => {
    //   dispatch(view_all_quotation())
    // },
    // view_all_manufactured: () => {
    //   dispatch(view_all_manufactured())
    // },
    // view_all_noplate: () => {
    //   dispatch(view_all_noplate())
    // },
    // view_all_vechdash: () => {
    //   dispatch(view_all_vechdash())
    // },
    // dashconvert_to_booking: (_id,advance_amount,estimated_delivery_date,mode) => {
    //   dispatch(dashconvert_to_booking(_id,advance_amount,estimated_delivery_date,mode))
    // },
    // dash_Rtoreg: (_id, challan_number, payment_slip) => {
    //   dispatch(dash_Rtoreg(_id, challan_number, payment_slip))
    // },
    // dashoutdeliveryvehicle: (_id) => {
    //   dispatch(dashoutdeliveryvehicle(_id))
    // },
    // Noplatevehicle: (_id,no_plate) => {
    //   dispatch(Noplatevehicle(_id,no_plate))
    // },
   
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);