import React, { Component } from 'react'
import Box from '@mui/material/Box';
import {Card,Grid,TableRow} from '@mui/material';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Typography,Dialog, DialogTitle, DialogActions, TextField, MenuItem, Stack, Input } from '@mui/material';

export default class allotedservice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manufactured: false,
      registered: false,
      noplate: false,
      booking: false,
      allottee: false,
      servicecomplete:false

    }
  }
  componentDidMount() {
    // this.props.viewAllvehicle();
    // this.props.view_dashboard_statistitcs();
    // this.props.view_all_quotation();
    // this.props.view_all_user();
    // this.props.view_vehicles_delivery();
    // this.props.view_rto_reg();
    // this.props.viewAllOrder();
    // this.props.view_pending_order();
    // this.props.viewDashManufacturing();
    // this.props.view_vechicle_booking();
    // this.props.view_all_manufactured();
    // this.props.view_all_noplate();
    // this.props.view_all_vechdash();
    // this.props.dash_Rtoreg();
    // this.props.viewAllService();
    this.props.viewAllServiceAllotted(this.props.login.user_id);
  }
  render() {
    const {
      all_vehicles,
      all_order,
      all_service,
      employee,
    } = this.props;
    return (
      <div>
         <Grid  item xs={12} md={12} style={{ marginLeft: "60px" }} sx={{ display: 'flex', flexDirection: "row", }}>
            <Card  style={{ height: '100%', width: "100%",marginRight:"10%" }}>
              <Grid className='orderdash'>
                <CardContent sx={{ fontSize: 20, display: 'flex', flexDirection: "row", fontWeight: 400, color: "white", }} >
                  <Typography style={{ fontSize: 20, marginTop: -10 }}> Vehicle Service </Typography>
                  <Typography style={{ color: "#D41111", marginLeft: "80%", marginTop: "-20px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_service.service_allotted.length}
                    
                    <Typography marginLeft={-2} sx={{ fontWeight: 600 }}>Pending</Typography>
                  </Typography>

                </CardContent></Grid>
              <TableContainer >
              <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                  <TableRow
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1 }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell style={{color:"tomato",fontWeight:"bold"}}>Customer</TableCell>
                          <TableCell style={{color:"tomato",fontWeight:"bold"}}>Employee</TableCell>
                          <TableCell style={{color:"tomato",textAlign:"center",fontWeight:"bold"}}>Action</TableCell>
                        


                        </TableRow>
                    {all_service.service_allotted.slice(0, 2).map((c) => {
                      return (
                        <TableRow
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1 }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell  >{c.customer}</TableCell>
                          <TableCell  >{c.employee}</TableCell>
                          {/* <TableCell>{quotation.category}</TableCell> */}
                          {/* <TableCell>{c.variant}</TableCell> */}
                          {/* <TableCell> */}
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          {/* </TableCell> */}
                          <TableCell> <Button
                            onClick={() => {
                              this.setState({
                                servicecomplete: true,
                                service_id: c._id,
                              });
                            }}
                            style={{ marginLeft: "2px" }}> <Typography marginTop={0} style={{ color: "#057E13", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Completed

                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>


            

            </Card>
          </Grid>
          <Dialog
          open={this.state.servicecomplete}
          onClose={() => {
            this.setState({ servicecomplete: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <FactCheckIcon style={{ fontSize: "60", color: "green", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ servicecomplete: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained" onClick={() => { this.props.serviceCompleted(this.state.service_id); this.setState({ servicecomplete: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog>




        <Dialog
          open={this.state.allottee}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "150px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>


              <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Allottee Employee"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  // defaultValue={this.state.model_id}
                  onChange={(e) => { this.setState({ emp_id: e.target.value }) }}
                >
                  {employee.all_users.map((e) => (
                  <MenuItem value={e._id} >
                    {e.name}
                  </MenuItem>
                   
                  ))}
                </TextField>

              </div>

              <Button
                className="btns"
                onClick={() => {
                  this.props.AllottedEmployee(
                    this.state.service_id, this.state.emp_id
                  );
                  this.setState({ allottee: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Allottee
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ allottee: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </div>
    )
  }
}
