import React, { Component } from "react";
import { connect } from "react-redux";
import Category from "../component/category";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  onLogout
} from "../../../../auth/actions";
import {
    createCategory,
    updateCategory,
    deleteCategory,
    viewAllCategory
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Category {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_category: store.all_category,
    snackbar: store.snackbar,
    login: store.login,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createCategory: (category_name) => {
      dispatch(createCategory(category_name))
    },
    updateCategory: (category_id,category_name) => {
      dispatch(updateCategory(category_id,category_name))
    },
    deleteCategory: (category_id) => {
      dispatch(deleteCategory(category_id))
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    onLogout: () => {
      dispatch(onLogout());
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);