import React, { Component } from "react";
import { connect } from "react-redux";
import Taskmanagement from "../Componenet/taskmanagement";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";

import {
  createtask,
  viewAlltask,
  viewAlltask_by_type
 
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Taskmanagement {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    // user: store.user
    all_color:store.all_color,
    snackbar: store.snackbar,
    login: store.login,
    taskmanagement: store.taskmanagement,

  };
};
export const mapDispatchToProps = dispatch => {
  return {

   
    createtask: (assigned_id, assigned_type,assign_time,assign_date,deadline_time,deadline_date,to_do) => {
      dispatch(createtask(assigned_id, assigned_type,assign_time,assign_date,deadline_time,deadline_date,to_do))
    },
    viewAlltask: () => {
      dispatch(viewAlltask())
    },
    viewAlltask_by_type: (type) => {
      dispatch(viewAlltask_by_type(type))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);