import {
  SET_ORDER,
  SET_RTOREG,
  SET_ORDER_PENDING,
  SET_VEHICLEBOOKING,
  SET_ALL_QUOTATION,
  SET_BOOKING_VECHILE,
  VIEW_DASHBOARD_MANUFACTURING,
  SET_ORDER_ID, SET_AMOUNT,

  SET_ORDERS_ID,
  SET_CUSTOMER_NAME,
  SET_CUSTOMER_ADDRESS,
  SET_CUSTOMER_MOBILE,
  SET_CUSTOMER_GSTNO,
  SET_VARIANT_NAME,
  SET_VARIANT_VOLT,
  SET_VARIANT_LENGTH,
  SET_VARIANT_WIDTH,
  SET_VARIANT_CAPACITY,
  SET_VARIANT_MOTOR,
  SET_VARIANT_RANGE,
  SET_BATTERY_NAME,
  SET_BATTERY_VOLTAGE,
  SET_COLOR_NAME,
  SET_MODEL_SPEED,
  SET_MODEL_NAME,
  SET_ONROAD_PRICE,
  SET_INSURANCE_PRICE,
  SET_REGISTRATION_PRICE,
  SET_TRANSPORTATION_PRICE,
  SET_TOTAL_PRICE,
  SET_QUANTITY,
  SET_BOOKING_DATE,
  SET_DELIVERY_DATE,
  SET_ESTIMATE_NO,
  SET_FILTERED_VALUE,
  SET_DISCOUNT_AMOUNT,
  SET_PAYMENT,
  SET_AADHAR,
  SET_PAN,
  SET_GSTCERT,
  SET_PHOTO,
  STATUS_BOOKING,
  STATUS_DELIVERED
} from "./constant";
const initial_state = {
  all_order: [],
  all_rtoreg: [],
  pending_order: [],
  vehicle_booking: [],
  all_quotation: [],
  booking_vehicle_details: [],
  dashboard_manufacturing: [],
  order_id: "",
  pending_amount: "",
  total_amount: "",

  order_qu_id: "",
  customer_name: "",
  customer_address: "",
  customer_mobile: "",
  customer_gstno: "",
  customer_aadhar: "",
  customer_pan: "",
  customer_gstcert: "",
  customer_photo: "",
  variant_name: "",
  variant_volt: "",
  variant_length: "",
  variant_width: "",
  variant_capacity: "",
  variant_motor: "",
  variant_range: "",
  battery_name: "",
  battery_voltage: "",
  color_name: "",
  model_speed: "",
  model_name: "",
  onroad_price: "",
  insurance_fee: "",
  registration_fee: "",
  transportation_cost: "",
  total_price: "",
  quantity: "",
  booking_date: "",
  delivery_date: "",
  count_quotas: "",
  discount_amount: "",
  payment: "",
  filtered_model: [],
  all_orders_by_admin_id:[],
  length:0,
  status_booking:"",
  status_delivered:""
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_ORDER:
      console.log("res", action.payload.data)
      return state = { ...state, all_order: action.payload.data, length: action.payload.count };
    case SET_FILTERED_VALUE:
      return state = { ...state, filtered_model: action.payload };
    case SET_ORDER_PENDING:
      return state = { ...state, pending_order: action.payload };
    case SET_RTOREG:
      return state = { ...state, all_rtoreg: action.payload };
    case SET_VEHICLEBOOKING:
      return state = { ...state, vehicle_booking: action.payload };
    case SET_ALL_QUOTATION:
      return state = { ...state, all_quotation: action.payload };
    case SET_BOOKING_VECHILE:
      return state = { ...state, booking_vehicle_details: action.payload };
    case VIEW_DASHBOARD_MANUFACTURING:
      return state = { ...state, dashboard_manufacturing: action.payload };
    case SET_ORDER_ID:
      return state = { ...state, order_id: action.payload };
    case SET_AMOUNT:
      return state = { ...state, total_payment: action.payload.total_amount, pending_amount: action.payload.pending_amount };

    //for pdf quotations

    case SET_ORDERS_ID:
      return state = { ...state, order_qu_id: action.payload };
    case SET_CUSTOMER_NAME:
      return state = { ...state, customer_name: action.payload };
    case SET_CUSTOMER_ADDRESS:
      return state = { ...state, customer_address: action.payload };
    case SET_CUSTOMER_MOBILE:
      return state = { ...state, customer_mobile: action.payload };
    case SET_CUSTOMER_GSTNO:
      return state = { ...state, customer_gstno: action.payload };
    case SET_AADHAR:
      return state = { ...state, customer_aadhar: action.payload };
    case SET_PAN:
      return state = { ...state, customer_pan: action.payload };
    case SET_GSTCERT:
      return state = { ...state, customer_gstcert: action.payload };
    case SET_PHOTO:
      return state = { ...state, customer_photo: action.payload };
    case SET_VARIANT_NAME:
      return state = { ...state, variant_name: action.payload };
    case SET_VARIANT_VOLT:
      return state = { ...state, variant_volt: action.payload };
    case SET_VARIANT_LENGTH:
      return state = { ...state, variant_length: action.payload };
    case SET_VARIANT_WIDTH:
      return state = { ...state, variant_width: action.payload };
    case SET_VARIANT_CAPACITY:
      return state = { ...state, variant_capacity: action.payload };
    case SET_VARIANT_MOTOR:
      return state = { ...state, variant_motor: action.payload };
    case SET_VARIANT_RANGE:
      return state = { ...state, variant_range: action.payload };
    case SET_BATTERY_NAME:
      return state = { ...state, battery_name: action.payload };
    case SET_BATTERY_VOLTAGE:
      return state = { ...state, battery_voltage: action.payload };
    case SET_COLOR_NAME:
      return state = { ...state, color_name: action.payload };
    case SET_MODEL_SPEED:
      return state = { ...state, model_speed: action.payload };
    case SET_MODEL_NAME:
      return state = { ...state, model_name: action.payload, filtered_model: action.payload };
    case SET_ONROAD_PRICE:
      return state = { ...state, onroad_price: action.payload };
    case SET_INSURANCE_PRICE:
      return state = { ...state, insurance_fee: action.payload };
    case SET_REGISTRATION_PRICE:
      return state = { ...state, registration_fee: action.payload };
    case SET_TRANSPORTATION_PRICE:
      return state = { ...state, transportation_cost: action.payload };
    case SET_TOTAL_PRICE:
      return state = { ...state, total_price: action.payload };
    case SET_QUANTITY:
      return state = { ...state, quantity: action.payload };
    case SET_BOOKING_DATE:
      return state = { ...state, booking_date: action.payload };
    case SET_DELIVERY_DATE:
      return state = { ...state, delivery_date: action.payload };
    case SET_ESTIMATE_NO:
      return state = { ...state, count_quotas: action.payload };
    case SET_DISCOUNT_AMOUNT:
      return state = { ...state, discount_amount: action.payload };
    case SET_PAYMENT:
      return state = { ...state, payment: action.payload };
      case STATUS_BOOKING:
      console.log("res", action.payload)
      return state = { ...state, status_booking: action.payload };
      case STATUS_DELIVERED:
      return state = { ...state, status_delivered: action.payload };
   
    default:
      return state;
  }
}
