import {
  SET_VEHICLE,
  SET_VEHICLES_DELIVERY,
  SET_DASHBOARD_STATISTICS,
  SET_VEHICLEBOOKING,
  SET_RTO,
  SET_RTO_NOPLATE,
  SET_VECH_DASH

} from "./constant";
const initial_state = {
  all_vehicles: [],
  vehicle_to_be_delivered: [],
  vehicle_booking: [],
  manufactured: '',
  manufacturing: '',
  pending: '',
  all_rto: [],
  all_rto_noplate: [],
  all_vech_dash:[],
  length:0
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_VEHICLE:
      return state = { ...state, all_vehicles: action.payload.data,length: action.payload.count };
    // case SET_ONE_USER:
    //   return state = { ...state, one_user: action.payload };

    case SET_DASHBOARD_STATISTICS:
      return state = { ...state, manufacturing: action.payload.manufacturing, manufactured: action.payload.manufactured, pending: action.payload.pending };
    case SET_VEHICLES_DELIVERY:
      return state = { ...state, vehicle_to_be_delivered: action.payload };
    // case SET_DASHBOARD_STATISTICS:
    //   return state = { ...state, vehicle_to_be_delivered: action.payload };
    case SET_VEHICLEBOOKING:
      return state = { ...state, vehicle_booking: action.payload };
    case SET_RTO:
      return state = { ...state, all_rto: action.payload };

    // default:
    case SET_RTO_NOPLATE:
      return state = { ...state, all_rto_noplate: action.payload };
    case SET_VECH_DASH:
      return state = { ...state, all_vech_dash: action.payload };

    default:
      return state;
  }
}
