import React, { Component } from 'react'
import {
    Grid,
    // Card,
    // CardContent,
    Typography,
    Box,
    IconButton,
    Stack, Input,
    Avatar
} from "@mui/material";
// import React from "react";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
// import "../../style/variant.css"
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import VisibilityIcon from '@mui/icons-material/Visibility';

import LoaderCon from "../../../common/loader/containers/loader_cont";
import Snackbar from "../../../common/snackbar/components/snackbar";


export default class addvariant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            variant_name: "",
            profile_pic: "",
            basic_salary:"",

        }
    }
    componentDidMount() {
        this.props.viewAlldepartment();

    }
    render() {
        const {
            superadmin,
            employee,
            snackbar,
            close_snack_bar
            // all_category,
            // all_model
        } = this.props;
        console.log(superadmin.profile_pic)
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='makeorder'>
                        <center style={{ display: "flex" }}>
                            <Link to="/employee" style={{ textDecoration: "none" }}>
                                <IconButton
                                    style={{ marginLeft: "18px" }}

                                >
                                    <ArrowBackIcon style={{ color: "white" }} />
                                </IconButton>
                            </Link>


                            <Typography style={{ color: "white ", marginLeft: "28px", fontSize: 16, marginTop: "8px" }}>UPDATE TEAM LEADER</Typography>
                        </center>
                    </Box>


                </Grid>
                <Grid style={{ marginLeft: "50px", width: "300px" }}>
                    <Typography style={{ color: "black ", fontSize: 18, marginTop: "8px", fontWeight: "500", marginLeft: "30px" }}>TEAM LEADER PICTURE</Typography>


                    <Stack
                        spacing={1}
                        style={{
                            textDecoration: "none",
                            // display: "inline-block",
                            padding: "0 30px",
                            marginTop: "10px",
                        }}
                        className="imagemedia"
                    >
                        <Avatar
                            alt="Profile"
                            // src={superadmin.profile_pic !== "" ? URL.createObjectURL(superadmin.profile_pic) : superadmin.profile_pic}
                            src={superadmin.profile_pic}
                            style={{ height: "114px", width: "114px", borderRadius: 100 }}
                            variant={"rounded"}
                        />
                        {/* {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt={imageAlt}
                                            className="displayed-image"
                                        />
                                    )} */}
                        <Input
                            type="file"
                            // onChange={(e)=>this.handleFileChange(e)}
                            onChange={(e) => { this.setState({ profile_pic: e.target.files[0] }) }}
                        />
                    </Stack>
                </Grid>

                <Grid  class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}>Department </label>
                        <select style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            color:"#5C5C5C",
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",
                            // borderRadius:8

                        }}
                            onChange={(e) => { this.setState({ _id: e.target.value }) }}
                            name=""
                            defaultvalue={this.state.eventName}
                            select

                        >
                            <option value='' style={{color:"grey"}}>
                                Select Department
                            </option>
                            {superadmin.all_department.map((c) => {
                                        return (
                            <option value={c._id}>
                            {c.department_name}
                            </option>
                              );
                            })}
                            
                        </select>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


           </Grid>
           <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 55, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>Basic Salary</label>
                        <input required={true}
                        type={"number"} 
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            onChange={(e) => {
                                this.setState({ basic_salary: e.target.value });
                            }}
                        >

                        </input>

           </Grid>
               </Grid>
               
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>Employee Name</label>
                        <input
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            value={superadmin.name}


                            onChange={(e) => {
                                this.props.setName(e.target.value);
                                // this.props.viewCategortModel(e.target.value);
                            }}
                        >

                        </input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 40, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>Mobile</label>
                        <input
                            type="text" pattern="[0-9]{10}" name="formName" placeholder="mobile No"
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                           

                            value={superadmin.mobile}
                            min={0}
                            // onKeyDown="if(this.value.length==2 && event.keyCode!=8) return false;"
                            maxLength={10}


                            onChange={(e) => {
                                this.props.setMobile(e.target.value);
                            }}
                        >

                        </input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>

                </Grid>



                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> Email </label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={superadmin.email}
                            onChange={(e) => { this.props.setEmail(e.target.value) }}


                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 40, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> Password</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={superadmin.password}
                            onChange={(e) => { this.props.setPassword(e.target.value) }}
                        ></input>



                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>


                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                    <label style={{ marginTop: -10, }}> Designation </label>
                    <input style={{
                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                        height: 30,
                        // marginLeft:-55,
                        marginTop: 0,
                        width: "300px",
                        border: "2px solid #000000",
                        // borderRadius:8
                    }}

                        value={superadmin.designation}
                        onChange={(e) => { this.setState({ designation: e.target.value }) }}


                    ></input>


                    {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                </Grid>

                <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                    <label style={{ marginTop: -10, }}> time </label>
                    <select style={{
                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                        height: 30,
                        // marginLeft:-55,
                        marginTop: 0,
                        width: "300px",
                        border: "2px solid #000000",
                        // borderRadius:8

                    }}
                        onChange={(e) => { this.setState({ time: e.target.value }) }}
                        name=""
                        defaultvalue={superadmin.time}
                        select

                    >
                        <option value=''>
                            time
                        </option>
                        <option value='10 am'>
                            10am
                        </option>
                        <option value='5 pm '>
                            5pm
                        </option>
                    </select>


                    {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                </Grid>

                <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                    <label style={{ marginTop: -10, }}> shift </label>
                    <select style={{
                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                        height: 30,
                        // marginLeft:-55,
                        marginTop: 0,
                        width: "300px",
                        border: "2px solid #000000",
                        // borderRadius:8

                    }}
                        onChange={(e) => { this.setState({ shift: e.target.value }) }}
                        name=""
                        defaultvalue={superadmin.shift}
                        select

                    >
                        <option value=''>
                            shift
                        </option>
                        <option value='1st'>
                            1st
                        </option>
                        <option value='2nd'>
                            2nd
                        </option>
                    </select>


                    {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                </Grid>

                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> Gender </label>
                        <select style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",
                            // borderRadius:8

                        }}
                            value={superadmin.gender}
                            onChange={(e) => { this.props.setGender(e.target.value) }}
                            name=""
                            // value={this.state.eventName}
                            select

                        >
                            <option value=''>
                                SELECT GENDER
                            </option>
                            <option value='male'>
                                Male
                            </option>
                            <option value='female'>
                                Female
                            </option>
                        </select>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 10, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: 30, }} >D.O.B</label>
                        <input
                            type={"date"} style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                marginLeft: 30,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}
                            value={superadmin.dob}
                            onChange={(e) => { this.props.setDob(e.target.value) }}
                        ></input>



                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex', flexDirection: 'coloum' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}


                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex', flexDirection: "column" }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}

      /> */}
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}>  Permanent Address </label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 90,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "650px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={superadmin.per_address}
                            onChange={(e) => { this.props.setPer_address(e.target.value) }}


                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> Present Adress </label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 90,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "650px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={superadmin.pre_address}
                            onChange={(e) => { this.props.setPre_address(e.target.value) }}


                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>






                </Grid>

                <Grid class="register-form-custom-select" style={{ marginTop: 25, display: 'flex', marginBottom: 25 }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}
<Link to="/" style={{ textDecoration: "none", marginLeft: "0%" }}>

                    <Typography marginLeft={40} sx={{ borderRadius: "15px" }}>
                        <Button onClick={() => {
                             this.props.update_profile_pic(superadmin.teamleader_id,this.state._id, superadmin.name, superadmin.mobile, superadmin.email, superadmin.password, superadmin.gender, superadmin.profile_pic, this.state.profile_pic, superadmin.dob, superadmin.per_address, superadmin.pre_address,superadmin.basic_salary);
                            // this.setState({ add: false });
                        }}
                            className='button' variant='contained' sx={{ borderRadius: "20px" }}>Update teamleader</Button></Typography>
</Link>

                </Grid>
                <LoaderCon />
                <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />

            </Grid>
        )
    }
}
