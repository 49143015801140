import {
  // SET_BATTERY,
  // SET_BATTERY_DETAILS,
  SET_ALL_ADMIN_DETAILS,
  SET_CALL_TAKEN,
  SET_NEW_LEAD,
  SET_TOTAL_LEAD,
  SET_TOTAL_CONVERSION,
  SET_DEPARTMENT,
  SET_TL_ID,
  SET_TL_DEPARTMENT_NAME,
  SET_TL_NAME,
  SET_TL_MOBILE,
  SET_TL_EMAIL,
  SET_TL_GENDER,
  SET_TL_DOB,
  SET_TL_PADDRESS,
  SET_TL_PRADDRESS,
  SET_TL_PROFILE_PIC,
  SET_TL_PASSWORD,
  SET_TL_BASICSALARY,
  SET_TL_DEPARTMENT,
  SET_ONETL_USER,
  SET_TL_DESIGNATION,
  SET_TL_TIME,
  SET_TL_SHIFT

} from "./constant";
const initial_state = {
  // all_battery: [],
  all_admin_details: [],
  call_details: "",
  new_lead: "",
  set_total_lead: "",
  total_conversion: "",
  all_department: [],
  teamleader_id: "",
  department_name: "",
  name: "",
  mobile: "",
  email: "",
  password:"",
  gender: "",
  profile_pic: "",
  dob: "",
  per_address: "",
  pre_address: "",
  basic_salary: "",
  department: "",
  designation: "",
  time: "",
  shift: "",
  all_tl:[],

};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    //   case SET_BATTERY:
    //     return state = { ...state, all_battery: action.payload };
    //   case SET_BATTERY_DETAILS:
    //     return state = { ...state, battery_details: action.payload };
    case SET_ALL_ADMIN_DETAILS:
      return state = { ...state, all_admin_details: action.payload };

    case SET_CALL_TAKEN:
      return state = { ...state, call_details: action.payload };

    case SET_NEW_LEAD:
      return state = { ...state, new_lead: action.payload };

    case SET_TOTAL_LEAD:
      return state = { ...state, new_lead: action.payload };
    case SET_TOTAL_CONVERSION:
      return state = { ...state, total_conversion: action.payload };
    case SET_DEPARTMENT:
      return state = { ...state, all_department: action.payload };



/////////////////////////////////////////////////////////////////////////////

      case SET_ONETL_USER:
        return state = { ...state, all_tl: action.payload };
      case SET_TL_ID:
        return state = { ...state, teamleader_id: action.payload };
      case SET_TL_DEPARTMENT_NAME:
        return state = { ...state, department_name: action.payload };
      case SET_TL_NAME:
        return state = { ...state, name: action.payload };
      case SET_TL_MOBILE:
        return state = { ...state, mobile: action.payload };
      case SET_TL_EMAIL:
        return state = { ...state, email: action.payload };
      case  SET_TL_PASSWORD:
        return state = { ...state, password: action.payload };
      case SET_TL_GENDER:
        return state = { ...state, gender: action.payload };
      case SET_TL_PROFILE_PIC:
        return state = { ...state, profile_pic: action.payload };
      case SET_TL_DOB:
        return state = { ...state, dob: action.payload };
      case SET_TL_PADDRESS:
        return state = { ...state, per_address: action.payload };
      case SET_TL_PRADDRESS:
        return state = { ...state, pre_address: action.payload };
      case SET_TL_BASICSALARY:
        return state = { ...state, basic_salary: action.payload };
      case SET_TL_DEPARTMENT:
        return state = { ...state, department: action.payload };
      case SET_TL_DESIGNATION:
        return state = { ...state, designation: action.payload };
      case SET_TL_TIME:
        return state = { ...state, time: action.payload };
      case SET_TL_SHIFT:
        return state = { ...state, shift: action.payload };
        ///////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
}