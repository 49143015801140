import React, { Component } from 'react'
// import Manufacturingdash from "../../dashboard/component/manufacturingdash";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from "../../../../common/snackbar/components/snackbar";

// import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import CardActions from '@mui/material/CardActions';
import { Typography, Card, Dialog, DialogTitle, DialogActions, TextField, MenuItem, Stack, Input, CardMedia } from '@mui/material';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';


import "../../style/order.css"
import teamworkkk from '../../../image/teamwork 1.png'
import teamworkk from '../../../image/teamwork 2.png'
import teamwork from '../../../image/teamwork 3.png'
import Loader from '../../../../common/loader/containers/loader_cont';

export default class dashbord extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manufactured: false,
      registered: false,
      noplate: false,
      booking: false,
      allottee: false,
      servicecomplete: false


    }
  }

  componentDidMount() {
    this.props.viewAllvehicle(this.props.login.user_id);
    this.props.view_dashboard_statistitcs(this.props.login.user_id);
    // this.props.view_all_quotation(this.props.login.user_id);
    this.props.view_all_user(this.props.login.user_id);
    this.props.view_vehicles_delivery(this.props.login.user_id);
    this.props.view_rto_reg(this.props.login.user_id);
    this.props.viewAllOrder(this.props.login.user_id);
    this.props.view_pending_order(this.props.login.user_id);
    this.props.viewDashManufacturing(this.props.login.user_id);
    this.props.view_vechicle_booking(this.props.login.user_id);
    this.props.view_all_manufactured(this.props.login.user_id);
    this.props.view_all_noplate(this.props.login.user_id);
    this.props.view_all_vechdash(this.props.login.user_id);
    // this.props.dash_Rtoreg();
    this.props.viewAllService(this.props.login.user_id);
    this.props.viewAllServiceAllotted(this.props.login.user_id);
  }
  render() {
    const {
      all_vehicles,
      all_order,
      all_service,
      employee,
      snackbar,
      login,
      close_snack_bar
    } = this.props;
    return (
      <Grid container className='dashbord' style={{ overflow: "hidden", overflowX: "hidden", }}>

        <Grid className='topbutton' item xs={12} md={12} style={{ display: "flex", flexDirection: "row" }}>
          <Link to="/addcustomer" style={{ textDecoration: 'none', marginLeft: "40%" }}><Typography sx={{ borderRadius: "15px" }}>
            <Button className='button' variant='contained' sx={{ borderRadius: "20px" }}>Add Customer</Button>
          </Typography>
          </Link>
          <Link to="/addorder" style={{ textDecoration: 'none', marginLeft: "4%" }}><Typography marginLeft={{}}>
            <Button className='button' variant='contained' sx={{ borderRadius: "20px" }}>Create Order</Button>
          </Typography>
          </Link>
          {/* <Link to="/addservice" style={{ textDecoration: 'none',marginLeft:"4%"  }}>
            <Typography marginLeft={{}}>
              <Button className='button' variant='contained' sx={{ borderRadius: "20px" }}>Book Service</Button>
            </Typography>
          </Link> */}
        </Grid>

        {/* first line start */}
        <Grid flexDirection={"row"} display={"flex"} xs={12} md={12} style={{ marginTop: "20px", }}>
          <Grid item xs={12} md={12} lg={7} width={520} marginLeft={-2} sx={{ display: 'flex', flexDirection: "row" }}>
            <Card className='countdash' style={{ height: '200px', width: "290px" }}>
              <Grid style={{ marginLeft: 15 }}>
                <CardContent sx={{ fontSize: 22, display: 'flex', flexDirection: "row", fontWeight: "600", fontFamily: "Montserrat", fontStyle: "normal" }}>
                  Manufacturing   <Typography style={{ color: "#9317F4", fontFamily: "Montserrat", fontStyle: "normal", marginTop: -6 }} sx={{ fontWeight: 600 }} marginLeft={5} fontSize={35}>{this.props.all_vehicles.manufacturing}</Typography>
                </CardContent>
                <CardContent sx={{ marginTop: -1.5, fontSize: 22, display: 'flex', flexDirection: "row", fontWeight: "600", fontFamily: "Montserrat", fontStyle: "normal" }}>
                  Manufactured   <Typography sx={{ fontWeight: 600, marginTop: -0.5, fontFamily: "Montserrat", fontStyle: "normal", marginTop: -1 }} style={{
                    color: "#16C35B"
                  }} marginLeft={6} fontSize={35}>{this.props.all_vehicles.manufactured}</Typography>
                </CardContent>
                <CardContent sx={{ marginTop: -1, fontSize: 22, display: 'flex', flexDirection: "row", fontWeight: "600", fontFamily: "Montserrat", fontStyle: "normal", }}>
                  Pending Service   <Typography sx={{ fontWeight: 600, fontFamily: "Montserrat", fontStyle: "normal", marginTop: -1 }} style={{ color: "#D41111" }} marginTop={-1} marginLeft={4} fontSize={35}>{this.props.all_vehicles.pending}</Typography>
                </CardContent>
              </Grid>
            </Card>

          </Grid>


          <Grid item xs={12} md={8} lg={12} style={{ marginLeft: "30px" }} >

            <Card className='dashmanufact' style={{ height: '200px', width: "400px", }}>
              <Grid className='orderdash'>
                <CardContent style={{ fontSize: 25, display: 'flex', flexDirection: "row", fontWeight: 600, color: "white" }}  >
                  <Typography style={{ fontSize: 20, marginTop: -10 }}> Manufacturing</Typography>   <Typography style={{ color: "#D41111", marginLeft: "160px", marginTop: "-20px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_order.dashboard_manufacturing.length}
                    <Typography marginLeft={-2} sx={{ fontWeight: 600 }}>Pending</Typography>
                  </Typography>
                </CardContent></Grid>
              <TableContainer >
                <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>
                  {/* <TableHead className="">
                    <TableRow >
                      <TableCell style={{ fontWeight: "600" }}>Customer</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Quotation</TableCell>
                     
                      <TableCell style={{ fontWeight: "600" }}>Order</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                    {all_order.dashboard_manufacturing.slice(0, 3).map((c) => {
                      return (
                        <TableRow
                          // key={row.name}
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1, marginTop: "-10px" }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell style={{ fontSize: 14 }}>{c.customer}</TableCell>
                          <TableCell>
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          </TableCell>
                          <TableCell> <Button onClick={() => {
                            this.setState({
                              manufactured: true,
                              vechicle_id: c._id,
                            });
                          }} style={{ marginLeft: "2px" }}> <Typography marginTop={0} style={{ color: "#B4BDBB", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Completed



                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* {all_order.vehicle_booking.slice(0,3).map((c) => {
                return (
                  <Grid>

                    <CardContent sx={{ display: 'flex', flexDirection: "row", }} >
                      <Typography style={{ fontSize: 14, fontWeight: 600, marginTop: 5 }}>{c.customer}
                      </Typography>
                      <Grid style={{ marginLeft: 25 }}>
                        <Button style={{ marginLeft: "20px", }}> <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                          Pending</Typography></Button>


                        <Button style={{ marginLeft: "2px" }}> <Typography marginTop={0} style={{ color: "#B4BDBB", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                          Completed

                        </Typography> </Button>
                      </Grid>
                    </CardContent>
                    <Box style={{ color: "red", height: '3px' }} marginTop={-4}><hr /></Box>
                  </Grid>
                );
              })} */}



              {all_order.dashboard_manufacturing.length > 3 && <Link to="/manufacturingdash" style={{ textDecoration: "none" }}>


                <Button style={{ textDecoration: "none", marginLeft: "38%", marginTop: "4px", }}><Typography ml={0} style={{
                  color: "#2A367C", marginTop: "0px", fontSize: "12px", textDecoration: "none", width: "100%"
                }}>View More</Typography></Button>
              </Link>}

            </Card>

          </Grid>


          <Grid item xs={12} md={12} style={{ marginLeft: "60px" }} sx={{ display: 'flex', flexDirection: "row", }}>
            <Card className='dashquatation' style={{ height: '200px', width: "390px", }}>
              <Grid className='orderdash'>
                <CardContent sx={{ fontSize: 20, display: 'flex', flexDirection: "row", fontWeight: 400, color: "white", }} >
                  <Typography style={{ fontSize: 20, marginTop: -10 }}> Quotation</Typography>
                  <Typography style={{ color: "#D41111", marginLeft: "208px", marginTop: "-20px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_order.all_quotation.length}
                    <Typography marginLeft={-2} sx={{ fontWeight: 600 }}>Pending</Typography>
                  </Typography>

                </CardContent></Grid>
              <TableContainer >
                <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>


                  {/* <TableHead className="">
                    <TableRow >
                      <TableCell style={{ fontWeight: "600" }}>Customer</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Quotation</TableCell>
                      <TableCell>Variant</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Status</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Order</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                    {all_order.all_quotation.slice(0, 3).map((quotation) => {
                      return (
                        <TableRow
                          // key={row.name}
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1 }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell>{quotation.customer}</TableCell>
                          {/* <TableCell>{quotation.category}</TableCell> */}
                          <TableCell>{quotation.variant}</TableCell>
                          <TableCell>
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          </TableCell>
                          <TableCell> <Button onClick={() => {
                            this.setState({
                              booking: true,

                              order_id: quotation._id,
                              advance_amount: quotation.advance_amount,
                              estimated_delivery_date: quotation.estimated_delivery_date,
                              payment_mode: quotation.mode
                            });
                          }}
                            style={{ marginLeft: "2px" }}> <Typography marginTop={0} style={{ color: "#B4BDBB", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Booking

                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>


              {all_order.all_quotation.length > 3 && <Link to="/paymentdash" style={{ textDecoration: "none" }}>
                <Button style={{ marginLeft: "40%", marginTop: "4px" }}> <Typography ml={0} style={{
                  color: "#2A367C", fontSize: "12px"
                }}>View More</Typography></Button></Link>}

            </Card>
          </Grid>
        </Grid>



        {/* second line start */}
        <Grid item xs={12} md={6} lg={7} style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
          <Grid item xs={6} md={8} sx={{ display: 'flex', flexDirection: "row", }}>
            <Card className='regdash' style={{ height: '200px', width: "390px", }}>
              <Grid className='orderdash'>
                <CardContent sx={{ fontSize: 18, display: 'flex', flexDirection: "row", fontWeight: 400, color: "white" }}  >
                  {/* RTO Registration   <Typography style={{ color: "#D41111", marginLeft: "130px", fontWeight: 600, marginTop: -15 }} sx={{ fontWeight: 600 }} fontSize={16}>60
                    <Typography marginLeft={-2} sx={{ fontWeight: 600 ,marginTop:-1}}>Pending</Typography>
                  </Typography> */}
                  <Typography style={{ fontSize: 20, marginTop: -10, width: "100%" }}> RTO Registration </Typography>
                  <Typography style={{ color: "#D41111", marginLeft: "100px", marginTop: "-18px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_vehicles.all_rto.length}
                    <Typography marginLeft={-3} sx={{ fontWeight: 600 }}>Pending
                    </Typography>
                  </Typography>
                </CardContent></Grid>

              <TableContainer >
                <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>


                  {/* <TableHead className="">
                    <TableRow >
                      <TableCell style={{ fontWeight: "600" }}>Customer</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Quotation</TableCell>
                      <TableCell>Variant</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Status</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Order</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                    {all_vehicles.all_rto.slice(0, 3).map((c) => {
                      return (
                        <TableRow
                          // key={row.name}
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1 }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell>{c.customer}</TableCell>
                          {/* <TableCell>{quotation.category}</TableCell> */}
                          {/* <TableCell>{c.variant}</TableCell> */}
                          <TableCell>
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          </TableCell>
                          <TableCell> <Button onClick={() => {
                            this.setState({
                              registered: true,
                              vechicle_id: c._id,
                            });
                          }} style={{ marginLeft: "2px" }}> <Typography marginTop={0} style={{ color: "#B4BDBB", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Completed

                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>





              {all_vehicles.all_rto.length > 3 && <Link to="/registrationdash" style={{ textDecoration: "none" }}>
                <Button style={{ marginLeft: "40%", marginTop: "18px" }}> <Typography ml={0} style={{
                  color: "#2A367C", fontSize: "12px"
                }}>View More</Typography></Button></Link>}


            </Card>
          </Grid>


          <Grid item xs={6} md={6} lg={7} style={{ marginLeft: "15px" }} sx={{ display: 'flex', flexDirection: "row", }}>
            <Card className='noplatedash' style={{ height: '200px', width: "420px", }}>

              <Grid className='orderdash'>
                <CardContent sx={{ fontSize: 18, display: 'flex', flexDirection: "row", fontWeight: 400, color: "white" }}  >
                  {/* RTO Registration   <Typography style={{ color: "#D41111", marginLeft: "130px", fontWeight: 600, marginTop: -15 }} sx={{ fontWeight: 600 }} fontSize={16}>60
                    <Typography marginLeft={-2} sx={{ fontWeight: 600 ,marginTop:-1}}>Pending</Typography>
                  </Typography> */}
                  <Typography style={{ fontSize: 20, marginTop: -10, width: "100%" }}> RTO Number Plate </Typography>
                  <Typography style={{ color: "#D41111", marginLeft: "100px", marginTop: "-19px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_vehicles.all_rto_noplate.length}
                    <Typography marginLeft={-3} sx={{ fontWeight: 600 }}>Pending
                    </Typography>
                  </Typography>
                </CardContent></Grid>
              <TableContainer >
                <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>


                  {/* <TableHead className="">
                    <TableRow >
                      <TableCell style={{ fontWeight: "600" }}>Customer</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Quotation</TableCell>
                      <TableCell>Variant</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Status</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Order</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                    {all_vehicles.all_rto_noplate.slice(0, 3).map((c) => {
                      return (
                        <TableRow
                          // key={row.name}
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1 }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell>{c.customer}</TableCell>
                          {/* <TableCell>{quotation.category}</TableCell> */}
                          {/* <TableCell>{c.variant}</TableCell> */}
                          <TableCell>
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          </TableCell>
                          <TableCell> <Button onClick={() => {
                            this.setState({
                              noplate: true,
                              _id: c._id,
                              no_plate: c.no_plate,

                            });
                          }} style={{ marginLeft: "2px" }}> <Typography marginTop={0} style={{ color: "#B4BDBB", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Received


                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>


              {all_vehicles.all_rto_noplate.length > 3 && <Link to="/numberplatedash" style={{ textDecoration: "none" }}>
                <Button style={{ marginLeft: "40%", marginTop: "18px" }}> <Typography ml={0} style={{
                  color: "#2A367C", fontSize: "12px"
                }}>View More</Typography></Button></Link>}



            </Card>
          </Grid>




        </Grid>



        {/* third line start */}


        <Dialog
          open={this.state.noplate}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "190px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  // type={"date"}
                  required
                  id="outlined-required"
                  label="Vehicle No"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ no_plate: e.target.value });
                  }}
                />
              </div>
              {/* <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    // type={"date"}
                                    required
                                    id="outlined-required"
                                    label="Payment Slip"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    //   value={this.state.category_name}
                                    onChange={(e) => {
                                        this.setState({ payment_slip: e.target.value });
                                    }}
                                />
                            </div> */}

              {/* <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Mode "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // defaultValue={this.state.model_id}
                                    onChange={(e) => { this.setState({ mode: e.target.value }) }}
                                >
                                   
                                    <MenuItem value={"cash"} >
                                       
                                    </MenuItem>
                                    <MenuItem value={"cheque"}>
                                       
                                        Cheque
                                    </MenuItem>
                                    <MenuItem value={"onlinepay"}>
                                       
                                    </MenuItem>
                                    
                                </TextField>

                            </div> */}

              <Button
                className="btns"
                onClick={() => {
                  this.props.Noplatevehicle(
                    // this.state.id,
                    this.state._id,
                    this.state.no_plate,
                    this.props.login.user_id
                    // this.state.payment_slip,
                    // this.state.mode
                    // this.state.category_name
                  );
                  this.setState({ noplate: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Add
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ noplate: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>


        <Dialog
          open={this.state.manufactured}
          onClose={() => {
            this.setState({ manufactured: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <NoCrashOutlinedIcon style={{ fontSize: "60", color: "green", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ manufactured: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained" onClick={() => { this.props.dashConfirmvehicle(this.state.vechicle_id, this.props.login.user_id); this.setState({ manufactured: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog><Dialog
          open={this.state.servicecomplete}
          onClose={() => {
            this.setState({ servicecomplete: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <FactCheckIcon style={{ fontSize: "60", color: "green", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ servicecomplete: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained" onClick={() => { this.props.serviceCompleted(this.state.service_id); this.setState({ servicecomplete: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog>




        <Dialog
          open={this.state.allottee}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "150px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>


              <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Allottee Employee"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  // defaultValue={this.state.model_id}
                  onChange={(e) => { this.setState({ emp_id: e.target.value }) }}
                >
                  {employee.all_users.map((e) => (
                    <MenuItem value={e._id} >
                      {e.name}
                    </MenuItem>

                  ))}
                </TextField>

              </div>

              <Button
                className="btns"
                onClick={() => {
                  this.props.AllottedEmployee(
                    this.state.service_id, this.state.emp_id
                  );
                  this.setState({ allottee: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Allottee
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ allottee: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>


        <Dialog
          open={this.state.booking}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "300px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  type={"date"}
                  required
                  id="outlined-required"
                  label="Estimate delivery Date"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ estimated_delivery_date: e.target.value });
                  }}
                />
              </div>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  // type={"date"}
                  required
                  id="outlined-required"
                  label="Advance Amount"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ advance_amount: e.target.value });
                  }}
                />
              </div>
              <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Mode "
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  // defaultValue={this.state.model_id}
                  onChange={(e) => { this.setState({ mode: e.target.value }) }}
                >
                  {/* {all_model.all_model.map((e) => ( */}
                  <MenuItem value={"cash"} >
                    {/* {e.model_name} */}
                    Cash
                  </MenuItem>
                  <MenuItem value={"cheque"}>
                    {/* {e.model_name} */}
                    Cheque
                  </MenuItem>
                  <MenuItem value={"onlinepay"}>
                    {/* {e.model_name} */}
                    Online Pay
                  </MenuItem>
                  {/* ))} */}
                </TextField>

              </div>
              <Link to="/bookings" style={{ textDecoration: "none", marginLeft: "0%" }}>

                <Button
                  className="btns"
                  onClick={() => {
                    this.props.dashconvert_to_booking(
                      // this.state.id,
                      this.state.order_id,
                      this.props.login.user_id,
                      this.state.advance_amount,
                      this.state.estimated_delivery_date,
                      this.state.mode
                      // this.state.category_name
                    );
                    this.setState({ booking: false });
                  }}
                  style={{
                    marginLeft: "20px",
                    // backgroundColor: "ButtonFace",
                    marginBottom: "30px",
                    marginTop: "20px",
                    color: "white"
                  }}
                >
                  Update
                </Button>
              </Link>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ booking: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>





        <Dialog
          open={this.state.registered}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "250px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  // type={"date"}
                  required
                  id="outlined-required"
                  label="Challan No"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ challan_number: e.target.value });
                  }}
                />
              </div>
              {/* <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    // type={"date"}
                                    required
                                    id="outlined-required"
                                    label="Payment Slip"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    //   value={this.state.category_name}
                                    onChange={(e) => {
                                        this.setState({ payment_slip: e.target.value });
                                    }}
                                />
                            </div> */}
              <Grid style={{ display: "flex", flexDirection: "column", width: "300px" }} >
                <Typography style={{ color: "black ", fontSize: 16, marginTop: "8px", fontWeight: "400", marginLeft: "30px" }}> Payment Slip :    </Typography>


                <Stack
                  spacing={1}
                  style={{
                    textDecoration: "none",
                    // display: "inline-block",
                    padding: "0 30px",
                    marginTop: "10px",
                  }}
                  className="imagemedia"
                >
                  {/* <Avatar
                                        alt="Profile"
                                        src={this.state.cust_aadhar}
                                        style={{ height: "114px", width: "114px" }}
                                        variant={"rounded"}
                                    /> */}
                  {/* {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt={imageAlt}
                                            className="displayed-image"
                                        />
                                    )} */}
                  <Input
                    type="file"
                    onChange={(e) => this.setState({ payment_slip: e.target.files[0] })}
                  />
                </Stack>
              </Grid>
              {/* <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Mode "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // defaultValue={this.state.model_id}
                                    onChange={(e) => { this.setState({ mode: e.target.value }) }}
                                >
                                   
                                    <MenuItem value={"cash"} >
                                       
                                    </MenuItem>
                                    <MenuItem value={"cheque"}>
                                       
                                        Cheque
                                    </MenuItem>
                                    <MenuItem value={"onlinepay"}>
                                       
                                    </MenuItem>
                                    
                                </TextField>

                            </div> */}

              <Button
                className="btns"
                onClick={() => {
                  this.props.dash_Rtoreg(
                    // this.state.id,
                    this.state.vechicle_id,
                    this.state.challan_number,
                    this.state.payment_slip,
                    this.props.login.user_id
                  );
                  this.setState({ registered: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Add
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ registered: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>



        <Dialog
          open={this.state.delivery}
          onClose={() => {
            this.setState({ delivery: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <LibraryAddCheckOutlinedIcon style={{ fontSize: "60", color: "blue", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ delivery: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained" onClick={() => { this.props.dashoutdeliveryvehicle(this.state.vechicle_id, this.props.login.user_id); this.setState({ delivery: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog>





        <Loader />
        <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />

      </Grid >
    )
  }
}
