import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import MenuItem from "@mui/material/MenuItem"
import Snackbar from "../../common/snackbar/components/snackbar";
import logo1 from "../../pages/image/logo1.png";
import "../styles/style.scss";
import LoaderCon from "../../common/loader/containers/loader_cont";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';

import FormHelperText from '@mui/material/FormHelperText';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      type: "",
      active: false,
      phone: "",
      showPassword:false
    };
  }
  componentDidMount() {
    this.props.clear_login()
    // if (localStorage.getItem('socus_token') !== null) {
    //   return <Redirect to="/" />
    // }
  }
  render() {
    const {
      login,
      setEmail,
      setPwd,
      login_email,
      snackbar,
      close_snack_bar,
      super_login
    } = this.props;

    if (login.user_id !== "") {
      return <Navigate to="/dashboard" />
    }

    return (
     
        <Grid className="login-background" >
          <Grid container spacing={10} justifyContent="center" marginTop="-10%" >
            <Grid item md={4} xs={12}>
              <Card style={{
                boxShadow: "0px 4px 4px 14px #00000040",
                width: "95%",
                // left: 790,
                marginTop: "15%",
                borderradius: 15,
                marginTop: "-12%",'borderRadius':'15px'
              

              }} className="login_card">
                <img
                  src={logo1}
                  alt="logo_img" style={{ height: 169 }}
                />
                

                <Grid container justify="center" marginTop={3} style={{
                  boxShadow: "0px 4px 4px 4px #00000040",
                  height: "212px",
                  width: "80%",
                  marginLeft: "10%",
                  top: "254px",
                  borderradius: "20px",'borderRadius':'12px'
                 


                }}>
                  <Grid item xs={12} marginTop={3}  >
                    {/* <TextField
                      id="outlined-email-input"
                      label="Please enter your Email Id"
                      type="text"
                      onChange={(e) => { this.setState({ email: e.target.value }) }}
                      fullWidth={true}
                      autoComplete={false}
                      margin="normal"
                      variant="outlined"
                      required={true}
                    /> */}
                    <Input
                      placeholder="Please enter your Email ID"
                      onChange={(e) => { this.setState({ email: e.target.value }) }}
                      // style={

                      // }
                      // style = {{width: 370}}
                      style={{
                        width: '90%',

                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <MarkunreadIcon />
                        </InputAdornment>
                      }
                    />
                  </Grid>


                  <Grid item xs={12} marginTop={-2}>
                    {/* <TextField
                      id="outlined-password-input"
                      label="Password"
                      type="password"
                      onChange={(e) => { this.setState({ password: e.target.value }) }}
                      fullWidth={true}
                      autoComplete={false}
                      margin="normal"
                      variant="outlined"
                      required={true}
                    /> */}
                    


                    <Input
                      id="standard-adornment-password"
                      placeholder="Please enter the Password"
                      type={this.state.showPassword?"text":"password"}

                      onChange={(e) => { this.setState({ password: e.target.value }) }}
                      style={{
                        width: '90%',

                      }}
                      // style = {{width: 370}}
                      startAdornment={
                        <InputAdornment position="start">
                          <VpnKeyIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={()=>{this.setState({showPassword:!(this.state.showPassword)})}}>
                            {/* <VisibilityOff /> */}
                            {this.state.showPassword ? < Visibility/> : <VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>}
                    />
                  </Grid>

                  

                  <Grid item xs={12} marginTop={-2}>
                    

                    <Button 
                      // disabled={(login.email === "" || login.password === "") ? true : false}
                      id="outlined-email-input"
                      variant="contained"
                      // sx={{ mt: 3, mb: 2, px: 21,marginLeft:1,marginRight:1 }}
                      style={{
                        width: '90%', background: 'linear-gradient(91.75deg, #2B2D74 0.93%, #1789C0 110.51%)'

                      }}
                      disabled={this.state.email==="" ||this.state.password===""}

                      onClick={() => { super_login(this.state.email, this.state.password) }}
                    >
                      LOGIN
                    </Button>
                    

                  </Grid>
                </Grid>
                <Grid height="128.9432373046875px"
                  width="440px"
                  // left: 0p
                  // top: 500.53271484375px;
                  // border-radius: 0px;
                  background="#FAFAFA"
                ></Grid>

              </Card>
            </Grid>
          </Grid>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid >
      
    );
  }
}
// class Login extends Component {
//     render(){
//         return(
//             <div>
//                 helloooooooooooooooooo
//             </div>
//         )
//     }
// }



export default Login;