import React, { Component } from "react";
import { connect } from "react-redux";
import Addservice from "../component/addservice";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
 
import {
  createService,
  viewDeliveredVechicle,
  createServiceOutside,
  // createServiceOutside,
  viewAllService
} from "../action";
import {
  viewAllCustomerByAdmin
} from "../../customer/action"
export class Controller extends Component {
  render() {
    return (
      <Addservice {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_service: store.all_service,
    login: store.login,
    // user: store.user,
    // one_user: store.one_user,
    all_customer: store.all_customer,
    snackbar: store.snackbar
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createService: (admin_id, customer_id,phone, email, battery_number,  model_name, agent_name, page_number, rowper_page) => {
      dispatch(createService(admin_id, customer_id,phone, email, battery_number,  model_name, agent_name, page_number, rowper_page))
    },
    createServiceOutside: (admin_id, name, phone, email, address, page_number, rowper_page ) => {
      dispatch(createServiceOutside(admin_id, name, phone, email, address, page_number, rowper_page ))
    },
    createServiceOutside: (admin_id, pan_card, adhar_number, name, email, phone, model_name, battery_number, page_number, rowper_page ) => {
      dispatch(createServiceOutside(admin_id, pan_card, adhar_number, name, email, phone, model_name, battery_number, page_number, rowper_page ))
    },
    viewAllService: (admin_id) => {
      dispatch(viewAllService(admin_id))
    },
    viewAllCustomerByAdmin: (admin_id) => {
      dispatch(viewAllCustomerByAdmin(admin_id))
    },
    viewDeliveredVechicle: () => {
      dispatch(viewDeliveredVechicle())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);