import {
    SET_USER,
    SET_ONE_USER,
    SET_USER_ID,
    SET_USER_NAME,
    SET_USER_MOBILE,
    SET_USER_EMAIL,
    SET_USER_GENDER,
    SET_USER_DOB,
    SET_USER_PADDRESS,
    SET_USER_PRADDRESS,
    SET_USER_PROFILE_PIC,
    SET_USER_PASSWORD,
    SET_USER_TL,
    SET_USER_DEPARTMENT,
    SET_USER_BASICSALARY,
    SET_USER_DESIGNATION,
    SET_USER_TIME,
    SET_USER_SHIFT
} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
// import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import 'firebase/storage';
import { set_snack_bar } from "../../../common/snackbar/action";

// import firebase from 'firebase/app'

export function add_upload_profile(TL_Id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/Profile/" + mobile + ".png")
        // .put(image)
        // uploadBytes(storageRef, image).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //   });
        const uploadTask = uploadBytesResumable(storageRef, profile_pic);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                    // console.log(downloadURL)
                    dispatch(createUser(TL_Id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift));
                });
            }
        );

    }
}





export function update_profile_pic(id,TL_Id,name, mobile, email, password, gender,profile_pic_old, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        if (profile_pic == null) {
            dispatch(updateUser(id,TL_Id,name, mobile, email, password, gender, profile_pic_old, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + id + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, profile_pic);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                        // console.log(downloadURL)
                        dispatch(updateUser(id,TL_Id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id));
                    });
                }
            );
        }
    }
}


export function createUser(TL_Id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                TL_Id: TL_Id,
                name: name,
                mobile: mobile,
                email: email,
                password: password,
                gender: gender,
                profile_pic: profile_pic,
                dob: dob,
                per_address: per_address,
                pre_address: pre_address,
                basic_salary: basic_salary,
                department_id: department_id,
                designation: designation,
                time: time,
                shift: shift,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_user(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function AllottedEmployee(service_id, emp_id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "allotted_employee", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                service_id: service_id,
                emp_id: emp_id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_user(admin_id));
                    dispatch(view_user_profile());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_user() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_tl_emp", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_USER, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function upload_profile(id, name, mobile, email, dob, gender, profile_pic, p_address, pr_address, password) {
    return dispatch => {
        console.log(id)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/Profile/" + id + ".png")
        // .put(image)
        // uploadBytes(storageRef, image).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //   });
        const uploadTask = uploadBytesResumable(storageRef, profile_pic);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (downloadURL) {
                    console.log(downloadURL)
                    dispatch(updateUser(id, name, mobile, email, dob, gender, downloadURL, p_address, pr_address, password));
                });
            }
        );

    }
}

export function updateUser(id,TL_Id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                TL_Id: TL_Id,
                name: name,
                mobile: mobile,
                email: email,
                password: password,
                gender: gender,
                profile_pic: profile_pic,
                dob: dob,
                per_address: per_address,
                pre_address: pre_address,
                basic_salary: basic_salary,
                department_id: department_id,
                designation: designation,
                time: time,
                shift: shift,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_user(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteUser(Emp_Id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: Emp_Id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_user(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function view_user_profile(id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_user_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiii",responseJson)
                if (responseJson.status) {
                    dispatch(setID(responseJson.result._id));
                    dispatch(setName(responseJson.result.name));
                    dispatch(setMobile(responseJson.result.mobile));
                    dispatch(setEmail(responseJson.result.email));
                    dispatch(setPassword(responseJson.result.password));
                    dispatch(setGender(responseJson.result.gender));
                    dispatch(setProfile_pic(responseJson.result.profile_pic));
                    dispatch(setDob(responseJson.result.dob));
                    dispatch(setPer_address(responseJson.result.per_address));
                    dispatch(setTL(responseJson.result.tlname));
                    dispatch(setdepartment(responseJson.result.departname));
                    dispatch(setbasicsalary(responseJson.result.basic_salary));
                    dispatch(setDesignation(responseJson.result.designation));
                    dispatch(settime(responseJson.result.time));
                    dispatch(setshift(responseJson.result.shift));
                    dispatch({ type: SET_ONE_USER, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ONE_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function setID(payload) {
    return {
        type: SET_USER_ID,
        payload: payload,
    };
}

export function setName(payload) {
    return {
        type: SET_USER_NAME,
        payload: payload,
    };
}

export function setMobile(payload) {
    return {
        type: SET_USER_MOBILE,
        payload: payload,
    };
}
export function setEmail(payload) {
    return {
        type: SET_USER_EMAIL,
        payload: payload,
    };
}
export function setGender(payload) {
    return {
        type: SET_USER_GENDER,
        payload: payload,
    };
}
export function setDob(payload) {
    return {
        type: SET_USER_DOB,
        payload: payload,
    };
}
export function setPer_address(payload) {
    return {
        type: SET_USER_PADDRESS,
        payload: payload,
    };
}
export function setPre_address(payload) {
    return {
        type: SET_USER_PRADDRESS,
        payload: payload,
    };
}
export function setProfile_pic(payload) {
    return {
        type: SET_USER_PROFILE_PIC,
        payload: payload,
    };
}
export function setPassword(payload) {
    return {
        type: SET_USER_PASSWORD,
        payload: payload,
    };
}
export function setTL(payload) {
    return {
        type: SET_USER_TL,
        payload: payload,
    };
}
export function setdepartment(payload) {
    return {
        type: SET_USER_DEPARTMENT,
        payload: payload,
    };
}
export function setbasicsalary(payload) {
    return {
        type: SET_USER_BASICSALARY,
        payload: payload,
    };
}
export function setDesignation(payload) {
    return {
        type: SET_USER_DESIGNATION,
        payload: payload,
    };
}
export function settime(payload) {
    return {
        type: SET_USER_TIME,
        payload: payload,
    };
}
export function setshift(payload) {
    return {
        type: SET_USER_SHIFT,
        payload: payload,
    };
}

