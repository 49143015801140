import {
    Grid, Typography, Box, IconButton, MenuItem
} from "@mui/material";

import React from "react";

import Button from "@mui/material/Button";

import { Navigate } from "react-router-dom";

import { styled, alpha } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
// import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Dialog from "@mui/material/Dialog";
import LoaderCon from "../../../../common/loader/containers/loader_cont";

import "../../style/order.css"

import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Link } from "react-router-dom";

import { Textarea } from "@mui/joy";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Snackbar from "../../../../common/snackbar/components/snackbar";

// import { colourOptions } from '../data';
const options = [
    { key: 'English', text: 'English', value: 'English' },
    { key: 'French', text: 'French', value: 'French' },
    { key: 'Spanish', text: 'Spanish', value: 'Spanish' },
    { key: 'German', text: 'German', value: 'German' },
    { key: 'Chinese', text: 'Chinese', value: 'Chinese' },
]

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));


const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));


export default class Dashboard extends React.Component {

    // state = { options }
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            quotation: false,
            will_transport: false,
            booking_invoice: "booking",
            will_registration: false,
            registration_fee: 0,
            transportation_cost: 0,
            ex_shoeroom_price: 0,
            insurance_fee: 0,
            discount_amount: 0,
            preview: false,
            customer_id: "",
            category_id: "",
            model_id: "",
            variant_id: "",
            color_id: "",
            battery_id: ""

            // options:""
            // imageUrl: null,
            // imageAlt: null,

        }
    }
    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            options: [{ text: value, value }, ...prevState.options],
        }))
    }

    handleChange = (e, { value }) => this.setState({ currentValue: value })

    componentDidMount() {
        this.props.viewAllOrder(this.props.login.user_id);
        this.props.viewModel();
        this.props.viewVarient();
        this.props.viewAllCategory();
        // this.props.viewAllCustomer(this.props.login.user_id);
        this.props.viewAllCustomerByAdmin(this.props.login.user_id)
        this.props.viewAllColor();
        this.props.viewAllBattery();
        console.log(this.props.all_customer.customer)
    }
    render() {
        const {
            all_customer,
            all_battery,
            all_category,
            all_model,
            all_varient,
            all_color,
            snackbar,
            login,
            close_snack_bar
        } = this.props;
        // const { currentValue } = this.state
        // console.log(all_model.all_category_model)
        if (this.state.redirect === true) {
            return <Navigate to="/" />;
        }



        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='makeorder'>
                        <center>
                            <Link to="/order" style={{ textDecoration: "none" }}>
                                <IconButton
                                    style={{ marginLeft: "-8px" }}

                                >
                                    <ArrowBackIcon style={{ color: "white" }} />
                                </IconButton>
                            </Link>

                            <Button style={{ color: "white ", textAlign: 'center', marginLeft: "20px", fontSize: 16 }}>MAKE A NEW ORDER</Button>
                        </center>
                    </Box>


                </Grid>

                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}
                    {/* <Autocomplete
                        style={{
                            // backgroundColor: "#DCE4E5",
                            // background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                            height: 32,
                            marginLeft: 80,
                            marginTop: 48,
                            width: "400px",
                            // border: "2px solid #000000"
                        }}
                        id="free-solo-demo"
                        freeSolo
                        options={all_customer.all_customer.map((option) => option.cust_name)}
                        renderInput={(params) => <TextField {...params} label="freeSolo" />}
                    /> */}




                    <select
                        // style={{ width: "200px", marginLeft: "30px" }}
                        style={{
                            // backgroundColor: "#DCE4E5",
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                            height: 32,
                            marginLeft: 80,
                            marginTop: 48,
                            width: "400px",
                            border: "2px solid #000000"
                        }}

                        name=""

                        // value={this.state.eventName}
                        select
                        required={true}
                        onChange={(e) => {
                            this.setState({ customer_id: e.target.value });
                            // this.props.viewCategortModel(e.target.value);
                        }}
                    // onChange={handleChangeDepartment}
                    >



                        <option value="Select Model" >
                            <SearchIconWrapper>
                                <SearchIcon style={{ color: "black" }} />
                            </SearchIconWrapper>
                            SELECT CUSTOMER

                        </option>
                        {Array.isArray(all_customer.customer)&& all_customer.customer.map((e) => (
                            <option value={e._id}>
                                <Search
                                    style={{
                                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                                        height: 32,
                                        marginRight: 19,
                                        marginTop: 52,
                                        width: "300px",
                                        border: "2px solid #000000"
                                    }}
                                    direction="row"
                                > *
                                    <SearchIconWrapper>
                                        <SearchIcon style={{ color: "black" }} />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search By Order / ID"
                                        color="black"
                                        inputProps={{ "aria-label": "search" }}
                                        style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}

                                    />{e.cust_name}
                                </Search>

                            </option>
                        ))}


                        {/* <>
                            <option value={e._id}>{e.cust_name}</option>
                        </> */}


                    </select>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label>BOOKING/QUOTATION</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                borderRadius: 8
                            }}
                            required={true}
                            // name=""
                            onChange={(e) => { this.setState({ booking_invoice: e.target.value }); }}
                            // value={this.state.eventName}
                            select

                        // onChange={handleChangeDepartment}
                        >



                            <option value="booking" >

                                BOOKING

                            </option>
                            <option value="quotation" >

                                QUOTATION

                            </option>
                        </select>
                    </Grid>
                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>CATEGORY</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "400px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}
                            // value={this.state.category}
                            required={true}
                            onChange={(e) => {
                                this.setState({ category_id: e.target.value });
                                this.props.viewCategortModel(e.target.value);
                            }}



                            // value={this.state.eventName}
                            select

                        // onChange={handleChangeDepartment}
                        >



                            <option value="Select Model" >
                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                SELECT CATEGORY

                            </option>

                            {all_category.all_category.map((e) => (
                                <>
                                    <option value={e._id}>{e.category_name}</option>
                                </>
                            ))}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}



                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>EX-SHOWROOM PRICE</label>
                        {/* <div class="input-group mb-3" style={{ marginLeft: "20px" }}> */}

                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:80,
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",
                            // borderRadius:8

                        }}
                            type={"number"}
                            disabled
                            value={parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price)}
                        // onChange={(e) => { this.setState({ ex_shoeroom_price: parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) }); }}

                        >



                        </input>

                        {/* </div> */}
                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>




                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>MODEL</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "400px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            // value={this.state.eventName}
                            select
                            required={true}
                            onChange={(e) => {
                                this.setState({ model_id: e.target.value });
                                this.props.viewModelVarient(e.target.value);
                            }}
                        >



                            <option value="Select Model" >

                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                SELECT MODEL

                            </option>

                            {all_model.all_category_model.map((e) => (
                                <>
                                    <option value={e._id}>{e.model_name}</option>
                                </>
                            ))}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>INSURANCE FEES</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:80,
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",

                            // borderRadius:8
                        }}
                            type={"number"}
                            disabled
                            value={all_varient.all_variant_details.insurance_price}
                        // onChange={(e) => { this.setState({ insurance_fee: e.target.value }); }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>




                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>VARIANT</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "400px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            value={this.state.eventName}
                            select
                            required={true}
                            onChange={(e) => {
                                this.setState({ variant_id: e.target.value });
                                this.props.viewVarientByid(e.target.value);
                            }}
                        >



                            <option value="Select Model" >

                                <SearchIconWrapper>
                                    <SearchIcon style={{ color: "black" }} />
                                </SearchIconWrapper>
                                SELECT VARIANT

                            </option>

                            {all_varient.all_model_variant.map((e) => (

                                <>

                                    <option value={e._id}>{e.variant_name}</option>

                                </>


                            ))}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> WIDTH</label>
                        {/* {all_varient.all_variant_details.map((e) => ( */}
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "140px",
                            border: "2px solid #000000",
                            // borderRadius:8

                        }}
                            // type={"number"}
                            value={all_varient.all_variant_details.width}
                            // onChange={() => { this.setState({ width: all_varient.all_variant_details.width }) }}

                            disabled
                        ></input>
                        {/* ))} */}


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 75, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> LENGTH</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "140px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            // type={"number"}
                            // onChange={(e) => { this.setState({ length: e.target.value }); }}
                            value={all_varient.all_variant_details.overall_length}
                            disabled

                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>




                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>COLOR</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "185px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            // value={this.state.eventName}
                            select

                            onChange={(e) => { this.setState({ color_id: e.target.value }) }}
                        >

                            <option value="" >
                                Select Color
                            </option>
                            {/* <option value="Select Model" >
                                         </option> */}

                            {all_color.all_color.map((e) => (
                                <>
                                    <option value={e._id}>{e.color_name}</option>
                                </>
                            ))}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>




                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 10, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>Battery</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "205px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            // value={this.state.eventName}
                            select

                            onChange={(e) => {
                                this.setState({ battery_id: e.target.value });
                                this.props.viewBatteryByid(e.target.value);
                            }}
                        >
                            <option value="" >
                                Select Battery
                            </option>

                            {all_battery.all_battery.map((e) => (
                                <>
                                    <option value={e._id}>{e.battery_name}</option>
                                </>
                            ))}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>



                    {this.state.will_registration && <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 135, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> RTO FEE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "150px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            type={"number"}
                            value={this.state.registration_fee}
                            onChange={(e) => { this.setState({ registration_fee: e.target.value }); }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>}
                    {this.state.will_transport && <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 10, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: 5, }}>TRANSPORT COST</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: 5,
                            marginTop: 0,
                            width: "140px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            type={"number"}
                            value={this.state.transportation_cost}
                            onChange={(e) => { this.setState({ transportation_cost: e.target.value }); }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>}

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>QUANTITY</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                            height: 30,
                            // marginLeft:80,
                            marginTop: 0,
                            width: "150px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            type={"number"}
                            onChange={(e) => { this.setState({ quanty: e.target.value }); }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 30, marginTop: 25, }}>
                        {/* <label style={{ marginTop: -10, marginLeft: -35, }}>DISCOUNT</label> */}
                        <label style={{ marginTop: -10 }}>Discount Amount</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft: -35,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            type={"number"}
                            // placeholder="Discount Amount"
                            onChange={(e) => { this.setState({ discount_amount: e.target.value }); }}
                        ></input>


                    </Grid>

                    {this.state.booking_invoice === "booking" && <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 112, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: 5, }}>ESTIMATED DELIVERY DATE</label>
                        <input
                            type={"date"} style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                marginLeft: 5,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}
                            onChange={(e) => { this.setState({ estimated_delivery_date: e.target.value }); }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>}

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginTop: 0, }}>
                        <Grid style={{ display: "flex", flexDirection: "row", marginLeft: 80, marginTop: 25, }}>
                            <input type={"checkbox"} style={{
                                // background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:-55,
                                marginTop: 0,
                                width: "35px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}
                                onChange={(e) => { this.setState({ will_registration: !(this.state.will_registration) }); }}
                            >


                            </input>
                            <label style={{ marginTop: 0 }}>WILL REGISTER</label>


                            {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}



                        </Grid>
                        <Grid style={{ display: "flex", flexDirection: "row", marginLeft: 80, marginTop: 25, }}>
                            <input type={"checkbox"} style={{
                                // background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                                // value={this.state.will_transport}
                                height: 30,
                                // marginLeft:-55,
                                marginTop: 0,
                                width: "35px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}
                                onChange={(e) => { this.setState({ will_transport: !(this.state.will_transport) }); }}
                            >

                            </input>
                            <label style={{ marginTop: 0 }}> WILL TRANSPORT</label>
                            {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                        </Grid>
                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column" }}>
                        <Grid style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
                            <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 50, marginTop: 10, }}>
                                {/* <label style={{ marginTop: 10, marginLeft:0,}}>STATUS</label> */}
                                <Typography style={{ fontSize: 16, fontWeight: 0 }}>ON-ROAD PRICE :-</Typography>
                                {/* <Typography style={{ fontSize: 16, fontWeight: 600 }}>12,000000</Typography> */}
                                <input style={{
                                    background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                                    height: 30,
                                    marginLeft: 0,
                                    marginTop: 0,
                                    width: "180px",
                                    border: "2px solid #000000",

                                    // borderRadius:8
                                }}
                                    // type={"number"}

                                    disabled
                                    value={parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)}
                                onChange={() => { this.setState({ on_road_price: parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost) }); }}
                                ></input>

                                {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                            </Grid>
                            <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 10, }}>
                                {/* <label style={{ marginTop: 10, marginLeft:0,}}>STATUS</label> */}
                                <Typography style={{ fontSize: 16, fontWeight: 0 }}>TOTAL PRICE :-</Typography>
                                {/* <Typography style={{ fontSize: 16, fontWeight: 600 }}>12,000000</Typography> */}
                                <input style={{
                                    background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                                    height: 30,
                                    marginLeft: 0,
                                    marginTop: 0,
                                    width: "298px",
                                    border: "2px solid #000000",

                                    // borderRadius:8
                                }}
                                    // type={"number"}
                                    disabled
                                    value={((parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)) * (5 / 100)) - (parseInt(this.state.discount_amount)) + ((parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)) * (this.state.quanty))}
                                // onChange={() => { this.setState({ total_price: ((parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)) * (5 / 100)) - (parseInt(this.state.discount_amount)) + (parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)) }); }}
                                ></input>

                                {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                            </Grid>
                        </Grid>



                        <Grid style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
                            {this.state.booking_invoice === "booking" && <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 45, marginTop: 40, }}>
                                <label style={{ marginTop: -35, marginLeft: 5, }}>Payment Mode</label>

                                <select
                                    required
                                    select
                                    // id="outlined-required"
                                    // label="Mode "
                                    // InputLabelProps={{ shrink: true, required: true }}
                                    style={{
                                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                                        height: 30,
                                        marginLeft: 5,
                                        marginTop: 0,
                                        width: "180px",
                                        border: "2px solid #000000",
                                    }}
                                    // defaultValue={this.state.model_id}
                                    onChange={(e) => { this.setState({ payment_mode: e.target.value }) }}
                                >
                                    <option value="" >
                                        Select payment mode
                                    </option>
                                    <option value="cash" >
                                        Cash
                                    </option>
                                    <option value="cheque">
                                        Cheque
                                    </option>
                                    <option value="onlinepay">
                                        Online Pay
                                    </option>
                                </select>



                            </Grid>}
                            {this.state.booking_invoice === "booking" && <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                                <label style={{ marginTop: -20, marginLeft: 0, }}>Advance Amount</label>
                                {/* <Typography style={{ fontSize: 16, fontWeight: 0 }}>Amount</Typography> */}
                                {/* <Typography style={{ fontSize: 16, fontWeight: 600 }}>12,000000</Typography> */}
                                <input style={{
                                    background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                                    height: 30,
                                    marginLeft: 0,
                                    marginTop: 0,
                                    width: "298px",
                                    border: "2px solid #000000",

                                    // borderRadius:8
                                }}
                                    type={"number"}
                                    onChange={(e) => { this.setState({ advance_amount: e.target.value }); }}
                                ></input>

                                {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}


                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: 0 }}> COMMENT</label>

                        <Textarea type={""} style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 80,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "780px",
                            border: "2px solid   #C0ADAD",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ comment: e.target.value }); }}

                        >


                        </Textarea>
                        {/* <label style={{ marginTop: 0}}>WILL REGISTER</label> */}


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>


                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 25, display: 'flex', marginBottom: 25 }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    {this.state.booking_invoice === "quotation" ?

                    
                          <Link to="/quoation2" style={{ textDecoration: "none", marginLeft: "30%" }}>
                            <Typography sx={{ borderRadius: "15px" }}>

                                <Button
                                    disabled={this.state.customer_id === "" || this.state.category_id === "" || this.state.model_id === "" || this.state.variant_id === "" || this.state.color_id === "" || this.state.battery_id === ""}
                                    onClick={() => {
                                        this.props.createOrder(this.props.login.user_id,this.state.customer_id, this.state.category_id, this.state.model_id, this.state.variant_id, this.state.color_id, this.state.battery_id, this.state.booking_invoice, parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price), all_varient.all_variant_details.insurance_price, this.state.registration_fee, this.state.transportation_cost, this.state.estimated_delivery_date, this.state.quanty, all_varient.all_variant_details.width, all_varient.all_variant_details.overall_length, this.state.will_registration, this.state.will_transport, parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost), ((parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)) * (5 / 100)) - (parseInt(this.state.discount_amount)) + ((parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)) * (this.state.quanty)), this.state.discount_amount, this.state.advance_amount, this.state.payment_mode, this.state.comment, this.props.login.user_id);
                                        // this.setState({ add: false });
                                    }}
                                    className='button' variant='contained' sx={{ borderRadius: "20px", }}>Add ORDER</Button>
                            </Typography>

                        </Link> :
                        <Link to="/bookings" style={{ textDecoration: "none", marginLeft: "30%" }}>
                            <Typography sx={{ borderRadius: "15px" }}>

                                <Button
                                    disabled={this.state.customer_id === "" && this.state.category_id === "" && this.state.model_id === "" && this.state.variant_id === "" && this.state.color_id === "" && this.state.battery_id === ""}

                                    onClick={() => {
                                        this.props.createOrder(this.props.login.user_id,this.state.customer_id, this.state.category_id, this.state.model_id, this.state.variant_id, this.state.color_id, this.state.battery_id, this.state.booking_invoice, parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price), all_varient.all_variant_details.insurance_price, this.state.registration_fee, this.state.transportation_cost, this.state.estimated_delivery_date, this.state.quanty, all_varient.all_variant_details.width, all_varient.all_variant_details.overall_length, this.state.will_registration, this.state.will_transport, parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost), ((parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)) * (5 / 100)) - (parseInt(this.state.discount_amount)) + ((parseInt(all_varient.all_variant_details.exshowroom_price) + parseInt(all_battery.battery_details.battery_price) + parseInt(all_varient.all_variant_details.insurance_price) + parseInt(this.state.registration_fee) + parseInt(this.state.transportation_cost)) * (this.state.quanty)), this.state.discount_amount, this.state.advance_amount, this.state.payment_mode, this.state.comment, this.props.login.user_id);
                                        // this.setState({ add: false });
                                    }}
                                    className='button' variant='contained' sx={{ borderRadius: "20px", }}>Add ORDER</Button>
                            </Typography>

                        </Link>}
                    <Typography sx={{ borderRadius: "15px" }} >
                        {/* <Link to="/bookings" style={{textDecoration:"none"}}>
                        
                        <Button
                            onClick={() => { this.setState({ preview: true }) }}
                            className='button' variant='contained' sx={{ borderRadius: "20px", marginLeft: 3 }}>Preview and Send</Button>
                        </Link> */}
                    </Typography>
                </Grid>

                <Dialog
                    open={this.state.preview}
                    onClose={() => { this.setState({ preview: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                ><Box style={{ width: "400px", height: "400px" }}>

                    </Box>

                </Dialog>

                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />

            </Grid>
        )
    }
}