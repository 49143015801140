import React, { Component } from 'react'
// import Box from '@mui/material/Box';
import {Card,Grid} from '@mui/material';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
export default class attendancedash extends Component {
  render() {
    return (
      <div>
      <Grid  style={{marginLeft:"20px"}}  sx={{ display:'flex', flexDirection:"row", width:"100%"}}>
      <Card style={{height:'200px', width:"270px",}}>
        <div className='atten'>
        <CardContent  style={{marginLeft:"50px"}} sx={{ fontSize: 13 , display:'flex', flexDirection:"row", fontWeight:600 ,color:"white" }} >
          Attendance    
        </CardContent></div>
       
        <CardContent sx={{ fontSize: 10 , display:'flex', flexDirection:"row" , fontWeight:"600"}}>
          Total Employee  <Typography  style={{ color: "#136CAD"}} sx={{fontWeight:600}} marginLeft={17} fontSize={20}>69</Typography>   
        </CardContent>
        <CardContent sx={{ fontSize: 10 , display:'flex', flexDirection:"row", marginTop:"-15px", fontWeight:"600"}}>
          Present  <Typography  style={{ color: "#16C35B" , marginTop:"-10px"}} sx={{fontWeight:600}} marginLeft={22} fontSize={20}>57</Typography>   
        </CardContent>
        <CardContent sx={{ fontSize: 10 , display:'flex', flexDirection:"row" , marginTop:"-15px", fontWeight:"600"}}>
          Absent   <Typography sx={{fontWeight:600}} style={{ color: "#D41111" }} marginLeft={22} fontSize={20}>12</Typography>   
        </CardContent>
        </Card>
        </Grid>
      </div>
    )
  }
}
