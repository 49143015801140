import {
    LOGIN,
    LOGOUT,
    // NEWIMAGE_UPLOAD,
    HANDLEDRAWER,
    // SET_HOME,
    // SET_EMAIL,
    // SET_PASSWORD,
    CLEAR_LOGIN,
    SET_ADMIN_ID,
    // REGISTRATION,
    // SUCESSFUL,
    // VERIFY_OTP,
    // SUPERLOGIN,
    // ADMINLOGIN,
    // SETALL,
    // ALL_HOTELS,
    // TOURISMLOGIN,
    // SETACTIVE
} from "./constant";
import UNIVERSAL from "../config/config";
import { setLoader, unsetLoader } from "../common/loader/action";
import { set_snack_bar } from "../common/snackbar/action";
// import { redirect} from "react-router-dom"
// import { RedirectFunction } from "react-router-dom";
// import firebase from "firebase"

 
 
export function super_login(email, password) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
                // type:'E'
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                if (responseJson.status) {
                    // <redirect to="/"/>
                    // console.log(responseJson)
                    dispatch(setAdminLogin(responseJson.result));
                    dispatch(setAdminId(responseJson.result._id));
                    console.log(responseJson.result);
                    dispatch(set_snack_bar(responseJson.status, "Login  Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function clear_login() {
    return {
        type: CLEAR_LOGIN,
    };
}
export function setAdminId(payload) {
    return {
        type: SET_ADMIN_ID,
        payload: payload
    }
}

export function setAdminLogin(payload) {
    localStorage.setItem("user_id", payload._id);
    localStorage.setItem("type", payload.type);
    localStorage.setItem("name", payload.name);
    localStorage.setItem("email", payload.email);
   
    return {
        type: LOGIN,
        payload: payload,
    };
}


export function handleDrawerToggle(mobileOpen) {
    mobileOpen = !mobileOpen;
    return {
        type: HANDLEDRAWER,
        payload: mobileOpen,
    };
}
export function onLogout() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("type");
    return {
        type: LOGOUT,
    };
}
// export function successfull(payload) {
//     return {
//         type: SUCESSFUL,
//         payload: payload,
//     };
// }


// export function update_image(newphoto,user_id) {
//     return (dispatch) => {
//         // dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "image_upload", {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 // "Content-Type": "application/json",
//             },
//             body: newphoto,
//             user_id:user_id,
//             file: {
//                 photo: newphoto
//             }
//         }).then((response) => response.json())
//             .then((responseJson) => {
//                 console.log(responseJson)
//                 if (responseJson.status) {
//                     dispatch(send_otp(false))
//                     dispatch(successfull(true))
//                     // dispatch(set_snack_bar(responseJson.status, "Password Changed Successfully"));
//                 } else {
//                     dispatch(set_snack_bar(responseJson.status, responseJson.message));
//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     }
// }


