import React, { Component } from 'react';
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Typography,
    Icon, DialogActions,
    DialogTitle,
    Tooltip, Avatar, Card, Dialog

} from "@mui/material";
import Rating from '@mui/material/Rating';
import "../../Kiosk/Component/kiosk.css";
import ScheduleIcon from '@mui/icons-material/Schedule';
// import av from "../../../image/869c456be34c21809afe503fedb283c9.webp";
import av from "../../../image/avatar.png";
import ButtonGroup from '@mui/material/ButtonGroup';
import logo1 from "../../../../pages/image/logo1.png";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import { GridSaveAltIcon } from '@mui/x-data-grid';
// import moment from 'moment';
// import logo1 from "../../pages/image/logo1.png";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const timeZone = 'Asia/Kolkata';
export default class Kiosk extends Component {
    constructor(props) {


        super(props);
        this.state = {
            edit: false,
            view: false,
            add: false,
            modify: false,
            password: "",
            isBlinking: true,
            open: false,
            todayClient: [],
            followUp: [],
            remains: [],
            givenTask:[],
            assignTask:[],
        }
    }
    componentDidMount() {
        // this.props.viewAllClients(this.props.login.user_id);
        // this.props.viewModel();
        this.props.view_admins_task_details()
        console.log(this.props.kiosk.task_kiosk_details)
        this.interval = setInterval(() => {
            this.props.view_admins_task_details();
            console.log("called");
        }, 5 * 60 * 1000);
    }
    render() {
        const { isBlinking } = this.state;
        return (
            this.props.kiosk.password_verify ?
                <>
                    <Grid style={{
                        marginRight: "1%",
                        backgroundImage: "linear-gradient(315deg, #537895 0%, #09203f 74%)", width: "100%", height: "50vh", backgroundRepeat: "no-repeat", fontFamily: "cursive"
                    }}>
                        <Grid>

                            <center>
                                <div>
                                    <Typography style={{ fontSize: "30px", fontWeight: 700, color: "white", padding: "20px" }}>
                                        CURRENT TASK REPORT
                                    </Typography>
                                    {/* <Link to={"/kiosk"}>
                                        <Button
                                            variant="outlined"
                                            style={{
                                                color: "white", fontweight: 600,
                                                fontSize: "10px", marginLeft: "30%",
                                                borderColor: "1px solid white",
                                                borderRadius: "15px"
                                            }} endIcon={<ArrowForwardIcon />}
                                        >
                                            Go To Performance Page
                                        </Button>
                                    </Link> */}
                                </div>
                            </center>
                        </Grid>

                        <Grid style={{ marginTop: "20vh", display: "flex", flexDirection: "row", justifyContent: "space-evenly", paddingBottom: "210px" }} >






                            {/* //1nd///////////////////////////////// */}
                            {/* <Grid class="" style={{ marginTop: "40px", marginLeft: "%" }}>


                                <Grid style={{ boxShadow: "none", width: "300px", }}>
                                    <center>

                                        <Avatar

                                            src={av}
                                            sx={{
                                                width: 300, height: 300, position: "relative", marginTop: "-140px", borderColor: "red"
                                                //  zIndex:-1
                                            }}
                                        />
                                        <Card style={{ boxshadow: "none", backgroundColor: "#4682B4", width: "320px", height: "350px", position: "absolute", marginTop: "-90px" }}>
                                            <Typography style={{ fontSize: "22px", fontWeight: 500, color: "white", marginTop: "10px" }}>
                                                Subhasmita
                                            </Typography>
                                            <Rating style={{ fontSize: "40px", marginTop: "10px" }} name="simple-controlled" />
                                            <Button variant="contained" style={{ borderRadius: 50, backgroundColor: "white", color: "skyblue", marginTop: "180px" }}>
                                                Entry Details
                                            </Button>

                                        </Card>


                                    </center>


                                </Grid>

                            </Grid> */}

                            {/* 2rd///////////////////////////////////////////////////////////// */}
                            {/* <Grid class="" style={{ marginTop: "40px", marginLeft: "%" }}>


                                <Grid style={{ boxShadow: "none", width: "300px", }}>
                                    <center>

                                        <Avatar

                                            src={av}
                                            sx={{
                                                width: 300, height: 300, position: "relative", marginTop: "-140px", borderColor: "red"
                                                //  zIndex:-1
                                            }}
                                        />
                                        <Card style={{ boxshadow: "none", backgroundColor: "#4682B4", width: "320px", height: "350px", position: "absolute", marginTop: "-90px" }}>
                                            <Typography style={{ fontSize: "22px", fontWeight: 500, color: "white", marginTop: "10px" }}>
                                                Subhasmita
                                            </Typography>
                                            <Rating style={{ fontSize: "40px", marginTop: "10px" }} name="simple-controlled" />
                                            <Button variant="contained" style={{ borderRadius: 50, backgroundColor: "white", color: "skyblue", marginTop: "185px" }}>
                                                Entry Details
                                            </Button>

                                        </Card>


                                    </center>


                                </Grid>

                            </Grid> */}



                            <Grid container ml={12} >
                                <Grid item xs={0.5}></Grid>
                                <Grid item xs={11}>
                                    <Grid container spacing={2}>
                                        {Array.isArray(this.props.kiosk.task_kiosk_details) && this.props.kiosk.task_kiosk_details.filter(element => (element.givenTask.length !== 0 || element.assignTask.length !== 0))
                                            .sort((element1, element2) => {
                                                // Calculate the values for each element
                                                let value1 =((element1.givenTask.filter((element) => element.status === "Completed").length / element1.givenTask.length) * 100);

                                                let value2 =((element2.givenTask.filter((element) => element.status === "Completed").length / element2.givenTask.length) * 100);

                                                // Compare the values for sorting
                                                return value2 - value1; // Sorting in descending order, change to value1 - value2 for ascending order
                                            })
                                            .map((element) => {
                                                // const yourTime = moment(element.todayClient[0]?.created_date);
                                                // const currentTime = moment();
                                                // const yourTime2 = moment(element.todayFollowUpTakens[0]?.created_date);
                                                // const minutesDifference = currentTime.diff(yourTime, 'minutes');
                                                // const minutesDifference2 = currentTime.diff(yourTime2, 'minutes');
                                                // const isBefore30MinutesAgo = (minutesDifference > 30 || minutesDifference === 0) && minutesDifference2 > 30;
                                                // const isBefore60MinutesAgo = (minutesDifference > 60 || minutesDifference === 0) && minutesDifference2 > 60;

                                                return (
                                                    <Grid item xs={3} style={{ boxShadow: "none", }} >

                                                        <Grid container>
                                                            <Grid item xs={12} >
                                                                <Avatar
                                                                    src={element.profile_pic ? element.profile_pic : av}
                                                                    sx={{
                                                                        width: 300, height: 300, borderColor: "red", mixBlendMode: "normal",
                                                                        //  zIndex:-1
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} style={{ paddingBottom: "50px" }}>
                                                                <Card
                                                                    className={`blink-container ${isBlinking ? 'blinking' : ''}`}
                                                                    style={{
                                                                        zindex: -3,
                                                                        // boxShadow: isBefore60MinutesAgo ? '0 0 15px 15px red' : isBefore30MinutesAgo ? '0 0 15px 15px #c9c00a' : 'none',
                                                                        backgroundColor: '#4682B4',
                                                                        width: "320px",
                                                                        height: "350px",
                                                                        marginTop: '-90px',
                                                                        position: "relative",
                                                                        textAlign: 'center',
                                                                    }}>
                                                                    <Typography style={{ fontSize: "22px", fontWeight: 500, color: "white", marginTop: "10px", }}>
                                                                        {element?.name}
                                                                    </Typography>
                                                                    <ButtonGroup style={{ borderRadius: 50, backgroundColor: "#87CEEB", marginTop: "20px" }} variant="contained">
                                                                        <Button style={{ borderRadius: 50, backgroundColor: "#87CEEB", color: "white", }}>Task Given:{Array.isArray(element.givenTask) && element.givenTask.length}</Button>
                                                                        <Button style={{ borderRadius: 50, backgroundColor: "#1484CD", color: "white", }}>Pending:{ Array.isArray(element.givenTask) && element.givenTask.filter((element) => element.status === "Not Started").length}</Button>

                                                                    </ButtonGroup>
                                                                    <ButtonGroup style={{ borderRadius: 50, backgroundColor: "green", marginTop: "20px" }} variant="contained">
                                                                        <Button style={{ borderRadius: 50, backgroundColor: "green", color: "white", }}> Completed:{Array.isArray(element.givenTask) && element.givenTask.filter((element) => element.status === "Completed").length}</Button>
                                                                        <Button style={{ borderRadius: 50, backgroundColor: "red", color: "white", }}>Remaining:{Array.isArray(element.givenTask) && element.givenTask.filter((element) => element.status !== "Completed").length}</Button>

                                                                    </ButtonGroup>
                                                                    <ButtonGroup style={{ borderRadius: 50, backgroundColor: "green", marginTop: "20px" }} variant="contained">
                                                                        <Button style={{ borderRadius: 50, backgroundColor: "green", color: "white", }}>Efficiency:{
                                                                            ((Array.isArray(element.givenTask) && element.givenTask.filter((element) => element.status === "Completed").length / element.givenTask.length) * 100).toFixed(1)

                                                                        }%</Button>
                                                                        <Button style={{ borderRadius: 50, backgroundColor: "red", color: "white", }}>Inefficient:{
                                                                            ((Array.isArray(element.givenTask) && element.givenTask.filter((element) => element.status !== "Completed").length / element.givenTask.length) * 100).toFixed(1)
                                                                        }%</Button>

                                                                    </ButtonGroup>
                                                                    <Button variant="contained"
                                                                        style={{
                                                                            fontWeight: 600,
                                                                            borderRadius: 50,
                                                                            backgroundColor: "white",
                                                                            color: "skyblue",
                                                                            marginTop: "20px"
                                                                        }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                open: true,
                                                                                givenTask: element?.givenTask,
                                                                                assignTask: element?.assignTask
                                                                            })
                                                                        }}
                                                                    >
                                                                        Task Details
                                                                    </Button>

                                                                </Card>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={0.5}></Grid>
                            </Grid>
                            <Dialog
                                open={this.state.open}
                                onClose={() => {
                                    this.setState({ open: false })
                                }}
                                fullWidth
                                maxWidth="lg"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                sx={{

                                    backdropFilter: "blur(5px) sepia(5%)",
                                    // 👇 Another option to style Paper
                                    "& .MuiDialog-paper": {
                                        borderRadius: "17.782px",
                                    },
                                }}

                            >
                                <Grid container>
                                    <Grid item xs={1} />
                                    <Grid item xs={5} justifyContent={'center'} textAlign={'center'}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell >Given Task</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.givenTask.map((row) => (
                                                        <TableRow
                                                            key={row.desc}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Typography>Task Name :{row.desc} </Typography>
                                                                <Typography>Given By :{row.given_by?.name} </Typography>
                                                                <Typography>Status :{row.status} </Typography>
                                                                <Typography>Response :{row.response} </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <h4>Today Client</h4>
                                        {this.state.todayClient.map((element) => {
                                            return (
                                                <div >
                                                    <Typography>{element.name}</Typography>
                                                    <Typography>{moment.tz(element.created_date, timeZone).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                                                </div>
                                            )
                                        })
                                        } */}
                                    </Grid>
                                    <Grid item xs={5} justifyContent={'center'} textAlign={'center'}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Assign Task</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.assignTask.map((row) => (
                                                        <TableRow
                                                            key={row.desc}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Typography>Task Name :{row.desc} </Typography>
                                                                <Typography>Given To :{row.given_to?.name} </Typography>
                                                                <Typography>Status :{row.status} </Typography>
                                                                <Typography>Response :{row.response} </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* <h4>Follow Up</h4>
                                        {this.state.followUp.map((element) => {
                                            return (
                                                <div >
                                                    <Typography>{element.message}</Typography>
                                                    <Typography>{moment.tz(element.created_date, timeZone).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                                                </div>
                                            )
                                        })
                                        } */}
                                    </Grid>
                                    <Grid item xs={1} />

                                </Grid>
                            </Dialog>

                        </Grid>




                    </Grid >
                    {/* <Grid style={{
                        marginRight: "1%", marginTop: "35%",
                        backgroundImage: "linear-gradient(315deg, #537895 0%, #09203f 74%)", width: "100%", height: "50vh", backgroundRepeat: "no-repeat", fontFamily: "cursive"
                    }}>
                        <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", }}>
                            <Grid style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                <ScheduleIcon style={{ marginTop: "90px", color: "white", fontSize: 35 }} />
                                <Typography style={{ marginTop: "90px", color: "white" }}>
                                    Time<br />9.00 AM-10.00 AM

                                </Typography>
                            </Grid>
                            <Grid>
                                <Button variant="contained" style={{
                                    fontWeight: 600, borderRadius: 50, backgroundColor: "white", color: "skyblue", marginTop: "80px",
                                    // marginLeft: "70%"
                                }}>
                                    Register Now
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid> */}

                </>
                :
                <>

                    <Grid className="login-background" >
                        <Grid container spacing={10} justifyContent="center" marginTop="-10%" >
                            <Grid item md={4} xs={12}>
                                <Card style={{
                                    boxShadow: "0px 4px 4px 14px #00000040",
                                    width: "95%",
                                    // left: 790,
                                    marginTop: "15%",
                                    borderradius: 15,
                                    marginTop: "-12%", 'borderRadius': '15px'


                                }} className="login_card">
                                    <img
                                        src={logo1}
                                        alt="logo_img" style={{ height: 169 }}
                                    />


                                    <Grid container justify="center" marginTop={3} style={{
                                        boxShadow: "0px 4px 4px 4px #00000040",
                                        height: "212px",
                                        width: "80%",
                                        marginLeft: "10%",
                                        top: "254px",
                                        borderradius: "20px", 'borderRadius': '12px'



                                    }}>


                                        <Grid item xs={12} marginTop={5}>
                                            <Input
                                                id="standard-adornment-password"
                                                placeholder="Please enter the Password"
                                                type={this.state.showPassword ? "text" : "password"}

                                                onChange={(e) => { this.setState({ password: e.target.value }) }}
                                                style={{
                                                    width: '90%',

                                                }}
                                                // style = {{width: 370}}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <VpnKeyIcon />
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => { this.setState({ showPassword: !(this.state.showPassword) }) }}>
                                                            {/* <VisibilityOff /> */}
                                                            {this.state.showPassword ? < Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>}
                                            />
                                        </Grid>



                                        <Grid item xs={12} marginTop={-2}>


                                            <Button
                                                // disabled={(login.email === "" || login.password === "") ? true : false}
                                                id="outlined-email-input"
                                                variant="contained"
                                                // sx={{ mt: 3, mb: 2, px: 21,marginLeft:1,marginRight:1 }}
                                                style={{
                                                    width: '90%', background: 'linear-gradient(91.75deg, #2B2D74 0.93%, #1789C0 110.51%)'

                                                }}
                                                disabled={this.state.password === ""}

                                                onClick={() => {
                                                    this.props.passwordVerify(this.state.password)
                                                }}
                                            >
                                                LOGIN
                                            </Button>


                                        </Grid>
                                    </Grid>
                                    <Grid height="128.9432373046875px"
                                        width="440px"
                                        // left: 0p
                                        // top: 500.53271484375px;
                                        // border-radius: 0px;
                                        background="#FAFAFA"
                                    ></Grid>

                                </Card>
                            </Grid>
                        </Grid>
                    </Grid >

                </>
        )
    }
}
