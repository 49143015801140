import React, { Component } from "react";
import { connect } from "react-redux";
import Order from "../component/order";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  createQuotation,
  createOrder,
  updateOrder,
  deleteOrder,
  viewAllOrder,
  convertBooking,
  viewVechileBooking,
  viewPaymentSummary,
  AddPayment,
  filter_model,
  filter_status,
  reSendQuotationpdf,
  reSendBookingpdf,
  searchorder,
  dashconvert_to_booking,
  view_status_delivered,
  view_status_booking
} from "../action";
import {
  viewAllCategory
} from "../../category/action";
import {
  viewModel,
  viewCategortModel
} from "../../model/action";
import {
  viewVarient,
  viewModelVarient
} from "../../variante/action";
import {
  viewAllCustomer
} from "../../customer/action"
import {
  viewAllColor
} from "../../color/action";

export class Controller extends Component {
  render() {
    return (
      <Order {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    // user: store.user
    login: store.login,
    all_order: store.all_order,
    all_category: store.all_category,
    all_model: store.all_model,
    all_varient: store.all_varient,
    all_customer: store.all_customer,
    all_color: store.all_color,
    all_vehicles: store.all_vehicles,
    snackbar: store.snackbar
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createQuotation: (customer_id,admin_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) => {
      dispatch(createQuotation(customer_id,admin_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment))
    },
    createOrder: (admin_id,customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) => {
      dispatch(createOrder(admin_id,customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment))
    },
    updateOrder: (_id,admin_id, customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) => {
      dispatch(updateOrder(_id,admin_id, customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment))
    },
    deleteOrder: (_id,admin_id) => {
      dispatch(deleteOrder(_id,admin_id))
    },
    viewAllOrder: (admin_id,status,order_status,page_number) => {
      dispatch(viewAllOrder(admin_id,status,order_status,page_number))
    },
  
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    viewModel: () => {
      dispatch(viewModel())
    },
    viewVarient: () => {
      dispatch(viewVarient())
    },
    viewAllCustomer: (admin_id) => {
      dispatch(viewAllCustomer(admin_id))
    },
    viewAllColor: () => {
      dispatch(viewAllColor())
    },
    viewCategortModel: (category_id) => {
      dispatch(viewCategortModel(category_id))
    },
    viewModelVarient: (model_id) => {
      dispatch(viewModelVarient(model_id))
    },
    convertBooking: (_id,admin_id, advance_amount, estimated_delivery_date, mode) => {
      dispatch(convertBooking(_id,admin_id, advance_amount, estimated_delivery_date, mode))
    },
    viewVechileBooking: (admin_id) => {
      dispatch(viewVechileBooking(admin_id))
    },
    viewPaymentSummary: (_id) => {
      dispatch(viewPaymentSummary(_id))
    },
    searchorder: (all_order,e) => {
      dispatch(searchorder(all_order,e))
    },
    filter_model: (all_order,e) => {
      dispatch(filter_model(all_order,e))
    },
    filter_status: (all_order,e) => {
      dispatch(filter_status(all_order,e))
    },
    AddPayment: (_id,admin_id, pending_amount, payment_mode, amount) => {
      dispatch(AddPayment(_id,admin_id, pending_amount, payment_mode, amount))
    },
    reSendQuotationpdf: (id, quotation_pdf) => {
      dispatch(reSendQuotationpdf(id, quotation_pdf))
    },
    reSendBookingpdf: (id, booking_pdf) => {
      dispatch(reSendBookingpdf(id, booking_pdf))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    dashconvert_to_booking: (_id,admin_id,advance_amount,estimated_delivery_date,mode) => {
      dispatch(dashconvert_to_booking(_id,admin_id,advance_amount,estimated_delivery_date,mode))
    },
    view_status_booking:() => {
      dispatch(view_status_booking())
    },
    view_status_delivered: () => {
      dispatch(view_status_delivered() )
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);