import React, { Component } from "react";
import { connect } from "react-redux";
import Addemployee from "../components/addemployee";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";

import {
    add_upload_profile
} from "../actions";
import {
    viewAlldepartment,
    view_all_TL
} from "../../../superadmin/action";
export class Controller extends Component {
    render() {
        return (
            <Addemployee {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        // user: store.user,
        // one_user: store.one_user,
        // login: store.login
        snackbar: store.snackbar,
        superadmin: store.superadmin

    };
};
export const mapDispatchToProps = dispatch => {
    return {
        add_upload_profile: (TL_Id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id) => {
            dispatch(add_upload_profile(TL_Id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address,basic_salary,department_id,designation,time,shift,admin_id))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
          },
          viewAlldepartment: () => {
            dispatch(viewAlldepartment());
          },
          view_all_TL: () => {
            dispatch(view_all_TL());
          },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);