export const SET_ORDER = "SET_ORDER";
export const SET_ORDER_PENDING = "SET_ORDER_PENDING";
export const SET_RTOREG = "SET_RTOREG";
export const SET_VEHICLEBOOKING = "SET_VEHICLEBOOKING";
export const SET_ALL_QUOTATION = "SET_ALL_QUOTATION";
export const SET_BOOKING_VECHILE= "SET_BOOKING_VECHILE";
export const VIEW_DASHBOARD_MANUFACTURING= "VIEW_DASHBOARD_MANUFACTURING";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_AMOUNT = "SET_AMOUNT";

//for pdf quotations
export const SET_ORDERS_ID = "SET_ORDERS_ID";
export const SET_CUSTOMER_NAME = "SET_CUSTOMER_NAME";
export const SET_CUSTOMER_ADDRESS = "SET_CUSTOMER_ADDRESS";
export const SET_CUSTOMER_MOBILE = "SET_CUSTOMER_MOBILE";
export const SET_CUSTOMER_GSTNO = "SET_CUSTOMER_GSTNO";
export const SET_VARIANT_NAME = "SET_VARIANT_NAME";
export const SET_VARIANT_VOLT = "SET_VARIANT_VOLT";
export const SET_VARIANT_LENGTH = "SET_VARIANT_LENGTH";
export const SET_VARIANT_WIDTH = "SET_VARIANT_WIDTH";
export const SET_VARIANT_CAPACITY = "SET_VARIANT_CAPACITY";
export const SET_VARIANT_MOTOR = "SET_VARIANT_MOTOR";
export const SET_VARIANT_RANGE = "SET_VARIANT_RANGE";
export const SET_BATTERY_NAME = "SET_BATTERY_NAME";
export const SET_BATTERY_VOLTAGE = "SET_BATTERY_VOLTAGE";
export const SET_COLOR_NAME = "SET_COLOR_NAME";
export const SET_MODEL_SPEED = "SET_MODEL_SPEED";
export const SET_MODEL_NAME = "SET_MODEL_NAME";
export const SET_ONROAD_PRICE = "SET_ONROAD_PRICE";
export const SET_INSURANCE_PRICE = "SET_INSURANCE_PRICE";
export const SET_REGISTRATION_PRICE = "SET_REGISTRATION_PRICE";
export const SET_TRANSPORTATION_PRICE = "SET_TRANSPORTATION_PRICE";
export const SET_TOTAL_PRICE = "SET_TOTAL_PRICE";
export const SET_QUANTITY = "SET_QUANTITY";
export const SET_BOOKING_DATE = "SET_BOOKING_DATE";
export const SET_DELIVERY_DATE = "SET_DELIVERY_DATE";
export const SET_ESTIMATE_NO = "SET_ESTIMATE_NO";
export const  SET_FILTERED_VALUE= "SET_FILTERED_VALUE";
export const  SET_DISCOUNT_AMOUNT= "SET_DISCOUNT_AMOUNT";
export const  SET_PAYMENT= "SET_PAYMENT";
export const  SET_AADHAR= "SET_AADHAR";
export const  SET_PAN= "SET_PAN";
export const  SET_GSTCERT= "SET_GSTCERT";
export const  SET_PHOTO= "SET_PHOTO";
export const  STATUS_DELIVERED= "STATUS_DELIVERED";
export const  STATUS_BOOKING= "STATUS_BOOKING";