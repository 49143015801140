import React, { Component } from "react";
import { connect } from "react-redux";
import Department from "../component/department";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    createdeparment,
    updatedepartment,
    deletedepartment,
    viewAlldepartment
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Department {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    
    snackbar: store.snackbar,
    admin: store.admin,
    superadmin: store.superadmin


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createdeparment: (department_name) => {
      dispatch(createdeparment(department_name))
    },
    updatedepartment: (department_id, department_name) => {
      dispatch(updatedepartment(department_id, department_name))
    },
    deletedepartment: (department_id) => {
      dispatch(deletedepartment(department_id))
    },
    viewAlldepartment: () => {
      dispatch(viewAlldepartment())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);