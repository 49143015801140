import React, { Component } from "react";
import { connect } from "react-redux";
import Admin_user from "../component/admin_user";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  onLogout
} from "../../../../auth/actions";
import {
    createadmin_user,
    updateadmin_user,
    // deleteCategory,
    view_all_admin_user,
    add_upload_profile,
    deleteUser,
    update_upload_admin_user
} from "../action";


// import{
//   deleteUser
// }from "../../empolyee/actions"
export class Controller extends Component {
  render() {
    return (
      <Admin_user {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_admin_user: store.all_admin_user,
    snackbar: store.snackbar

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createadmin_user: (admin_username,admin_useremail,admin_mobile,admin_userpassword) => {
      dispatch(createadmin_user(admin_username,admin_useremail,admin_mobile,admin_userpassword))
    },
    add_upload_profile: (admin_username,admin_useremail,admin_mobile,admin_userpassword, admin_profile) => {
      dispatch(add_upload_profile(admin_username, admin_useremail, admin_mobile, admin_userpassword, admin_profile))
    },
    update_upload_admin_user: (id, admin_username, admin_useremail, admin_mobile, admin_userpassword, profile_pic, profile_pic2) => {
      dispatch(update_upload_admin_user(id, admin_username, admin_useremail, admin_mobile, admin_userpassword, profile_pic, profile_pic2))
    },
    updateadmin_user: (id,admin_username,admin_useremail,admin_mobile,admin_userpassword) => {
      dispatch(updateadmin_user(id,admin_username,admin_useremail,admin_mobile,admin_userpassword))
    },
    deleteUser: (id) => {
      dispatch(deleteUser(id))
    },
    view_all_admin_user: () => {
      dispatch(view_all_admin_user())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    // onLogout: () => {
    //   dispatch(onLogout());
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);