import React, { Component } from "react";
import TextField from "@mui/material/TextField";
// import Box from "@mui/material/Box";
// import { Grid, Input, Typography } from '@mui/material';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import Avatar from "@material-ui/core/Avatar";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Grid, Input, Card } from "@mui/material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


// import Textarea from '@mui/joy/Textarea';


export default class Editprofile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      imageUrl: null,
      imageAlt: null,
      startDate: ""
    };
    // this.handleChange = this.handleChange.bind(this);
  }
  handleChange(date) {
    this.setState({
      startDate: date
    })
  }
  // handleFileChange(e){
  //   // if (e.target.files) {
  //     // console.log
  //     // this.props.upload_profile(e.target.files[0],this.props.login.user_id);
  //     this.props.upload_profile(this.props.login.user_id, this.props.one_user.name,this.props.one_user.mobile, this.props.one_user.email, this.state.dob, this.props.one_user.gender,e.target.files[0], this.props.one_user.p_address, this.props.one_user.pr_address, this.props.one_user.password);

  //   // }
  // };


  componentDidMount() {
    this.props.view_user_profile(this.props.login.user_id);

  }

  render() {

    // const { setStartDate } = this.state;
    const { imageUrl, imageAlt } = this.state;
    const {
      one_user,
    } = this.props;

    // const options = [ 'female', 'male', 'other']

    return (
      <div className="displayu">
        <Card
          component="form"
          style={{
            "& .MuiTextField-root": { m: 2, width: "25ch" },
            padding: '10vh 4vw',
            boxShadow: 'inset 0px 0px 4px 0px'

          }}
          noValidate
          autoComplete="off"
        > 
        <Grid marginTop={-5} marginLeft={-4} >
        <Link to="/myprofile" ><ArrowBackIcon/></Link></Grid>

          <Grid

            container
            spacing={1}
          // style={{  display: "flex", justifyContent: "center" }}
          >
            <Grid xs={4} lg={4} md={4} xlg={4}>
              <Stack
                spacing={1}
                style={{
                  textDecoration: "none",
                  // display: "inline-block",
                  padding: "0 45px",
                }}
                className="imagemedia"
              >
                <Avatar
                  alt="Cindy Baker"
                  src={one_user.profile_pic}
                  style={{ height: "114px", width: "114px" }}
                />
                {/* {imageUrl && (
                  <Avatar
                    src={imageUrl}
                    alt={imageAlt}
                    className="displayed-image"
                  />
                )} */}
                <Input type="file" onChange={(e) => this.setState({ profile_pic: e.target.files[0] })} />
              </Stack>
            </Grid>
            <Grid
              xs={8}
              lg={8}
              md={8}
              xlg={8}
              // style={{ padding: "87px 0vw" }}
              className="textmedia"
            >
              <Grid style={{ display: 'flex', gap: '10px' }}>
                <TextField
                  //  required
                  disabled
                  id="outlined--disabled"
                  label="EmployeeId"
                  InputLabelProps={{ shrink: true, required: true }}
                  value={one_user.Emp_Id}
                  placeholder="employeeid"
                // onChange={(event1) => { this.props.setID(event1.target.value) }}
                />

                <TextField
                  required="required"
                  id="outlined-disabled"
                  label="Name"
                  placeholder="vincanzo cassano"
                  value={one_user.name}
                  onChange={(event1) => { this.props.setName(event1.target.value) }}
                  InputLabelProps={{ shrink: true, required: true }}
                /></Grid>
              <br />

              <Grid style={{ display: 'flex', gap: '10px' }}>
                <TextField
                  required
                  id="outlined-password-input"
                  label="Mobile"
                  type="text" pattern="[0-9]{6}"  placeholder="mobile No"
                  value={one_user.mobile}
                  min={0}
                  // onKeyDown="if(this.value.length==2 && event.keyCode!=8) return false;"
                  maxLength={10}
                  onChange={(event1) => { this.props.setMobile(event1.target.value) }}
                  InputLabelProps={{ shrink: true, required: true }}
                />
                <TextField
                  id="outlined-read-only-input"
                  label="E-mail"
                  type="email"
                  // placeholder="ethical@yahoo.com"
                  value={one_user.email}
                  onChange={(event1) => { this.props.setEmail(event1.target.value) }}
                  InputLabelProps={{ shrink: true, required: true }}
                />

              </Grid>
              <br />
              <Grid style={{ display: 'flex', gap: '10px' }}>
                <TextField
                  required
                  id="outlined-password-input"
                  label="Password"
                  placeholder="password"
                  // type="number"
                  value={one_user.password}
                  onChange={(event1) => { this.props.setPassword(event1.target.value) }}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>
              <br />

              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={one_user.gender}
                  onChange={(event1) => { this.props.setGender(event1.target.value) }}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio checked={one_user.gender === "male"} />}
                    label="Male"

                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio checked={one_user.gender === "female"} />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio checked={one_user.gender === "other"} />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
              <br />


              {/* <TextField

                name="someDate"
                label="Some Date"
                type="date"
                value={one_user.one_user.dob}
                InputLabelProps={{ shrink: true, required: true }}
              /> */}

              {/* <div className="form-group">   */}
              <TextField
                required
                id="outlined-sTime-input"
                // countryCodeEditable={false}
                // helperText="Select Date Of Arrival"
                label="D.O.B"
                type="date"
                // fullWidth={true}
                InputLabelProps={{ shrink: true, required: true }}
                style={{ height: "30px", width: "280px", marginTop: "10px" }}
                // margin="normal"
                // variant="outlined"
                // value={one_user.dob}
                value={one_user.dob}
                onChange={(event1) => { this.props.setDob(event1.target.value) }}
              />

              {/* </div>   */}


              <br /> <br />
              <br />
              <Grid style={{ display: 'flex', gap: '10px' }}>
                <textarea
                  id="outlined-helperText"
                  label="present Adress"
                  placeholder="Present Adress"
                  value={one_user.pr_address}
                  onChange={(event1) => { this.props.setPr_address(event1.target.value) }}
                  style={{ borderRadius: '3px', height: '52px', width: '208px' }} />
                <textarea
                  id="outlined-helperText"
                  label="permanet adress"
                  placeholder="Peramanet Adress"
                  value={one_user.p_address}
                  onChange={(event1) => { this.props.setP_address(event1.target.value) }}
                  style={{ borderRadius: '3px', height: '52px', width: '208px' }} /></Grid>
              <br />

              <Grid
                xs={2}
                md={2}
                lg={2}
                xlg={2}
                style={{ display: "flex", gap: "303px" }}
                className="cancel_but"
              >
                <Link to="/myprofile"><Button
                  variant="contained"

                  style={{
                    width: "max-content",
                    display: "inline-block",
                    textAlign: "center",
                    padding: "1vh", backgroundColor: "orangered"
                  }}
                >
                  cancel
                </Button></Link>
                <Link to="/myprofile" style={{ textDecoration: "none" }}>
                  <Button

                    variant="contained"

                    style={{
                      width: "max-content",
                      display: "inline-block",
                      textAlign: "center",
                      padding: "1vh",
                      backgroundColor: "orangered"
                    }}
                    onClick={() => { this.props.upload_profile(one_user.one_user._id, one_user.name, one_user.mobile, one_user.email, one_user.dob, one_user.gender, this.state.profile_pic, one_user.p_address, one_user.pr_address, one_user.password) }}
                  >
                    save

                  </Button>
                </Link>

              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  }
}
