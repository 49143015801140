import {
    KIOSK_DETAILS,
    PASSWORD_VERIFY,
    TASK_KIOSK_DETAILS
    } from "./Constant";
    const initial_state = {
      password_verify: false,
      kiosk_details:[],
      task_kiosk_details:[],
    };
    export default function reducer(state = initial_state, action) {
      switch (action.type) {
        case KIOSK_DETAILS:
          return state = { ...state, kiosk_details: action.payload };    
        case PASSWORD_VERIFY:
          return state = { ...state, password_verify: action.payload };    
        case TASK_KIOSK_DETAILS:
          return state = { ...state, task_kiosk_details: action.payload };    
        default:
          return state;
      }
    }
    