import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../component/service";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  createService,
  updateService,
  deleteService,
  viewAllService,setService,
  updateServiceStatus,
  search_service,
  view_all_booking_service,
  view_All_Service_By_Admin_And_Superadmin,
  view_all_completed_service
} from "../action";
import {
  view_all_user
} from "../../empolyee/actions"
export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_service: store.all_service,
    user: store.user,
    one_user: store.one_user,
    snackbar: store.snackbar,
    login: store.login,


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createService: (admin_id,customer_id, vechicle_id, booking_date, status, estimated_visit_date, visit_date, address, components, service_charges, amount, comment) => {
      dispatch(createService(admin_id,customer_id, vechicle_id, booking_date, status, estimated_visit_date, visit_date, address, components, service_charges, amount, comment))
    },
    updateService: (admin_id, id, phone, email, battery_number, model_name, agent_name, page_number, rowper_page) => {
      dispatch(updateService(admin_id, id, phone, email, battery_number, model_name, agent_name, page_number, rowper_page))
    },
    updateServiceStatus: (admin_id, id, status, page_number, rowper_page) => {
      dispatch(updateServiceStatus(admin_id, id, status, page_number, rowper_page))
    },
    deleteService: (admin_id, id, page_number, rowper_page) => {
      dispatch(deleteService(admin_id, id, page_number, rowper_page))
    },
    viewAllService: (admin_id, page_number, rowper_page) => {
      dispatch(viewAllService(admin_id, page_number, rowper_page))
    },
    search_service: (all_service, e) => {
      dispatch(search_service(all_service, e))
    },
    view_all_user: (admin_id) => {
      dispatch(view_all_user(admin_id))
    },
    view_all_booking_service: () => {
      dispatch(view_all_booking_service())
    },
    view_all_completed_service: () => {
      dispatch(view_all_completed_service())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    setService: (payload) => {
      dispatch(setService(payload));
    },

    view_All_Service_By_Admin_And_Superadmin: (admin_id, page_number, rowper_page) => {
      dispatch(view_All_Service_By_Admin_And_Superadmin(admin_id, page_number, rowper_page));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);