import {
    SET_VEHICLE,
    SET_VEHICLES_DELIVERY,
    SET_DASHBOARD_STATISTICS,
    SET_VEHICLEBOOKING,
    SET_RTO,
    SET_RTO_NOPLATE,
    SET_VECH_DASH
} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
// import { set_snack_bar } from "../../../common/snackbar/action"
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import 'firebase/storage';
import { set_snack_bar } from "../../../common/snackbar/action";
// import firebase from 'firebase/app'


// export function createOrder(customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) {
//     return (dispatch) => {
//         dispatch(setLoader());
//         // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
//         return fetch(UNIVERSAL.BASEURL + "add_order", {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//                 customer_id: customer_id,
//                 model_id: model_id,
//                 category_id: category_id,
//                 variant_id: variant_id,
//                 quanty: quanty,
//                 order_color: order_color,
//                 will_registration: will_registration,
//                 applied: applied,
//                 received: received,
//                 length: length,
//                 width: width,
//                 height: height,
//                 insurance: insurance,
//                 registration: registration,
//                 transportation: transportation,
//                 booking_date: booking_date,
//                 estimated_delivery_date: estimated_delivery_date,
//                 delivery_date: delivery_date,
//                 order_status: order_status,
//                 comment: comment
//             })
//         })
//             .then((response) => response.json())
//             .then((responseJson) => {

//                 if (responseJson.status) {
//                     dispatch(viewAllOrder());
//                     // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
//                     dispatch(unsetLoader());
//                 } else {
//                     // alert("No Registration Found! Please Register First");
//                     // dispatch({ type: SET_USER, payload: [] })
//                     // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }

export function updateOrder(_id , customer_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: _id,
                customer_id: customer_id,
                model_id: model_id,
                category_id: category_id,
                variant_id: variant_id,
                quanty: quanty,
                order_color: order_color,
                will_registration: will_registration,
                applied: applied,
                received: received,
                length: length,
                width: width,
                width: width,
                height: height,
                insurance: insurance,
                registration: registration,
                transportation: transportation,
                booking_date: booking_date,
                estimated_delivery_date: estimated_delivery_date,
                delivery_date: delivery_date,
                order_status: order_status,
                comment: comment
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// export function deleteOrder(_id) {
//     return (dispatch) => {
//         dispatch(setLoader());
//         // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
//         return fetch(UNIVERSAL.BASEURL + "delete_order", {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//                 id: _id,
//             })
//         })
//             .then((response) => response.json())
//             .then((responseJson) => {

//                 if (responseJson.status) {
//                     dispatch(viewAllOrder());
//                     // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
//                     dispatch(unsetLoader());
//                 } else {
//                     // alert("No Registration Found! Please Register First");
//                     // dispatch({ type: SET_USER, payload: [] })
//                     // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }

export function viewAllvehicle(admin_id,pagenumber) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_vechicle", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id:admin_id,
                pagenumber:pagenumber
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    console.log(responseJson.result)
                    dispatch({ type: SET_VEHICLE, payload: { data: responseJson.result, count: responseJson.length } });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_VEHICLE, payload: { data: [], count: 0 } })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_all_manufactured(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_manufactured", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_RTO, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_RTO, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_all_noplate(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_rto_registered", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_RTO_NOPLATE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_RTO_NOPLATE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function view_all_vechdash(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "ready_for_delivery", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_VECH_DASH, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_VECH_DASH, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function Confirmvehicle(_id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "manufacturing_done", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                vechicle_id: _id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllvehicle(admin_id))
                    // dispatch(setuser(responseJson.result));
                    // dispatch({ type: SET_VEHICLE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_VEHICLE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}




export function dashConfirmvehicle(_id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "manufacturing_done", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                vechicle_id: _id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_vechicle_booking(admin_id));
                    dispatch(viewAllvehicle(admin_id));
                    dispatch(view_all_manufactured(admin_id));
                    dispatch(view_all_noplate(admin_id));
                    dispatch(view_all_vechdash(admin_id));
                    dispatch(view_vehicles_delivery(admin_id));
                    dispatch(view_dashboard_statistitcs(admin_id));



                    // dispatch(setuser(responseJson.result));
                    // dispatch({ type: SET_VEHICLE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_VEHICLE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function outdeliveryvehicle(_id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "out_for_delivery", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                vechicle_id: _id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllvehicle(admin_id))
                    // dispatch(setuser(responseJson.result));
                    // dispatch({ type: SET_VEHICLE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_VEHICLE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}




export function dashoutdeliveryvehicle(_id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "out_for_delivery", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                vechicle_id: _id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(outdeliveryvehicle(_id,admin_id));
                    dispatch(view_vechicle_booking(admin_id));
                    dispatch(viewAllvehicle(admin_id));
                    dispatch(view_all_manufactured(admin_id));
                    dispatch(view_all_noplate(admin_id));
                    dispatch(view_all_vechdash(admin_id));
                    dispatch(view_vehicles_delivery(admin_id));
                    dispatch(view_dashboard_statistitcs(admin_id));
                    // dispatch(setuser(responseJson.result));
                    // dispatch({ type: SET_VEHICLE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_VEHICLE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function Noplatevehicle(_id, no_plate,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "vechicle_number", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                vechicle_id: _id,
                number_plate: no_plate

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllvehicle())
                    dispatch(view_vechicle_booking(admin_id));
                    dispatch(viewAllvehicle(admin_id));
                    dispatch(view_all_manufactured(admin_id));
                    dispatch(view_all_noplate(admin_id));
                    dispatch(view_all_vechdash(admin_id));
                    dispatch(view_vehicles_delivery(admin_id));
                    dispatch(view_dashboard_statistitcs(admin_id));

                    // dispatch(setuser(responseJson.result));
                    // dispatch({ type: SET_VEHICLE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_VEHICLE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function upload_payment(_id, challan_number, payment_slip,admin_id) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/Challan/" + _id + ".pdf")
        // .put(image)
        // uploadBytes(storageRef, image).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //   });
        const uploadTask = uploadBytesResumable(storageRef, payment_slip);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (payment_slip) {
                    // console.log(downloadURL)
                    dispatch(Rtoreg(_id, challan_number, payment_slip,admin_id));
                });
            }
        );

    }
}

export function dash_Rtoreg(_id, challan_number, payment_slip,admin_id) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/Challan/" + _id + ".pdf")
        // .put(image)
        // uploadBytes(storageRef, image).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //   });
        const uploadTask = uploadBytesResumable(storageRef, payment_slip);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (payment_slip) {
                    // console.log(downloadURL)
                    dispatch(Rtoreg(_id, challan_number, payment_slip,admin_id));
                    dispatch(view_vechicle_booking(admin_id));
                    dispatch(viewAllvehicle(admin_id));
                    dispatch(view_all_manufactured(admin_id));
                    dispatch(view_all_noplate(admin_id));
                    dispatch(view_all_vechdash(admin_id));
                    dispatch(view_vehicles_delivery(admin_id));
                    dispatch(view_dashboard_statistitcs(admin_id));
                });
            }
        );

    }
}

export function Rtoreg(_id, challan_number, payment_slip,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_rto_reg", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                vechicle_id: _id,
                challan_number: challan_number,
                payment_slip: payment_slip

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch(viewAllvehicle(admin_id))
                    // dispatch({ type: SET_VEHICLE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_VEHICLE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}




// export function dash_Rtoreg(_id, challan_number, payment_slip) {
//     return (dispatch) => {
//         dispatch(setLoader());
//         return fetch(UNIVERSAL.BASEURL + "add_rto_reg", {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },

//             body: JSON.stringify({
//                 vechicle_id: _id,
//                 challan_number: challan_number,
//                 payment_slip: payment_slip

//             })

//         })
//             .then((response) => response.json())
//             .then((responseJson) => {

//                 if (responseJson.status) {
//                     dispatch(Rtoreg());
//                     // dispatch(setuser(responseJson.result));
//                     // dispatch({ type: SET_VEHICLE, payload: responseJson.result });
//                     // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
//                     dispatch(unsetLoader());
//                 } else {
//                     // alert("No Registration Found! Please Register First");
//                     // dispatch({ type: SET_VEHICLE, payload: [] })
//                     // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }


export function view_vehicles_delivery(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_vechicle_status_delivered", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_VEHICLES_DELIVERY, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_VEHICLES_DELIVERY, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function view_dashboard_statistitcs(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_dashboard_statistics", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
               

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson.manufacturing);
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_DASHBOARD_STATISTICS, payload: responseJson });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_DASHBOARD_STATISTICS, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {

                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_vechicle_booking(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_vechicle_booking", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_VEHICLEBOOKING, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_VEHICLEBOOKING, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
