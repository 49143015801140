import React, { Component } from 'react'
import {
    Grid,
    // Card,
    CardContent,
    Typography,
    Box,
    IconButton,
    Icon,
    TextField,
    DialogActions,
    DialogTitle,
    Stack, Input,
    Avatar
} from "@mui/material";
// import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MenuItem from "@mui/material/MenuItem";
import "../../style/variant.css"
import Dialog from "@mui/material/Dialog";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import CloseIcon from '@mui/icons-material/Close';
import { Link, Navigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from "../../../../common/snackbar/components/snackbar";



export default class addvariant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            model_pic: "",
            category_id:"",
            model_id:"",
            variant_name:"",
            braking:"",
            range:"",
            motor:"",
            warranty:"",
            capacity:"",
            charging_time:"",
            rear_axle:"",
            system:"",
            overall_length:"",
            width:"",
            exshowroom_price:"",
            registration_price:"",
            insurance_price:"",







            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            setRowsPerPage: 10,
        }
    }

    handleFileChange(e) {
        // if (e.target.files) {
        // console.log
        // this.props.upload_profile(e.target.files[0],this.props.login.user_id);
        // this.props.add_upload_profile(this.props.login.user_id, e.target.files[0], this.props.one_user.p_address, this.props.one_user.pr_address, this.props.one_user.password);
        // this.props.add_model_picture(this.state.name, this.state.mobile, this.state.email, this.state.address, e.target.files[0], e.target.files[0], e.target.files[0], e.target.files[0], e.target.files[0], e.target.files[0]);
        // this.props.add_model_picture(this.state.category_id, this.state.model_id, this.state.variant_name, this.state.model_pic, this.state.exshowroom_price, this.state.registration_price, this.state.insurance_price, this.state.braking, this.state.range, this.state.motor, this.state.warranty, this.state.capacity, this.state.charging_time, this.state.battery_voltage, this.state.system, this.state.overall_length, this.state.width, this.state.rear_axle, e.target.files[0]);


        // }
    };
    componentDidMount() {
        this.props.viewVarient();
        this.props.viewAllCategory();
        this.props.viewModel();
    }
    render() {
        const {
            all_varient,
            all_category,
            all_model,
            snackbar,
            login,
            close_snack_bar
        } = this.props;
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='makeorder'>
                        <center style={{ display: "flex" }}>
                            <Link to="/variant" style={{ textDecoration: "none" }}>
                                <IconButton
                                    style={{ marginLeft: "18px" }}

                                >
                                    <ArrowBackIcon style={{ color: "white" }} />
                                </IconButton>
                            </Link>


                            <Typography style={{ color: "white ", marginLeft: "40px", fontSize: 16, marginTop: "8px" }}>MAKE A NEW VARIANT</Typography>
                        </center>
                    </Box>


                </Grid>
                <Grid style={{ marginLeft: "50px", width: "300px" }}>
                    <Typography style={{ color: "black ", fontSize: 18, marginTop: "8px", fontWeight: "500", marginLeft: "30px" }}>MODEL PICTURE</Typography>


                    <Stack
                        spacing={1}
                        style={{
                            textDecoration: "none",
                            // display: "inline-block",
                            padding: "0 30px",
                            marginTop: "10px",
                        }}
                        className="imagemedia"
                    >
                        <Avatar
                            alt="Profile"
                            
                            src={this.state.model_pic !== "" ? URL.createObjectURL(this.state.model_pic) : this.state.model_pic}
                            style={{ height: "114px", width: "114px" }}
                            variant={"square"}
                            
                        />
                        {/* {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt={imageAlt}
                                            className="displayed-image"
                                        />
                                    )} */}
                        <Input
                            type="file"
                            // value={model_pic=""}
                            // onChange={(e)=>this.handleFileChange(e)}
                            onChange={(e) => { this.setState({ model_pic: e.target.files[0] }) }}
                        />
                    </Stack>
                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>CATEGORY NAME</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "400px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            // value={this.state.eventName}
                            
                            select

                            onChange={(e) => {
                                this.setState({ category_id: e.target.value });
                                this.props.viewCategortModel(e.target.value);
                            }}
                        >



                            <option value="Select Model" >

                                {/* SELECT CUSTOMER */}

                            </option>

                            {all_category.all_category.map((e) => (

                                <>

                                    <option value={e._id}>{e.category_name}</option>
                                </>

                            ))}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>MODEL NAME</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "400px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            // value={this.state.eventName}
                            select

                            onChange={(e) => {
                                this.setState({ model_id: e.target.value });
                                // this.props.viewCategortModel(e.target.value);
                            }}
                        >



                            <option value="Select Model" >

                                {/* SELECT CUSTOMER */}

                            </option>

                            {all_model.all_category_model.map((e) => (

                                <>
                                    <option value={e._id}>{e.model_name}</option>
                                </>

                            ))}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>

                </Grid>



                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> VARIANT NAME </label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ variant_name: e.target.value }) }}


                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> BRAKING</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ braking: e.target.value }) }}

                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> RANGE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ range: e.target.value }) }}
                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> MOTOR</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ motor: e.target.value }) }}

                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> WARRANTY</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ warranty: e.target.value }) }}

                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> CAPACITY</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ capacity: e.target.value }) }}

                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> CHARGING TIME</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ charging_time: e.target.value }) }}

                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    {/* <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> BATTERY TYPE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ battery_type: e.target.value }) }}

                        ></input>

                      

                    </Grid> */}

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> REAR AXLE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ rear_axle: e.target.value }) }}

                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> SYSTEM</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ system: e.target.value }) }}

                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}>  OVERALL LENGTH</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ overall_length: e.target.value }) }}

                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> WIDTH</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ width: e.target.value }) }}

                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> EX-SHOWROOM PRICE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ exshowroom_price: e.target.value }) }}

                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> REGISTRATION PRICE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ registration_price: e.target.value }) }}

                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}>  INSURANCE PRICE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ insurance_price: e.target.value }) }}

                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>


                </Grid>

                <Grid class="register-form-custom-select" style={{ marginTop: 25, display: 'flex', marginBottom: 25 }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}
                    {/* <Link to="/variant" style={{textDecoration:"none",marginLeft:"36%",pointerEvents: "none"}}> */}
                    {/* > */}
                    
                        <Typography marginLeft={0} sx={{ borderRadius: "0px" }}>
                             {/* <Link to="/variant" style={{textDecoration:"none",marginLeft:"36%",pointerEvents: "none"}}> */}
                            <Button 
                            style={{marginLeft:"356%",}}
                            // type='submit'
                            disabled={this.state.category_id==="" || this.state.model_id==="" || this.state.variant_name==="" || this.state.model_pic==="" || this.state.braking==="" || this.state.range==="" || this.state.motor==="" || this.state.warranty==="" || this.state.capacity==="" || this.state.charging_time==="" || this.state.system==="" || this.state.overall_length==="" || this.state.width==="" || this.state.rear_axle==="" || this.state.exshowroom_price==="" || this.state.insurance_price==="" || this.state.registration_price==="" }
                            onClick={() => {
                                // if()
                                this.props.add_model_picture(this.state.category_id, this.state.model_id, this.state.variant_name, this.state.model_pic, this.state.braking, this.state.range, this.state.motor, this.state.warranty, this.state.capacity, this.state.charging_time, this.state.system, this.state.overall_length, this.state.width, this.state.rear_axle, this.state.exshowroom_price, this.state.insurance_price, this.state.registration_price);
                                this.setState({
                                    category_id:"",
                                    model_id:"",
                                    variant_name:"",
                                    model_pic:"",
                                    braking:"",
                                    range:"",
                                    motor:"",
                                    warranty:"",
                                    capacity:"",
                                    charging_time:"",
                                    system:"",
                                    overall_length:"",
                                    width:"",
                                    rear_axle:"",
                                    exshowroom_price:"",
                                    insurance_price:"",
                                    registration_price:""











                                    
                                })
                                // <Navigate to="/variant"/>
                                // this.setState({ add: false });
                            }}
                                className='button' variant='contained' sx={{ borderRadius: "20px",width:"100%" }}>Add VARIANT</Button>
                                {/* </Link> */}
                                
                                </Typography>

                    {/* </Link> */}
                </Grid>
                <LoaderCon/>
                <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />

            </Grid>
        )
    }
}
