import React, { Component } from 'react'
import Box from '@mui/material/Box';
import { Card, Grid ,TableRow,Dialog,TextField,Stack,Input} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import "../../dashboard/component/dash.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Link } from 'react-router-dom';

export default class registrationdash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manufactured: false,
      registered: false,
      noplate: false,
      booking: false,
      allottee: false,
      servicecomplete:false

    }
  }
  componentDidMount() {
    // this.props.viewAllvehicle();
    // this.props.view_dashboard_statistitcs();
    // this.props.view_all_quotation();
    // this.props.view_all_user();
    // this.props.view_vehicles_delivery();
    // this.props.view_rto_reg();
    // this.props.viewAllOrder();
    // this.props.view_pending_order();
    // this.props.viewDashManufacturing();
    // this.props.view_vechicle_booking();
    this.props.view_all_manufactured(this.props.login.user_id);
    // this.props.view_all_noplate();
    // this.props.view_all_vechdash();
    // this.props.dash_Rtoreg();
    // this.props.viewAllService();
    // this.props.viewAllServiceAllotted();
  }
  render() {
    const {
      all_order,
      all_vehicles
    } = this.props;
    return (
      <div>
         <Grid  item xs={12} md={12} style={{ marginLeft: "60px" }} sx={{ display: 'flex', flexDirection: "row", }}>
            <Card  style={{ height: '100%', width: "100%",marginRight:"10%" }}>
              <Grid className='orderdash'>
                <CardContent sx={{ fontSize: 20, display: 'flex', flexDirection: "row", fontWeight: 400, color: "white", }} >
                  <Typography style={{ fontSize: 20, marginTop: -10 }}> RTO Registration</Typography>
                  <Typography style={{ color: "#D41111", marginLeft: "80%", marginTop: "-20px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_vehicles.all_rto.length}
                    <Typography marginLeft={-2} sx={{ fontWeight: 600 }}>Pending</Typography>
                  </Typography>

                </CardContent></Grid>
              <TableContainer >
                <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>


                  {/* <TableHead className="">
                    <TableRow >
                      <TableCell style={{ fontWeight: "600" }}>Customer</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Quotation</TableCell>
                      <TableCell>Variant</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Status</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Order</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                  {all_vehicles.all_rto.map((c) => {
                      return (
                        <TableRow
                          // key={row.name}
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1 }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell>{c.customer}</TableCell>
                          {/* <TableCell>{quotation.category}</TableCell> */}
                          {/* <TableCell>{c.variant}</TableCell> */}
                          <TableCell>
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          </TableCell>
                          <TableCell> <Button onClick={() => {
                            this.setState({
                              registered: true,
                              vechicle_id: c._id,
                            });
                          }} style={{ marginLeft: "40%" }}> <Typography marginTop={0} style={{ color: "#B4BDBB", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Completed

                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>


            

            </Card>
          </Grid>
          <Dialog
          open={this.state.registered}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "250px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  // type={"date"}
                  required
                  id="outlined-required"
                  label="Challan No"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ challan_number: e.target.value });
                  }}
                />
              </div>
              {/* <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    // type={"date"}
                                    required
                                    id="outlined-required"
                                    label="Payment Slip"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    //   value={this.state.category_name}
                                    onChange={(e) => {
                                        this.setState({ payment_slip: e.target.value });
                                    }}
                                />
                            </div> */}
              <Grid style={{ display: "flex", flexDirection: "column", width: "300px" }} >
                <Typography style={{ color: "black ", fontSize: 16, marginTop: "8px", fontWeight: "400", marginLeft: "30px" }}> Payment Slip :    </Typography>


                <Stack
                  spacing={1}
                  style={{
                    textDecoration: "none",
                    // display: "inline-block",
                    padding: "0 30px",
                    marginTop: "10px",
                  }}
                  className="imagemedia"
                >
                  {/* <Avatar
                                        alt="Profile"
                                        src={this.state.cust_aadhar}
                                        style={{ height: "114px", width: "114px" }}
                                        variant={"rounded"}
                                    /> */}
                  {/* {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt={imageAlt}
                                            className="displayed-image"
                                        />
                                    )} */}
                  <Input
                    type="file"
                    onChange={(e) => this.setState({ payment_slip: e.target.files[0] })}
                  />
                </Stack>
              </Grid>
              {/* <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Mode "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // defaultValue={this.state.model_id}
                                    onChange={(e) => { this.setState({ mode: e.target.value }) }}
                                >
                                   
                                    <MenuItem value={"cash"} >
                                       
                                    </MenuItem>
                                    <MenuItem value={"cheque"}>
                                       
                                        Cheque
                                    </MenuItem>
                                    <MenuItem value={"onlinepay"}>
                                       
                                    </MenuItem>
                                    
                                </TextField>

                            </div> */}

              <Button
                className="btns"
                onClick={() => {
                  this.props.dash_Rtoreg(
                    // this.state.id,
                    this.state.vechicle_id,
                    this.state.challan_number,
                    this.state.payment_slip,
                    this.props.login.user_id
                  );
                  this.setState({ registered: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Add
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ registered: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>



      </div>
    )
  }
}
