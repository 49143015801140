import {
  SET_ADMIN_USER
} from "./constant";
const initial_state = {
  all_admin_user: []
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_ADMIN_USER:
      return state = { ...state, all_admin_user: action.payload };
    // case SET_ONE_USER:
    //   return state = { ...state, one_user: action.payload };

    default:
      return state;
  }
}
