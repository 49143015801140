import React, { Component } from 'react';
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Icon,
    Typography,
    DialogActions,
    DialogTitle,
    Tooltip,
    TablePagination

} from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import {
    // Link,
    Navigate,
} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddIcon from '@mui/icons-material/Add';
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Snackbar from "../../../../common/snackbar/components/snackbar";


export default class admin_user extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            name: "",
            email: "",
            password: "",
            mobile: "",
            delar_name: "",
            page: 0,
            setPage: 0,
            rowsPerPage: 20,
            setRowsPerPage: "",


        }
    }
    componentDidMount() {
        this.props.viewAllDealerUser(this.state.page_number, this.state.rowsPerPage);

    }
    render() {
        const {
            all_admin_user,
            snackbar,
            close_snack_bar
        } = this.props;
        const { imageUrl, imageAlt } = this.state;
        const handleChangePage = (event, newPage) => {
            this.props.viewAllDealerUser(newPage * this.state.rowsPerPage, this.state.rowsPerPage);
            this.setState({
                page: newPage,
            });
        };
        const handleChangeRowsPerPage = (e) => {
            this.props.viewAllDealerUser(this.state.page * e.target.value, e.target.value,);
            this.setState({
                rowsPerPage: e.target.value,
                page: 0,
            });
        };
        if (this.state.redirect === true) {
            return <Navigate to="/" />;
        }
        return (
            <div>

                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='colorname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Dealer User</Button>
                        </center>
                    </Box>

                    <center style={{ borderRadius: 0 }}>

                        <Button className='coloradd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                            this.setState({
                                add: true,
                            })
                        }}>Add Dealer User</Button>
                    </center>

                </Grid>
                <Grid
                    // container
                    // direction="row"
                    alignItems="end"
                    // justifyContent="space-between"
                    mr={10}
                    style={{}}
                >
                    <TablePagination
                        rowsPerPageOptions={[20,]}
                        component="div"
                        count={Array.isArray(this.props.dealer.all_dealer_user) && this.props.dealer.all_dealer_user.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <Box
                    marginLeft={'5%'}
                    // marginTop={"%"}
                    marginRight={"5%"}
                >
                    <CardContent style={{ marginLeft: "20px", marginRight: "65px" }}>
                        <TableContainer component={Paper}>
                            <Table style={{ minWidth: 700 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                <TableHead className="colorhead">
                                    <TableRow >
                                        <TableCell>User Name</TableCell>
                                        <TableCell>Mobile No</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>password</TableCell>
                                        {/* <TableCell>Action</TableCell> */}

                                        <TableCell style={{ marginLeft: "-90px" }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="colorbody">
                                    {this.props.dealer.all_dealer_user.map((adminuser) => {
                                        return (
                                            <TableRow
                                                // key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >

                                                <TableCell >{adminuser.name}</TableCell>
                                                <TableCell >{adminuser.mobile}</TableCell>
                                                <TableCell >{adminuser.email}</TableCell>
                                                <TableCell >
                                                    {adminuser.password}
                                                    {/* <IconButton>
                                                        <Icon style={{ color: "blue" }}>visibility</Icon>
                                                    </IconButton> */}
                                                </TableCell>
                                                <TableCell  >
                                                    {/* <IconButton color="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                quotation: true,
                                                            })
                                                        }}
                                                        style={{ marginLeft: "-35px" }}
                                                    >
                                                        <Icon style={{ color: 'black' }} >
                                                            assignmentIcon
                                                        </Icon>

                                                    </IconButton> */}
                                                    {/* <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                view: true,
                                                               


                                                            });
                                                        }}
                                                    >
                                                        <Icon style={{ color: 'blue' }}>
                                                            visibility
                                                        </Icon>
                                                    </IconButton> */}
                                                    <Tooltip title="Edit">

                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    edit: true,
                                                                    id: adminuser._id,
                                                                    name: adminuser.name,
                                                                    email: adminuser.email,
                                                                    password: adminuser.password,
                                                                    mobile: adminuser.mobile,
                                                                    // _id: c._id,
                                                                    //     color_name: c.color_name,
                                                                    //     color_code: c.color_code,
                                                                });

                                                            }}
                                                            style={{ marginLeft: "-16px" }}
                                                        >
                                                            {/* <Icon style={{ color: 'green' }}>
                                                            edit
                                                        </Icon> */}
                                                            <EditOutlinedIcon style={{ color: 'green' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    delete: true,
                                                                    id: adminuser._id,

                                                                    // _id: c._id,
                                                                })

                                                            }}
                                                        >
                                                            {/* <Icon style={{ color: 'red' }}> */}
                                                            <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />
                                                            {/* </Icon> */}
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>

                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </CardContent>
                </Box>





                <Dialog
                    open={this.state.edit}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    // style={{wi}}
                    style={
                        {
                            // top: "50%",
                            // left: "10%",
                            // right: "auto",
                            // bottom: "auto",
                            // marginRight: "-50%",
                            // transform: "translate(-50%, -50%)",
                            // width: "1000px",
                            // marginLeft: "500px",
                            // paddingTop: "40px",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            // borderRadius: "0px",
                            height: "700px",
                            // zIndex: "200",
                            // backgroundColor: "#fff"
                        }}
                >
                    <Grid style={{ backgroundColor: "#fff", height: "400px", minWidth: "323px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Name"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.name}
                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Mobile"
                                    type={"text"}  pattern="[0-9]{10}" 
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.mobile}
                                    min={0}
                                    // onKeyDown="if(this.value.length==2 && event.keyCode!=8) return false;"
                                    maxLength={10}
                                    onChange={(e) => { this.setState({ mobile: e.target.value }) }}
                                />

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Email"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.email}
                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                />

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Password"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.password}
                                    onChange={(e) => { this.setState({ password: e.target.value }) }}
                                />

                            </div>

                            <Button className='btns'
                                disabled={this.state.email === "" || this.state.name === "" || this.state.password === ""}
                                onClick={() => {
                                    this.props.updateDealerUser(this.props.login.user, this.state.id, this.state.name, this.state.email, this.state.mobile, this.state.password, this.state.page_number, this.state.rowsPerPage);
                                    this.setState({ edit: false });
                                }} style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Update</Button>
                            <Button className='btns' onClick={() => {
                                this.setState({ edit: false });
                            }} style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Close</Button>


                        </Grid>

                    </Grid>

                </Dialog>



                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                    > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained" 
                        onClick={() => { 
                            this.props.deletedealerUser(this.state.id, this.state.page_number, this.state.rowsPerPage); 
                            this.setState({ delete: false }) 
                            }}>

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.add}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    // style={{wi}}
                    style={
                        {
                            // top: "50%",
                            // left: "10%",
                            // right: "auto",
                            // bottom: "auto",
                            // marginRight: "-50%",
                            // transform: "translate(-50%, -50%)",
                            // width: "1000px",
                            // marginLeft: "500px",
                            // paddingTop: "40px",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            // borderRadius: "0px",
                            height: "700px",
                            // zIndex: "200",
                            // backgroundColor: "#fff"
                        }}
                >
                    <Grid style={{ backgroundColor: "#fff", height: "370px", minWidth: "323px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="username"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Mobile No"
                                    type={"number"}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ mobile: e.target.value }) }}
                                />
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="email"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                />
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="password"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ password: e.target.value }) }}
                                />

                            </div>

                            <Button className='btns'
                                disabled={this.state.admin_useremail === "" || this.state.admin_username === "" || this.state.admin_userpassword === ""}
                                onClick={() => {
                                    this.props.addDelarUser(this.props.login.user_id, this.state.name, this.state.email, this.state.mobile,this.state.password, this.state.page_number, this.state.rowsPerPage);
                                    this.setState({ add: false });
                                }} style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                            <Button className='btns' onClick={() => {
                                this.setState({ add: false });
                            }} style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Close</Button>


                        </Grid>

                    </Grid>

                </Dialog>
                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />
            </div>
        )
    }
}