import React, { Component } from "react";
import {
    Grid,
    // Card,
    CardContent,
    Typography,
    Box,
    IconButton,
    Icon,
    TextField,
    DialogActions,
    DialogTitle,
    Stack, Input,
    Avatar,Tooltip

} from "@mui/material";
// import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MenuItem from "@mui/material/MenuItem";
import "../../style/variant.css"
import Dialog from "@mui/material/Dialog";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from "../../../../common/snackbar/components/snackbar";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import "../../style/order.css";
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));
// import "../../style/order.css";

export default class variant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            filterValue: this.props.all_varient.all_varient,


        }
    }
    componentDidMount() {
        this.props.viewVarient();
        this.props.viewAllCategory();
        this.props.viewModel();
        console.log(this.props.all_varient.filtered_model)
    }

    handleChange = (e) => {
        // const el=e.target.value
        if (e.target.value === "") {
        //   this.setState({ filterValue: this.props.all_model.all_model })
          this.props.set_filtered_value(this.props.all_varient.all_varient);
        }
        else {
          const newArray = this.props.all_varient.all_varient.filter((el) => {
            return (el.variant_name.toLowerCase().includes(e.target.value.toLowerCase()))
          })
          this.props.set_filtered_value(newArray);
        //   this.setState({ filterValue: newArray })
        }
        console.log(this.state.filterValue);
      }
    //   console.log(this.state.variant_name);
    

    render() {
        const {
            all_varient,
            all_category,
            all_model,
            snackbar,
            close_snack_bar,
            login,
            setFilter
        } = this.props;
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className="ordername">
                        <center>
                            <Button style={{ color: "white ", textAlign: "center" }}>
                                Variant
                            </Button>
                        </center>
                    </Box>

                    <center style={{ borderRadius: 0 }}>
                        <Link to="/addvarint" style={{ textDecoration: "none" }}>



                            <Button
                                className="orderadd"
                                style={{ color: "white ", textAlign: "center" }}
                            // onClick={() => {
                            //     this.setState({
                            //         add: true,
                            //     });
                            // }}
                            >
                                Add Variant
                            </Button>
                        </Link>
                    </center>
                </Grid>

                <Grid container direction="row" justifyContent="space-between"
                    style={{ paddingLeft: 48, paddingRight: 64 }}>
                    <Grid item lg={4}>
                        <Search
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 52,
                                width: "300px",
                                border: "2px solid #000000"
                            }}
                            direction="row"
                        >
                            <SearchIconWrapper>
                                <SearchIcon style={{ color: "black" }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search Varient  "
                                color="black"
                                inputProps={{ "aria-label": "search" }}
                                style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                            // onChange={handleChange}
                            onChange={(e)=>this.props.searchModel(all_varient.all_varient,e)}

                            />
                        </Search>
                    </Grid>
                    <Grid class="register-form-custom-select" style={{ marginTop: 5, }} >
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 48,
                                width: "200px",
                                border: "2px solid #000000"
                            }}
                            name=""
                            // value={this.state.eventName}
                            select
                            onChange={(e)=>{this.props.filter_category(all_varient.all_varient,e.target.value)}}

                        // onChange={handleChangeDepartment}
                        >

                            <option value="">All Category</option>
                            {all_category.all_category.map((option) => (
                                        <option key={option._id} value={option._id}>
                                            {option.category_name}
                                        </option>
                                    ))}
                           
                        </select>
                        <select
                            // style={{ width: "200px", marginLeft: "10px" }}
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 48,
                                width: "200px",
                                border: "2px solid #000000"
                            }}
                            name="participant type"
                        // value={this.state.eventName}
                        // onChange={handleChangeEvent}
                        onChange={(e)=>{this.props.filter_model(all_varient.all_varient,e.target.value)}}
                        >

                            <option value="">All Model</option>
                            {all_model.all_model.map((e) => (
                                    <option key={e._id} value={e._id}>
                                        {e.model_name}
                                    </option>
                                ))}
                            {/* {events.all_events.map((row) => (

                                        <>
                                            <option value={row.event_id}>{row.event_name}</option>
                                        </>

                                    ))} */}
                        </select>


                    </Grid>
                </Grid>

                <CardContent style={{ marginLeft: "40px", marginRight: "65px" }}>
                    <TableContainer component={Paper}>
                        <Table
                            style={{ minWidth: 600 }}
                            size="small"
                            aria-label="a dense table"
                            ref={this.tableRef}
                        >
                            <TableHead className="orderhead">
                                <TableRow>
                                    {/* <TableCell>Category ID</TableCell> */}

                                    {/* <TableCell>Category name</TableCell> */}
                                    <TableCell>Variant name</TableCell>
                                    <TableCell>Breaking </TableCell>
                                    {/* <TableCell>Speed </TableCell> */}
                                    <TableCell>Range</TableCell>
                                    <TableCell>Motor</TableCell>
                                    {/* <TableCell>Warranty</TableCell>
                                    <TableCell>Capacity</TableCell>
                                    <TableCell>Charging time</TableCell>
                                    <TableCell>Battery Type</TableCell>
                                    <TableCell>Battery Voltage</TableCell>
                                    <TableCell>System</TableCell>
                                    <TableCell>Overall length</TableCell>
                                    <TableCell>Width</TableCell>
                                    <TableCell>Rear Axle</TableCell> */}

                                    <TableCell style={{ marginLeft: "-80px" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="orderbody">

                                {all_varient.filtered_model.sort((a, b) => (a.variant_name || '').localeCompare(b.variant_name || '', undefined, { sensitivity: 'base' })).map((allvarient) => {
                                    return (
                                        <TableRow
                                            // key={row.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            {/* <TableCell >{category._id}</TableCell>  */}
                                            {/* <TableCell >{allvarient.category}</TableCell> */}
                                            <TableCell >{allvarient.variant_name}</TableCell>
                                            <TableCell >{allvarient.braking}</TableCell>
                                            {/* <TableCell >{allvarient.speed}</TableCell> */}
                                            <TableCell >{allvarient.range}</TableCell>
                                            <TableCell >{allvarient.motor}</TableCell>
                                            {/* <TableCell >{allvarient.warranty}</TableCell>
                                            <TableCell >{allvarient.capacity}</TableCell>
                                            <TableCell >{allvarient.charging_time}</TableCell>
                                            <TableCell >{allvarient.battery_type}</TableCell>
                                            <TableCell >{allvarient.battery_voltage}</TableCell>
                                            <TableCell >{allvarient.system}</TableCell>
                                            <TableCell >{allvarient.overall_length}</TableCell>
                                            <TableCell >{allvarient.width}</TableCell>
                                            <TableCell >{allvarient.rear_axle}</TableCell> */}

                                            <TableCell>
                                            <Tooltip title="Edit">
                                                <Link to="/editvariant" style={{ textDecoration: "none" }}>
                                                    <IconButton
                                                        style={{ marginLeft: "-15px" }}
                                                        onClick={() => {

                                                            this.props.viewVarientByid(allvarient._id)
                                                            // this.setState({
                                                            //     edit: true,
                                                            // id:_id
                                                            // category_id: allvarient.category_id,
                                                            // model_id: allvarient.model_id,
                                                            // // model_name: allvarient.model_name,
                                                            // // variant_name: allvarient.variant_name,
                                                            // braking: allvarient.braking,
                                                            // speed: allvarient.speed,
                                                            // range: allvarient.range,
                                                            // motor: allvarient.motor,
                                                            // warranty: allvarient.warranty,
                                                            // capacity: allvarient.capacity,
                                                            // charging_time: allvarient.charging_time,
                                                            // battery_type: allvarient.battery_type,
                                                            // battery_voltage: allvarient.battery_voltage,
                                                            // system: allvarient.system,
                                                            // overall_length: allvarient.overall_length,
                                                            // width: allvarient.width,
                                                            // rear_axle: allvarient.rear_axle,
                                                            // });
                                                        }}
                                                    >
                                                        <EditOutlinedIcon style={{ color: "green" }} />
                                                    </IconButton>
                                                </Link>
                                                </Tooltip>
                                                <Tooltip title="View">

                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            view: true,
                                                            id: allvarient._id,
                                                            category_id: allvarient.category_id,
                                                            model_pic: allvarient.model_pic,
                                                            model_id: allvarient.model_id,
                                                            // model_name: allvarient.model_name,
                                                            variant_name: allvarient.variant_name,
                                                            exshowroom_price: allvarient.exshowroom_price,
                                                            insurance_price: allvarient.insurance_price,
                                                            registration_price: allvarient.registration_price,
                                                            braking: allvarient.braking,
                                                            speed: allvarient.speed,
                                                            range: allvarient.range,
                                                            motor: allvarient.motor,
                                                            warranty: allvarient.warranty,
                                                            capacity: allvarient.capacity,
                                                            charging_time: allvarient.charging_time,
                                                            battery_type: allvarient.battery_type,
                                                            battery_voltage: allvarient.battery_voltage,
                                                            system: allvarient.system,
                                                            overall_length: allvarient.overall_length,
                                                            width: allvarient.width,
                                                            rear_axle: allvarient.rear_axle,


                                                        });
                                                    }}
                                                >
                                                    <RemoveRedEyeOutlinedIcon style={{ color: 'blue' }} />

                                                </IconButton>
                                                </Tooltip>
                                                {/* <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            delete: true,
                                                            id: allvarient._id,
                                                        });
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon style={{ color: "red" }} />
                                                </IconButton> */}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>


                <Dialog
                    open={this.state.view}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ marginLeft: "0%", width: "100%" }}
                >
                    <Grid className="view" height={460}>


                        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box className='details'>
                                <center>

                                    <Button style={{ color: "white ", textAlign: 'center', marginLeft: "0px", fontSize: 16 }}>   Variant Details</Button>
                                </center>




                            </Box>
                            <IconButton
                                // color=""
                                style={{ marginLeft: "0%", marginTop: "-0%" }}
                                onClick={() => {
                                    this.setState({
                                        view: false,
                                        // _id: c._id,
                                        // color_name: c.color_name,
                                        // color_code: c.color_code,
                                    })
                                }}
                            // size={18}
                            >
                                <CloseIcon style={{ color: 'black', fontSize: 48 }}
                                />
                            </IconButton>

                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item sm={6}>
                                <Box textAlign="center">
                                    <Typography style={{ color: "black ", fontSize: 18, marginTop: "8px", fontWeight: "500", marginLeft: "4px" }}>MODEL PICTURE</Typography>


                                    {/* <Stack
                        spacing={1}
                        style={{
                            textDecoration: "none",
                            // display: "inline-block",
                            padding: "0 30px",
                            marginTop: "10px",
                        }}
                        className="imagemedia"
                    > */}
                                    {/* <Avatar
                            alt="Profile"
                            src={this.state.model_pic !== "" ? URL.createObjectURL(this.state.model_pic) : this.state.model_pic}
                            style={{ height: "114px", width: "114px" }}
                            variant={"square"}
                        /> */}
                                    {/* {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt={imageAlt}
                                            className="displayed-image"
                                        />
                                    )} */}
                                    {/* <Input
                            type="file"
                            // onChange={(e)=>this.handleFileChange(e)}
                            onChange={(e) => { this.setState({ model_pic: e.target.files[0] }) }}
                        /> */}
                                    {/* </Stack> */}

                                    <img
                                        src={this.state.model_pic}
                                        style={{ width: 140, height: 150 }} />


                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box textAlign="center">
                                    <br></br>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Warranty:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.warranty}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Capacity:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.capacity}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Charging Time:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.charging_time}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>System:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.system}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Overall length:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.overall_length}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Width:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.width}</Typography>
                                    </Grid>
                                    


                                </Box>
                            </Grid>
                        </Grid>



                        <Grid class="register-form-custom-select" style={{  width: 1200, display: 'flex' }}  >

                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item sm={6}>
                                <Box textAlign="center">

                                
                                    <Grid item style={{ display: "flex", width: 200,  marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Ex-showroom price:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.exshowroom_price}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Registration price:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.registration_price}</Typography>
                                    </Grid>
                                  




                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box textAlign="center">

                                   
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Rear Axle:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.rear_axle}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Braking:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.braking}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "2%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Insurance price:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;{this.state.insurance_price}</Typography>
                                    </Grid>


                                </Box>
                            </Grid>
                        </Grid>





                    </Grid>

                </Dialog>
                {/* <Dialog
          open={this.state.view}
          // onClose={() => { this.setState({ view: false }) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography style={{ color: "blue", fontSize: 25 }}>
              Category Deatils
            </Typography>

            <IconButton
              color="primary"
              style={{ marginLeft: "93%", marginTop: "-15%" }}
              onClick={() => {
                this.setState({
                  view: false,
                  // _id: c._id,
                  // color_name: c.color_name,
                  // color_code: c.color_code,
                });
              }}
            >
              <CloseIcon style={{ color: "blue" }} />
            </IconButton>
          </DialogTitle>

          <Grid
            lg={8}
            style={{ display: "flex", flexDirection: "row", width: "600px" }}
          >
            

            <Grid style={{ marginLeft: "25px", marginTop: "-30px" }}>
              <Grid item style={{ display: "flex", width: 200 }}>
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>
                  Category Name:
                </Typography>
                <Typography style={{ fontSize: 15 }}>
                  &nbsp;{this.state.category_name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Dialog> */}

                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                    > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained" onClick={() => { this.props.deleteVariant(this.state.id); this.setState({ delete: false }) }}>

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.add}

                >
                    <Box borderRadius={5} sx={{
                        width: 300,
                        height: 400,
                        backgroundColor: 'white',

                    }}>
                        <Typography textAlign="center" marginTop={2}>
                            VARIANT
                        </Typography>

                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                select
                                id="outlined-required"
                                label="category name"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.category_name}
                                onChange={(e) => { this.setState({ category_id: e.target.value }) }}
                            >
                                {all_category.all_category.map((e) => (
                                    <MenuItem key={e._id} value={e._id}>
                                        {e.category_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Typography>

                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                select
                                id="outlined-required"
                                label="Model name"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.model_name}
                                onChange={(e) => { this.setState({ model_id: e.target.value }) }}
                            >
                                {all_model.all_model.map((e) => (
                                    <MenuItem key={e._id} value={e._id}>
                                        {e.model_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Breaking"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.category_name}
                                onChange={(e) => { this.setState({ braking: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Speed"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.category_name}
                                onChange={(e) => { this.setState({ speed: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Range"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.varient_name}
                                onChange={(e) => { this.setState({ Range: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>


                            <TextField
                                required
                                id="outlined-required"
                                label="Motor"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "60px", width: "280px" }}
                                // value={this.state.type}
                                onChange={(e) => { this.setState({ motor: e.target.value }) }}
                            />

                        </Typography>

                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Warranty"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.battery_type}
                                onChange={(e) => { this.setState({ warranty: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Capacity"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.battery_voltage}
                                onChange={(e) => { this.setState({ capacity: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Charging time"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.rear_axle}
                                onChange={(e) => { this.setState({ charging_time: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Battery Type"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.braking}
                                onChange={(e) => { this.setState({ battery_type: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Battery Voltage"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.braking}
                                onChange={(e) => { this.setState({ battery_voltage: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="System"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.braking}
                                onChange={(e) => { this.setState({ system: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Overall length"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.braking}
                                onChange={(e) => { this.setState({ overall_length: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Width"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.braking}
                                onChange={(e) => { this.setState({ width: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Rear Axle"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                // value={this.state.braking}
                                onChange={(e) => { this.setState({ rear_axle: e.target.value }) }}
                            />
                        </Typography>


                        <Button onClick={() => {
                            this.props.createVarient(this.state.category_id, this.state.model_id, this.state.braking, this.state.speed, this.state.range, this.state.motor, this.state.warranty, this.state.capacity, this.state.charging_time, this.state.battery_type, this.state.battery_voltage, this.state.system, this.state.overall_length, this.state.width, this.state.rear_axle);
                            this.setState({ add: false });
                        }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Add</Button>
                        <Button onClick={() => {
                            this.setState({ add: false });
                        }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Close</Button>
                    </Box>
                </Dialog>


                {/* <Grid
                        style={{
                            backgroundColor: "#fff",
                            height: "450px",
                            minWidth: "323px",
                            scrollBehavior: "smooth",
                            overflow: "revert-layer",
                            alignSelf: "center",
                            overflowX: "hidden",
                            flexDirection: "row",
                            display: "flex",
                            overflowY: "hidden",
                        }}
                    >
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    id="outlined-name-input"
                                    countryCodeEditable={false}
                                    label="Variant Name"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    style={{ height: "50px", width: "280px" }}
                                    required={false}
                                    value={this.state.category_name}
                                    onChange={(e) => this.setState({ category_id: e.target.value })}
                                    select
                                >
                                    {all_category.all_category.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.category_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Range"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => {
                                        this.setState({ model_name: e.target.value });
                                    }}
                                />

                            </div>
                            <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Battery Volatage"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => {
                                        this.setState({ speed: e.target.value });
                                    }}
                                />

                            </div>
                            <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Battery Type"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => {
                                        this.setState({ speed: e.target.value });
                                    }}
                                />

                            </div>
                            <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Real Axle"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => {
                                        this.setState({ speed: e.target.value });
                                    }}
                                />

                            </div>

                            <Button
                                onClick={() => {
                                    this.props.createModel(this.state.model_name, this.state.speed);
                                    this.setState({ add: false });
                                }}
                                style={{
                                    marginLeft: "20px",
                                    backgroundColor: "ButtonFace",
                                    marginBottom: "30px",
                                    marginTop: "20px",
                                }}
                            >
                                Add
                            </Button>
                            <Button
                                onClick={() => {
                                    this.setState({ add: false });
                                }}
                                style={{
                                    marginLeft: "20px",
                                    backgroundColor: "ButtonFace",
                                    marginBottom: "30px",
                                    marginTop: "20px",
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid> */}
                {/* // </Dialog> */}






                {/* <Dialog
                    open={this.state.edit}
                >
                    <Box borderRadius={5} sx={{
                        width: 300,
                        height: 400,
                        backgroundColor: 'white',

                    }}>
                        <Typography textAlign="center" marginTop={2}>
                            VARIANT
                        </Typography>


                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                select
                                id="outlined-required"
                                label="category name"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.category_name}
                                onChange={(e) => { this.setState({ category_id: e.target.value }) }}
                            >
                                {all_category.all_category.map((e) => (
                                    <MenuItem key={e._id} value={e._id}>
                                        {e.category_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Typography>

                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                select
                                id="outlined-required"
                                label="Model name"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.model_name}
                                onChange={(e) => { this.setState({ model_id: e.target.value }) }}
                            >
                                {all_model.all_model.map((e) => (
                                    <MenuItem key={e._id} value={e._id}>
                                        {e.model_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Typography> */}
                {/* <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label=" Model e"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.variant_name}
                                onChange={(e) => { this.setState({ model_id: e.target.value }) }}
                            />
                        </Typography> */}
                {/* <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Breaking"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.braking}
                                onChange={(e) => { this.setState({ braking: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>


                            <TextField
                                required
                                id="outlined-required"
                                label="Speed"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "60px", width: "280px" }}
                                value={this.state.speed}
                                onChange={(e) => { this.setState({ speed: e.target.value }) }}
                            />

                        </Typography>

                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Range"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.range}
                                onChange={(e) => { this.setState({ range: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Motor"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.motor}
                                onChange={(e) => { this.setState({ motor: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Warranty"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.warranty}
                                onChange={(e) => { this.setState({ warranty: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Capacity"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.capacity}
                                onChange={(e) => { this.setState({ capacity: e.target.value }) }}
                            />
                        </Typography>
                        <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Charging time"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.charging_time}
                                onChange={(e) => { this.setState({ charging_time: e.target.value }) }}
                            />
                        </Typography>  <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Battery Type"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.battery_type}
                                onChange={(e) => { this.setState({ battery_type: e.target.value }) }}
                            />
                        </Typography>  <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Battery Voltage"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.battery_voltage}
                                onChange={(e) => { this.setState({ battery_voltage: e.target.value }) }}
                            />
                        </Typography>  <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="System"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.system}
                                onChange={(e) => { this.setState({ system: e.target.value }) }}
                            />
                        </Typography>  <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Overall length"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.overall_length}
                                onChange={(e) => { this.setState({ overall_length: e.target.value }) }}
                            />
                        </Typography>  <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Width"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.width}
                                onChange={(e) => { this.setState({ width: e.target.value }) }}
                            />
                        </Typography>  <Typography marginLeft={1} marginTop={2}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Rear Axle"
                                InputLabelProps={{ shrink: true, required: true }}
                                style={{ height: "50px", width: "280px" }}
                                value={this.state.rear_axle}
                                onChange={(e) => { this.setState({ rear_axle: e.target.value }) }}
                            />
                        </Typography>


                        <Button onClick={() => {
                            this.props.updateVarient(this.state.id, this.state.category_id, this.state.model_id, this.state.braking, this.state.speed, this.state.range, this.state.motor, this.state.warranty, this.state.capacity, this.state.charging_time, this.state.battery_type, this.state.battery_voltage, this.state.system, this.state.overall_length, this.state.width, this.state.rear_axle);
                            this.setState({ edit: false });
                        }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>UPDATE</Button>
                        <Button onClick={() => {
                            this.setState({ edit: false });
                        }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Close</Button>
                    </Box>
                </Dialog> */}



                <LoaderCon />
                <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
            </Grid>
            // <div>
            //     {/* model */}
            //     <Box
            //         marginLeft={'5%'}
            //         // marginTop={"%"}
            //         marginRight={"5%"}
            //     >
            //         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
            //             <Button style={{ marginLeft: 14, backgroundColor: "ButtonFace   " }} onClick={() => {
            //                 this.setState({
            //                     add: true,
            //                     // id: allusers._id,
            //                 })
            //             }}>ADD

            //                 <AddIcon style={{ marginTop: 0, color: "blue" }} />
            //             </Button>
            //         </Grid>

            //         <CardContent>
            //             <TableContainer
            //                 component={Paper}
            //             >
            //                 <Table
            //                     style={{ minWidth: '10%' }}
            //                     size="small"
            //                     aria-label="a dense table"
            //                     ref={this.tableRef}
            //                 >
            //                     <TableHead>
            //                         <TableRow>
            //                             <Typography style={{ fontSize: 20, color: "blue", marginLeft: "10px", fontWeight: "bold" }}>ALL MODEL</Typography>

            //                         </TableRow>
            //                         <TableRow>
            //                             {/* <TableCell>Model ID</TableCell> */}
            //                             {/* <TableCell>Category ID</TableCell> */}
            //                             <TableCell>Model Name</TableCell>
            //                             <TableCell>Speed</TableCell>
            //                             <TableCell>Action</TableCell>
            //                         </TableRow>

            //                     </TableHead>
            //                     {all_model.all_model.map((allmodel) => {
            //                         return (
            //                             <TableBody>
            //                                 <TableRow
            //                                     sx={{
            //                                         "&:last-child td, &:last-child th": { border: 0 },
            //                                     }}
            //                                 >
            //                                     {/* <TableCell >{allmodel._id}</TableCell> */}
            //                                     {/* <TableCell >{allmodel.category_id}</TableCell> */}
            //                                     <TableCell >{allmodel.model_name}</TableCell>
            //                                     <TableCell >{allmodel.speed}</TableCell>

            //                                     {/* <TableCell >{allusers.type}</TableCell> */}
            //                                     <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", }}>
            //                                         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
            //                                             {/* //edit */}
            //                                             <IconButton
            //                                                 onClick={() => {
            //                                                     this.setState({
            //                                                         edit: true,
            //                                                         id: allmodel._id,
            //                                                         category_id: allmodel.category_id,
            //                                                         model_name: allmodel.model_name,
            //                                                         speed: allmodel.speed,

            //                                                         // name: allusers.name,
            //                                                         // mobile: allusers.mobile,
            //                                                         // email: allusers.email,
            //                                                         // dob: allusers.dob,
            //                                                         // gender: allusers.gender,
            //                                                         // p_address: allusers.p_address,
            //                                                         // pr_address: allusers.pr_address,
            //                                                         // password: allusers.password,
            //                                                     })
            //                                                 }}
            //                                             >
            //                                                 <EditIcon style={{ color: 'green' }}
            //                                                 />
            //                                             </IconButton>

            //                                         </Grid>
            //                                         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 40 }}>
            //                                             <IconButton onClick={() => { this.setState({ delete: true }) }}>

            //                                                 <DeleteRoundedIcon style={{ color: 'red' }}
            //                                                     onClick={() => {
            //                                                         this.setState({
            //                                                             delete: true,
            //                                                             id: allmodel._id,
            //                                                         })
            //                                                     }}
            //                                                 />
            //                                             </IconButton>
            //                                         </Grid>
            //                                         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 40 }}>
            //                                             <IconButton onClick={() => {
            //                                                 this.setState({
            //                                                     view: true,

            //                                                 })
            //                                             }}
            //                                             >

            //                                                 {/* <VisibilityIcon
            //                                             style={{ color: "Blue", pointerEvents: "visiblePainted" }}
            //                                         // onClick={() => {
            //                                         //     this.setState({
            //                                         //         view: true,
            //                                         //         profile_pic: allusers.profile_pic,
            //                                         //         gender: allusers.gender,
            //                                         //         dob: allusers.dob,
            //                                         //         p_address: allusers.p_address,
            //                                         //         pr_address: allusers.pr_address
            //                                         //     })
            //                                         // }}
            //                                         /> */}
            //                                             </IconButton>
            //                                         </Grid>

            //                                     </Grid>

            //                                 </TableRow>

            //                             </TableBody>
            //                         );
            //                     })}
            //                 </Table>

            //             </TableContainer>
            //         </CardContent>
            //     </Box>

            //     
            //     <Dialog
            //         open={this.state.delete}
            //         onClose={() => {
            //             this.setState({ delete: false });
            //         }}
            //         // style={{wi}}
            //         style={
            //             {
            //                 // top: "50%",
            //                 // left: "50%",
            //                 // right: "auto",
            //                 // bottom: "auto",
            //                 // marginRight: "-50%",
            //                 // transform: "translate(-50%, -50%)",
            //                 // width: "470px",
            //                 // marginLeft: "500px",
            //                 // paddingTop: "40px",
            //                 // paddingLeft: "25px",
            //                 // paddingRight: "25px",
            //                 // borderRadius: "0px",
            //                 // height: "300px",
            //                 // zIndex: "200",
            //                 // backgroundColor: "#fff"
            //             }}

            //     >
            //         <Box style={{ backgroundColor: "#fff", height: "100px", width: "350px" }}>
            //             <Typography

            //                 style={{ fontSize: "16px", textAlign: 'center' }}
            //             >
            //                 Are You Sure ?
            //             </Typography>
            //             <Grid sx={{ marginLeft: "32%", marginTop: "8%" }}>
            //                 <Button
            //                     onClick={() => { this.props.deleteModel(this.state.id); this.setState({ delete: false }) }}
            //                 >Yes</Button>
            //                 <Button onClick={() => {
            //                     this.setState({ delete: false });
            //                 }}>No</Button>
            //             </Grid>

            //         </Box>

            //     </Dialog>
            //     <Dialog
            //         open={this.state.add}
            //         // onClose={() => {
            //         //     this.setState({ add: false });
            //         // }}
            //         // style={{wi}}
            //         style={
            //             {
            //                 // top: "50%",
            //                 // left: "10%",
            //                 // right: "auto",
            //                 // bottom: "auto",
            //                 // marginRight: "-50%",
            //                 // transform: "translate(-50%, -50%)",
            //                 // width: "1000px",
            //                 // marginLeft: "500px",
            //                 // paddingTop: "40px",
            //                 // paddingLeft: "25px",
            //                 // paddingRight: "25px",
            //                 // borderRadius: "0px",
            //                 height: "700px",
            //                 // zIndex: "200",
            //                 // backgroundColor: "#fff"
            //             }}
            //     >
            //         <Grid style={{ backgroundColor: "#fff", height: "300px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
            //             <Grid>
            //                 <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

            //                     <TextField
            //                         id="outlined-name-input"
            //                         countryCodeEditable={false}
            //                         label="Category Name"
            //                         type="text"
            //                         fullWidth={true}
            //                         margin="normal"
            //                         variant="outlined"
            //                         style={{ height: "50px", width: "280px" }}
            //                         required={false}
            //                         value={this.state.category_name}
            //                         onChange={(e) => this.setState({ category_id: e.target.value })}
            //                         select
            //                     >
            //                         {all_category.all_category.map((option) => (
            //                             <MenuItem key={option._id} value={option._id}>
            //                                 {option.category_name}
            //                             </MenuItem>
            //                         ))}
            //                     </TextField>
            //                 </div>

            //                 <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                     <TextField
            //                         required
            //                         id="outlined-required"
            //                         label="MODEL NAME"
            //                         // value={this.state.name}
            //                         InputLabelProps={{ shrink: true, required: true }}
            //                         style={{ height: "50px", width: "280px" }}
            //                         onChange={(e) => { this.setState({ model_name: e.target.value }) }}
            //                     />

            //                 </div>

            //                 <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
            //                     <TextField
            //                         required
            //                         id="outlined-required"
            //                         label="SPEED"
            //                         // value={this.state.name}
            //                         InputLabelProps={{ shrink: true, required: true }}
            //                         style={{ height: "50px", width: "280px" }}
            //                         onChange={(e) => { this.setState({ speed: e.target.value }) }}
            //                     />

            //                 </div>

            //                 <Button
            //                     onClick={() => {
            //                         this.props.createModel(this.state.category_id, this.state.model_name, this.state.speed);
            //                         this.setState({ add: false });
            //                     }}
            //                     style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Add</Button>
            //                 <Button onClick={() => {
            //                     this.setState({ add: false });
            //                 }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Close</Button>

            //             </Grid>

            //         </Grid>

            //     </Dialog>
            //     <LoaderCon />

            // </div>
        );
    }
}

// import React, { Component } from 'react'
// import {
//   Grid,
//   CardContent,
//   Box,
//   IconButton,
//   Button,
//   Typography,

// } from "@mui/material";
// import {
//   // Link,
//   Navigate,
// } from "react-router-dom";
// import Dialog from "@mui/material/Dialog";
// // import Modal from "react-modal";
// import TextField from '@mui/material/TextField';
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// // import TablePagination from "@mui/material/TablePagination";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
// import AddIcon from '@mui/icons-material/Add';
// import MenuItem from '@mui/material/MenuItem';
// // import AppBar from '@mui/material/AppBar';
// // import Toolbar from '@mui/material/Toolbar';
// // import InputLabel from '@mui/material/InputLabel';
// // import MenuItem from '@mui/material/MenuItem';
// // import FormControl from '@mui/material/FormControl';
// // import Select from '@mui/material/Select';
// // import { Input } from "@mui/material";
// // import VisibilityIcon from '@mui/icons-material/Visibility';
// // import CloseIcon from '@mui/icons-material/Close';
// // import DialogActions from "@mui/material/DialogActions";
// // import DialogContent from "@mui/material/DialogContent";
// // import DialogContentText from "@mui/material/DialogContentText";
// // import DialogTitle from "@mui/material/DialogTitle";
// // import { Button } from "@mui/material";
// // import { Grade } from '@mui/icons-material';
// // import Avatar from '@mui/material/Avatar';
// // import Stack from "@mui/material/Stack";
// import LoaderCon from "../../../../common/loader/containers/loader_cont";

// export default class variant extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       edit: false,
//       delete: false,
//       add: false,
//       view: false,
//       imageUrl: null,
//       imageAlt: null,

//     }
//   }
//   componentDidMount() {
//     this.props.viewVarient();
//     this.props.viewAllCategory();
//     this.props.viewModel();
//   }

//   render() {
//     const {
//       all_varient,
//       all_category,
//       all_model
//     } = this.props;
//     const { imageUrl, imageAlt } = this.state;
//     if (this.state.redirect === true) {
//       return <Navigate to="/" />;
//     }

//     return (
//       <div>
//         <Box
//           marginLeft={'5%'}
//           // marginTop={"%"}
//           marginRight={"5%"}
//         >
//           <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
//             <Button style={{ marginLeft: 14, backgroundColor: "ButtonFace   " }} onClick={() => {
//               this.setState({
//                 add: true,
//                 // id: allusers._id,
//               })
//             }}>ADD

//               <AddIcon style={{ marginTop: 0, color: "blue" }} />
//             </Button>
//           </Grid>

//           <CardContent>
//             <TableContainer
//               component={Paper}
//             >
//               <Table
//                 style={{ minWidth: '10%' }}
//                 size="small"
//                 aria-label="a dense table"
//                 ref={this.tableRef}
//               >
//                 <TableHead>
//                   <TableRow>
//                     <Typography style={{ fontSize: 20, color: "blue", marginLeft: "10px", fontWeight: "bold" }}>ALL VARIANT</Typography>

//                   </TableRow>
//                   <TableRow>
//                     {/* <TableCell>Category ID</TableCell> */}
//                     {/* <TableCell>Model ID</TableCell> */}
//                     <TableCell>Variant Name</TableCell>
//                     <TableCell>Range</TableCell>
//                     <TableCell>Battery Voltage</TableCell>
//                     <TableCell>Battery Type</TableCell>
//                     <TableCell>Rear Axle</TableCell>
//                     <TableCell>Breaking </TableCell>
//                     <TableCell>varient type</TableCell>
//                     <TableCell>Action</TableCell>
//                   </TableRow>

//                 </TableHead>
//                 {all_varient.all_varient.map((allvarient) => {
//                   return (
//                     <TableBody>
//                       <TableRow
//                         sx={{
//                           "&:last-child td, &:last-child th": { border: 0 },
//                         }}
//                       >
//                         {/* <TableCell >{allvarient.category_id}</TableCell> */}
//                         {/* <TableCell >{allvarient.model_id}</TableCell> */}
//                         <TableCell >{allvarient.variant_name}</TableCell>
//                         <TableCell >{allvarient.range}</TableCell>
//                         <TableCell >{allvarient.battery_voltage}</TableCell>
//                         <TableCell >{allvarient.battery_type}</TableCell>
//                         <TableCell >{allvarient.rear_axle}</TableCell>
//                         <TableCell >{allvarient.braking}</TableCell>
//                         <TableCell >{allvarient.type}</TableCell>

//                         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", }}>
//                           <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
//                             <IconButton onClick={() => {
//                               this.setState({
//                                 edit: true,
//                                 id: allvarient._id,
//                                 category_id: allvarient.category_id,
//                                 model_id: allvarient.model_id,
//                                 model_name: allvarient.model_name,
//                                 variant_name: allvarient.variant_name,
//                                 range: allvarient.range,
//                                 battery_voltage: allvarient.battery_voltage,
//                                 battery_type: allvarient.battery_type,
//                                 rear_axle: allvarient.rear_axle,
//                                 braking: allvarient.braking,
//                                 type: allvarient.type
//                               })
//                             }}>
//                               <EditIcon
//                                 style={{ color: 'green' }}
//                               />
//                             </IconButton>

//                           </Grid>
//                           <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 20 }}>
//                             <IconButton onClick={() => { this.setState({ delete: true }) }}>

//                               <DeleteRoundedIcon
//                                 style={{ color: 'red' }} onClick={() => {
//                                   this.setState({
//                                     delete: true,
//                                     id: allvarient._id,
//                                   })
//                                 }} />
//                             </IconButton>
//                           </Grid>


//                         </Grid>

//                       </TableRow>

//                     </TableBody>
//                   );
//                 })}
//               </Table>

//             </TableContainer>
//           </CardContent>
//         </Box>
//         <Dialog
//           open={this.state.add}

//         >
//           <Box borderRadius={5} sx={{
//             width: 300,
//             height: 400,
//             backgroundColor: 'white',

//           }}>
//             <Typography textAlign="center" marginTop={2}>
//               VARIANT
//             </Typography>

//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 select
//                 id="outlined-required"
//                 label="category Id"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.category_name}
//                 onChange={(e) => { this.setState({ category_id: e.target.value }) }}
//               >
//                 {all_category.all_category.map((e) => (
//                   <MenuItem key={e._id} value={e._id}>
//                     {e.category_name}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Typography>

//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 select
//                 id="outlined-required"
//                 label="Model ID"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.model_name}
//                 onChange={(e) => { this.setState({ model_id: e.target.value }) }}
//               >
//                 {all_model.all_model.map((e) => (
//                   <MenuItem key={e._id} value={e._id}>
//                     {e.model_name}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label=" variant name"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 // value={this.state.category_name}
//                 onChange={(e) => { this.setState({ variant_name: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Range"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 // value={this.state.varient_name}
//                 onChange={(e) => { this.setState({ range: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>


//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Battery Volatage"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "60px", width: "280px" }}
//                 // value={this.state.type}
//                 onChange={(e) => { this.setState({ battery_voltage: e.target.value }) }}
//               />

//             </Typography>

//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Battery type"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 // value={this.state.battery_type}
//                 onChange={(e) => { this.setState({ battery_type: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Rear axle"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 // value={this.state.battery_voltage}
//                 onChange={(e) => { this.setState({ rear_axle: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Braking"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 // value={this.state.rear_axle}
//                 onChange={(e) => { this.setState({ braking: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="varient type"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 // value={this.state.braking}
//                 onChange={(e) => { this.setState({ type: e.target.value }) }}
//               />
//             </Typography>


//             <Button onClick={() => {
//               this.props.createVarient(this.state.category_id, this.state.model_id, this.state.variant_name, this.state.range, this.state.battery_voltage, this.state.battery_type, this.state.rear_axle, this.state.braking, this.state.type);
//               this.setState({ add: false });
//             }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Add</Button>
//             <Button onClick={() => {
//               this.setState({ add: false });
//             }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Close</Button>
//           </Box>
//         </Dialog>
//         <Dialog
//           open={this.state.delete}
//           onClose={() => {
//             this.setState({ delete: false });
//           }}
//           // style={{wi}}
//           style={
//             {
//               // top: "50%",
//               // left: "50%",
//               // right: "auto",
//               // bottom: "auto",
//               // marginRight: "-50%",
//               // transform: "translate(-50%, -50%)",
//               width: "470px",
//               marginLeft: "500px",
//               // paddingTop: "40px",
//               // paddingLeft: "25px",
//               // paddingRight: "25px",
//               borderRadius: "0px",
//               // height: "300px",
//               zIndex: "200",
//               backgroundColor: "#fff"
//             }}

//         >
//           <Box style={{ backgroundColor: "#fff", height: "100px", width: "350px" }}>
//             <Typography

//               style={{ fontSize: "16px", textAlign: 'center' }}
//             >
//               Are You Sure ?
//             </Typography>
//             <Grid sx={{ marginLeft: "32%", marginTop: "8%" }}>
//               <Button onClick={() => { this.props.deleteVariant(this.state.id); this.setState({ delete: false }) }}>Yes</Button>
//               <Button onClick={() => {
//                 this.setState({ delete: false });
//               }}>No</Button>
//             </Grid>


//           </Box>




//         </Dialog>
//         <Dialog
//           open={this.state.edit}
//         >
//           <Box borderRadius={5} sx={{
//             width: 300,
//             height: 400,
//             backgroundColor: 'white',

//           }}>
//             <Typography textAlign="center" marginTop={2}>
//               VARIANT
//             </Typography>


//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 select
//                 id="outlined-required"
//                 label="category Id"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.category_name}
//                 onChange={(e) => { this.setState({ category_id: e.target.value }) }}
//               >
//                 {all_category.all_category.map((e) => (
//                   <MenuItem key={e._id} value={e._id}>
//                     {e.category_name}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Typography>

//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 select
//                 id="outlined-required"
//                 label="Model ID"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.model_name}
//                 onChange={(e) => { this.setState({ model_id: e.target.value }) }}
//               >
//                 {all_model.all_model.map((e) => (
//                   <MenuItem key={e._id} value={e._id}>
//                     {e.model_name}
//                   </MenuItem>
//                 ))}
//               </TextField>
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label=" variant name"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.variant_name}
//                 onChange={(e) => { this.setState({ variant_name: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Range"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.range}
//                 onChange={(e) => { this.setState({ range: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>


//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Battery Volatage"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "60px", width: "280px" }}
//                 value={this.state.battery_voltage}
//                 onChange={(e) => { this.setState({ battery_voltage: e.target.value }) }}
//               />

//             </Typography>

//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Battery type"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.battery_type}
//                 onChange={(e) => { this.setState({ battery_type: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Rear axle"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.rear_axle}
//                 onChange={(e) => { this.setState({ rear_axle: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="Braking"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.braking}
//                 onChange={(e) => { this.setState({ braking: e.target.value }) }}
//               />
//             </Typography>
//             <Typography marginLeft={1} marginTop={2}>
//               <TextField
//                 required
//                 id="outlined-required"
//                 label="varient type"
//                 InputLabelProps={{ shrink: true, required: true }}
//                 style={{ height: "50px", width: "280px" }}
//                 value={this.state.type}
//                 onChange={(e) => { this.setState({ type: e.target.value }) }}
//               />
//             </Typography>


//             <Button onClick={() => {
//               this.props.updateVarient(this.state.id, this.state.category_id, this.state.model_id, this.state.variant_name, this.state.range, this.state.battery_voltage, this.state.battery_type, this.state.rear_axle, this.state.braking, this.state.type);
//               this.setState({ edit: false });
//             }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>UPDATE</Button>
//             <Button onClick={() => {
//               this.setState({ edit: false });
//             }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Close</Button>
//           </Box>
//         </Dialog>
//         <LoaderCon />

//       </div>
//     )
//   }
// }
