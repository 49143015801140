import React, { Component } from 'react';
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Icon,
    Typography,
    DialogActions,
    DialogTitle,
    Tooltip

} from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import {
    // Link,
    Navigate,
} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddIcon from '@mui/icons-material/Add';
// import LoaderCon from "../../../../common/loader/containers/loader_cont";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Snackbar from "../../../common/snackbar/components/snackbar";
import { Link } from 'react-router-dom';


export default class admin_user extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            admin_username: "",
            admin_useremail: "",
            admin_userpassword: "",
            id:""


        }
    }
    componentDidMount() {
        this.props.view_all_TL();

    }
    render() {
        const {
            superadmin,
            snackbar,
            close_snack_bar
        } = this.props;
        const { imageUrl, imageAlt } = this.state;
        if (this.state.redirect === true) {
            return <Navigate to="/" />;
        }
        return (
            <div>

                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='colorname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Team Leader</Button>
                        </center>
                    </Box>

                    <center style={{ borderRadius: 0 }}>

                    <Link to = "/addteamlead"> <Button className='coloradd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                            this.setState({
                                add: true,
                            })
                        }}>Add Team Leader</Button></Link>
                    </center>

                </Grid>
                <Box
                    marginLeft={'5%'}
                    // marginTop={"%"}
                    marginRight={"5%"}
                >
                    <CardContent style={{ marginLeft: "20px", marginRight: "65px" }}>
                        <TableContainer component={Paper}>
                            <Table style={{ minWidth: 700 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                <TableHead className="colorhead">
                                    <TableRow >
                                        <TableCell>Team Leader Name</TableCell>
                                        <TableCell>Mobile No</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>password</TableCell>
                                        {/* <TableCell>Action</TableCell> */}

                                        <TableCell style={{ marginLeft: "-90px" }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="colorbody">
                                    {superadmin.all_tl.map((tl) => {
                                        return (
                                            <TableRow
                                                // key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >

                                                <TableCell >{tl.name}</TableCell>
                                                <TableCell >{tl.mobile}</TableCell>
                                                <TableCell >{tl.email}</TableCell>
                                                <TableCell >
                                                    {tl.password}
                                                    {/* <IconButton>
                                                        <Icon style={{ color: "blue" }}>visibility</Icon>
                                                    </IconButton> */}
                                                </TableCell>
                                                <TableCell  >
                                                    {/* <IconButton color="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                quotation: true,
                                                            })
                                                        }}
                                                        style={{ marginLeft: "-35px" }}
                                                    >
                                                        <Icon style={{ color: 'black' }} >
                                                            assignmentIcon
                                                        </Icon>

                                                    </IconButton> */}
                                                    {/* <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                view: true,
                                                               


                                                            });
                                                        }}
                                                    >
                                                        <Icon style={{ color: 'blue' }}>
                                                            visibility
                                                        </Icon>
                                                    </IconButton> */}
                                                    <Tooltip title="Edit">
                                                    <Link to = "/editteamlead">
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    edit: true,
                                                                 //    id: adminuser._id,
                                                                 //    admin_username: adminuser.name,
                                                                 //    admin_useremail: adminuser.email,
                                                                 //    admin_userpassword: adminuser.password,
                                                                 //    admin_mobile: adminuser.mobile,
                                                                    // _id: c._id,
                                                                    //     color_name: c.color_name,
                                                                    //     color_code: c.color_code,
                                                                });

                                                            }}
                                                            style={{ marginLeft: "-16px" }}
                                                        >
                                                            {/* <Icon style={{ color: 'green' }}>
                                                            edit
                                                        </Icon> */}
                                                            <EditOutlinedIcon style={{ color: 'green' }} />
                                                        </IconButton></Link>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    delete: true,
                                                                    // id: tl_id,

                                                                    // _id: c._id,
                                                                })

                                                            }}
                                                        >
                                                            {/* <Icon style={{ color: 'red' }}> */}
                                                            <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />
                                                            {/* </Icon> */}
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>

                                         );
                                    })} 
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </CardContent>
                </Box>





            



                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                    > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained" onClick={() => { this.props.delete_TL(this.state.id); this.setState({ delete: false }) }}>

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.add}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    // style={{wi}}
                    style={
                        {
                            // top: "50%",
                            // left: "10%",
                            // right: "auto",
                            // bottom: "auto",
                            // marginRight: "-50%",
                            // transform: "translate(-50%, -50%)",
                            // width: "1000px",
                            // marginLeft: "500px",
                            // paddingTop: "40px",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            // borderRadius: "0px",
                            height: "700px",
                            // zIndex: "200",
                            // backgroundColor: "#fff"
                        }}
                >
                    <Grid style={{ backgroundColor: "#fff", height: "370px", minWidth: "323px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="username"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ admin_username: e.target.value }) }}
                                />
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Mobile No"
                                    type={"number"}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ admin_mobile: e.target.value }) }}
                                />
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="email"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ admin_useremail: e.target.value }) }}
                                />
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    id="outlined-required"
                                    label="password"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ admin_userpassword: e.target.value }) }}
                                />

                            </div>

                            <Button className='btns'
                                disabled={this.state.admin_useremail === "" || this.state.admin_username === "" || this.state.admin_userpassword === ""}
                                onClick={() => {
                                   //  this.props.createadmin_user(this.state.admin_username, this.state.admin_useremail, this.state.admin_mobile,this.state.admin_userpassword);
                                    this.setState({ add: false });
                                }} style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                            <Button className='btns' onClick={() => {
                                this.setState({ add: false });
                            }} style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Close</Button>


                        </Grid>

                    </Grid>

                </Dialog>
                <LoaderCon />
                {/* <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}
            </div>
        )
    }
}