import {
  SET_DEALER,
  SET_DEALER_USER
} from "./constant";
const initial_state = {
  all_dealer: [],
  all_dealer_user: [],
  length:"",
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_DEALER:
      return state = { ...state, all_dealer:action.payload.data, length: action.payload.count };
      case SET_DEALER_USER:
        return state = { ...state, all_dealer_user:action.payload.data, length: action.payload.count };
    // case SET_ONE_USER:
    //   return state = { ...state, one_user: action.payload };

    default:
      return state;
  }
}
