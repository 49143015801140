import { Button, Typography } from "@mui/material";
import React, { Component } from "react";
// import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Link from '@mui/material/Link';
// import { Link } from "react-router-dom";
import "../pages/startpage.css";
// import { Gradient } from '@mui/icons-material';
// import bg from './/image/bg.jpeg.jpeg'
import check from'.//image/check your task.png'
import crmm from  './/image/crm.png.png'
import order from './image/order.png'
import Service from "./image/service.png"
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import "../pages/user/components/profile.css";
import LogoutIcon from '@mui/icons-material/Logout';

export default class Startpage extends Component {
  componentDidMount() {
    this.props.view_user_profile(this.props.login.user_id);
  }
  render() {
    const {
      one_user
    } = this.props;
    
    return (
      <div className="display">
        <Box>
        <Grid style={{ display: "flex",marginLeft:"97%" }}>
            <Avatar
              alt="Cindy Baker"
              src={one_user.one_user.profile_pic}
              style={{ width: "100px", height: "100px", marginTop: "-30px", }}
            />
            <Link to="/myprofile" style={{textDecoration:'none'}}>

            <Button style={{backgroundColor:"#00016b",color:"white",marginLeft:"-122px",width:"100%",minWidth:"150px",borderRadius:50, marginTop: "80px",}}>
              My Profile
            </Button>
            </Link>
            <Link 
                to="/"  style={{textDecoration:"none"}}>
                        <Button style={{ color:"red",textDecoration:"none",borderWidth:4,borderColor:"red",marginLeft:"-122px",marginTop:"120px"}}
                        
                        
                        onClick={() => {
                            // localStorage.removeItem("user_id");
                            this.setState({ redirect: true });
                            this.props.onLogout();
                          }}>
                            LogOut

                            <LogoutIcon style={{ marginTop: 0, color: "red" }} />
                        </Button>
            </Link>
            </Grid>


          <Grid style={{display:'flex',justifyContent:'center',alignItems:'center',marginRight:"50px"}}>
          <Grid xs={4} lg={4} md={4} xlg={4}>
          <Link to="/emporder" style={{textDecoration:'none'}}><img src={order} className='firstimg' style={{    border: '0.01px solid gray',borderRadius: '19px'}}/></Link>  
          <Link to="/empservice" style={{textDecoration:'none',marginLeft:40}}><img src={Service} className='firstimg' style={{    border: '0.01px solid gray',borderRadius: '19px',}}/></Link>  
            </Grid>
            <Grid xs={4} lg={4} md={4} xlg={4}>
            {/* <Link style={{textDecoration:'none'}}>- */}
              <a href="https://trello.com/en" target={"_blank"}><img src={check} className='secondimg'style={{marginLeft:40}} /></a>
              {/* </Link>   */}

            </Grid>
            <Grid xs={4} lg={4} md={4} xlg={4}>
            {/* <Link style={{textDecoration:'none'}}>  */}
            <a href="https://skyyriderelectric.teknovatecrm.in/web/login" target={"_blank"} >

            <img src={crmm} className='firstimg' style={{marginLeft:40}}/>
            </a>
            {/* </Link>   */}

            </Grid>
           





          </Grid>
        </Box>
      </div>
    );
  }
}
