import {
  SET_MODEL,
  SET_CATEGORY_MODEL,
  SET_FILTERED_VALUE
} from "./constant";
const initial_state = {
  all_model: [],
  all_category_model:[],
  filtered_model:[]
  };
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_MODEL:
      return state = { ...state, all_model: action.payload,filtered_model:action.payload };
      case SET_CATEGORY_MODEL:
      return state = { ...state, all_category_model: action.payload };
      case SET_FILTERED_VALUE:
      return state = { ...state, filtered_model: action.payload };
    // case SET_ONE_USER:
    //   return state = { ...state, one_user: action.payload };

    default:
      return state;
  }
}
