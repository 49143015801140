import React, { Component } from "react";
import { connect } from "react-redux";
import Bookingpdf from "../component/bookingpdf";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  uploadBooking,
  viewQuotationByOrderId
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Bookingpdf {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    // user: store.user
    all_order: store.all_order,
    snackbar: store.snackbar,
    login: store.login,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    uploadBooking: (image,order_id) => {
      dispatch(uploadBooking(image,order_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewQuotationByOrderId: (id) => {
      dispatch(viewQuotationByOrderId(id));
    },
  };  
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);