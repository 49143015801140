// export const SET_VEHICLE = "SET_VEHICLE";
export const SET_ALL_ADMIN_DETAILS = 'SET_ALL_ADMIN_DETAILS';
export const SET_CALL_TAKEN = 'SET_CALL_TAKEN';
export const SET_NEW_LEAD = 'SET_NEW_LEAD';
export const SET_TOTAL_LEAD = 'SET_TOTAL_LEAD';
export const SET_TOTAL_CONVERSION = 'SET_TOTAL_CONVERSION';
export const SET_DEPARTMENT = 'SET_DEPARTMENT';
export const SET_ONETL_USER = 'SET_ONETL_USER';
export const SET_TL_ID = 'SET_TL_ID';
export const SET_TL_DEPARTMENT_NAME = 'SET_TL_DEPARTMENT_NAME';
export const SET_TL_NAME = 'SET_TL_NAME';
export const SET_TL_MOBILE = 'SET_TL_MOBILE';
export const SET_TL_EMAIL = 'SET_TL_EMAIL';
export const SET_TL_GENDER = 'SET_TL_GENDER';
export const SET_TL_DOB = 'SET_TL_DOB';
export const SET_TL_PADDRESS = 'SET_TL_PADDRESS';
export const SET_TL_PRADDRESS = 'SET_TL_PRADDRESS';
export const SET_TL_PROFILE_PIC = 'SET_TL_PROFILE_PIC';
export const SET_TL_PASSWORD = 'SET_TL_PASSWORD';
export const SET_TL_BASICSALARY = 'SET_TL_BASICSALARY';
export const SET_TL_DEPARTMENT = 'SET_TL_DEPARTMENT';
export const SET_TL_DESIGNATION = 'SET_TL_DESIGNATION';
export const SET_TL_TIME = 'SET_TL_TIME';
export const SET_TL_SHIFT = 'SET_TL_SHIFT';