import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// import '../../../pages/'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import "../../admin/style/order.css"
import Button from '@mui/material/Button';

export default class orders extends Component {
  render() {
    return (
      <div>
        <Grid item xs={12}>

          <Grid className='status' style={{ width: "40%" }}>
            <center>

              <img src="https://www.skyyriderelectric.com/public/image/logo1.png" height={200} width={250} />
            </center>
            <Grid sx={{ width: "83.5%", height: "50%", }}>

              <Grid>
                <center>
                  <Typography sx={{ width: "120%", height: "40px", }} className="track">
                    <Typography sx={{ fontSize: "140%", color: "white", width: "100%" }}>Track Your Order/Service Status</Typography>
                  </Typography>

                </center>
              </Grid>






              <br></br>
              <br></br>
              <br></br>
              <Grid className='bodysts'>





                <center>
                  <Grid style={{ display: "flex" }}>
                    {/* <Grid> */}


                    <Typography className='servicetype' sx={{ fontWeight: 600, width: "200px" }}>
                      Service Type
                    </Typography>
                    <Grid >

                      <select
                        // style={{ width: "200px", marginLeft: "10px" }}
                        className='inputs'
                        style={{
                          backgroundColor: "#DCE4E5",
                          // position: "absolute",
                          boxshadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                          border: '0.830269px solid #C0ADAD',
                          width: "200px",
                          height: "30px",
                          // left: "1004px",
                          // top: "535px",
                        }}
                      // name="participant type"
                      // value={this.state.eventName}
                      // onChange={handleChangeEvent}
                      >

                        <option value="">Order</option>
                        {/* {events.all_events.map((row) => (

                                            <>
                                                <option value={row.event_id}>{row.event_name}</option>
                                            </>

                                        ))} */}
                      </select>


                    </Grid>

                  </Grid>
                </center>



                {/* </Grid> */}





                <br></br>
                <center>
                  <Grid style={{ display: "flex" }}>


                    <Typography sx={{ fontWeight: 600, width: "215px" }}>
                      Order Id
                    </Typography>




                    {/* <Grid style={{marginLeft:"0%"}}> */}

                    <input style={{
                      backgroundColor: "#DCE4E5",
                      // position: "absolute",
                      boxshadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                      border: '0.830269px solid #C0ADAD',

                      height: 30,
                      // marginLeft:"8.5%",
                      marginTop: 0,
                      width: "200px",

                      // borderRadius:8
                    }}
                    // onChange={(e) => { this.setState({ range: e.target.value }) }}
                    ></input>
                    {/* </Grid> */}




                  </Grid>


                </center>




              </Grid>




              <center>

                <Button variant="contained" className='submit' sx={{ borderRadius: 25, marginTop: "5%", marginLeft: "15%", marginBottom: "10%" }}>Submit</Button>
              </center>


            </Grid>
          </Grid>
        </Grid>


      </div>
    )
  }
}
