import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const REGISTRATION = "REGISTRATION";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const SET_EMAIL = "SET_EMAIL";
export const SET_PASSWORD = "SET_PASSWORD";
export const LOGIN = "LOGIN";
export const SUPERLOGIN = "SUPERLOGIN";
export const ADMINLOGIN = "ADMINLOGIN";
export const SET_NAME = "SET_NAME";
export const LOGOUT = "LOGOUT";
// export const NEWIMAGE_UPLOAD = "NEWIMAGE_UPLOAD";
export const HANDLEDRAWER = "HANDLEDRAWER";
// export const SET_HOME = "SET_HOME";
export const HANDLEDRAWER1 = "HANDLEDRAWER1";
export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const SUCESSFUL = "SUCESSFUL";
export const VERIFY_OTP = "VERIFY_OTP";
export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const ALL_HOTELS = "ALL_HOTELS";
export const SETALL = "SETALL";
export const TOURISMLOGIN = "TOURISMLOGIN";
export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const SETACTIVE = "SETACTIVE";
export const firebaseConfig = {
    apiKey: "AIzaSyC_xE_UhX9Kt2swPj7E9NzUkpo3Zy-3fQ8",
    authDomain: "skyyriderev.firebaseapp.com",
    projectId: "skyyriderev",
    storageBucket: "skyyriderev.appspot.com",
    messagingSenderId: "99935744531",
    appId: "1:99935744531:web:43aa069bff4ef7c683c5e6",
    measurementId: "G-GQ649EXGTC"
};
export const app = initializeApp(firebaseConfig);
// export const fb = firebase.initializeApp(firebaseConfig);