import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../component/empservice";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  createService,
  updateService,
  deleteService,
  viewAllService
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Service {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_service: store.all_service,
    login: store.login,
    snackbar: store.snackbar
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createService: (admin_id,customer_id, vechicle_id, booking_date, status, estimated_visit_date, visit_date, address, components, service_charges, amount, comment) => {
      dispatch(createService(admin_id,customer_id, vechicle_id, booking_date, status, estimated_visit_date, visit_date, address, components, service_charges, amount, comment))
    },
    updateService: (_id, customer_id, vechicle_id, booking_date, status, estimated_visit_date, visit_date, address, components, service_charges, amount, comment) => {
      dispatch(updateService(_id, customer_id, vechicle_id, booking_date, status, estimated_visit_date, visit_date, address, components, service_charges, amount, comment))
    },
    deleteService: (_id) => {
      dispatch(deleteService(_id))
    },
    viewAllService: (admin_id) => {
      dispatch(viewAllService(admin_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);