import { Celebration } from '@mui/icons-material'
import React, { Component } from 'react'
import html2canvas from "html2canvas";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import "./booking.css"
import { Link } from 'react-router-dom';
import Logo from "../../../image/logo.png"
import Steps from "../../../image/steps.png"
import Ordersts from "../../../image/ordersts.png"
import Webqr from "../../../image/webqr.png"

import Volt from "../../../image/volt.png"
import Dimension from "../../../image/tape.png"
import Motor from "../../../image/motor.png"
import Speed from "../../../image/speed.png"
import Range from "../../../image/range1.png"
import Battery from "../../../image/btr.png"
import { fontSize } from '@mui/system';
import { Button } from '@mui/material';
import Snackbar from "../../../../common/snackbar/components/snackbar";

const saveAs = (blob, fileName) => {
    var elem = window.document.createElement('a');
    elem.href = blob
    elem.download = fileName;
    elem.style = 'display:flex;';
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === 'function') {
        elem.click();
    } else {
        elem.target = '_blank';
        elem.dispatchEvent(new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
        }));
    }
    URL.revokeObjectURL(elem.href);
    elem.remove()
}

export default class quation extends Component {
    exportAsPicture = () => {

        var t0 = performance.now()

        var data = document.getElementById('test')

        // for (var i = 0; i < data.length; ++i) {
        html2canvas(data).then((canvas) => {
            var image = canvas.toDataURL('image/PNG', 1.0);
            console.log(image)
            this.props.uploadBooking(image, this.props.all_order.order_id)

            // window.open(pdf.output('bloburl', { filename: 'new-file.pdf' }), '_blank');
        })
        // }
    }
    componentDidMount() {
        // this.props.viewVarient();
        // this.props.viewAllCategory();
        // this.props.viewModel();
        // componentDidMount(){
        if (this.props.all_order.order_id !== "") {
            this.props.viewQuotationByOrderId(this.props.all_order.order_id);
        }
        // }
    }
    render() {
        const {
            all_order,
            snackbar,
            close_snack_bar,
            login,
        } = this.props;
        console.log("booking",all_order.order_id);

        return (
            <>
                <div id="test">
                    <div  >
                        <img src={Logo} width="170" height="120"
                        />
                        <div>
                            <center style={{ marginTop: "-100px" }}>

                                <h1 style={{ color: "#20719C" }} >SKYY RIDER ELECTRIC PVT. LTD.</h1>
                                <h1 style={{ color: "#0C99CD" }} >Booking Confirmation</h1>
                            </center>
                            {/* <!-- <p style="margin-top: -20px;text-align: center;">PLOT NO-137/136,CENTURION UNIVERSITY,RAMCHANDRA PUR,JATNI,KHORDHA,ODISHA-752050</p> --> */}
                        </div>
                    </div>
                    <hr></hr>
                    <div style={{ display: "flex" }} >
                        <div style={{ marginTop: "10px" }}
                        >
                            <i style={{ fontSize: 80 }} class="material-icons"
                            >local_shipping</i>
                        </div>

                        <div style={{ marginLeft: "10px" }}>
                            <h2 style={{ color: "#304EE7" }}>Booking Id -</h2>
                            <h3>{all_order.order_qu_id}</h3>


                        </div>


                    </div>
                    <hr></hr>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "revert-layer" }}>

                            <h4>Customer/Organization Name:</h4>
                            <p style={{ marginLeft: "10px", fontSize: 18, marginTop: "4px" }}>{all_order.customer_name}</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "revert-layer" }}>

                            <h4 style={{ marginLeft: '240px' }}>Contact:</h4>
                            <p style={{ marginLeft: "10px", fontSize: 18, marginTop: "4px" }}>{all_order.customer_mobile}</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "revert-layer" }}>

                            <h4 style={{ marginLeft: '240px' }}>GST No:</h4>
                            <p style={{ marginLeft: "10px", fontSize: 18, marginTop: "4px" }}>{all_order.customer_gstno}</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "revert-layer" }}>

                            <h4 style={{ marginLeft: '235px' }}>Address:</h4>
                            <p style={{ marginLeft: "10px", fontSize: 18, marginTop: "4px" }}>{all_order.customer_address}</p>
                        </div>


                        {/* </h4> */}
                    </div>
                    <div style={{ marginLeft: "30%" }}>


                        <table style={{ border: "2px solid #000", width: "50%" }}>
                            <tr style={{ border: "2px solid #000" }} >
                                <td colSpan="8" style={{ border: "2px solid #000", color: "#000", fontWeight: "500" }}>Aadhaar Card</td>
                                {all_order.customer_aadhar === "" ?
                                    <td colSpan="8" style={{ border: "2px solid #000", color: "red", fontWeight: "500" }}> <i class="material-icons" >close</i>
                                    </td>
                                    :
                                    <td colSpan="8" style={{ border: "2px solid #000", color: "green", fontWeight: "500" }}> <i class="material-icons" >done</i>
                                    </td>


                                }




                            </tr>
                            <tr style={{ border: "2px solid #000" }} >
                                <td colSpan="8" style={{ border: "2px solid #000", color: "#000", fontWeight: "500" }} >PAN Card</td>
                                {/* <!-- <td colspan="3"></td> --> */}
                                {all_order.customer_pan === "" ?
                                    <td colSpan="8" style={{ border: "2px solid #000", color: "red", fontWeight: "500" }}> <i class="material-icons" >close</i>
                                    </td>
                                    :
                                    <td colSpan="8" style={{ border: "2px solid #000", color: "green", fontWeight: "500" }}> <i class="material-icons" >done</i>
                                    </td>


                                }


                            </tr>
                            <tr style={{ border: "2px solid #000" }} >
                                <td colSpan="8" style={{ border: "2px solid #000", color: "#000", fontWeight: "500" }}>Passport Photo</td>
                                {/* <!-- <td colspan="3"></td> --> */}
                                {all_order.customer_photo === "" ?
                                    <td colSpan="8" style={{ border: "2px solid #000", color: "red", fontWeight: "500" }}> <i class="material-icons" >close</i>
                                    </td>
                                    :
                                    <td colSpan="8" style={{ border: "2px solid #000", color: "green", fontWeight: "500" }}> <i class="material-icons" >done</i>
                                    </td>


                                }



                            </tr>
                            <tr style={{ border: "2px solid #000" }}>
                                <td colSpan="8" style={{ border: "2px solid #000", color: "#000", fontWeight: "500" }}>GST Certificate(if any)</td>
                                {/* <!-- <td colspan="3"></td> --> */}
                                {all_order.customer_gstcert === "" ?
                                    <td colSpan="8" style={{ border: "2px solid #000", color: "red", fontWeight: "500" }}> <i class="material-icons" >close</i>
                                    </td>
                                    :
                                    <td colSpan="8" style={{ border: "2px solid #000", color: "green", fontWeight: "500" }}> <i class="material-icons" >done</i>
                                    </td>


                                }



                            </tr>
                        </table>
                    </div>
                    <div style={{ display: "flex", marginTop: "10px", flexDirection: "row", }}>
                        <br></br>
                        <br></br>
                        <h3 style={{ color: "#304EE7" }}>
                            <u>
                                Vehicle Name:
                            </u>
                        </h3>
                        <h5 style={{ marginLeft: "580px", marginTop: "10px" }} >
                            {all_order.variant_name}({all_order.color_name})
                        </h5>


                    </div>
                    <div>
                        <center>

                            <h3 style={{ color: "#304EE7" }}><u>Specification</u></h3>
                        </center>
                    </div>
                    <div style={{ marginLeft: "400px", marginTop: "40px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: "flex" }}>

                                <img src={Volt} height={40} width={40}></img>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>

                                    <h5>Volt </h5>
                                    <p>{all_order.variant_volt}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginLeft: '10px' }}>

                                <img src={Dimension} height={40} width={40}></img>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>

                                    <h5>Dimension </h5>
                                    <p>{all_order.variant_length}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginLeft: '10px' }}>

                                <img src={Motor} height={40} width={40}></img>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>

                                    <h5> Motor Power </h5>
                                    <p>{all_order.variant_motor}</p>
                                </div>
                            </div>


                        </div>
                        <div style={{ display: "flex" }} >
                            <div style={{ display: "flex" }}>

                                <img src={Speed} height={40} width={40}></img>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>

                                    <h5> Speed</h5>
                                    <p>1000w</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginLeft: '10px' }}>

                                <img src={Range} height={40} width={40}></img>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>

                                    <h5> Ranges</h5>
                                    <p>{all_order.variant_range}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginLeft: '25px' }}>

                                <img src={Battery} height={40} width={40}></img>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>

                                    <h5> Battery</h5>
                                    <p>{all_order.battery_voltage}</p>
                                </div>
                            </div>
                        </div>




                    </div>
                    <div style={{ marginLeft: "15%" }}>


                        <table style={{ border: "2px solid #000", width: "80%" }}>
                            <tr style={{ border: "2px solid #000" }} >
                                <td colSpan="8" style={{ border: "2px solid #000" }} >Date Of Oder</td>
                                <td colSpan="8" style={{ border: "2px solid #000" }}>{all_order.booking_date}
                                    {/* <!-- <i class="material-icons" style="font-size:30px;color: darkgreen;">done</i> --> */}
                                </td>



                            </tr>
                            <tr style={{ border: "2px solid #000" }}>
                                <td colSpan="8" style={{ border: "2px solid #000" }}>Est. Date Of Oder Delivery</td>
                                {/* <!-- <td colspan="3"></td> --> */}
                                <td colSpan="8" style={{ border: "2px solid #000" }}> {all_order.delivery_date}
                                    {/* <!-- <i class="material-icons" style="font-size:30px;color: darkgreen;">done</i> --> */}
                                </td>


                            </tr>
                            <tr style={{ border: "2px solid #000" }}>
                                <td colSpan="8" style={{ border: "2px solid #000" }}>Oder Value</td>
                                {/* <!-- <td colspan="3"></td> --> */}
                                <td colSpan="8" style={{ border: "2px solid #000" }}><b>

                                    {all_order.total_price}
                                </b>
                                    {/* <!-- <i class="material-icons" style="font-size:30px;color: darkgreen;">done</i> --> */}
                                </td>



                            </tr>
                            <tr style={{ border: "2px solid #000" }}>
                                <td colSpan="8" style={{ border: "2px solid #000" }}>Advance Received</td>
                                {/* <!-- <td colspan="3"></td> --> */}
                                <td colSpan="8" style={{ border: "2px solid #000" }}> {all_order.payment}
                                    {/* <!-- <i class="material-icons" style="font-size:30px;color: red;">close</i> --> */}
                                </td>



                            </tr>
                        </table>
                    </div>
                    <hr></hr>
                    <center>
                        <h3 style={{ color: "#304EE7" }}  >3 Easy Step To  Know Your Oder Status</h3>
                    </center>
                    <center>
                        <img src={Steps} />
                    </center>
                    <hr></hr>




                    <div style={{ display: "flex", justifyContent: "space-evenly" }} >
                        <div style={{ display: "flex" }}>


                            <div>
                                <img src={Ordersts} alt="" />
                            </div>

                            <div style={{ marginLeft: '10px' }}>

                                <h3 >Check order Status</h3>
                                <h5>
                                    <a href="https://order.skyyriderelectric.com/checkstatus">https://order.skyyriderelectric.com/checkstatus</a>
                                </h5>
                            </div>


                        </div>
                        <div style={{ display: "flex", }}>


                            <div>
                                <img src={Webqr} alt="" />
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                                <h3 >Skyy Rider Electric</h3>
                                <h5 >Best E-Rickshaw Manufacturer
                                    Of India</h5>
                                <h5 >E-mail- e-vehicles@skyyrider.com</h5>
                                <h5 >
                                    <a href="https://skyyriderelectric.com">https://skyyriderelectric.com</a>
                                </h5>
                            </div>


                        </div>



                    </div>
                </div>
                <center>


                    <Link to="/" style={{ textDecoration: "none", marginLeft: "0%" }}>

                        <Button variant='contained' style={{ borderRadius: 10, marginBottom: 20 }} onClick={() => this.exportAsPicture()}>Save & Continue</Button>
                    </Link>
                </center>
                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />

            </>



        )
    }
}











