import React, { Component } from "react";
import {
  Grid,
  // Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Icon,
  TextField,
  DialogActions,
  Avatar
} from "@mui/material";
// import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Navigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';
// import CloseIcon from "@mui/icons-material/Close";
import SendTimeExtensionOutlinedIcon from '@mui/icons-material/SendTimeExtensionOutlined';
import CarRentalOutlinedIcon from '@mui/icons-material/CarRentalOutlined';
import GarageOutlinedIcon from '@mui/icons-material/GarageOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
// import {
//     // Link,
//     Navigate,
// } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import Snackbar from "../../../../common/snackbar/components/snackbar";
import CloseIcon from '@mui/icons-material/Close';

import TablePagination from "@mui/material/TablePagination";

import "../../style/order.css";

export default class color extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      noplate: false,
      delivery: false,
      view: false,
      rto: false,
      imageUrl: null,
      imageAlt: null,
      manufactured: false,
      payment_slip: "",
      no_plate: "",
      delete: false,
      page: 0,
      setPage: 0,
      rowsPerPage: 20,
      setRowsPerPage: "",


    }
  }
  componentDidMount() {
    this.props.viewAllvehicle(this.props.login.user_id,this.state.page);
  }


  render() {
    const {
      all_vehicles,
      all_category,
      snackbar,
      login,
      close_snack_bar
    } = this.props;
    const handleChangePage = (event, newPage) => {
      // console.log(newPage)
      // this.props.viewAlltheClients(this.props.login.user_id,newPage);
      this.props.viewAllvehicle(this.props.login.user_id,newPage);

      // this.props.view_all_participants(this.props.login.user_token, newPage * this.state.rowsPerPage, this.state.rowsPerPage, this.state.status, this.state.event_id, this.state.department_id,this.state.sort);
      // this.props.view_all_participants()
      this.setState({
        page: newPage,
      });
    };
    // const { imageUrl, imageAlt } = this.state;
    if (this.state.redirect === true) {
      return <Navigate to="/" />;
    }
    return (
      <Grid>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Box className="ordername">
            <center>
              <Button style={{ color: "white ", textAlign: "center" }}>
                Sold Vehicle
              </Button>
            </center>
          </Box>

          {/* <center style={{ borderRadius: 0 }}>
            <Button
              className="orderadd"
              style={{ color: "white ", textAlign: "center" }}
              onClick={() => {
                this.setState({
                  add: true,
                });
              }}
            >
              Add Vechicle
            </Button>
          </center> */}
        </Grid>

        <Grid
                // container
                // direction="row"
                alignItems="end"
                // justifyContent="space-between"
                mr={10}
                style={{}}
              >
        <TablePagination
                  rowsPerPageOptions={[20,]}
                  component="div"
                  count={all_vehicles.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={handleChangePage}
                  // onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Grid>

        <CardContent style={{ marginLeft: "40px", marginRight: "65px" }}>
          <TableContainer component={Paper}>
            <Table
              style={{ minWidth: 600 }}
              size="small"
              aria-label="a dense table"
              ref={this.tableRef}
            >
              <TableHead className="orderhead">
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Model</TableCell>
                  {/* <TableCell>Estimated delivery date</TableCell> */}
                  <TableCell>Variant</TableCell>
                  {/* <TableCell>Quantity</TableCell> */}
                  <TableCell style={{ marginLeft: "0px", textAlign: "center" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="orderbody">
                {all_vehicles.all_vehicles.map((c) => {
                  return (
                    <TableRow
                      // key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell >{c.customer}</TableCell>
                      <TableCell >{c.category}</TableCell>
                      <TableCell >{c.color}</TableCell>
                      <TableCell >{c.model}</TableCell>
                      <TableCell >{c.variant}</TableCell>
                      {/* <TableCell >{c.quanty}</TableCell> */}
                      <TableCell style={{ alignItems: "center" }}>
                        {c.status === "manufactured" &&
                          <IconButton
                            // style={{marginLeft:"60px"}}
                            onClick={() => {
                              this.setState({
                                manufactured: true,
                                id: c._id,
                                // customer: c.customer,
                                // category: c.category,
                                // color: c.color,
                                // model: c.model,
                                // variant: c.variant,
                                // quantity: c.quanty,
                                // is_registred: c.will_registration,
                                // length: c.length,
                                // width: c.width,
                                // height: c.height,
                                // estimated_delivery_date: c.estimated_delivery_date,
                                // orderstatus: c.order_status,
                                // status: c.status,
                                // comment: c.comment
                              });
                            }}
                          >
                            {/* <Icon style={{ color: "black" }}>view</Icon> */}
                            <CheckCircleOutlineOutlinedIcon style={{ color: "violet" }} />

                          </IconButton>}
                        {c.status === "registered" &&
                          <IconButton
                            // style={{marginLeft:"60px"}}
                            onClick={() => {
                              this.setState({
                                rto: true,
                                _id: c._id,
                                challan_number: c.challan_number,

                                payment_slip: c.payment_slip,
                                // model: c.model,
                                // variant: c.variant,
                                // quantity: c.quanty,
                                // is_registred: c.will_registration,
                                // length: c.length,
                                // width: c.width,
                                // height: c.height,
                                // estimated_delivery_date: c.estimated_delivery_date,
                                // orderstatus: c.order_status,
                                // status: c.status,
                                // comment: c.comment
                              });
                            }}
                          >
                            {/* <Icon style={{ color: "black" }}>view</Icon> */}
                            <CarRentalOutlinedIcon style={{ color: "teal" }} />

                          </IconButton>}
                        {c.status === "completed" &&

                          <IconButton
                            // style={{marginLeft:"60px"}}
                            onClick={() => {
                              this.setState({
                                noplate: true,
                                _id: c._id,
                                no_plate: c.no_plate,

                              });
                            }}
                          >
                            {/* <Icon style={{ color: "black" }}>view</Icon> */}
                            <GarageOutlinedIcon style={{ color: "sienna" }} />

                          </IconButton>}
                        {c.status === "delivered" &&

                          <IconButton
                            // style={{marginLeft:"60px"}}
                            onClick={() => {
                              this.setState({
                                delivery: true,
                                id: c._id,
                              });
                            }}
                          >
                            {/* <Icon style={{ color: "black" }}>view</Icon> */}
                            <SendTimeExtensionOutlinedIcon style={{ color: "coral" }} />

                          </IconButton>}
                        <IconButton
                          // style={{marginLeft:"60px"}}
                          onClick={() => {
                            this.setState({
                              view: true,
                              // customer: c.customer,
                              customer: c.customer,
                              category: c.category,
                              color: c.color,
                              model: c.model,
                              variant: c.variant,
                              quantity: c.quanty,
                              is_registred: c.will_registration,
                              length: c.length,
                              width: c.width,
                              height: c.height,
                              estimated_delivery_date: c.estimated_delivery_date,
                              status: c.status,
                              status: c.status,
                              comment: c.comment,
                              total_price: c.total_price

                            });
                          }}
                        >
                          {/* <Icon style={{ color: "black" }}>view</Icon> */}
                          <RemoveRedEyeOutlinedIcon style={{ color: 'blue' }} />

                        </IconButton>


                        {/* <IconButton
                          onClick={() => {
                            this.setState({
                              edit: true,
                              id: c._id,
                              customer: c.customer,
                              category: c.category,
                              color: c.color,
                              model: c.model,
                              variant: c.variant,
                              quantity: c.quanty,
                              is_registred: c.will_registration,
                              length: c.length,
                              width: c.width,
                              height: c.height,
                              estimated_delivery_date: c.estimated_delivery_date,
                              orderstatus: c.order_status,
                              status: c.status,
                              comment: c.comment
                            });
                          }}
                        >
                          {/* <Icon style={{ color: "green" }}>edit</Icon> 
                          <EditOutlinedIcon style={{ color: 'green' }} />
                        </IconButton> */}
                        {/* <IconButton
                          onClick={() => {
                            this.setState({
                              delete: true,
                              id: c._id,
                            });
                          }}
                        >
                          <Icon style={{ color: "red" }}>delete</Icon>
                          <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />

                        </IconButton> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>




        <Dialog
          open={this.state.rto}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "250px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  // type={"date"}
                  required
                  id="outlined-required"
                  label="Challan No"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ challan_number: e.target.value });
                  }}
                />
              </div>
              {/* <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    // type={"date"}
                                    required
                                    id="outlined-required"
                                    label="Payment Slip"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    //   value={this.state.category_name}
                                    onChange={(e) => {
                                        this.setState({ payment_slip: e.target.value });
                                    }}
                                />
                            </div> */}
              <Grid style={{ display: "flex", flexDirection: "column", width: "300px" }} >
                <Typography style={{ color: "black ", fontSize: 16, marginTop: "8px", fontWeight: "400", marginLeft: "30px" }}> Payment Slip :    </Typography>


                <Stack
                  spacing={1}
                  style={{
                    textDecoration: "none",
                    // display: "inline-block",
                    padding: "0 30px",
                    marginTop: "10px",
                  }}
                  className="imagemedia"
                >
                  {/* <Avatar
                                        alt="Profile"
                                        src={this.state.cust_aadhar}
                                        style={{ height: "114px", width: "114px" }}
                                        variant={"rounded"}
                                    /> */}
                  {/* {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt={imageAlt}
                                            className="displayed-image"
                                        />
                                    )} */}
                  <Input
                    type="file"
                    onChange={(e) => this.setState({ payment_slip: e.target.files[0] })}
                  />
                </Stack>
              </Grid>
              {/* <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Mode "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // defaultValue={this.state.model_id}
                                    onChange={(e) => { this.setState({ mode: e.target.value }) }}
                                >
                                   
                                    <MenuItem value={"cash"} >
                                       
                                    </MenuItem>
                                    <MenuItem value={"cheque"}>
                                       
                                        Cheque
                                    </MenuItem>
                                    <MenuItem value={"onlinepay"}>
                                       
                                    </MenuItem>
                                    
                                </TextField>

                            </div> */}

              <Button
                className="btns"
                onClick={() => {
                  this.props.upload_payment(
                    // this.state.id,
                    this.state._id,
                    this.state.challan_number,
                    this.state.payment_slip,
                    this.props.login.user_id
                    // this.state.mode
                    // this.state.category_name
                  );
                  this.setState({ rto: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Add
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ rto: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        <Dialog
          open={this.state.noplate}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "190px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  // type={"date"}
                  required
                  id="outlined-required"
                  label="Vechicle No"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ no_plate: e.target.value });
                  }}
                />
              </div>
              {/* <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    // type={"date"}
                                    required
                                    id="outlined-required"
                                    label="Payment Slip"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    //   value={this.state.category_name}
                                    onChange={(e) => {
                                        this.setState({ payment_slip: e.target.value });
                                    }}
                                />
                            </div> */}

              {/* <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Mode "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // defaultValue={this.state.model_id}
                                    onChange={(e) => { this.setState({ mode: e.target.value }) }}
                                >
                                   
                                    <MenuItem value={"cash"} >
                                       
                                    </MenuItem>
                                    <MenuItem value={"cheque"}>
                                       
                                        Cheque
                                    </MenuItem>
                                    <MenuItem value={"onlinepay"}>
                                       
                                    </MenuItem>
                                    
                                </TextField>

                            </div> */}

              <Button
                className="btns"
                onClick={() => {
                  this.props.Noplatevehicle(
                    // this.state.id,
                    this.state._id,
                    this.state.no_plate,
                    // this.state.payment_slip,
                    // this.state.mode
                    // this.state.category_name
                  );
                  this.setState({ noplate: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Add
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ noplate: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>





        <Dialog
          open={this.state.manufactured}
          onClose={() => {
            this.setState({ manufactured: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <NoCrashOutlinedIcon style={{ fontSize: "60", color: "green", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ manufactured: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained" onClick={() => { this.props.Confirmvehicle(this.state.id,this.props.login.user_id); this.setState({ manufactured: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.delivery}
          onClose={() => {
            this.setState({ delivery: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <LibraryAddCheckOutlinedIcon style={{ fontSize: "60", color: "blue", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ delivery: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained" onClick={() => { this.props.outdeliveryvehicle(this.state.id); this.setState({ delivery: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={this.state.view}
          // onClose={() => { this.setState({ view: false }) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

          style={
            {
              // top: "50%",
              // left: "10%",
              // right: "auto",
              // bottom: "auto",
              // marginRight: "-50%",
              // transform: "translate(-50%, -50%)",
              width: "100%",
              marginLeft: "0%",
              // paddingTop: "40px",
              // paddingLeft: "25px",
              // paddingRight: "25px",
              // borderRadius: "0px",
              height: "700px",
              // zIndex: "200",
              // backgroundColor: "#fff"
            }}
        >
          {/* <DialogTitle id="alert-dialog-title">
            Vechicle Details


          </DialogTitle> */}


          <Grid className="view">


            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
              <Box className='details'>
                <center>

                  <Button style={{ color: "white ", textAlign: 'center', marginLeft: "0px", fontSize: 16 }}>  Vehicle Details</Button>
                </center>
              </Box>






              <IconButton
                // color=""
                style={{ marginLeft: "0%", marginTop: "-0%" }}
                onClick={() => {
                  this.setState({
                    view: false,
                    // _id: c._id,
                    // color_name: c.color_name,
                    // color_code: c.color_code,
                  })
                }}
              // size={18}
              >
                <CloseIcon style={{ color: 'black', fontSize: 48 }}
                />
              </IconButton>

              {/* </Grid> */}


            </Grid>
            <Grid item style={{ display: "flex", }} >
              <Grid item style={{ display: "flex", width: 250, marginTop: "30px", marginLeft: "50px" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Customer:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, width: 100 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.customer}</Typography>
              </Grid>
              <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>category:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;{this.state.category}</Typography>
              </Grid>
              {/* <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Color:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;{this.state.color_id}</Typography>
                            </Grid> */}
            </Grid>
            <Grid item style={{ display: "flex", }} >
              <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50px" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>color:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.color}</Typography>
              </Grid>
              <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>model :</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.model}</Typography>
              </Grid>
            </Grid>
            <Grid item style={{ display: "flex", }} >
              <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>variant:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.variant}</Typography>
              </Grid>
              {/* <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Is registered:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.will_registration}</Typography>
              </Grid> */}
              <Grid item style={{ display: "flex", width: 250, marginTop: "30px", marginLeft: "50PX" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>order status:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;{this.state.status}</Typography>
              </Grid>
            </Grid>
            <Grid item style={{ display: "flex", }} >
              <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>length:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.length}</Typography>
              </Grid>
              <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>width:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.width}</Typography>
              </Grid>
            </Grid>
            <Grid item style={{ display: "flex", }} >
              {/* <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>height:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.length}</Typography>
              </Grid> */}
              {/* <Grid item style={{ display: "flex", width: 250, marginTop: "30px", marginLeft: "80px" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>estimated_delivery_date:</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>{this.state.estimated_delivery_date}</Typography>
              </Grid> */}
            </Grid>
            {/* <Grid item style={{ display: "flex", marginBottom: "0px" }} > */}
              
              <Grid item style={{ display: "flex", marginBottom: "0px" }} >
                <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                                    <Typography style={{ fontWeight: 600, fontSize: 15, }}>status:</Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.status}</Typography>
                                </Grid>
                <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                  <Typography style={{ fontWeight: 600, fontSize: 15, }}>comment:</Typography>
                  <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;{this.state.comment}</Typography>
                </Grid>
              {/* </Grid> */}



            </Grid>
            <Grid item style={{ display: "flex", marginBottom: "0px" }} >
              {/* <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "20PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>On Road price:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;;{this.state.on_road_price}</Typography>
                            </Grid> */}
              {/* <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "50px" }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Total price :</Typography>
                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.total_price}</Typography>
              </Grid> */}
            </Grid>
           
          </Grid>

        </Dialog>



        {/* <Grid lg={8} style={{ display: "flex", flexDirection: "row", width: "500px", height: "800" }}>
            <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "25px" }}> */}
        {/* <img
                src={this.state.profile_pic}
                style={{ width: 140, height: 150 }} /> */}
        {/* 
            </Grid>


            <Grid style={{ marginLeft: "25px" }}>
              <Grid item style={{ display: "flex", width: 400 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>customer:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.customer}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 200 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>category:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.category}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 100 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>color:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.color}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 100 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>model:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.model}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 100 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>variant:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.variant}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 300 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Is registered:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.is_registred}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 100 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>length:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.length}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 100 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>width:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.width}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 100 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>height:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.height}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 100 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>estimated_delivery_date:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.estimated_delivery_date}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 320 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>order status:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.orderstatus}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 100 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>status:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.status}</Typography>
              </Grid>

              <Grid item style={{ display: "flex", width: 400 }} >
                <Typography style={{ fontWeight: 600, fontSize: 15, }}>comment:</Typography>
                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.comment}</Typography>
              </Grid>

            </Grid>

          </Grid>
          <Grid sx={{ marginLeft: "25px", marginTop: "0%" }}>
            <Button onClick={() => { this.props.deleteUser(this.state.Emp_Id); this.setState({ delete: false }) }}>Yes</Button>
            <Button onClick={() => {
              this.setState({ view: false });
            }}>Close</Button>
          </Grid>

        </Dialog> */}
        {/* 
        <Dialog
          open={this.state.delete}
          onClose={() => {
            this.setState({ delete: false });
          }}
          // style={{wi}}
          style={
            {
              // top: "50%",
              // left: "50%",       }}  >
          <Box style={{ backgroundColor: "#fff", height: "100px", width: "350px" }}>
            <Typography
              style={{ fontSize: "16px", textAlign: 'center' }}   >
              Are You Sure  </Typography>
            <Grid sx={{ marginLeft: "32%", marginTop: "8%" }}>
              <Button
                // onClick={() => { this.props.deleteUser(this.state.Emp_Id); this.setState({ delete: false }) }}
                onClick={() => { this.props.deleteColor(this.state._id); this.setState({ delete: false }) }}
              >Yes</Button>
              <Button onClick={() => {
                this.setState({ delete: false });
              }}>No</Button>
            </Grid> </Box></Dialog> */}

        <Dialog
          open={this.state.delete}
          onClose={() => {
            this.setState({ delete: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ delete: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained"
              onClick={() => { this.props.deleteVehicle(this.state._id); this.setState({ delete: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.edit}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          // style={{wi}}
          style={
            {
              // top: "50%",
              // left: "10%",
              // right: "auto",
              // bottom: "auto",
              // marginRight: "-50%",
              // transform: "translate(-50%, -50%)",
              // width: "1000px",
              // marginLeft: "500px",
              // paddingTop: "40px",
              // paddingLeft: "25px",
              // paddingRight: "25px",
              // borderRadius: "0px",
              height: "700px",
              // zIndex: "200",
              // backgroundColor: "#fff"
            }}
        >
          <Box borderRadius={5} sx={{
            width: 500,
            height: 500,
            backgroundColor: 'white',

          }}>
            <Typography textAlign="center" marginTop={2}>

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                fullWidth={true}
                id="outlined-required"
                label="Registration Name"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "50px", width: "280px" }}
                value={this.state.customer}
                onChange={(e) => { this.setState({ customer: e.target.value }) }}
              />
            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Customer ID"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "50px", width: "280px" }}
                fullWidth={true}
                value={this.state.category}
                onChange={(e) => { this.setState({ category: e.target.value }) }}
              />
            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Color"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "50px", width: "280px" }}
                fullWidth={true}
                value={this.state.color}
                onChange={(e) => { this.setState({ color: e.target.value }) }}
              />
            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Model"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "50px", width: "280px" }}
                fullWidth={true}
                value={this.state.model}
                onChange={(e) => { this.setState({ model: e.target.value }) }}
              />
            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Varient"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.variant}
                onChange={(e) => { this.setState({ variant: e.target.value }) }}
              />

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Quantity"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.quantity}
                onChange={(e) => { this.setState({ quantity: e.target.value }) }}
              />

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Is registred"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.is_registred}
                onChange={(e) => { this.setState({ is_registred: e.target.value }) }}
              />

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="length"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.length}
                onChange={(e) => { this.setState({ length: e.target.value }) }}
              />

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="width"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.width}
                onChange={(e) => { this.setState({ category_name: e.target.value }) }}
              />

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Delivery date"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.estimated_delivery_date}
                onChange={(e) => { this.setState({ estimated_delivery_date: e.target.value }) }}
              />

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Order status"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.orderstatus}
                onChange={(e) => { this.setState({ orderstatus: e.target.value }) }}
              />

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="Status"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.status}
                onChange={(e) => { this.setState({ status: e.target.value }) }}
              />

            </Typography>
            <Typography marginLeft={1} marginTop={2}>
              <TextField
                required
                id="outlined-required"
                label="comment"
                InputLabelProps={{ shrink: true, required: true }}
                // style={{ height: "60px", width: "280px" }}
                fullWidth={true}
                value={this.state.comment}
                onChange={(e) => { this.setState({ comment: e.target.value }) }}
              />

            </Typography>

            <Button onClick={() => {
              this.props.updatecategory(this.state._id, this.state.category_name);
              this.setState({ edit: false });
            }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Add</Button>
            <Button onClick={() => {
              this.setState({ edit: false });
            }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Close</Button>
          </Box>
        </Dialog>
        <LoaderCon />
        <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />
      </Grid>

      // <div>
      //   <Box
      //     marginLeft={'5%'}
      //     // marginTop={"%"}
      //     marginRight={"5%"}
      //   >
      //     <CardContent>
      //       <TableContainer
      //         component={Paper}
      //       >
      //         <Table
      //           style={{ minWidth: '10%' }}
      //           size="small"
      //           aria-label="a dense table"
      //           ref={this.tableRef}
      //         >
      //           <TableHead>
      //             <TableRow>
      //               <TableCell>Customer</TableCell>
      //               <TableCell>Category</TableCell>
      //               <TableCell>Color</TableCell>
      //               <TableCell>Model</TableCell>
      //               {/* <TableCell>Estimated delivery date</TableCell> */}
      //               <TableCell>Varient</TableCell>
      //               <TableCell>Quantity</TableCell>
      //               {/* <TableCell>Height</TableCell> */}
      //               <TableCell>action</TableCell>
      //             </TableRow>

      //           </TableHead>
      //           {all_vehicles.all_vehicles.map((c) => {
      //             return (
      //               <TableBody>
      //                 <TableRow
      //                   sx={{
      //                     "&:last-child td, &:last-child th": { border: 0 },
      //                   }}
      //                 >
      //                   <TableCell >{c.customer}</TableCell>
      //                   <TableCell >{c.category}</TableCell>
      //                   <TableCell >{c.color}</TableCell>
      //                   <TableCell >{c.model}</TableCell>
      //                   <TableCell >{c.variant}</TableCell>
      //                   <TableCell >{c.quanty}</TableCell>


      //                   {/* <TableCell >{allusers.type}</TableCell> */}


      //                   <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", }}>
      //                     <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
      //                       <IconButton color="primary"
      //                         onClick={() => {
      //                           this.setState({
      //                             edit: true,
      //                             // _id: c._id,
      //                             // color_name: c.color_name,
      //                             // color_code: c.color_code,
      //                           })
      //                         }}
      //                       >
      //                         <EditIcon style={{ color: 'green' }}
      //                         />
      //                       </IconButton>

      //                     </Grid>
      //                     {/* <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 20 }}>
      //                       <IconButton onClick={() => { this.setState({ delete: true }) }}>

      //                         <DeleteRoundedIcon style={{ color: 'red' }}
      //                           onClick={() => {
      //                             this.setState({
      //                               delete: true,
      //                               // _id: c._id,
      //                             })
      //                           }}
      //                         />
      //                       </IconButton>
      //                     </Grid> */}
      //                     <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "", marginLeft: 20 }}>
      //                       <IconButton onClick={() => {
      //                         this.setState({
      //                           view: true,
      //                           customer: c.customer,
      //                           category: c.category,
      //                           color: c.color,
      //                           model: c.model,
      //                           variant: c.variant,
      //                           quantity: c.quanty,
      //                           is_registred:c.will_registration,
      //                           length:c.length,
      //                           width:c.width,
      //                           height:c.height,
      //                           estimated_delivery_date:c.estimated_delivery_date,
      //                           orderstatus:c.order_status,
      //                           status:c.status,
      //                           comment:c.comment


      //                         })
      //                       }}
      //                       >

      //                         <VisibilityIcon
      //                           style={{ color: "Blue", pointerEvents: "visiblePainted" }}
      //                           onClick={() => {
      //                             this.setState({
      //                               // edit: true,
      //                               id:c._id,
      //                               customer: c.customer,
      //                               category: c.category,
      //                               color: c.color,
      //                               model: c.model,
      //                               variant: c.variant,
      //                               quantity: c.quanty,
      //                               is_registred:c.will_registration,
      //                               length:c.length,
      //                               width:c.width,
      //                               height:c.height,
      //                               estimated_delivery_date:c.estimated_delivery_date,
      //                               orderstatus:c.order_status,
      //                               status:c.status,
      //                               comment:c.comment
      //                               // profile_pic: allusers.profile_pic,
      //                               // gender: allusers.gender,
      //                               // dob: allusers.dob,
      //                               // p_address: allusers.p_address,
      //                               // pr_address: allusers.pr_address
      //                             })
      //                           }}
      //                         />
      //                       </IconButton>
      //                     </Grid>

      //                   </Grid>

      //                 </TableRow>

      //               </TableBody>
      //             );
      //           })}
      //         </Table>

      //       </TableContainer>
      //     </CardContent>



      //     <Dialog
      //       open={this.state.edit}
      //       // onClose={() => {
      //       //     this.setState({ add: false });
      //       // }}
      //       // style={{wi}}
      //       style={
      //         {
      //           // top: "50%",
      //           // left: "10%",
      //           // right: "auto",
      //           // bottom: "auto",
      //           // marginRight: "-50%",
      //           // transform: "translate(-50%, -50%)",
      //           // width: "1000px",
      //           // marginLeft: "500px",
      //           // paddingTop: "40px",
      //           // paddingLeft: "25px",
      //           // paddingRight: "25px",
      //           // borderRadius: "0px",
      //           height: "700px",
      //           // zIndex: "200",
      //           // backgroundColor: "#fff"
      //         }}
      //     >
      //       <Box borderRadius={5} sx={{
      //         width: 500,
      //         height: 500,
      //         backgroundColor: 'white',

      //       }}>
      //         <Typography textAlign="center" marginTop={2}>

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             fullWidth={true}
      //             id="outlined-required"
      //             label="Registration Name"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "50px", width: "280px" }}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />
      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Customer ID"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "50px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />
      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Color"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "50px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />
      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Model"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "50px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />
      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Varient"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Range"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Battery Type"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Chassis Number"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Motor Number"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Delivery date"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Warranty"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Capacity"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Registration Challan No."
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Registration Slip"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Length"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Width"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="Height"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={1} marginTop={2}>
      //           <TextField
      //             required
      //             id="outlined-required"
      //             label="System"
      //             InputLabelProps={{ shrink: true, required: true }}
      //             // style={{ height: "60px", width: "280px" }}
      //             fullWidth={true}
      //             value={this.state.category_name}
      //             onChange={(e) => { this.setState({ category_name: e.target.value }) }}
      //           />

      //         </Typography>
      //         <Typography marginLeft={0} marginTop={2}>
      //           <Stack
      //             spacing={1}
      //             style={{
      //               textDecoration: "none",
      //               // display: "inline-block",
      //               padding: "0 20px",
      //               marginTop: "80px"
      //             }}
      //             className="imagemedia"
      //           >
      //             <img
      //               alt="Delivery Pic"
      //               src={this.state.profile_pic}
      //               style={{ height: "114px", width: "114px" }}
      //               variant={"square"}
      //             />
      //             {/* {imageUrl && (
      //                               <img
      //                                   src={imageUrl}
      //                                   alt={imageAlt}
      //                                   className="displayed-image"
      //                               />
      //                           )} */}
      //             <Input type="file" onChange={(e) => this.setState({ profile_pic: e.target.files[0] })} />
      //           </Stack>


      //         </Typography>


      //         <Button onClick={() => {
      //           this.props.updatecategory(this.state._id, this.state.category_name);
      //           this.setState({ edit: false });
      //         }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Add</Button>
      //         <Button onClick={() => {
      //           this.setState({ edit: false });
      //         }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginTop: "10px", marginBottom: "15px" }}>Close</Button>
      //       </Box>
      //     </Dialog>



      //     {/* <Dialog
      //       open={this.state.delete}
      //       onClose={() => {
      //         this.setState({ delete: false });
      //       }}
      //       // style={{wi}}
      //       style={
      //         {
      //           // top: "50%",
      //           // left: "50%",
      //           // right: "auto",
      //           // bottom: "auto",
      //           // marginRight: "-50%",
      //           // transform: "translate(-50%, -50%)",
      //           // width: "470px",
      //           // marginLeft: "500px",
      //           // paddingTop: "40px",
      //           // paddingLeft: "25px",
      //           // paddingRight: "25px",
      //           // borderRadius: "0px",
      //           // height: "300px",
      //           // zIndex: "200",
      //           // backgroundColor: "#fff"
      //         }}

      //     >
      //       <Box style={{ backgroundColor: "#fff", height: "100px", width: "350px" }}>
      //         <Typography

      //           style={{ fontSize: "16px", textAlign: 'center' }}
      //         >
      //           Are You Sure ?
      //         </Typography>
      //         <Grid sx={{ marginLeft: "32%", marginTop: "8%" }}>
      //           <Button
      //             // onClick={() => { this.props.deleteUser(this.state.Emp_Id); this.setState({ delete: false }) }}
      //             onClick={() => { this.props.deleteColor(this.state._id); this.setState({ delete: false }) }}
      //           >Yes</Button>
      //           <Button onClick={() => {
      //             this.setState({ delete: false });
      //           }}>No</Button>
      //         </Grid>


      //       </Box>




      //     </Dialog> */}


      //   </Box>
      //   <Dialog
      //     open={this.state.view}
      //     // onClose={() => { this.setState({ view: false }) }}
      //     aria-labelledby="alert-dialog-title"
      //     aria-describedby="alert-dialog-description"

      //     style={
      //       {
      //         // top: "50%",
      //         // left: "10%",
      //         // right: "auto",
      //         // bottom: "auto",
      //         // marginRight: "-50%",
      //         // transform: "translate(-50%, -50%)",
      //         // width: "1000px",
      //         // marginLeft: "500px",
      //         // paddingTop: "40px",
      //         // paddingLeft: "25px",
      //         // paddingRight: "25px",
      //         // borderRadius: "0px",
      //         height: "700px",
      //         // zIndex: "200",
      //         // backgroundColor: "#fff"
      //       }}
      //   >
      //     <DialogTitle id="alert-dialog-title">
      //       Vechicle Details


      //     </DialogTitle>



      //     <Grid lg={8} style={{ display: "flex", flexDirection: "row", width: "500px", height: "800" }}>
      //       <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "25px" }}>
      //         {/* <img
      //           src={this.state.profile_pic}
      //           style={{ width: 140, height: 150 }} /> */}

      //       </Grid>


      //       <Grid style={{ marginLeft: "25px" }}>
      //         <Grid item style={{ display: "flex", width: 400}} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>customer:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.customer}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 200 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>category:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.category}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 100 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>color:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.color}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 100 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>model:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.model}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 100 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>variant:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.variant}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 300 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>Is registered:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.is_registred}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 100 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>length:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.length}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 100 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>width:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.width}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 100 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>height:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.height}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 100 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>estimated_delivery_date:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.estimated_delivery_date}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 320}} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>order status:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.orderstatus}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 100 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>status:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.status}</Typography>
      //         </Grid>

      //         <Grid item style={{ display: "flex", width: 400 }} >
      //           <Typography style={{ fontWeight: 600, fontSize: 15, }}>comment:</Typography>
      //           <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.comment}</Typography>
      //         </Grid>




      //       </Grid>




      //     </Grid>
      //     <Grid sx={{ marginLeft: "25px", marginTop: "0%" }}>
      //       {/* <Button onClick={() => { this.props.deleteUser(this.state.Emp_Id); this.setState({ delete: false }) }}>Yes</Button> */}
      //       <Button onClick={() => {
      //         this.setState({ view: false });
      //       }}>Close</Button>
      //     </Grid>

      //   </Dialog>
      //   <LoaderCon />

      // </div>
    )
  }
}
