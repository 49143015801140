import React, { Component } from "react";
import { connect } from "react-redux";
import Variant from "../component/variant";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  createVarient,
  updateVarient,
  deleteVariant,
  viewVarient,
  viewVarientByid,
  filter_category,
  set_filtered_value,
  searchModel,
  filter_model
} from "../action";
import {
  viewAllCategory
}from "../../category/action";
import {
  viewModel
}from "../../model/action";
export class Controller extends Component {
  render() {
    return (
      <Variant {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_varient: store.all_varient,
    all_category:store.all_category,
    all_model:store.all_model,
    snackbar: store.snackbar,
    login: store.login,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createVarient: (category_id, model_id, braking, speed, range, motor, warranty, capacity, charging_time, battery_type, battery_voltage, system, overall_length, width, rear_axle) => {
      dispatch(createVarient(category_id, model_id, braking, speed, range, motor, warranty, capacity, charging_time, battery_type, battery_voltage, system, overall_length, width, rear_axle))
    },
    updateVarient: (id, category_id, model_id, braking, speed, range, motor, warranty, capacity, charging_time, battery_type, battery_voltage, system, overall_length, width, rear_axle) => {
      dispatch(updateVarient(id, category_id, model_id, braking, speed, range, motor, warranty, capacity, charging_time, battery_type, battery_voltage, system, overall_length, width, rear_axle))
    },
    deleteVariant: (id) => {
      dispatch(deleteVariant(id))
    },
    viewVarient: () => {
      dispatch(viewVarient())
    },
    viewVarientByid: (variant_id) => {
      dispatch(viewVarientByid(variant_id))
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    viewModel: () => {
      dispatch(viewModel())
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    set_filtered_value: (all_varient,e) => {
      dispatch(set_filtered_value(all_varient,e))
    },
    searchModel: (all_varient,e) => {
      dispatch(searchModel(all_varient,e))
    },
    filter_category: (variant,e) => {
      dispatch(filter_category(variant,e))
    },
    filter_model: (variant,e) => {
      dispatch(filter_model(variant,e))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);