import React, { Component } from "react";
import { connect } from "react-redux";
import Editteamlead from "../component/editteamlead";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    update_profile_pic,
    view_all_TL,
    setName,
    setDEPARTMENTNAME,
    setMobile,
    setEmail,
    setPassword,
    setGender,
    setDob,
    setPer_address,
    setPre_address,
    setProfile_pic,
    viewAlldepartment,
    setbasicsalary,
    setdepartment
} from "../action";
export class Controller extends Component {
    render() {
        return (
            <Editteamlead {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        employee: store.employee,
        snackbar: store.snackbar,
        superadmin: store.superadmin

    };
};
export const mapDispatchToProps = dispatch => {
    return {
        update_profile_pic: (id, department_id, name, mobile, email, password, gender, profile_pic_old,profile_pic, dob, per_address, pre_address, basic_salary) => {
            dispatch(update_profile_pic(id, department_id, name, mobile, email, password, gender, profile_pic_old,profile_pic, dob, per_address, pre_address, basic_salary))
        },
        view_all_TL: () => {
            dispatch(view_all_TL())
        },
        viewAlldepartment: () => {
            dispatch(viewAlldepartment())
          },




          
        setName: (name) => {
            dispatch(setName(name))
        },
        setDEPARTMENTNAME: (department_name) => {
            dispatch(setDEPARTMENTNAME(department_name))
        },
        setMobile: (mobile) => {
            dispatch(setMobile(mobile))
        },
        setEmail: (email) => {
            dispatch(setEmail(email))
        },
        setPassword: (password) => {
            dispatch(setPassword(password))
        },
        setGender: (gender) => {
            dispatch(setGender(gender))
        },
        setProfile_pic: (profile_pic) => {
            dispatch(setProfile_pic(profile_pic))
        },
        setDob: (dob) => {
            dispatch(setDob(dob))
        },
        setPer_address: (per_address) => {
            dispatch(setPer_address(per_address))
        },
        setPre_address: (pre_address) => {
            dispatch(setPre_address(pre_address))
        },
        setbasicsalary: (basic_salary) => {
            dispatch(setbasicsalary(basic_salary))
        },
        setdepartment: (department_name) => {
            dispatch(setdepartment(department_name))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
          },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);