import React, { Component } from "react";
import { connect } from "react-redux";
import Model from "../component/model";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  createModel,
  viewModel,
  updateMOdal,
  deleteModel,
  set_filtered_value,
  searchModel,
  filter_category
} from "../action";
import{
  viewAllCategory

} from "../../category/action"
export class Controller extends Component {
  render() {
    return (
      <Model {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_model: store.all_model,
    all_category:store.all_category,
    snackbar: store.snackbar,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createModel: (category_id,model_name,speed) => {
      dispatch(createModel(category_id,model_name,speed))
    },
    viewModel: () => {
      dispatch(viewModel())
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    updateMOdal: (id,category_id,model_name,speed) => {
      dispatch(updateMOdal(id,category_id,model_name,speed))
    },
    set_filtered_value: (model,e) => {
      dispatch(set_filtered_value(model,e))
    },
    searchModel: (model,e) => {
      dispatch(searchModel(model,e))
    },
    filter_category: (model,e) => {
      dispatch(filter_category(model,e))
    },
    deleteModel: (id) => {
      dispatch(deleteModel(id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);