import { act } from "react-dom/test-utils";
import {
  SET_TASK,
  SET_TASK_BY_ID,
  SET_ALL_USERS,
  SET_TASK_GIVEN_BY,
  SET_TASK_GIVEN_TO

} from "./constant";
const initial_state = {
  all_tasks: [],
  all_assign_to: [],
  users: [],
  given_by: [],
  given_to: [],
  length: 0,
  given_to_length:0,
  given_by_length:0,


};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_TASK:
      return state = { ...state, all_tasks: action.payload };
    case SET_TASK_BY_ID:
      return state = { ...state, all_assign_to: action.payload };
    case SET_ALL_USERS:
      console.log("user", action.payload)
      return state = { ...state, users: action.payload };
    case SET_TASK_GIVEN_BY:
      console.log("resssssssssssgb", action.payload.data)
      return state = { ...state, given_by: action.payload.data, given_by_length: action.payload.count };
    case SET_TASK_GIVEN_TO:
      console.log("rtb", action.payload.data)
      return state = { ...state, given_to: action.payload.data, given_to_length: action.payload.count };

    default:
      return state;
  }
}
