import React, { Component } from "react";
import { connect } from "react-redux";
import Customer from "../component/customer";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  createCustomer,
  updateCustomer,
  deleteCustomer,
  viewAllCustomer,
  viewCustomerId
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Customer {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_customer:store.all_customer,
    snackbar: store.snackbar,
    login:store.login
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    createCustomer: (admin_id, name, mobile, email, address, aadhar, pan, gst_cert, profile_pic) => {
      dispatch(createCustomer(admin_id, name, mobile, email, address, aadhar, pan, gst_cert, profile_pic))
    },
    updateCustomer:(_id,admin_id, name, mobile, email, address, aadhar, pan, gst_cert, profile_pic) => {
      dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, aadhar, pan, gst_cert, profile_pic))
    },
    deleteCustomer: (_id,admin_id) => {
      dispatch(deleteCustomer(_id,admin_id))
    },
    viewAllCustomer: (admin_id,pagenumber) => {
      dispatch(viewAllCustomer(admin_id,pagenumber))
    },
    viewCustomerId: (_id) => {
      dispatch(viewCustomerId(_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);