import React, { Component } from 'react'
// import Box from '@mui/material/Box';
import {
  Card, Grid, Dialog, DialogTitle, DialogActions, Box,
  IconButton,
} from '@mui/material';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import "../../dashboard/component/dash.css";
// import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


import TableRow from '@mui/material/TableRow';
export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manufactured: false,
      registered: false,
      noplate: false,
      booking: false

    }
  }

  componentDidMount() {
    // this.props.viewAllvehicle();
    this.props.viewDashManufacturing(this.props.login.user_id);

    // this.props.view_vehicles_status();
    // this.props.view_vehicles_delivery();
    // this.props.view_rto_reg();
    // this.props.viewAllOrder();
  }
  render() {
    const {
      all_vehicles,
      all_order
    } = this.props;
    return (
      <div>
        <>
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Box className='makeorder'>
              <center style={{ display: "flex" }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <IconButton
                    style={{ marginLeft: "18px" }}

                  >
                    <ArrowBackIcon style={{ color: "white" }} />
                  </IconButton>
                </Link>

                <Typography style={{ color: "white ", marginLeft: "40px", fontSize: 16, marginTop: "8px" }}>Back to Dashboard</Typography>
              </center>
            </Box>


          </Grid>
          <Grid style={{ marginTop: "30px", }} sx={{ display: 'flex', flexDirection: "row", }}>
            {/* <Card style={{ height: '100%', width: "100%", marginRight: "10%" }}> */}

            <Card style={{ height: '100%', width: "100%", marginRight: "10%" }}>
              <Grid className='orderdash'>
                <CardContent style={{ fontSize: 25, display: 'flex', flexDirection: "row", fontWeight: 600, color: "white" }}  >
                  <Typography style={{ fontSize: 20, marginTop: -10 }}> Manufacturing</Typography>   <Typography style={{ color: "#D41111", marginLeft: "81%", marginTop: "-20px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_order.dashboard_manufacturing.length}
                    <Typography marginLeft={-2.5} sx={{ fontWeight: 600 }}>Pending</Typography>
                  </Typography>
                </CardContent></Grid>




              <TableContainer >
                <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>
                  {/* <TableHead className="">
                    <TableRow >
                      <TableCell style={{ fontWeight: "600" }}>Customer</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Quotation</TableCell>
                     
                      <TableCell style={{ fontWeight: "600" }}>Order</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                    {all_order.dashboard_manufacturing.map((c) => {
                      return (
                        <TableRow
                          // key={row.name}
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1, marginTop: "-10px" }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell style={{ fontSize: 14, fontWeight: "600  " }}>{c.customer}</TableCell>
                          <TableCell>
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          </TableCell>
                          <TableCell> <Button onClick={() => {
                            this.setState({
                              manufactured: true,
                              vechicle_id: c._id,
                            });
                          }} style={{ marginLeft: "50%" }}> <Typography marginTop={0} style={{ color: "#B4BDBB", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Completed



                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>



              {/* <Box style={{ color: "red", height: '3px' }} marginTop={-5}><hr /></Box> */}

              {/* <Box style={{ color: "red", height: '3px' }} marginTop={-5}><hr /></Box> */}

            </Card>


            <Dialog
              open={this.state.manufactured}
              onClose={() => {
                this.setState({ manufactured: false });
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{ width: "100%", height: "100%" }}
            >
              {/* <IconButton
                    > */}
              <NoCrashOutlinedIcon style={{ fontSize: "60", color: "green", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
              {/* </IconButton> */}
              <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

              <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                <Button className="delete" variant="contained"
                  style={{}}
                  onClick={() => {
                    this.setState({ manufactured: false });
                  }}
                >Cancel
                </Button>
                <Button className="delete" variant="contained" onClick={() => { this.props.dashConfirmvehicle(this.state.vechicle_id, this.props.login.user_id); this.setState({ manufactured: false }) }}>

                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

          </Grid>
        </>

      </div>
    )
  }
}
