// import React, { Component } from 'react';
// // import Navbar from "../../common/navbar/index";
// import {
//     Grid,
//     CardContent,
//     Box,
//     IconButton,
//     Button,
//     Typography,

// } from "@mui/material";
// import {
//     // Link,
//     Navigate,
// } from "react-router-dom";
// import Dialog from "@mui/material/Dialog";
// // import Modal from "react-modal";
// import TextField from '@mui/material/TextField';
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// // import TablePagination from "@mui/material/TablePagination";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
// import AddIcon from '@mui/icons-material/Add';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';

// import VisibilityIcon from '@mui/icons-material/Visibility';
// // import CloseIcon from '@mui/icons-material/Close';
// // import DialogActions from "@mui/material/DialogActions";
// // import DialogContent from "@mui/material/DialogContent";
// // import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// // import { Button } from "@mui/material";
// // import { Grade } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Input } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Snackbar from "../../../../common/snackbar/components/snackbar";


// import LoaderCon from "../../../../common/loader/containers/loader_cont";
import React, { Component } from "react";
import {
    Grid,
    // Card,
    CardContent,
    Typography,
    Box,
    IconButton,
    Icon,
    TextField,
    Tooltip

} from "@mui/material";
// import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import { Navigate } from "react-router-dom";
// import MenuItem from "@mui/material/MenuItem";

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
    // Link,
    Navigate,
} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import LoaderCon from "../../../../common/loader/containers/loader_cont";

import "../../style/order.css";
import { Link } from "react-router-dom";

export default class home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,

        }
    }
    //     handleFileChange(e){
    //       this.props.upload_profile(this.props.login.user_id, this.state.name,this.state.mobile, this.state.email, this.state.dob, this.state.gender,e.target.files[0], this.state.p_address, this.state.pr_address, this.state.password);
    //   };

    componentDidMount() {
        this.props.view_all_user(this.props.login.user_id);
    }
    render() {
        const {
            user,
            snackbar,
            close_snack_bar
        } = this.props;
        const { imageUrl, imageAlt } = this.state;
        if (this.state.redirect === true) {
            return <Navigate to="/" />;
        }
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between"}}>
                    <Box className="ordername">
                        <center>
                            <Button style={{ color: "white ", textAlign: "center" }}>
                                Employee
                            </Button>
                        </center>
                    </Box>

                    <center style={{ borderRadius: 0 }}>
                        <Link to="/addemployee" style={{ textDecoration: "none" }}>
                            <Button
                                className="orderadd"
                                style={{ color: "white ", textAlign: "center" }}
                                onClick={() => {
                                    this.setState({
                                        add: true,
                                    });
                                }}
                            >
                                Add Employee
                            </Button>
                        </Link>
                    </center>
                </Grid>

                <CardContent style={{ marginLeft: "40px", marginRight: "65px" }}>
                    <TableContainer component={Paper}>
                        <Table
                            style={{ minWidth: 600 }}
                            size="small"
                            aria-label="a dense table"
                            ref={this.tableRef}
                        >
                            <TableHead className="orderhead">
                                <TableRow>
                                    {/* <TableCell>Category ID</TableCell> */}
                                    <TableCell>Empolyee ID</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Number</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell style={{ marginLeft: "-80px" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="orderbody">
                                {user.all_users.map((allusers,index) => {
                                    return (
                                        <TableRow
                                            // key={row.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell >{index+1}</TableCell>
                                            <TableCell >{allusers.name}</TableCell>
                                            <TableCell>{allusers.mobile}</TableCell>
                                            <TableCell >{allusers.email}</TableCell>
                                            <TableCell>
                                            <Tooltip title="View">
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            view: true,
                                                            password: allusers.password,
                                                            profile_pic: allusers.profile_pic,
                                                            gender: allusers.gender,
                                                            dob: allusers.dob,
                                                            per_address: allusers.per_address,
                                                            pre_address: allusers.pre_address
                                                        });
                                                    }}
                                                    style={{ marginLeft: "-36px" }}
                                                >
                                                    <RemoveRedEyeOutlinedIcon style={{ color: "blue" }} />
                                                </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    
                                                <Link to="/editemployee" style={{ textDecoration: "none" }}>

                                                    <IconButton
                                                        onClick={() => {
                                                            this.props.view_user_profile(
                                                                allusers._id
                                                                );
                                                                this.props.EmpId(allusers._id);
                                                        }}
                                                    >
                                                        <EditOutlinedIcon style={{ color: "green" }} />
                                                    </IconButton>
                                                </Link>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            delete: true,
                                                            id: allusers._id,
                                                        });
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon style={{ color: "red" }} />
                                                </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>


                <Dialog
                    open={this.state.view}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                    style={
                        {
                            // top: "50%",
                            // left: "10%",
                            // right: "auto",
                            // bottom: "auto",
                            // marginRight: "-50%",
                            // transform: "translate(-50%, -50%)",
                            // width: "1000px",
                            // marginLeft: "500px",
                            // paddingTop: "40px",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            // borderRadius: "0px",
                            height: "700px",
                            // zIndex: "200",
                            // backgroundColor: "#fff"
                        }}
                >

                    <DialogTitle className="alert-dialog-title" style={{ display: "flex" }}>
                        <Box className='ordername' sx={{ marginLeft: -2, width: 138, height: 27 }}>
                            <Typography sx={{ color: "white", marginLeft: 1, marginTop: 0.3 }}> Empolyee Deatils</Typography>
                        </Box>
                        <Button onClick={() => {
                            this.setState({ view: false });
                        }} style={{ marginLeft: 360, borderRadius: 100 }}>  <CloseIcon /></Button>

                    </DialogTitle>



                    <Grid className="alert-dialog-title" lg={8} style={{ display: "flex", flexDirection: "row", width: "600px", }}>
                        <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "25px" }}>
                            <img
                                src={this.state.profile_pic}
                                style={{ width: 140, height: 150 }} />

                        </Grid>


                        <Grid style={{ marginLeft: "25px" }}>
                            <Grid item style={{ display: "flex", width: 100 }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>DOB:</Typography>
                                <Typography style={{ fontSize: 12 }}>&nbsp;{this.state.dob}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 100 }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Gender:</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.gender}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}> Present Address :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.pre_address}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}> Permanate Address  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.per_address}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}> Password  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.password}</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid sx={{ marginLeft: "25px", marginTop: "0%" }}>
                        {/* <Button onClick={() => { this.props.deleteUser(this.state.Emp_Id); this.setState({ delete: false }) }}>Yes</Button> */}
                        {/* <Button onClick={() => {
                            this.setState({ view: false });
                        }}>Close</Button> */}
                    </Grid>
                </Dialog>


                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained" onClick={() => {
                            this.props.deleteUser(this.state.id);
                            this.setState({ delete: false });
                        }}>

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <LoaderCon />
                <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
            </Grid >
        )
    }
}
