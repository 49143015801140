import { createStore , combineReducers, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import Reducer from './src/screens/redux/Reducers';
import login from './auth/reducer';
import snackbar from './common/snackbar/reducer';
import loader from './common/loader/reducer';
import user from './pages/user/reducer';
import one_user from './pages/user/reducer';
import employee from './pages/admin/empolyee/reducer';
import all_category from './pages/admin/category/reducer';
import all_varient from "./pages/admin/variante/reducer"
import all_model from './pages/admin/model/reducer';
import all_color from "./pages/admin/color/reducer";
import all_battery from "./pages/admin/battery/reducer";
import all_order from "./pages/admin/orders/reducer";
import all_service from "./pages/admin/service/reducer";
import all_customer from "./pages/admin/customer/reducer";
import all_admin_user from "./pages/admin/admin_user/reducer";
import all_vehicles from "./pages/admin/vechicle/reducer";
import lead from "./pages/admin/leads/reducer";
import superadmin from "./pages/superadmin/reducer";
import taskmanagement from "./pages/admin/Taskmanagement/reducer";
import dealer from "./pages/admin/Deler/reducer";
import dashboard from "./pages/admin/dashboard/reducer"
import policy from "./pages/admin/Policy/Reducer";
import kiosk from "./pages/admin/Kiosk/Reducer";


const persistConfig = {
  key: 'root',
  storage,
}

//import banner from "./src/screens/Home/Reducers";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  snackbar,
  loader,
  login,
  user,
  employee,
  one_user,
  all_category,
  all_model,
  all_color,
  all_battery,
  all_order,
  all_varient,
  all_service,
  all_customer,
  all_admin_user,
  all_vehicles,
  dealer,
  lead,
  superadmin,
  taskmanagement,
  dashboard,
  policy,
  kiosk
});

const persistedReducer = persistReducer(persistConfig, reducer)
const configureStore = (initialState) => createStoreWithMiddleware(persistedReducer, initialState);
const store = configureStore();
// const persistor = persistStore(store)
// let store = createStore(persistedReducer)
let persistor = persistStore(store)
export default {store,persistor}

