import React, { Component } from 'react'
import Box from '@mui/material/Box';
import { Card, Grid ,TableRow,Dialog,DialogTitle,DialogActions} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';

import TableContainer from '@mui/material/TableContainer';
import { Link } from 'react-router-dom';
import "../../dashboard/component/dash.css";
export default class vehicledash extends Component {

  constructor(props) {
    super(props);

    this.state = {
      manufactured: false,
      registered: false,
      noplate: false,
      booking: false,
      allottee: false,
      servicecomplete:false

    }
  }
  componentDidMount() {
    // this.props.viewAllvehicle();
    // this.props.view_dashboard_statistitcs();
    // this.props.view_all_quotation();
    // this.props.view_all_user();
    // this.props.view_vehicles_delivery();
    // this.props.view_rto_reg();
    // this.props.viewAllOrder();
    // this.props.view_pending_order();
    // this.props.viewDashManufacturing();
    // this.props.view_vechicle_booking();
    // this.props.view_all_manufactured();
    // this.props.view_all_noplate();
    this.props.view_all_vechdash(this.props.login.user_id);
    // this.props.dash_Rtoreg();
    // this.props.viewAllService();
    // this.props.viewAllServiceAllotted();
  }
  render() {
    const {
      all_vehicles,
      all_order,
      all_service,
      employee,
    } = this.props;
    return (
      <div>
         <Grid  item xs={12} md={12} style={{ marginLeft: "60px" }} sx={{ display: 'flex', flexDirection: "row", }}>
            <Card  style={{ height: '100%', width: "100%",marginRight:"10%" }}>
              <Grid className='orderdash'>
                <CardContent sx={{ fontSize: 20, display: 'flex', flexDirection: "row", fontWeight: 400, color: "white", }} >
                  <Typography style={{ fontSize: 20, marginTop: -10 }}> Vehicle</Typography>
                  <Typography style={{ color: "#D41111", marginLeft: "80%", marginTop: "-20px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_vehicles.all_vech_dash.length}
                    <Typography marginLeft={-2} sx={{ fontWeight: 600 }}>Pending</Typography>
                  </Typography>

                </CardContent></Grid>
              <TableContainer >
                <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>


                  {/* <TableHead className="">
                    <TableRow >
                      <TableCell style={{ fontWeight: "600" }}>Customer</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Quotation</TableCell>
                      <TableCell>Variant</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Status</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Order</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                  {all_vehicles.all_vech_dash.map((c) => {
                      return (
                        <TableRow
                          // key={row.name}
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1 }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell>{c.customer}</TableCell>
                          {/* <TableCell>{quotation.category}</TableCell> */}
                          {/* <TableCell>{c.variant}</TableCell> */}
                          <TableCell>
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          </TableCell>
                          <TableCell> <Button
                            onClick={() => {
                              this.setState({
                                delivery: true,

                                vechicle_id: c._id,

                              });
                            }}
                            style={{ marginLeft: "2px" }}> <Typography marginTop={0} style={{ color: "#057E13", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Ready for Delivery

                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>


            

            </Card>
          </Grid>
          <Dialog
          open={this.state.delivery}
          onClose={() => {
            this.setState({ delivery: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ width: "100%", height: "100%" }}
        >
          {/* <IconButton
                    > */}
          <LibraryAddCheckOutlinedIcon style={{ fontSize: "60", color: "blue", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
          {/* </IconButton> */}
          <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

          <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
            <Button className="delete" variant="contained"
              style={{}}
              onClick={() => {
                this.setState({ delivery: false });
              }}
            >Cancel
            </Button>
            <Button className="delete" variant="contained" onClick={() => { this.props.dashoutdeliveryvehicle(this.state.vechicle_id,this.props.login.user_id); this.setState({ delivery: false }) }}>

              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
