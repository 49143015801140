import React, { Component } from "react";
import { connect } from "react-redux";
import Admin_user from "../component/deleruser";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  onLogout
} from "../../../../auth/actions";
import {
  addDelarUser,
  updateDealerUser,
  deletedealerUser,
    viewAllDealerUser,
} from "../action";


// import{
//   deleteUser
// }from "../../empolyee/actions"
export class Controller extends Component {
  render() {
    return (
      <Admin_user {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    dealer: store.dealer,
    snackbar: store.snackbar,
    login: store.login,
   

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    addDelarUser: (user_id, name, email, mobile, password, page_number, rowper_page) => {
      dispatch(addDelarUser(user_id, name, email, mobile, password, page_number, rowper_page))
    },
    updateDealerUser: (user_id, id, name, email, mobile, password, page_number, rowper_page) => {
      dispatch(updateDealerUser(user_id, id, name, email, mobile, password, page_number, rowper_page))
    },
    deletedealerUser: (id, page_number, rowper_page) => {
      dispatch(deletedealerUser(id, page_number, rowper_page))
    },
    viewAllDealerUser: (page_number, rowper_page) => {
      dispatch(viewAllDealerUser(page_number, rowper_page))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    // onLogout: () => {
    //   dispatch(onLogout());
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);