import {
    SET_VARIENT,
    SET_MODEL_VARIENT,
    SET_VARIENT_DETAILS,
    SET_VARIANTID,
    SET_CATEGORYID,
    SET_MODELID,
    SET_VARIANTNAME,
    SET_BRAKING,
    SET_MODELPIC,
    // SET_SPEED,
    SET_RANGE,
    SET_MOTOR,
    SET_WARRANTY,
    SET_CAPACITY,
    SET_CHARGETIME,
    // SET_BATTERYTYPE,
    // SET_BATTERYVOLT,
    SET_SYSTEM,
    SET_LENGTH,
    SET_WIDTH,
    SET_REARAXLE,
    SET_EXSHOWROOMPRICE,
    SET_INSURANCEPRICE,
    SET_REGISTRATIONPRICE,
    SET_MODEL,
    SET_CATEGORY,
    SET_FILTERED_VALUE
    // SET_CATEGORYNAME,
    // SET_MODALNAME

} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
import { set_snack_bar } from "../../../common/snackbar/action";

import {viewCategortModel} from "../model/action"
// import { set_snack_bar } from "../../common/snackbar/action";
// import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// import firebase from 'firebase/app'

export function add_model_picture(category_id, model_id, variant_name, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price) {
    return dispatch => {
        // console.log(id)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/Model picture/" + variant_name + ".png")
        const uploadTask = uploadBytesResumable(storageRef, model_pic);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (model_pic) {
                    console.log(model_pic)
                    dispatch(createVarient(category_id, model_id, variant_name, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price));
                });
            }
        );
        // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst, profile_pic));
    }
};


export function createVarient(category_id, model_id, variant_name, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_variant", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                category_id: category_id,
                model_id: model_id,
                variant_name: variant_name,
                model_pic: model_pic,
                braking: braking,
                range: range,
                motor: motor,
                warranty: warranty,
                capacity: capacity,
                charging_time: charging_time,
                system: system,
                overall_length: overall_length,
                width: width,
                rear_axle: rear_axle,
                exshowroom_price: exshowroom_price,
                insurance_price: insurance_price,
                registration_price: registration_price,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewVarient());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function update_model_picture(_id, category_id, model_id, variant_name, model_pic_old, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price) {
    return dispatch => {
        console.log(model_pic)
        dispatch(setLoader(true));
        if (model_pic == null) {
            dispatch(updateVarient(_id, category_id, model_id, variant_name, model_pic_old, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price));
        } else {
            const storageRef = ref(getStorage(), "/Model picture/" + variant_name + ".png")
            const uploadTask = uploadBytesResumable(storageRef, model_pic);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (model_pic_url) {
                        console.log(model_pic)
                        dispatch(updateVarient(_id, category_id, model_id, variant_name, model_pic_url, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price));
                    });
                }
            );
        }
        
        
        // dispatch(createCustomer(name, mobile, email, address, aadhar, pan, gst, profile_pic));
    }
};

export function updateVarient(id, category_id, model_id, variant_name, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_variant", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                category_id: category_id,
                model_id: model_id,
                variant_name: variant_name,
                model_pic: model_pic,
                braking: braking,
                range: range,
                motor: motor,
                warranty: warranty,
                capacity: capacity,
                charging_time: charging_time,
                system: system,
                overall_length: overall_length,
                width: width,
                rear_axle: rear_axle,
                exshowroom_price: exshowroom_price,
                insurance_price: insurance_price,
                registration_price: registration_price,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewVarient());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteVariant(id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_variant", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)
                    dispatch(viewVarient());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader());
                } else {
                     dispatch(unsetLoader());
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewVarient() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_variant", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson.result);
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_VARIENT, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_VARIENT, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewModelVarient(model_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_model_variant", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                model_id: model_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_MODEL_VARIENT, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_MODEL_VARIENT, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewVarientByid(variant_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_variant_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: variant_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(setvariantid(responseJson.result._id));
                    dispatch(setCategoryid(responseJson.result.category_id));
                    dispatch(setModelid(responseJson.result.model_id));
                    dispatch(setVariantname(responseJson.result.variant_name));
                    dispatch(setModelpic(responseJson.result.model_pic));
                    dispatch(setBraking(responseJson.result.braking));
                    dispatch(setRange(responseJson.result.range));
                    dispatch(setMotor(responseJson.result.motor));
                    dispatch(setWarranty(responseJson.result.warranty));
                    dispatch(setCapacity(responseJson.result.capacity));
                    dispatch(setChargeTime(responseJson.result.charging_time));
                    dispatch(setSystem(responseJson.result.system));
                    dispatch(setLength(responseJson.result.overall_length));
                    dispatch(setWidth(responseJson.result.width));
                    dispatch(setRearaxle(responseJson.result.rear_axle));
                    dispatch(setExshowroomprice(responseJson.result.exshowroom_price));
                    dispatch(setInsuranceprice(responseJson.result.insurance_price));
                    dispatch(setRegistrationprice(responseJson.result.registration_price));
                    dispatch(viewCategortModel(responseJson.result.category_id))
                    // dispatch(setModel(responseJson.result.model));
                    // dispatch(setCategory(responseJson.result.category));
                    // dispatch(setcategoryname(responseJson.result.category_name));
                    // dispatch(setmodalname(responseJson.result.modal_name));
                    console.log("op",responseJson.result);
                    dispatch({ type: SET_VARIENT_DETAILS, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_VARIENT_DETAILS, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function searchModel(all_varient,e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({type:SET_FILTERED_VALUE,payload:all_varient});
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
          //   setFilter(model.all_model);
          }
          else {
            const newArray =all_varient.filter((el) => {
              return (el.variant_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({type:SET_FILTERED_VALUE,payload:newArray})
            // dispatch(unsetLoader());

          //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
          }
    };
}

export function filter_category(all_varient,category_id) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (category_id === "") {
            dispatch({type:SET_FILTERED_VALUE,payload:all_varient});
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
          //   setFilter(model.all_model);
          }
          else {
            const newArray =all_varient.filter((el) => {
              return (el.category_id.toLowerCase().match(category_id.toLowerCase()));
            })
            dispatch({type:SET_FILTERED_VALUE,payload:newArray})
            // dispatch(unsetLoader());

          //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
          }
    };
}
export function filter_model(all_varient,model_id) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (model_id === "") {
            dispatch({type:SET_FILTERED_VALUE,payload:all_varient});
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
          //   setFilter(model.all_model);
          }
          else {
            const newArray =all_varient.filter((el) => {
              return (el.model_id.toLowerCase().match(model_id.toLowerCase()));
            })
            dispatch({type:SET_FILTERED_VALUE,payload:newArray})
            // dispatch(unsetLoader());

          //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
          }
    };
}


export function set_filtered_value(payload){
    return{
        type: SET_FILTERED_VALUE,
        payload: payload
    }
}
export function setvariantid(payload) {
    return {
        type: SET_VARIANTID,
        payload: payload,
    };
}
export function setCategoryid(payload) {
    return {
        type: SET_CATEGORYID,
        payload: payload,
    };
}
export function setModelid(payload) {
    return {
        type: SET_MODELID,
        payload: payload,
    };
}
export function setVariantname(payload) {
    return {
        type: SET_VARIANTNAME,
        payload: payload,
    };
}

export function setModelpic(payload) {
    return {
        type: SET_MODELPIC,
        payload: payload,
    };
}

export function setExshowroomprice(payload) {
    return {
        type: SET_EXSHOWROOMPRICE,
        payload: payload,
    };
}
export function setRegistrationprice(payload) {
    return {
        type: SET_REGISTRATIONPRICE,
        payload: payload,
    };
}
export function setInsuranceprice(payload) {
    return {
        type: SET_INSURANCEPRICE,
        payload: payload,
    };
}
export function setBraking(payload) {
    return {
        type: SET_BRAKING,
        payload: payload,
    };
}
export function setRange(payload) {
    return {
        type: SET_RANGE,
        payload: payload,
    };
}
export function setMotor(payload) {
    return {
        type: SET_MOTOR,
        payload: payload,
    };
}
export function setWarranty(payload) {
    return {
        type: SET_WARRANTY,
        payload: payload,
    };
}
export function setCapacity(payload) {
    return {
        type: SET_CAPACITY,
        payload: payload,
    };
}
export function setChargeTime(payload) {
    return {
        type: SET_CHARGETIME,
        payload: payload,
    };
}
export function setSystem(payload) {
    return {
        type: SET_SYSTEM,
        payload: payload,
    };
}
export function setLength(payload) {
    return {
        type: SET_LENGTH,
        payload: payload,
    };
}
export function setWidth(payload) {
    return {
        type: SET_WIDTH,
        payload: payload,
    };
}
export function setRearaxle(payload) {
    return {
        type: SET_REARAXLE,
        payload: payload,
    };
}


export function setModel(payload) {
    return {
        type: SET_MODEL,
        payload: payload,
    };
}
export function setCategory(payload) {
    return {
        type: SET_CATEGORY,
        payload: payload,
    };
}
