import React, { Component } from 'react'
import Box from '@mui/material/Box';
import { Card, Grid } from '@mui/material';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import "../../dashboard/component/dash.css";
import { Link } from 'react-router-dom';
export default class extends Component {
  render() {
    return (
      <div>

        <Grid style={{ marginLeft: "40px", }} sx={{ display: 'flex', flexDirection: "row", }}>
          <Card style={{ height: '200px', width: "380px", }}>
            <div className='order'>
              <CardContent sx={{ fontSize: 13, display: 'flex', flexDirection: "row", fontWeight: 600, color: "white" }}  >
                Manufacturing   <Typography style={{ color: "#D41111", marginLeft: "160px", marginTop: "-10px" }} sx={{ fontWeight: 600 }} fontSize={20}>
                  20
                  <Typography marginLeft={-2} sx={{ fontWeight: 600 }}>Pending</Typography>
                </Typography>
              </CardContent></div>


            <CardContent sx={{ fontSize: 13, display: 'flex', flexDirection: "row", fontWeight: 600, }} >
              Customer-1
              <Button><Typography style={{ color: "#D41111", marginLeft: "100px", }} sx={{ fontWeight: 600 }} marginLeft={19} fontSize={10}>
                Pending

              </Typography></Button>
              <Button><Typography style={{
                color: "#B4BDBB"
                , marginLeft: "4px"
              }} sx={{ fontWeight: 600 }} marginLeft={1} fontSize={10}>
               
              </Typography> </Button>


            </CardContent>
            <Box style={{ color: "red", height: '3px' }} marginTop={-5}><hr /></Box>
            <CardContent sx={{ fontSize: 13, display: 'flex', flexDirection: "row", fontWeight: 600, }} >
              Customer-2
              <Button> <Typography style={{ color: "#D41111", marginLeft: "100px" }} sx={{ fontWeight: 600 }} marginLeft={19} fontSize={10}>
                Pending

              </Typography> </Button>
              <Button> <Typography style={{ color: "#B4BDBB", marginLeft: "4px" }} sx={{ fontWeight: 600 }} marginLeft={1} fontSize={10}>
                Completed

              </Typography> </Button>

            </CardContent>
            <Box style={{ color: "red", height: '3px' }} marginTop={-5}><hr /></Box>
            <CardContent sx={{ fontSize: 13, display: 'flex', flexDirection: "row", fontWeight: 600, }} >
              Customer-3
              <Button> <Typography style={{ color: "#D41111", marginLeft: "100px" }} sx={{ fontWeight: 600 }} marginLeft={19} fontSize={10}>
                Pending

              </Typography>  </Button>
              <Button> <Typography style={{ color: "#B4BDBB", marginLeft: "4px" }} sx={{ fontWeight: 600 }} marginLeft={1} fontSize={10}>
                Completed

              </Typography> </Button>

            </CardContent>
            <Box style={{ color: "red", height: '3px' }} marginTop={-5}><hr /></Box>


            <Link to="/vechicle">


              <Button><Typography ml={17} style={{
                color: "#2A367C", marginTop: "-6px", fontSize: "12px"
              }}>View More</Typography></Button>
            </Link>

          </Card>
        </Grid>


      </div>
    )
  }
}
