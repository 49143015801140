import React, { Component } from "react";
import { connect } from "react-redux";
import Profile from "../components/Profile";
// import {
//   close_snack_bar
// } from "../../common/snackbar/action";
import {
    view_user_profile
} from "../actions";
import
{
  onLogout
} from "../../../auth/actions";
export class Controller extends Component {
  render() {
    return (
      <Profile {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    // user: store.user,
    one_user: store.one_user,
    login: store.login
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    view_user_profile: (id) => {
      dispatch(view_user_profile(id))
    },
    onLogout: () => {
      dispatch(onLogout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);