import {
  SET_CATEGORY
} from "./constant";
const initial_state = {
  all_category: []
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_CATEGORY:
      return state = { ...state, all_category: action.payload };
    // case SET_ONE_USER:
    //   return state = { ...state, one_user: action.payload };

    default:
      return state;
  }
}
