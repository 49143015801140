import {
    SET_ORDER,
    SET_RTOREG,
    SET_ORDER_PENDING,
    SET_BOOKING_VECHILE,
    SET_ALL_QUOTATION,
    VIEW_DASHBOARD_MANUFACTURING, SET_ORDER_ID, SET_AMOUNT,

    SET_ORDERS_ID,
    SET_CUSTOMER_NAME,
    SET_CUSTOMER_ADDRESS,
    SET_CUSTOMER_MOBILE,
    SET_CUSTOMER_GSTNO,
    SET_VARIANT_NAME,
    SET_VARIANT_VOLT,
    SET_VARIANT_LENGTH,
    SET_VARIANT_WIDTH,
    SET_VARIANT_CAPACITY,
    SET_VARIANT_MOTOR,
    SET_VARIANT_RANGE,
    SET_BATTERY_NAME,
    SET_BATTERY_VOLTAGE,
    SET_COLOR_NAME,
    SET_MODEL_SPEED,
    SET_MODEL_NAME,
    SET_ONROAD_PRICE,
    SET_INSURANCE_PRICE,
    SET_REGISTRATION_PRICE,
    SET_TRANSPORTATION_PRICE,
    SET_TOTAL_PRICE,
    SET_QUANTITY,
    SET_BOOKING_DATE,
    SET_DELIVERY_DATE,
    SET_ESTIMATE_NO,
    SET_FILTERED_VALUE,
    SET_DISCOUNT_AMOUNT,
    SET_PAYMENT,
    SET_AADHAR,
    SET_PAN,
    SET_GSTCERT,
    SET_PHOTO,
    SET_ORDER_BY_ADMIN_ID,
    STATUS_DELIVERED,
    STATUS_BOOKING

} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
// import { set_snack_bar } from "../../common/snackbar/action";
// import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import 'firebase/storage';
// import firebase from 'firebase/app'
import 'firebase/storage';
import { set_snack_bar } from "../../../common/snackbar/action";

import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadString } from "firebase/storage";




export function uploadQuot(image, order_id) {
    return dispatch => {
        var images = image.slice(22)
        console.log(images)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "Quotation/" + order_id + ".png")
        uploadString(storageRef, images, 'base64', { contentType: 'image/png' }).then((uploadTask) => {
            getDownloadURL(uploadTask.ref).then(function (url) {
                console.log(url)
                // dispatch(createVarient(category_id, model_id, variant_name, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price));
                dispatch(send_quation_pdf(url, order_id));
            });
        }
        );
    }
};
export function send_quation_pdf(image, order_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "send_quotation", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: order_id,
                quotation_pdf: image,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function uploadBooking(image, order_id) {
    return dispatch => {
        var images = image.slice(22)
        console.log(images)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "Booking/" + order_id + ".png")
        uploadString(storageRef, images, 'base64', { contentType: 'image/png' }).then((uploadTask) => {
            getDownloadURL(uploadTask.ref).then(function (url) {
                console.log(url)
                // dispatch(createVarient(category_id, model_id, variant_name, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price));
                dispatch(send_booking_pdf(url, order_id));
            });
        }
        );
    }
};
export function send_booking_pdf(image, order_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "send_bookingpdf", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: order_id,
                booking_pdf: image,


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function createOrder(admin_id,customer_id, category_id, model_id, variant_id, color_id, battery_id, booking_invoice, ex_shoeroom_price, insurance_fee, registration_fee, transportation_cost, estimated_delivery_date, quanty, width, length, will_registration, will_transport, on_road_price, total_price, discount_amount, advance_amount, payment_mode, comment,) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id:admin_id,
                customer_id: customer_id,
                category_id: category_id,
                model_id: model_id,
                variant_id: variant_id,
                color_id: color_id,
                battery_id: battery_id,
                booking_invoice: booking_invoice,
                ex_shoeroom_price: ex_shoeroom_price,
                insurance_fee: insurance_fee,
                registration_fee: registration_fee,
                transportation_cost: transportation_cost,
                estimated_delivery_date: estimated_delivery_date,
                // on_road_price: on_road_price,
                // total_price: total_price,
                quanty: quanty,
                width: width,
                length: length,
                will_registration: will_registration,
                will_transport: will_transport,
                on_road_price: on_road_price,
                total_price: total_price,
                discount_amount: discount_amount,
                amount: advance_amount,
                payment_mode: payment_mode,
                comment: comment,
                // admin_id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson.result)
                if (responseJson.status) {
                    dispatch(viewAllOrder(admin_id));
                    dispatch(setorderid(responseJson.result));
                    console.log(responseJson.result)
                    dispatch(viewQuotationByOrderId(responseJson.result));

                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());

                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

//for pdf quotations start
export function viewQuotationByOrderId(id) {
    return (dispatch) => {
        // dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "details_quotation_by_orderId", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    dispatch(setID(responseJson.result._id));
                    dispatch(setCustomerName(responseJson.result.name));
                    dispatch(setCustomerAddress(responseJson.result.address));
                    dispatch(setCustomerMobile(responseJson.result.mobile));
                    dispatch(setCustomerGstno(responseJson.result.gstno));
                    dispatch(setVariantName(responseJson.result.variant_name));
                    dispatch(setVariantVolt(responseJson.result.volt));
                    dispatch(setVariantLength(responseJson.result.length));
                    dispatch(setVariantWidth(responseJson.result.width));
                    dispatch(setVariantCapacity(responseJson.result.capacity));
                    dispatch(setVariantMotor(responseJson.result.motor));
                    dispatch(setVariantRange(responseJson.result.range));
                    dispatch(setBatteryName(responseJson.result.battery_name));
                    dispatch(setBatteryVoltage(responseJson.result.battery_voltage));
                    dispatch(setColorName(responseJson.result.color_name));
                    dispatch(setModelSpeed(responseJson.result.model_speed));
                    dispatch(setModelName(responseJson.result.model_name));
                    dispatch(setOnroadPrice(responseJson.result.on_road_price));
                    dispatch(setInsurancePrice(responseJson.result.insurance_fee));
                    dispatch(setRegistrationPrice(responseJson.result.registration_fee));
                    dispatch(setTransportationPrice(responseJson.result.transportation_cost));
                    dispatch(setTotalPrice(responseJson.result.total_price));
                    dispatch(setQuantity(responseJson.result.quanty));
                    dispatch(setBookingDate(responseJson.result.booking_date));
                    dispatch(setDeliveryDate(responseJson.result.estimated_delivery_date));
                    dispatch(setEstimateNo(responseJson.result.count_quotas));
                    dispatch(setDiscountAmount(responseJson.result.discount_amount));
                    dispatch(setPayment(responseJson.result.payment));
                    dispatch(setAadhar(responseJson.result.aadhar));
                    dispatch(setPan(responseJson.result.pan));
                    dispatch(setGstCert(responseJson.result.gstcert));
                    dispatch(setPhoto(responseJson.result.photo));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function setID(payload) {
    return {
        type: SET_ORDERS_ID,
        payload: payload,
    };
}

export function setCustomerName(payload) {
    return {
        type: SET_CUSTOMER_NAME,
        payload: payload,
    };
}

export function setCustomerAddress(payload) {
    return {
        type: SET_CUSTOMER_ADDRESS,
        payload: payload,
    };
}
export function setCustomerMobile(payload) {
    return {
        type: SET_CUSTOMER_MOBILE,
        payload: payload,
    };
}
export function setCustomerGstno(payload) {
    return {
        type: SET_CUSTOMER_GSTNO,
        payload: payload,
    };
}
export function setVariantName(payload) {
    return {
        type: SET_VARIANT_NAME,
        payload: payload,
    };
}
export function setVariantVolt(payload) {
    return {
        type: SET_VARIANT_VOLT,
        payload: payload,
    };
}
export function setVariantLength(payload) {
    return {
        type: SET_VARIANT_LENGTH,
        payload: payload,
    };
}

export function setVariantWidth(payload) {
    return {
        type: SET_VARIANT_WIDTH,
        payload: payload,
    };
}

export function setVariantCapacity(payload) {
    return {
        type: SET_VARIANT_CAPACITY,
        payload: payload,
    };
}
export function setVariantMotor(payload) {
    return {
        type: SET_VARIANT_MOTOR,
        payload: payload,
    };
}
export function setVariantRange(payload) {
    return {
        type: SET_VARIANT_RANGE,
        payload: payload,
    };
}
export function setBatteryName(payload) {
    return {
        type: SET_BATTERY_NAME,
        payload: payload,
    };
}
export function setBatteryVoltage(payload) {
    return {
        type: SET_BATTERY_VOLTAGE,
        payload: payload,
    };
}
export function setColorName(payload) {
    return {
        type: SET_COLOR_NAME,
        payload: payload,
    };
}
export function setModelSpeed(payload) {
    return {
        type: SET_MODEL_SPEED,
        payload: payload,
    };
}
export function setModelName(payload) {
    return {
        type: SET_MODEL_NAME,
        payload: payload,
    };
}
export function setOnroadPrice(payload) {
    return {
        type: SET_ONROAD_PRICE,
        payload: payload,
    };
}
export function setInsurancePrice(payload) {
    return {
        type: SET_INSURANCE_PRICE,
        payload: payload,
    };
}
export function setRegistrationPrice(payload) {
    return {
        type: SET_REGISTRATION_PRICE,
        payload: payload,
    };
}
export function setTransportationPrice(payload) {
    return {
        type: SET_TRANSPORTATION_PRICE,
        payload: payload,
    };
}
export function setTotalPrice(payload) {
    return {
        type: SET_TOTAL_PRICE,
        payload: payload,
    };
}
export function setQuantity(payload) {
    return {
        type: SET_QUANTITY,
        payload: payload,
    };
}
export function setBookingDate(payload) {
    return {
        type: SET_BOOKING_DATE,
        payload: payload,
    };
}
export function setDeliveryDate(payload) {
    return {
        type: SET_DELIVERY_DATE,
        payload: payload,
    };
}
export function setEstimateNo(payload) {
    return {
        type: SET_ESTIMATE_NO,
        payload: payload,
    };
}
export function setDiscountAmount(payload) {
    return {
        type: SET_DISCOUNT_AMOUNT,
        payload: payload,
    };
}
export function setPayment(payload) {
    return {
        type: SET_PAYMENT,
        payload: payload,
    };
}
export function setAadhar(payload) {
    return {
        type: SET_AADHAR,
        payload: payload,
    };
}
export function setPan(payload) {
    return {
        type: SET_PAN,
        payload: payload,
    };
}
export function setGstCert(payload) {
    return {
        type: SET_GSTCERT,
        payload: payload,
    };
}
export function setPhoto(payload) {
    return {
        type: SET_PHOTO,
        payload: payload,
    };
}
//for pdf quotations end


export function setorderid(payload) {
    console.log("ordetr", payload)
    return {
        type: SET_ORDER_ID,
        payload: payload,
    };
}

export function convertBooking(_id,admin_id, advance_amount, estimated_delivery_date, mode) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "convert_booking", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: _id,
                admin_id:admin_id,
                amount: advance_amount,
                estimated_delivery_date: estimated_delivery_date,
                payment_mode: mode

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllOrder(admin_id));
                    dispatch(viewQuotationByOrderId(_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function updateOrder(_id,admin_id, customer_id, category_id, model_id, variant_id, booking_invoice, ex_shoeroom_price, insurance_fee, registration_fee, transportation_cost, estimated_delivery_date, on_road_price, quanty, width, length, will_registration, will_transport, status, comment) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: _id,
                customer_id: customer_id,
                category_id: category_id,
                model_id: model_id,
                variant_id: variant_id,
                // order_id: order_id,
                booking_invoice: booking_invoice,
                ex_shoeroom_price: ex_shoeroom_price,
                // insurance_fee: insurance_fee,
                // registration_fee: registration_fee,
                // transportation_cost: transportation_cost,
                estimated_delivery_date: estimated_delivery_date,
                on_road_price: on_road_price,
                quanty: quanty,
                width: width,
                length: length,
                will_registration: will_registration,
                will_transport: will_transport,
                status: status,
                comment: comment,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllOrder(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteOrder(_id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: _id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllOrder(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



export function viewAllOrder(admin_id,status,order_status,page_number) {
    return (dispatch) => {
        console.log("view", admin_id,status,order_status,page_number)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_order_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
                status: status,
                order_status: order_status,
                page_number: page_number,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_ORDER, payload: { data: responseJson.result, count: responseJson.length }  });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ORDER, payload:{ data: [], count: 0 }})
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                // console.log(responseJson)
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function viewAllOrderBySA(page_number,rowper_page) {
    return (dispatch) => {
        console.log("orderSA");
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("orderSA", responseJson);
                if (responseJson.status) {
                    console.log("orderSA", responseJson.status);
                    dispatch({ type: SET_ORDER, payload: { data: responseJson.result, count: responseJson.length }  });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ORDER, payload:{ data: [], count: 0 }})
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                // console.log(responseJson)
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function view_rto_reg(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_vechicle_rto_reg_pending", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_RTOREG, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_RTOREG, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function createQuotation(customer_id,admin_id, model_id, category_id, variant_id, quanty, order_color, will_registration, applied, received, length, width, height, insurance, registration, transportation, booking_date, estimated_delivery_date, delivery_date, order_status, comment) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_quotation", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                customer_id: customer_id,
                model_id: model_id,
                category_id: category_id,
                variant_id: variant_id,
                quanty: quanty,
                order_color: order_color,
                will_registration: will_registration,
                applied: applied,
                received: received,
                length: length,
                width: width,
                height: height,
                insurance: insurance,
                registration: registration,
                transportation: transportation,
                booking_date: booking_date,
                estimated_delivery_date: estimated_delivery_date,
                delivery_date: delivery_date,
                order_status: order_status,
                comment: comment
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllOrder(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewDashManufacturing(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_dashboard_manufacturing", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    // dispatch(view_all_quotation());
                    // dispatch(viewDashManufacturing());

                    dispatch({ type: VIEW_DASHBOARD_MANUFACTURING, payload: responseJson.result });
                    console.log("khskjhkj,hks",responseJson.result);

                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: VIEW_DASHBOARD_MANUFACTURING, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());

            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_pending_order(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_pending_order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(view_all_quotation());

                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_ORDER_PENDING, payload: responseJson.result });
                    console.log(responseJson.result);
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ORDER_PENDING, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_quotation(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_quotation", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("jhsvjshgvjh",responseJson)

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_ALL_QUOTATION, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ALL_QUOTATION, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function dashconvert_to_booking(_id,admin_id, advance_amount, estimated_delivery_date, mode) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "convert_booking", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: _id,
                admin_id:admin_id,
                amount: advance_amount,
                estimated_delivery_date: estimated_delivery_date,
                payment_mode: mode

            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllOrder(admin_id));
                    dispatch(view_rto_reg(admin_id));
                    dispatch(viewDashManufacturing(admin_id));
                    dispatch(view_pending_order(admin_id));
                    dispatch(view_all_quotation(admin_id));
                    dispatch(viewVechileBooking(admin_id));
                    dispatch(viewPaymentSummary(_id));
                    dispatch(viewQuotationByOrderId(_id));
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_ALL_QUOTATION, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ALL_QUOTATION, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewVechileBooking(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_vechicle_booking", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_BOOKING_VECHILE, payload: responseJson.result });
                    console.log(responseJson.result);
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_BOOKING_VECHILE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                // console.log(responseJson)
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewPaymentSummary(_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "view_payment_summary", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: _id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch({ type: SET_AMOUNT, payload: responseJson });
                    console.log(responseJson);
                    // dispatch({ type: SET_PENDING_AMOUNT, payload: responseJson.pending_amount });
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_AMOUNT, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function AddPayment(_id,admin_id, pending_amount, payment_mode, amount) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_payment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: _id,
                pending_amount: pending_amount,
                payment_mode: payment_mode,
                amount: amount,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllOrder(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    console.log(responseJson);
                    // dispatch({ type: SET_PENDING_AMOUNT, payload: responseJson.pending_amount });
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_AMOUNT, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function filter_model(all_order, status) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (status === "") {
            dispatch({ type: SET_FILTERED_VALUE, payload: all_order });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_order.filter((el) => {
                return (el.status.toLowerCase().match(status.toLowerCase()));
            })
            dispatch({ type: SET_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

export function filter_status(all_order, order_status) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (order_status === "") {
            dispatch({ type: SET_FILTERED_VALUE, payload: all_order });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_order.filter((el) => {
                return (el.order_status.toLowerCase().match(order_status.toLowerCase()));
            })
            dispatch({ type: SET_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

export function searchorder(all_order, e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({ type: SET_FILTERED_VALUE, payload: all_order });
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
            //   setFilter(model.all_model);
        }
        else {
            const newArray = all_order.filter((el) => {
                return (el.customer.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({ type: SET_FILTERED_VALUE, payload: newArray })
            // dispatch(unsetLoader());

            //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
        }
    };
}

export function set_filtered_value(payload) {
    return {
        type: SET_FILTERED_VALUE,
        payload: payload
    }
}

export function reSendQuotationpdf(order_id, quotation_pdf) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "resend_quotation", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: order_id,
                quotation_pdf: quotation_pdf
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function reSendBookingpdf(order_id, booking_pdf) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "resend_bookingpdf", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_id: order_id,
                booking_pdf: booking_pdf
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(viewAllOrder());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));

                console.error(error);
            });
    };
}

export function view_status_booking() {
    console.log("book")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_status_booking", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resbook", responseJson)
                if (responseJson.status) {
                    dispatch({ type: STATUS_BOOKING, payload: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STATUS_BOOKING, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_Status_Booking_By_Admin(admin_id) {
    console.log("book")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_status_booking_by_admin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resbook", responseJson)
                if (responseJson.status) {
                    dispatch({ type: STATUS_BOOKING, payload: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STATUS_BOOKING, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_Status_Delivered_By_Admin(admin_id) {
    console.log("book")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_status_delivered_by_admin", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resbook", responseJson)
                if (responseJson.status) {
                    dispatch({ type: STATUS_DELIVERED, payload: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STATUS_DELIVERED, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_status_delivered() {
    console.log("com")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_status_delivered", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("rescom", responseJson)
                if (responseJson.status) {
                    dispatch({ type: STATUS_DELIVERED, payload: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: STATUS_DELIVERED, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}