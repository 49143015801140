import React, { Component } from "react";
import { connect } from "react-redux";
import Addvariant from "../component/addvariant";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  // createVarient,
  updateVarient,
  deleteVariant,
  viewVarient,
  add_model_picture
} from "../action";
import {
  viewAllCategory
} from "../../category/action";
import {
  viewModel,
  viewCategortModel
} from "../../model/action";
export class Controller extends Component {
  render() {
    return (
      <Addvariant {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_varient: store.all_varient,
    all_category: store.all_category,
    all_model: store.all_model,
    snackbar: store.snackbar,
    login: store.login,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // createVarient: (category_id, model_id,varient_name,model_pic,ex_showroom_price,registration_price,insurance_price,braking, range, motor, warranty, capacity, charging_time,battery_voltage, system, overall_length, width, rear_axle) => {
    //   dispatch(createVarient(category_id, model_id,varient_name,model_pic,ex_showroom_price,registration_price,insurance_price,braking, range, motor, warranty, capacity, charging_time,battery_voltage, system, overall_length, width, rear_axle))
    // },
    updateVarient: (id,category_id, model_id, variant_name, model_pic, exshowroom_price, registration_price, insurance_price, braking,range, motor, warranty, capacity, charging_time, battery_voltage, system, overall_length, width, rear_axle) => {
      dispatch(updateVarient(id,category_id, model_id, variant_name, model_pic, exshowroom_price, registration_price, insurance_price, braking, range, motor, warranty, capacity, charging_time, battery_voltage, system, overall_length, width, rear_axle))
    },
    deleteVariant: (id) => {
      dispatch(deleteVariant(id))
    },
    viewVarient: () => {
      dispatch(viewVarient())
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    viewModel: () => {
      dispatch(viewModel())
    },
    viewCategortModel: (category_id) => {
      dispatch(viewCategortModel(category_id))
    },
    add_model_picture: (category_id, model_id, variant_name, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price) => {
      dispatch(add_model_picture(category_id, model_id, variant_name, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);