import React, { Component } from 'react'
import Box from '@mui/material/Box';
import {Card,Grid,TableRow,Dialog,MenuItem,TextField} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import { Link } from 'react-router-dom';
export default class vehicleservicedash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manufactured: false,
      registered: false,
      noplate: false,
      booking: false,
      allottee: false,
      servicecomplete:false

    }
  }
  componentDidMount() {
    // this.props.viewAllvehicle();
    // this.props.view_dashboard_statistitcs();
    // this.props.view_all_quotation();
    // this.props.view_all_user();
    // this.props.view_vehicles_delivery();
    // this.props.view_rto_reg();
    // this.props.viewAllOrder();
    // this.props.view_pending_order();
    // this.props.viewDashManufacturing();
    // this.props.view_vechicle_booking();
    // this.props.view_all_manufactured();
    // this.props.view_all_noplate();
    // this.props.view_all_vechdash();
    // this.props.dash_Rtoreg();
    this.props.viewAllService(this.props.login.user_id);
    // this.props.viewAllServiceAllotted();
  }
  render() {
    const {
      all_vehicles,
      all_order,
      all_service,
      employee,
    } = this.props;
    return (
      <div>
         <Grid  item xs={12} md={12} style={{ marginLeft: "60px" }} sx={{ display: 'flex', flexDirection: "row", }}>
            <Card  style={{ height: '100%', width: "100%",marginRight:"10%" }}>
              <Grid className='orderdash'>
                <CardContent sx={{ fontSize: 20, display: 'flex', flexDirection: "row", fontWeight: 400, color: "white", }} >
                  <Typography style={{ fontSize: 20, marginTop: -10 }}> Vehicle Service </Typography>
                  <Typography style={{ color: "#D41111", marginLeft: "80%", marginTop: "-20px" }} sx={{ fontWeight: 600 }} fontSize={16}>{all_service.all_service.length}
                    <Typography marginLeft={-2} sx={{ fontWeight: 600 }}>Pending</Typography>
                  </Typography>

                </CardContent></Grid>
              <TableContainer >
                <Table style={{ minWidth: "100%", borderColor: "red" }} size="small" aria-label="a dense table" ref={this.tableRef}>


                  {/* <TableHead className="">
                    <TableRow >
                      <TableCell style={{ fontWeight: "600" }}>Customer</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Quotation</TableCell>
                      <TableCell>Variant</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Status</TableCell>
                      <TableCell style={{ fontWeight: "600" }}>Order</TableCell>
                    </TableRow>
                  </TableHead> */}
                  <TableBody className="" style={{ borderBottomColor: "red" }}>
                  {all_service.all_service.map((c) => {
                      return (
                        <TableRow
                          style={{ borderBlockEndColor: "", borderBottomWidth: 1 }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >

                          <TableCell>{c.customer}</TableCell>
                          {/* <TableCell>{quotation.category}</TableCell> */}
                          {/* <TableCell>{c.variant}</TableCell> */}
                          <TableCell>
                            {/* <Button style={{ marginLeft: "0px", }}>  */}
                            {/* <Typography marginTop={0} style={{ color: "#D41111", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} fontSize={12}>
                              Pending</Typography> */}
                            {/* </Button> */}
                          </TableCell>
                          <TableCell> <Button
                            onClick={() => {
                              this.setState({
                                allottee: true,
                                service_id: c._id,
                              });
                            }}
                            style={{ marginLeft: "2px" }}> <Typography marginTop={0} style={{ color: "#057E13", marginLeft: "0", textTransform: "capitalize" }} sx={{ fontWeight: 600 }} marginLeft={0} fontSize={12}>
                              Allottee Employee

                            </Typography> </Button></TableCell>


                        </TableRow>

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>


            

            </Card>
          </Grid>
          <Dialog
          open={this.state.allottee}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "150px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>


              <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Allottee Employee"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  // defaultValue={this.state.model_id}
                  onChange={(e) => { this.setState({ emp_id: e.target.value }) }}
                >
                  {employee.all_users.map((e) => (
                  <MenuItem value={e._id} >
                    {e.name}
                  </MenuItem>
                   
                  ))}
                </TextField>

              </div>

              <Button
                className="btns"
                onClick={() => {
                  this.props.AllottedEmployee(
                    this.state.service_id, this.state.emp_id
                  );
                  this.setState({ allottee: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Allottee
              </Button>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ allottee: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>


        <Dialog
          open={this.state.booking}
          // onClose={() => {
          //     this.setState({ add: false });
          // }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Grid
            style={{
              backgroundColor: "#fff",
              height: "300px",
              minWidth: "323px",
              scrollBehavior: "smooth",
              overflow: "revert-layer",
              alignSelf: "center",
              overflowX: "hidden",
              flexDirection: "row",
              display: "flex",
              overflowY: "hidden",
            }}
          >
            <Grid>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  type={"date"}
                  required
                  id="outlined-required"
                  label="Estimate delivery Date"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ estimated_delivery_date: e.target.value });
                  }}
                />
              </div>
              <div
                class="input-group mb-3"
                style={{ marginLeft: "20px", marginTop: "20px" }}
              >
                <TextField
                  // type={"date"}
                  required
                  id="outlined-required"
                  label="Advance Amount"
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  //   value={this.state.category_name}
                  onChange={(e) => {
                    this.setState({ advance_amount: e.target.value });
                  }}
                />
              </div>
              <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                <TextField
                  required
                  select
                  id="outlined-required"
                  label="Mode "
                  InputLabelProps={{ shrink: true, required: true }}
                  style={{ height: "50px", width: "280px" }}
                  // defaultValue={this.state.model_id}
                  onChange={(e) => { this.setState({ mode: e.target.value }) }}
                >
                  {/* {all_model.all_model.map((e) => ( */}
                  <MenuItem value={"cash"} >
                    {/* {e.model_name} */}
                    Cash
                  </MenuItem>
                  <MenuItem value={"cheque"}>
                    {/* {e.model_name} */}
                    Cheque
                  </MenuItem>
                  <MenuItem value={"onlinepay"}>
                    {/* {e.model_name} */}
                    Online Pay
                  </MenuItem>
                  {/* ))} */}
                </TextField>

              </div>
              <Link to="/bookings" style={{ textDecoration: "none", marginLeft: "0%" }}>

              <Button
                className="btns"
                onClick={() => {
                  this.props.dashconvert_to_booking(
                    // this.state.id,
                    this.state.order_id,
                    this.props.login.user_id,
                    this.state.advance_amount,
                    this.state.estimated_delivery_date,
                    this.state.mode
                    // this.state.category_name
                  );
                  this.setState({ booking: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"
                }}
              >
                Update
              </Button>
              </Link>
              <Button
                className="btns"
                onClick={() => {
                  this.setState({ booking: false });
                }}
                style={{
                  marginLeft: "20px",
                  // backgroundColor: "ButtonFace",
                  marginBottom: "30px",
                  marginTop: "20px",
                  color: "white"

                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Dialog>

      </div>
    )
  }
}
