import React, { Component } from "react";
import {
  Grid,
  // Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Icon,
  TextField,
  DialogActions,
  Avatar,
  Tooltip
} from "@mui/material";
// import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
import DateTimePicker from "react-datetime-picker";
import TablePagination from "@mui/material/TablePagination";

// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Navigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import NoCrashOutlinedIcon from '@mui/icons-material/NoCrashOutlined';
// import CloseIcon from "@mui/icons-material/Close";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import Dialog from "@mui/material/Dialog";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import Snackbar from "../../../../common/snackbar/components/snackbar";
import CloseIcon from '@mui/icons-material/Close';
import "../../style/order.css";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { DatePicker, Space } from 'antd';
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import es from 'date-fns/locale/en-GB';
import LogoutIcon from '@mui/icons-material/Logout';
import LG_Cont from "../../../../router/containers/Lg_Cont";



import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CircleIcon from '@mui/icons-material/Lens';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

registerLocale('en-GB', es)
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
}));





export default class color extends Component {
  constructor(props) {


    super(props);
    this.state = {
      edit: false,
      view: false,
      add: false,
      modify: false,
      type: "",
      status: "",
      imageUrl: null,
      imageAlt: null,
      name: "",
      company: "",
      phone: " ",
      email: "",
      location: "",
      source: "",
      type: "",
      status: "",
      model_id: "",
      cust_aadhar: "",
      cust_pan: "",
      cust_gst_cert: "",
      other_doc1: "",
      other_doc2: "",
      cust_gst_no: "",
      follow_up_date: new Date(),
      today: new Date(),
      page: 0,
      setPage: 0,
      rowsPerPage: 40,
      setRowsPerPage: "",
      alphone: "",
      type1: "High",
      type2: "Moderate",
      type3: "Bad",
      dealer: false

      // startDate: new Date()
    }
    // this.state = { follow_up_date: new };
    // this.dateChanged = this.dateChanged.bind(this);

    // this.handleChange = this.handleChange.bind(this);
    // this.onFormSubmit = this.onFormSubmit.bind(this);  

    this.handleChange = this.handleChange.bind(this);
    // this.onFormSubmit = this.onFormSubmit.bind(this);
  }


  handleChange(date) {
    this.setState({
      follow_up_date: date
    })
  }

  // onFormSubmit(e) {
  //   e.preventDefault();
  //   console.log(this.state.startDate)
  // }


  // handleChange(date) {
  //   this.setState({
  //     follow_up_date: date
  //   })
  // }


  // datechange(today) {
  //   // var today = new Date();
  //   var dd = today.getDate();

  //   var mm = today.getMonth() + 1;
  //   var yyyy = today.getFullYear();
  //   if (dd < 10) {
  //     dd = '0' + dd;
  //   }

  //   if (mm < 10) {
  //     mm = '0' + mm;
  //   }
  //   today = dd + '/' + mm + '/' + yyyy;
  //   this.setState({ follow_up_date: today })
  // }

  // dateChanged(newdate) {
  //   this.setState({ follow_up_date: newdate });
  // }

  //   TableDatePicker() {
  //  const [date, setDate] = useState(new Date());
  //   }

  // onFormSubmit(e) {  
  //   e.preventDefault();  
  //   console.log(this.state.startDate)  
  // }  
  componentDidMount() {
    this.props.viewAllWebSiteClient(0)
    console.log(this.props.lead.all_client_details)
    // this.props.viewAllWebSiteDealer(1)
  }




  render() {
    var i = 1;
    const {
      lead,
      snackbar,
      all_model,
      login,
      close_snack_bar
    } = this.props;
    // console.log("basillllllllllllllllllllllla ",lead.length)

    // const [startDate, setStartDate] = useState(new Date());


    // const onChange = (date, dateString) => {
    //   console.log(date, dateString);
    // };
    // const { imageUrl, imageAlt } = this.state;
    if (this.state.redirect === true) {
      return <Navigate to="/" />;
    }

    const handleChangePage = (event, newPage) => {
      // console.log(newPage)
      // this.props.viewAlltheClients(this.props.login.user_id,newPage);

      if (this.state.dealer) {
        this.props.viewAllWebSiteDealer(newPage)
      }
      else {
        this.props.viewAllWebSiteClient(newPage)
      }
      // this.props.view_all_participants(this.props.login.user_token, newPage * this.state.rowsPerPage, this.state.rowsPerPage, this.state.status, this.state.event_id, this.state.department_id,this.state.sort);
      // this.props.view_all_participants()
      this.setState({
        page: newPage,
      });
    };


    return (
      <Grid>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Box className="ordername">
            <center>
              <Button style={{ color: "white ", textAlign: "center" }}>
                Web Site Leads
              </Button>
            </center>
          </Box>
          <Box >
            <center>
            <center>
            <LG_Cont />

          </center>

            </center>
          </Box>
          <Box className="ordernames">
            <center>
              <Button style={{ color: "white ", textAlign: "center" }}>
                Total Leads:-{lead.length}
              </Button>
            </center>
          </Box>
        </Grid>


        <Grid container direction="row" justifyContent="space-between"
          style={{ paddingLeft: 48, paddingRight: 64, display: 'flex' }}>

        </Grid>
        <Grid
          container
          // container
          // direction="row"
          // alignItems="end"
          direction={"row"}
          // justifyContent="space-between"
          ml={10}
          mt={5}
          style={{}}
        >
          <Grid item xs={4}>
            <TextField
              type="date"
              // fullWidth
              size="small"
              onChange={(e) => {
                if (this.state.dealer) {
                  this.props.viewAllWebSiteDealerDate(e.target.value, 0)
                }
                else {
                  this.props.viewAllWebSiteClientDate(e.target.value, 0)
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={2}>
            <TablePagination
              rowsPerPageOptions={[40,]}
              component="div"
              count={lead.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={handleChangePage}
            />
          </Grid>
        </Grid>
        <CardContent style={{ marginLeft: "40px", marginRight: "65px" }}>
          <Tabs>
            <TabList>
              <Tab
                onClick={() => {
                  this.setState({ dealer: false })
                  this.props.viewAllWebSiteClient(0)
                }}
              >Client</Tab>
              <Tab
                onClick={() => {
                  this.setState({ dealer: true })
                  this.props.viewAllWebSiteDealer(0)
                }}
              >Dealer</Tab>
            </TabList>

            <TabPanel>
              <TableContainer component={Paper}>
                <Table
                  style={{ minWidth: 600 }}
                  size="small"
                  aria-label="a dense table"
                  ref={this.tableRef}
                >
                  <TableHead className="orderhead">
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>FollowUp Date</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Source</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="orderbody">


                    {lead.all_client_details.map((c, index) => {
                      return (

                        <TableRow
                          // key={row.name}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell> <CircleIcon style={{
                            color: c.type === 'Modrate' ? 'red' : c.type === 'Bad' ? '#e9d700' : 'darkgreen',
                            fontSize: c.type === 'Modrate' ? 12 : c.type === 'Bad' ? 12 : 12,

                          }} /> {i++}</TableCell>
                          <TableCell >{c.name}</TableCell>
                          <TableCell >{c.follow_up_date}</TableCell>
                          <TableCell >{c.phone}</TableCell>
                          <TableCell >{c.email}</TableCell>
                          <TableCell >{c.location}</TableCell>
                          <TableCell >{c.source}</TableCell>
                        </TableRow>
                      )
                    })}


                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel>
              <TableContainer component={Paper}>
                <Table
                  style={{ minWidth: 600 }}
                  size="small"
                  aria-label="a dense table"
                  ref={this.tableRef}
                >
                  <TableHead className="orderhead">
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>FollowUp Date</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Source</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="orderbody">


                    {lead.all_client_details.map((c, index) => {
                      return (

                        <TableRow
                          // key={row.name}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell> <CircleIcon style={{
                            color: c.type === 'Modrate' ? 'red' : c.type === 'Bad' ? '#e9d700' : 'darkgreen',
                            fontSize: c.type === 'Modrate' ? 12 : c.type === 'Bad' ? 12 : 12,

                          }} /> {i++}</TableCell>
                          <TableCell >{c.name}</TableCell>
                          <TableCell >{c.follow_up_date}</TableCell>
                          <TableCell >{c.phone}</TableCell>
                          <TableCell >{c.email}</TableCell>
                          <TableCell >{c.location}</TableCell>
                          <TableCell >{c.source}</TableCell>
                        </TableRow>
                      )
                    })}


                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </Tabs>

        </CardContent>

        <LoaderCon />
        <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />
      </Grid>

    )
  }
}
