import {

  
} from "./constant";
const initial_state = {


};
export default function reducer(state = initial_state, action) {
  switch (action.type) {



    default:
      return state;
  }
}
