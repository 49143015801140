import React, { Component } from "react";
import { connect } from "react-redux";
import Editcustomer from "../component/editcustomer";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  // createCustomer,
    updateCustomer,
  //   deleteCustomer,
  viewAllCustomer,
  edit_upload_profile,
  setCustomerid,
  setProfilepic,
  setCustomername,
  setCustomermobile,
  setCustomeremail,
  setCustomeraddress,
  setCustomergstno,
  setCustomeraadhar,
  setCustomerpan,
  setCustomergstcertificate,
  setCustomerother1,
  setCustomerother2
} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Editcustomer {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_customer: store.all_customer,
    snackbar: store.snackbar,
    login:store.login
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    edit_upload_profile: (_id,admin_id, name, mobile, email, address, gst_no, old_aadhar, addhar, old_pan, pan, old_gst_cert, gst_cert, old_profile_pic, profile_pic, old_other_doc1, other_doc1, old_other_doc2, other_doc2) => {
      dispatch(edit_upload_profile(_id,admin_id, name, mobile, email, address, gst_no, old_aadhar, addhar, old_pan, pan, old_gst_cert, gst_cert, old_profile_pic, profile_pic, old_other_doc1, other_doc1, old_other_doc2, other_doc2))
    },
    updateCustomer:(_id,admin_id,name, mobile, email, address, aadhar, pan, gst_cert, profile_pic,cust_gst_no,other_doc1,other_doc2) => {
      dispatch(updateCustomer(_id,admin_id, name, mobile, email, address, aadhar, pan, gst_cert, profile_pic,cust_gst_no,other_doc1,other_doc2))
    },
    // deleteCustomer: (_id) => {
    //   dispatch(deleteCustomer(_id))
    // },
    viewAllCustomer: (admin_id) => {
      dispatch(viewAllCustomer(admin_id))
    },
    setCustomerid: (_id) => {
      dispatch(setCustomerid(_id))
    },
    setProfilepic: (cust_profile_pic) => {
      dispatch(setProfilepic(cust_profile_pic))
    },
    setCustomername: (cust_name) => {
      dispatch(setCustomername(cust_name))
    },
    setCustomermobile: (cust_mobile) => {
      dispatch(setCustomermobile(cust_mobile))
    },
    setCustomeremail: (cust_email) => {
      dispatch(setCustomeremail(cust_email))
    },
    setCustomeraddress: (cust_address) => {
      dispatch(setCustomeraddress(cust_address))
    },
    setCustomergstno: (cust_gst_no) => {
      dispatch(setCustomergstno(cust_gst_no))
    },
    setCustomeraadhar: (cust_aadhar) => {
      dispatch(setCustomeraadhar(cust_aadhar))
    },
    setCustomerpan: (cust_pan) => {
      dispatch(setCustomerpan(cust_pan))
    },
    setCustomergstcertificate: (customer_gstcertificate) => {
      dispatch(setCustomergstcertificate(customer_gstcertificate))
    },

    setCustomerother1: (customer_other1) => {
      dispatch(setCustomerother1(customer_other1))
    },
    setCustomerother2: (customer_other2) => {
      dispatch(setCustomerother2(customer_other2))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },


    // add_upload_profile: (name, mobile, email, address, aadhar, pan, gst_cert, profile_pic) =>{
    //     dispatch(add_upload_profile(name, mobile, email, address, aadhar, pan, gst_cert, profile_pic))
    // }

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);