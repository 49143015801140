import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Typography,
    Icon, DialogActions,
    DialogTitle,
    Tooltip

} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import {

    Navigate,
} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import "../../style/color.css"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Snackbar from "../../../../common/snackbar/components/snackbar";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default class Policy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            color_code: "",
            color_name: ""

        }
    }
    componentDidMount() {
        this.props.viewAllPolicy();
    }


    render() {
        // const {
        //     all_color,
        //     snackbar,
        //     close_snack_bar
        // } = this.props;
        // const { imageUrl, imageAlt } = this.state;
        // if (this.state.redirect === true) {
        //     return <Navigate to="/" />;
        // }
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='colorname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Policy</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "23%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='coloradd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                })
                            }}>Add Policy</Button>
                        </center>
                    </Box>

                </Grid>
                <Box
                    marginLeft={'2.5%'}
                // marginTop={"%"}
                // marginRight={"5%"}
                >
                    <CardContent style={{ marginRight: "25%" }}>
                        <TableContainer component={Paper}>
                            <Table style={{ minWidth: 200 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                <TableHead className="colorhead">
                                    <TableRow >
                                        <TableCell>Name</TableCell>
                                        <TableCell>PDF Name</TableCell>

                                        <TableCell justifyContent={'center'} style={{ marginLeft: "120px", width: "500px" }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="colorbody">
                                    {Array.isArray(this.props.policy?.all_policy) && this.props?.policy.all_policy.map((c) => {
                                        return (
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >

                                                <TableCell >tyu</TableCell>
                                                <TableCell >tryt</TableCell>

                                                <TableCell style={{ marginLeft: "-50px" }}>
                      
                                                    <Tooltip title="Edit">


                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    edit: true,
                                                                    // id: category._id,
                                                                    // category_name: category.category_name,
                                                                });
                                                            }}
                                                        >
                                                            <EditOutlinedIcon style={{ color: "green" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">

                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    delete: true,
                                                                    id: c._id,
                                                                });
                                                            }}
                                                        >
                                                            <DeleteOutlineOutlinedIcon style={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>

                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </CardContent>














                </Box>
                {/* <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                /> */}



                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                    > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained"
                            onClick={() => { this.props.deletePolicy(this.state.id); this.setState({ delete: false }) }}
                        >

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.add}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    // style={{wi}}
                    style={
                        {
                            // top: "50%",
                            // left: "10%",
                            // right: "auto",
                            // bottom: "auto",
                            // marginRight: "-50%",
                            // transform: "translate(-50%, -50%)",
                            // width: "1000px",
                            // marginLeft: "500px",
                            // paddingTop: "40px",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            // borderRadius: "0px",
                            height: "700px",
                            // zIndex: "200",
                            // backgroundColor: "#fff"
                        }}
                >
                    <Grid style={{ backgroundColor: "#fff", height: "220px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>
                              
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Enter Name"
                                    // InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ policy_name: e.target.value }) }}
                                />

                            </div>

                            

                            <Grid style={{ display: 'flex', flexDirection: 'column', marginTop: "20px", gap: '20px', alignItems: 'center' }}>

                                <center>
                                  
                                    {this.state.policy_url === "" &&
                                        <Button sx={{ width: "280px" }} component="label" variant="text" startIcon={<AddIcon />}>
                                            Upload PDF
                                            <VisuallyHiddenInput
                                                accept="pdf/mp4,pdf/x-m4v,pdf/*"
                                                onChange={(e) => { this.setState({ policy_url: e.target.files[0] }) }}
                                                type="file" />
                                        </Button>
                                    }
                                </center>
                            </Grid>


                            <center>
                                <Button
                                    className='btns'

                                    disabled={this.state.color_name === "" && this.state.color_code === ""}
                                    onClick={() => {
                                        this.props.createPolicy(this.state.pdf_name, this.state.policy_url);
                                        this.setState({ add: false });
                                    }}
                           
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                                <Button className='btns' onClick={() => {
                                    this.setState({ add: false });
                                }} style={{ marginLeft: "20px", color: "white", marginBottom: "30px", marginTop: "20px" }}>Close</Button>

                            </center>
                        </Grid>

                    </Grid>

                </Dialog>


                <Dialog
                    open={this.state.edit}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                  
                    style={
                        {
                          
                            height: "700px",
                           
                        }}
                >
                    <Grid style={{ backgroundColor: "#fff", height: "220px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>
                               
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Enter Name"
                                    // InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    value={this.state.policy_name}
                                    onChange={(e) => { this.setState({ policy_name: e.target.value }) }}
                                />

                            </div>

                          

                            <Grid style={{ display: 'flex', flexDirection: 'column', marginTop: "20px", gap: '20px', alignItems: 'center' }}>

                                <center>
                                    
                                    {this.state.policy_url === "" &&
                                        <Button sx={{ width: "280px" }} component="label" variant="text" startIcon={<AddIcon />}>
                                            Upload PDF
                                            <VisuallyHiddenInput
                                                value={this.state.policy_url}
                                                onChange={(e) => { this.setState({ policy_url: e.target.value }) }}

                                                type="file" />
                                        </Button>
                                    }
                                </center>
                            </Grid>


                            <center>
                                <Button
                                    className='btns'

                                    disabled={this.state.color_name === "" && this.state.color_code === ""}
                                    onClick={() => {
                                        this.props.UpdatePolicy(this.state.pdf_name, this.state.policy_url);
                                        this.setState({ edit: false });
                                    }}
                                  
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Save</Button>
                                <Button className='btns' onClick={() => {
                                    this.setState({ edit: false });
                                }} style={{ marginLeft: "20px", color: "white", marginBottom: "30px", marginTop: "20px" }}>Close</Button>

                            </center>
                        </Grid>

                    </Grid>

                </Dialog>



            </Grid>
        )
    }
}
