import React, { Component } from "react";
import { connect } from "react-redux";
import Customer from "../component/addcustomer";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
// import {
//   onLogout
// } from "../../../auth/actions";
import {
  // createCustomer,
  // updateCustomer,
  // deleteCustomer,
  // viewAllCustomer,
  add_upload_profile

} from "../action";
export class Controller extends Component {
  render() {
    return (
      <Customer {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_customer:store.all_customer,
    snackbar: store.snackbar,
    login:store.login
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    add_upload_profile: ( admin_id, name, mobile, email, address, aadhar, pan, gst, profile_pic, cust_gst_no, other_doc1, other_doc2) => {
      dispatch(add_upload_profile(admin_id, name, mobile, email, address, aadhar, pan, gst, profile_pic, cust_gst_no, other_doc1, other_doc2))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    // updateCustomer:(_id,name, mobile, email, address, aadhar, pan, gst_cert, profile_pic,cust_gst_no,other_doc1,other_doc2) => {
    //   dispatch(updateCustomer(_id, name, mobile, email, address, aadhar, pan, gst_cert, profile_pic,cust_gst_no,other_doc1,other_doc2))
    // },
    // deleteCustomer: (_id) => {
    //   dispatch(deleteCustomer(_id))
    // },
    // viewAllCustomer: () => {
    //   dispatch(viewAllCustomer())
    // },
    // add_upload_profile: (name, mobile, email, address, aadhar, pan, gst_cert, profile_pic) =>{
    //     dispatch(add_upload_profile(name, mobile, email, address, aadhar, pan, gst_cert, profile_pic))
    // }
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);