export const  SET_VARIENT = "SET_VARIENT";
export const  SET_MODEL_VARIENT = "SET_MODEL_VARIENT";
export const  SET_VARIENT_DETAILS= "SET_VARIENT_DETAILS";
export const  SET_VARIANTID= "SET_VARIANTID";
export const  SET_CATEGORYID= "SET_CATEGORYID";
export const  SET_MODELID= "SET_MODELID";
export const  SET_VARIANTNAME= "SET_VARIANTNAME";
export const  SET_BRAKING= "SET_BRAKING";
export const  SET_MODELPIC= "SET_MODELPIC";
// export const  SET_SPEED= "SET_SPEED";
export const  SET_RANGE= "SET_RANGE";
export const  SET_MOTOR= "SET_MOTOR";
export const  SET_WARRANTY= "SET_WARRANTY";
export const  SET_CAPACITY= "SET_CAPACITY";
export const  SET_CHARGETIME= "SET_CHARGETIME";
// export const  SET_BATTERYTYPE= "SET_BATTERYTYPE";
// export const  SET_BATTERYVOLT= "SET_BATTERYVOLT";
export const  SET_SYSTEM= "SET_SYSTEM";
export const  SET_LENGTH= "SET_LENGTH";
export const  SET_WIDTH= "SET_WIDTH";
export const  SET_REARAXLE= "SET_REARAXLE";
export const  SET_EXSHOWROOMPRICE= "SET_EXSHOWROOMPRICE";
export const  SET_INSURANCEPRICE= "SET_INSURANCEPRICE";
export const  SET_REGISTRATIONPRICE= "SET_REGISTRATIONPRICE";
export const  SET_MODEL= "SET_MODEL";
export const  SET_CATEGORY= "SET_CATEGORY";
export const  SET_FILTERED_VALUE= "SET_FILTERED_VALUE";


// export const  SET_MODALNAME= "SET_MODALNAME";

// export const  SET_EDITVARIANT= "SET_EDITVARIANT";
