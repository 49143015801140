import {
    SET_TASK,
    SET_TASK_BY_ID,
    SET_ALL_USERS,
    SET_TASK_GIVEN_BY,
    SET_TASK_GIVEN_TO
} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
import { set_snack_bar } from "../../../common/snackbar/action";
// import { set_snack_bar } from "../../common/snackbar/action";
// import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import 'firebase/storage';
// import firebase from 'firebase/app'




export function createtask(given_by, given_to, date, desc, page_number, rowper_page) {
    return (dispatch) => {
        console.log("kkkkk", given_by, given_to, date, desc, page_number, rowper_page)
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_task", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                given_by: given_by,
                given_to: given_to,
                date: date,
                desc: desc,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("acccccccccccccccd", responseJson.result);
                if (responseJson.status) {
                    dispatch(viewAlltaskGivenTo(given_by, page_number, rowper_page))
                    dispatch(viewAlltaskGivenBy(given_by, page_number, rowper_page))
                    dispatch(set_snack_bar(responseJson.message));


                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {

                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAlltask(assigned_id, page_number, rowper_page) {
    return (dispatch) => {
        console.log("view", assigned_id, page_number, rowper_page)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                assigned_id: assigned_id,
                page_number: page_number,
                rowper_page: rowper_page

            })

        })

            .then((response) => response.json())
            .then((responseJson) => {
                console.log("AAAAA", responseJson.result);
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_TASK, payload: { data: responseJson.result, count: responseJson.length } });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_TASK, payload: { data: responseJson.result, count: responseJson.length } })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAlltaskGivenBy(given_by, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_by_givenBy", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                given_by: given_by,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })

            .then((response) => response.json())
            .then((responseJson) => {
                console.log("givenby", responseJson);
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_TASK_GIVEN_BY, payload: { data: responseJson.result, count: responseJson.length } });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_TASK_GIVEN_BY, payload: { data: [], count: 0 } })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAlltaskGivenTo(given_to, page_number, rowper_page) {
    return (dispatch) => {
        console.log("toooooooo", given_to);
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_task_by_givenTo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                given_to: given_to,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })

            .then((response) => response.json())
            .then((responseJson) => {
                console.log("giventoooooooo", responseJson);
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    console.log("test Response",responseJson.result )
                    dispatch({ type: SET_TASK_GIVEN_TO, payload: { data: responseJson.result, count: responseJson.length } });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_TASK_GIVEN_TO, payload: { data: [], count: 0 } })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function viewAllUser() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_user", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }

        })

            .then((response) => response.json())
            .then((responseJson) => {
                console.log("AAAAA", responseJson.result);
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_ALL_USERS, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ALL_USERS, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAlltask_by_type(type) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_user_by_type", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

            body: JSON.stringify({
                type: type,

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson.result);
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_TASK_BY_ID, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_TASK_BY_ID, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function updateTaskprogress(id, process, status) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_tl", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                task_id: id,
                process: process,
                status: status,

            })
        })

            .then((response) => response.json())

            .then((responseJson) => {
                // console.log(responseJson)
                if (responseJson.status) {
                    dispatch(viewAlltask());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_task_status(id,  status, given_by, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_task_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                task_id: id,
                status: status,

            })
        })

            .then((response) => response.json())

            .then((responseJson) => {
                // console.log(responseJson)
                if (responseJson.status) {
                    dispatch(viewAlltaskGivenTo(given_by, page_number, rowper_page))
                    dispatch(viewAlltaskGivenBy(given_by, page_number, rowper_page))
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function update_task_response(id, response, given_by, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_task_response", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                task_id: id,
                response: response,

            })
        })

            .then((response) => response.json())

            .then((responseJson) => {
                // console.log(responseJson)
                if (responseJson.status) {
                    dispatch(viewAlltaskGivenTo(given_by, page_number, rowper_page))
                    dispatch(viewAlltaskGivenBy(given_by, page_number, rowper_page))
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch(viewAlltaskGivenTo(given_by, page_number, rowper_page))
                    dispatch(viewAlltaskGivenBy(given_by, page_number, rowper_page))
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}