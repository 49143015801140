import React, { Component } from 'react'
import {
    Grid,
    // Card,
    Typography,
    Box,
    IconButton,
    Stack,
    Avatar,
    Input
} from "@mui/material";
// import React from "react";

// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import "../../style/variant.css"
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import Snackbar from "../../../../common/snackbar/components/snackbar";




export default class addvariant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            model_name: ""

        }
    }
    componentDidMount() {
        // this.props.viewVarient();
        this.props.viewAllCategory();
        // this.props.viewModel();
        // this.props.viewCategortModel(this.props.all_varient.category_id);
    }

    render() {
        const {
            all_varient,
            all_category,
            all_model,
            snackbar,
            login,
            close_snack_bar
        } = this.props;
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='makeorder'>
                        <center style={{ display: "flex" }}>
                            <Link to="/variant" style={{ textDecoration: "none" }}>
                                <IconButton
                                    style={{ marginLeft: "18px" }}

                                >
                                    <ArrowBackIcon style={{ color: "white" }} />
                                </IconButton>
                            </Link>


                            <Typography style={{ color: "white ", marginLeft: "40px", fontSize: 16, marginTop: "8px" }}>UPDATE VARIANT</Typography>
                        </center>
                    </Box>


                </Grid>
                <Grid style={{ marginLeft: "50px", width: "300px" }}>
                    <Typography style={{ color: "black ", fontSize: 18, marginTop: "8px", fontWeight: "500", marginLeft: "30px" }}>MODEL PICTURE</Typography>


                    <Stack
                        spacing={1}
                        style={{
                            textDecoration: "none",
                            // display: "inline-block",
                            padding: "0 30px",
                            marginTop: "10px",
                        }}
                        className="imagemedia"
                    >
                        <Avatar
                            alt="Profile"
                            src={all_varient.model_pic}
                            style={{ height: "114px", width: "114px" }}
                            variant={"square"}
                        />
                        {/* {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt={imageAlt}
                                            className="displayed-image"
                                        />
                                    )} */}
                        <Input
                            type="file"
                            // onChange={(e)=>this.handleFileChange(e)}
                            onChange={(e) => { this.setState({ model_pic: e.target.files[0] }) }}
                        // onChange={(e) => { this.props.setModelpic(e.target.files[0] ) }}
                        />
                    </Stack>
                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>CATEGORY NAME</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "400px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            // value={this.state.eventName}
                            value={all_varient.category_id}
                            // defaultValue={this.state.category_name}
                            // onChange={(e) => { this.setState({ category_id: e.target.value }) }}
                            onChange={(e) => {
                                this.props.setCategoryid(e.target.value);
                                this.props.viewCategortModel(e.target.value);
                            }}
                            select

                        // onChange={handleChangeDepartment}
                        >



                            <option value="Select Model" >

                                {/* SELECT CUSTOMER */}

                            </option>


                            {all_category.all_category.map((e) => (

                                <>

                                    <option value={e._id}>{e.category_name}</option>
                                </>

                            ))}

                            {/* {departments.all_departments.map((row) => (

                                            <>

                                                <option value={row.department_id}>{row.department_name}</option>
                                            </>

                                        ))} */}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>MODEL NAME</label>
                        <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "400px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            value={all_varient.model_id}
                            select

                            onChange={(e) => {
                                this.props.setModelid(e.target.value );
                            }}

                        // onChange={handleChangeDepartment}
                        >



                            <option value="Select Model" >

                                {/* SELECT CUSTOMER */}

                            </option>


                            {all_model.all_category_model.map((e) => (

                                <>
                                    <option value={e._id}>{e.model_name}</option>
                                </>

                            ))}

                            {/* {departments.all_departments.map((row) => (

                                            <>

                                                <option value={row.department_id}>{row.department_name}</option>
                                            </>

                                        ))} */}
                        </select>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>





                </Grid>



                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> VARIANT NAME</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.variant_name}
                            onChange={(event1) => { this.props.setVariantname(event1.target.value) }}
                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> BRAKING</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.braking}
                            onChange={(event1) => { this.props.setBraking(event1.target.value) }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> RANGE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.range}
                            onChange={(event1) => { this.props.setRange(event1.target.value) }}
                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> MOTOR</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.motor}
                            onChange={(event1) => { this.props.setMotor(event1.target.value) }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> WARRANTY</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.warranty}
                            onChange={(event1) => { this.props.setWarranty(event1.target.value) }}

                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> CAPACITY</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.capacity}
                            onChange={(event1) => { this.props.setCapacity(event1.target.value) }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> CHARGING TIME</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.charging_time}
                            onChange={(event1) => { this.props.setChargeTime(event1.target.value) }}

                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    {/* <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> BATTERY TYPE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            borderRadius:8
                        }}
                            value={all_varient.battery_type}
                            onChange={(event1) => { this.props.setBatteryType(event1.target.value) }}
                        ></input> */}

                    {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    {/* </Grid> */}

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> REAR AXLE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8


                        }}
                            value={all_varient.rear_axle}
                            onChange={(event1) => { this.props.setRearaxle(event1.target.value) }}
                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> SYSTEM</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.system}
                            onChange={(event1) => { this.props.setSystem(event1.target.value) }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}>  OVERALL LENGTH</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.overall_length}
                            onChange={(event1) => { this.props.setLength(event1.target.value) }}
                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> WIDTH</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.width}
                            onChange={(event1) => { this.props.setWidth(event1.target.value) }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> EX-SHOWROOM PRICE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.exshowroom_price}
                            onChange={(event1) => { this.props.setExshowroomprice(event1.target.value) }}
                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: -55, }}> REGISTRATION PRICE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            marginLeft: -55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.registration_price}
                            onChange={(event1) => { this.props.setRegistrationprice(event1.target.value) }}
                        ></input>

                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 85, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}>  INSURANCE PRICE</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "185px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            value={all_varient.insurance_price}
                            onChange={(event1) => { this.props.setInsuranceprice(event1.target.value) }}
                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                    </Grid>


                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 25, display: 'flex', marginBottom: 25 }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Link to="/variant" style={{ textDecoration: "none", marginLeft: "30%" }}>
                    <Typography marginLeft={0} sx={{ borderRadius: "15px", marginTop: "50px" }}>
                        <Button
                        // style={{  marginLeft: "450%" }}
                            // disabled={this.state.category_id === "" && this.state.model_id === "" && this.state.variant_name === "" && this.state.model_pic === "" && this.state.braking === "" && this.state.range === "" && this.state.motor === "" && this.state.warranty === "" && this.state.capacity === "" && this.state.charging_time === "" && this.state.system === "" && this.state.overall_length === "" && this.state.width === "" && this.state.rear_axle === "" && this.state.exshowroom_price === "" && this.state.insurance_price === "" && this.state.registration_price === ""}

                            onClick={() => {
                                this.props.update_model_picture(all_varient.varient_id, all_varient.category_id, all_varient.model_id, all_varient.variant_name, all_varient.model_pic, this.state.model_pic, all_varient.braking, all_varient.range, all_varient.motor, all_varient.warranty, all_varient.capacity, all_varient.charging_time, all_varient.system, all_varient.overall_length, all_varient.width, all_varient.rear_axle, all_varient.exshowroom_price, all_varient.insurance_price, all_varient.registration_price);

                            }}
                            className='button' variant='contained' sx={{ borderRadius: "20px" }}>UPDATE</Button></Typography>
                    </Link>
                    <Link to="/variant" style={{ textDecoration: "none" }}>


                        <Typography marginLeft={10} sx={{ borderRadius: "15px", marginTop: "50px" }}>
                            <Button style={{  marginLeft: "0%" }} className='button' variant='contained' sx={{ borderRadius: "20px" }}>CANCEL </Button></Typography>
                    </Link>

                </Grid>
                <LoaderCon />
                <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />

            </Grid>
        )
    }
}
