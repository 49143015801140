import {
  SET_SERVICE,
  SET_DELIVERED_VECHICLE,
  SET_SERVICE_ALLOTTED,
  SET_EDIT_SERVICE,
  SEARCH_SERVICE,
  BOOKING_SERVICE,
  COMPLETED_SERVICE
} from "./constant";
const initial_state = {
  all_service: [],
  delivered_vechicle: [],
  service_allotted: [],
  length: "",
  edit_service: {},
  serach_service: [],
  booking_service: 0,
  completed_service: 0,
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_SERVICE:
      console.log("reser", action.payload.data)
      return state = { ...state, all_service: action.payload.data, length: action.payload.count, serach_service: action.payload.data };
    case SET_DELIVERED_VECHICLE:
      return state = { ...state, delivered_vechicle: action.payload };
    case SET_SERVICE_ALLOTTED:
      return state = { ...state, service_allotted: action.payload };
    case SET_EDIT_SERVICE:
      return state = { ...state, edit_service: action.payload };
    case SEARCH_SERVICE:
      return state = { ...state, serach_service: action.payload };
    case BOOKING_SERVICE:
      console.log("length", action.payload)
      return state = { ...state, booking_service: action.payload };
    case COMPLETED_SERVICE:
      console.log("comlength", action.payload)
      return state = { ...state, completed_service: action.payload };
    default:
      return state;
  }
}
