import {
    Grid, Card, CardContent, Typography, Box, IconButton, Icon, TextField, DialogContent,
    DialogActions, Tooltip
} from "@mui/material";
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Close } from '@material-ui/icons';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Navigate } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import "../../style/order.css"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import NativeSelect from '@mui/material/NativeSelect';
import HighlightOffOutlinedIconss from '@mui/icons-material/HighlightOffOutlined';
import { Link } from "react-router-dom";
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { Row } from "rsuite";
import Snackbar from "../../../../common/snackbar/components/snackbar";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));


export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            booking: false,
            payment: false,
            view: false,
            quotation: false,
            preview: false,
            quotation_pdf: "",
            booking_pdf: "",
            order_color: "",
            battery: "",
            page: 0,
            setPage: 0,
            rowsPerPage: 20,
            setRowsPerPage: "",
            status: "",
            order_status: ""
            // imageUrl: null,
            // imageAlt: null,

        }
    }
    componentDidMount() {
        this.props.viewAllOrder(this.props.login.user_id, this.state.status, this.state.order_status, this.state.page);
        this.props.viewModel();
        this.props.viewVarient();
        this.props.viewAllCategory();
        this.props.viewAllCustomer(this.props.login.user_id);
        this.props.viewAllColor();
        // this.props.viewCategortModel();
    }
    render() {
        const {
            all_customer,
            all_order,
            all_category,
            all_model,
            all_varient,
            all_color,
            snackbar,
            login,
            close_snack_bar
        } = this.props;
        const handleChangePage = (event, newPage) => {
            this.props.viewAllOrder(this.props.login.user_id, this.state.status, this.state.order_status, newPage);
            this.setState({
                page: newPage,
            });
        };
        // console.log("jdhhjghjds",all_order.filtered_model)
        if (this.state.redirect === true) {
            return <Navigate to="/" />;
        }



        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='ordername'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Order</Button>
                        </center>
                    </Box>

                    <center style={{ borderRadius: 0 }}>
                        <Link to="/addorder" style={{ textDecoration: "none", }}>

                            <Button className='orderadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    // add: true,
                                })
                            }}>Add Order</Button>
                        </Link>
                    </center>

                </Grid>
                <Grid container direction="row" justifyContent="space-between"
                    style={{ paddingLeft: 48, paddingRight: 64 }}>
                    <Grid mt={5}>
                        {/* <Search
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 52,
                                width: "300px",
                                border: "2px solid #000000"
                            }}
                            direction="row"
                        >
                            <SearchIconWrapper>
                                <SearchIcon style={{ color: "black" }} />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search By Model"
                                color="black"
                                inputProps={{ "aria-label": "search" }}
                                style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                            // onChange={handleChange}
                            onChange={(e)=>this.props.searchorder(all_order.all_order,e)}

                            />
                        </Search> */}
                        <TablePagination
                            rowsPerPageOptions={[20,]}
                            component="div"
                            count={all_order.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={handleChangePage}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                    {/* <Grid
                // container
                // direction="row"
                alignItems="end"
                // justifyContent="space-between"
                mr={10}
                style={{}}
              >
       
                </Grid> */}

                    <Grid class="register-form-custom-select" style={{ marginTop: 5, }} >
                        {/* <select
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 48,
                                width: "200px",
                                border: "2px solid #000000"
                            }}
                            name=""
                            // value={this.state.eventName}
                            select
                        // onChange={handleChangeDepartment}
                        >

                            <option value="Select Model">Select Model</option>
                            {departments.all_departments.map((row) => (

                                            <>

                                                <option value={row.department_id}>{row.department_name}</option>
                                            </>

                                        ))}
                        </select> */}


                        {/* <select
                            // style={{ width: "200px", marginLeft: "10px" }}
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 48,
                                width: "200px",
                                border: "2px solid #000000"
                            }}
                            name="participant type"
                        // value={this.state.eventName}
                        // onChange={handleChangeEvent}
                        >

                            <option value="">Select variant</option>
                            {events.all_events.map((row) => (

                                            <>
                                                <option value={row.event_id}>{row.event_name}</option>
                                            </>

                                        ))}
                        </select> */}

                        <select
                            // style={{ width: "200px", marginLeft: "10px" }}
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 18,
                                marginTop: 48,
                                width: "200px",
                                border: "2px solid #000000"
                            }}
                            name="participant type"
                            // value={this.state.eventName}
                            // onChange={handleChangeEvent}
                            onChange={(e) => {
                                this.props.viewAllOrder(this.props.login.user_id, e.target.value, this.state.order_status, this.state.page);
                            }}
                        >

                            <option value="">Select Type</option>
                            {/* {events.all_events.map((row) => (
                                <>
                                <option value={row.event_id}>{row.event_name}</option>
                                
                                </>
                            ))} */}
                            <option value="booking" >Booking</option>
                            <option value="quotation">Quotation</option>
                        </select>

                        <select
                            // style={{ width: "200px", marginLeft: "10px" }}
                            style={{
                                backgroundColor: "#DCE4E5",
                                height: 32,
                                marginRight: 19,
                                marginTop: 48,
                                width: "200px",
                                border: "2px solid #000000"
                            }}
                            name="participant type"
                            // value={this.state.eventName}
                            // onChange={handleChangeEvent}
                            onChange={(e) => {
                                this.props.viewAllOrder(this.props.login.user_id, this.state.status, e.target.value, this.state.page);
                            }}
                        >

                            <option value="">Select Status</option>
                            <option value="true" >Ongoing</option>
                            <option value="false">Completed</option>
                            {/* <option  value="cancelled">Cancelled</option> */}
                            {/* {events.all_events.map((row) => (
                                
                                <>
                                <option value={row.event_id}>{row.event_name}</option>
                                </>
                                
                            ))} */}
                        </select>

                    </Grid>
                </Grid>


                <CardContent style={{ marginLeft: "40px", marginRight: "65px" }}>
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: 600 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                            <TableHead className="orderhead">
                                <TableRow >
                                    <TableCell>Customer Name</TableCell>
                                    <TableCell>Model</TableCell>
                                    {/* <TableCell>Variant</TableCell> */}
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell style={{ marginLeft: "-80px" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="orderbody">
                                {all_order.all_order.map((allorder) => {

                                    return (
                                        <TableRow
                                            // key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell>{allorder.customer}</TableCell>
                                            {/* <TableCell>{allorder.category}</TableCell> */}
                                            <TableCell>{allorder.model}</TableCell>
                                            {/* <TableCell>{allorder.variant}</TableCell> */}
                                            <TableCell>{allorder.quanty}</TableCell>
                                            <TableCell>{allorder.status}</TableCell>
                                            <TableCell  >
                                                {allorder.status === "quotation" &&
                                                    <Tooltip title="Convert to Booking">
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    booking: true,
                                                                    id: allorder._id,
                                                                    advance_amount: allorder.advance_amount,
                                                                    estimated_delivery_date: allorder.estimated_delivery_date,
                                                                    mode: allorder.mode,

                                                                })
                                                            }}
                                                            style={{ marginLeft: "-49px" }}
                                                        >
                                                            <Icon style={{ color: "yellowgreen" }}>refresh</Icon>


                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {/* {allorder.status !== "quotation" &&
                                                  <Tooltip title="Quotation">
                                                     <IconButton color="primary"
                                                    onClick={() => {
                                                        this.setState({
                                                            quotation: true,
                                                        })
                                                    }}
                                                // style={{ marginLeft: "-49px" }}
                                                >
                                                    <AssignmentOutlinedIcon style={{ color: 'black', fontWeight: "bolder" }} />


                                                </IconButton>
                                                 </Tooltip>
                                                } */}
                                                <Tooltip title="View">
                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                view: true,
                                                                id: allorder._id,
                                                                customer: allorder.customer,
                                                                model_id: allorder.model_id,
                                                                category: allorder.category,
                                                                variant: allorder.variant,
                                                                quanty: allorder.quanty,
                                                                order_color: allorder.color,
                                                                battery: allorder.battery,
                                                                will_registration: allorder.will_registration,
                                                                applied: allorder.applied,
                                                                received: allorder.received,
                                                                length: allorder.length,
                                                                width: allorder.width,
                                                                height: allorder.height,
                                                                insurance_fee: allorder.insurance_fee,
                                                                registration_fee: allorder.registration_fee,
                                                                transportation_cost: allorder.transportation_cost,
                                                                ex_shoeroom_price: allorder.ex_shoeroom_price,
                                                                booking_date: allorder.booking_date,
                                                                on_road_price: allorder.on_road_price,
                                                                total_price: allorder.total_price,
                                                                estimated_delivery_date: allorder.estimated_delivery_date,
                                                                delivery_date: allorder.delivery_date,
                                                                order_status: allorder.order_status,
                                                                comment: allorder.comment


                                                            });
                                                        }}
                                                    >
                                                        <RemoveRedEyeOutlinedIcon style={{ color: 'blue' }} />

                                                    </IconButton>
                                                </Tooltip>
                                                {/* <Tooltip title="Edit">
                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                edit: true,
                                                                id: allorder._id,
                                                                customer: allorder.customer,
                                                                model_id: allorder.model_id,
                                                                category_id: allorder.category_id,
                                                                variant_id: allorder.variant_id,
                                                                quanty: allorder.quanty,
                                                                color: allorder.color,
                                                                will_registration: allorder.will_registration,
                                                                applied: allorder.applied,
                                                                received: allorder.received,
                                                                length: allorder.length,
                                                                width: allorder.width,
                                                                height: allorder.height,
                                                                ex_shoeroom_price:all_order.ex_shoeroom_price,
                                                                insurance_fee: allorder.insurance_fee,
                                                                registration: allorder.registration,
                                                                transportation: allorder.transportation,
                                                                booking_date: allorder.booking_date,
                                                                estimated_delivery_date: allorder.estimated_delivery_date,
                                                                delivery_date: allorder.delivery_date,
                                                                order_status: allorder.order_status,
                                                                comment: allorder.comment
                                                            });

                                                        }}
                                                    >
                                                        <EditOutlinedIcon style={{ color: 'green' }} />

                                                    </IconButton>
                                                </Tooltip> */}
                                                {/* <Tooltip title="Cancel">
                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                delete: true,
                                                                id: allorder._id,
                                                            })

                                                        }}
                                                    >
                                                        <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />

                                                    </IconButton>
                                                </Tooltip> */}
                                                {allorder.status !== "quotation" && <Tooltip title="payment">
                                                    <IconButton
                                                        onClick={() => {
                                                            this.props.viewPaymentSummary(allorder._id);
                                                            this.setState({
                                                                payment: true,
                                                                id: allorder._id,
                                                            })

                                                        }}
                                                    >
                                                        <CurrencyRupeeIcon style={{ color: '#FF33DD' }} />


                                                    </IconButton>
                                                </Tooltip>}
                                                <Tooltip title="Preview">
                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                preview: true,
                                                                id: allorder._id,
                                                                customer: allorder.customer,
                                                                model: allorder.model,
                                                                variant: allorder.variant,
                                                                battery: allorder.battery,
                                                                showroom_price: allorder.ex_shoeroom_price,
                                                                insurance_fee: allorder.insurance_fee,
                                                                on_road_price: allorder.on_road_price,
                                                                total_price: allorder.total_price,
                                                                quotation_pdf: allorder.quotation_pdf,
                                                                booking_pdf: allorder.booking_pdf
                                                            })

                                                        }}
                                                    >
                                                        <PreviewOutlinedIcon style={{ color: 'green' }} />

                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>

                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </CardContent>






                <Dialog
                    open={this.state.booking}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid
                        style={{
                            backgroundColor: "#fff",
                            height: "300px",
                            minWidth: "323px",
                            scrollBehavior: "smooth",
                            overflow: "revert-layer",
                            alignSelf: "center",
                            overflowX: "hidden",
                            flexDirection: "row",
                            display: "flex",
                            overflowY: "hidden",
                        }}
                    >
                        <Grid>
                            <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    type={"date"}
                                    required
                                    id="outlined-required"
                                    label="Estimate delivery Date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    //   value={this.state.category_name}
                                    onChange={(e) => {
                                        this.setState({ estimated_delivery_date: e.target.value });
                                    }}
                                />
                            </div>
                            <div
                                class="input-group mb-3"
                                style={{ marginLeft: "20px", marginTop: "20px" }}
                            >
                                <TextField
                                    // type={"date"}
                                    required
                                    id="outlined-required"
                                    label="Advance Amount"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    //   value={this.state.category_name}
                                    onChange={(e) => {
                                        this.setState({ advance_amount: e.target.value });
                                    }}
                                />
                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Mode "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // defaultValue={this.state.model_id}
                                    onChange={(e) => { this.setState({ mode: e.target.value }) }}
                                >
                                    {/* {all_model.all_model.map((e) => ( */}
                                    <MenuItem value={"cash"} >
                                        {/* {e.model_name} */}
                                        Cash
                                    </MenuItem>
                                    <MenuItem value={"cheque"}>
                                        {/* {e.model_name} */}
                                        Cheque
                                    </MenuItem>
                                    <MenuItem value={"onlinepay"}>
                                        {/* {e.model_name} */}
                                        Online Pay
                                    </MenuItem>
                                    {/* ))} */}
                                </TextField>

                            </div>
                            <Link to="/bookings" style={{ textDecoration: "none", marginLeft: "0%" }}>

                                <Button
                                    className="btns"
                                    onClick={() => {
                                        this.props.convertBooking(
                                            // this.state.id,
                                            this.state.id,
                                            this.props.login.user_id,
                                            this.state.advance_amount,
                                            this.state.estimated_delivery_date,
                                            this.state.mode
                                            // this.state.category_name
                                        );
                                        this.setState({ booking: false });
                                    }}
                                    style={{
                                        marginLeft: "20px",
                                        // backgroundColor: "ButtonFace",
                                        marginBottom: "30px",
                                        marginTop: "20px",
                                        color: "white"
                                    }}
                                >
                                    Update
                                </Button>
                            </Link>
                            <Button
                                className="btns"
                                onClick={() => {
                                    this.setState({ booking: false });
                                }}
                                style={{
                                    marginLeft: "20px",
                                    // backgroundColor: "ButtonFace",
                                    marginBottom: "30px",
                                    marginTop: "20px",
                                    color: "white"

                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>


                <Dialog
                    open={this.state.view}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // style={{ marginLeft: "0%", width: "100%" }}
                >
                    <Grid className="view">


                        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box className='details'>
                                <center>

                                    <Button style={{ color: "white ", textAlign: 'center', marginLeft: "0px", fontSize: 16 }}>  Orders Details</Button>
                                </center>




                            </Box>
                            <IconButton
                                // color=""
                                style={{ marginLeft: "0%", marginTop: "-0%" }}
                                onClick={() => {
                                    this.setState({
                                        view: false,
                                        // _id: c._id,
                                        // color_name: c.color_name,
                                        // color_code: c.color_code,
                                    })
                                }}
                            // size={18}
                            >
                                <CloseIcon style={{ color: 'black', fontSize: 48 }}
                                />
                            </IconButton>

                            {/* </Grid> */}


                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 250, marginTop: "30px", marginLeft: "50px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Category:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, width: 300 }}>&nbsp;&nbsp;{this.state.category}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "40px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Color:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;{this.state.order_color}</Typography>
                            </Grid>
                            {/* <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Color:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;{this.state.color_id}</Typography>
                            </Grid> */}
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 250, marginTop: "30px", marginLeft: "50px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Category:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, width: 300 }}>&nbsp;&nbsp;{this.state.variant}</Typography>
                            </Grid>


                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Width:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.width}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Length :</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.length}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Insurance fee:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.insurance_fee}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Registation fee:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.registration_fee}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Ex showroom Price:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.ex_shoeroom_price}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Battery Type:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.battery}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Transporation:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.transportation_cost}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 250, marginTop: "30px", marginLeft: "80px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Booking Date :</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>{this.state.booking_date}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", marginBottom: "0px" }} >
                            <Grid item style={{ display: "flex", width: 250, marginTop: "30px", marginLeft: "50PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Estimate delivery Date:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;{this.state.estimated_delivery_date}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", marginBottom: "0px" }} >
                                {/* <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "30PX" }} >
                                    <Typography style={{ fontWeight: 600, fontSize: 15, }}>Delivery Date  :</Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.delivery_date}</Typography>
                                </Grid> */}
                                <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                                    <Typography style={{ fontWeight: 600, fontSize: 15, }}>On Road price:</Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;;{this.state.on_road_price}</Typography>
                                </Grid>
                            </Grid>



                        </Grid>
                        <Grid item style={{ display: "flex", marginBottom: "0px" }} >
                            {/* <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "20PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>On Road price:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;;{this.state.on_road_price}</Typography>
                            </Grid> */}
                            <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "50px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Total price :</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.total_price}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", marginBottom: "30px" }} >
                            {/* <Grid item style={{ display: "flex", width: 200, marginTop: "30px", marginLeft: "50PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Status:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;{this.state.order_status}</Typography>
                            </Grid> */}
                            <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "50px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>comment:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.comment}</Typography>
                            </Grid>


                        </Grid>
                    </Grid>

                </Dialog>
                
                <Dialog
                    open={this.state.preview}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ marginLeft: "0%", width: "100%" }}
                >
                    <Grid className="view">


                        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box className='details'>
                                <center>

                                    <Button style={{ color: "white ", textAlign: 'center', marginLeft: "0px", fontSize: 16 }}>Preview Order</Button>
                                </center>




                            </Box>
                            <IconButton
                                // color=""
                                style={{ marginLeft: "0%", marginTop: "-0%" }}
                                onClick={() => {
                                    this.setState({
                                        preview: false,
                                    })
                                }}
                            // size={18}
                            >
                                <CloseIcon style={{ color: 'black', fontSize: 48 }}
                                />
                            </IconButton>

                            {/* </Grid> */}


                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            {/* <Grid item style={{ display: "flex", width: 200 ,marginTop:"50px",marginLeft:"50px"}} >
                            <Typography style={{ fontWeight: 600, fontSize: 15, }}>Warranty:</Typography>
                        </Grid> */}
                            {this.state.quotation_pdf !== undefined && <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "20px" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 18, marginTop: 2, width: 300 }}>Quotation :</Typography>
                                <img
                                    src={this.state.quotation_pdf}
                                    style={{ width: 250, height: 150 }} />
                                <Button onClick={() => {
                                    this.props.reSendQuotationpdf(this.state.id, this.state.quotation_pdf);
                                    this.setState({ preview: false })
                                }}>
                                    Resend
                                </Button>
                                <Button onClick={() => { this.setState({ preview: false }) }} >
                                    <a href={this.state.quotation_pdf} target="_blank" style={{ textDecoration: "none" }}> Download</a>
                                </Button>

                            </Grid>}
                            {this.state.booking_pdf !== undefined && <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "10px" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 18, marginTop: 2, width: 300 }}>Booking :</Typography>
                                <img
                                    src={this.state.booking_pdf}
                                    style={{ width: 250, height: 150 }} />
                                <Button onClick={() => { this.props.reSendBookingpdf(this.state.id, this.state.booking_pdf); this.setState({ preview: false }) }}>
                                    Resend
                                </Button>
                                <Button onClick={() => this.setState({ preview: false })}>
                                    <a href={this.state.booking_pdf} target="_blank" style={{ textDecoration: "none" }}> Download</a>
                                </Button>
                            </Grid>}


                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 400, marginTop: "20px", marginLeft: "25px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}> Name:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, width: 300 }}>&nbsp;{this.state.customer}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 300, marginTop: "20px", marginLeft: "-40px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Battery:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;{this.state.battery}</Typography>
                            </Grid>
                            {/* <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Color:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;{this.state.color_id}</Typography>
                            </Grid> */}
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 300, marginTop: "20px", marginLeft: "25px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Model :</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>{this.state.model}</Typography>
                            </Grid>
                            {/* <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Length :</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.length}</Typography>
                            </Grid> */}
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 200, marginTop: "20px", marginLeft: "25PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Variant :</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.variant}</Typography>
                            </Grid>
                            {/* <Grid item style={{ display: "flex", width: 300, marginTop: "30px", marginLeft: "100px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Registation fee:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.registration_fee}</Typography>
                            </Grid> */}
                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 200, marginTop: "20px", marginLeft: "25PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 20, }}><u>PRICE :</u></Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                            </Grid>

                        </Grid>
                        <Grid item style={{ display: "flex", }} >
                            <Grid item style={{ display: "flex", width: 200, marginTop: "10px", marginLeft: "25PX" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Ex Showroom Price:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;&nbsp;{this.state.showroom_price}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 250, marginTop: "10px", marginLeft: "80px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>OnRoad Price :</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>{this.state.on_road_price}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: "flex", marginBottom: "0px" }} >
                            <Grid item style={{ display: "flex", width: 250, marginTop: "20px", marginLeft: "25PX", marginBottom: "20px" }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Insurance Fee:</Typography>
                                <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;&nbsp;{this.state.insurance_fee}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", marginBottom: "0px" }} >
                                <Grid item style={{ display: "flex", width: 200, marginTop: "20px", marginLeft: "30PX" }} >
                                    <Typography style={{ fontWeight: 600, fontSize: 15, }}>Total:</Typography>
                                    <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2 }}>&nbsp;&nbsp;{this.state.total_price}</Typography>
                                </Grid>
                            </Grid>



                        </Grid>

                    </Grid>

                </Dialog>


                <Dialog
                    open={this.state.payment}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{
                        height: "75%",
                        width: "60%",
                        left: "20%",
                        top: "10%",
                        borderradius: "0px"
                    }}

                >
                    <Grid className="book"
                    >


                        <Grid style={{ display: "flex", justifyContent: "space-between" }} >
                            <Box className='details' width="50%" >
                                <center>

                                    <Button style={{ color: "white ", textAlign: 'center', marginLeft: "0px", fontSize: 16 }}> PAYMENT</Button>
                                </center>




                            </Box>
                            <IconButton
                                // color=""
                                style={{ marginLeft: "0%", marginTop: "-0%" }}
                                onClick={() => {
                                    this.setState({
                                        payment: false,
                                    })
                                }}
                            // size={18}
                            >
                                <CloseIcon style={{ color: 'black', fontSize: 48 }}
                                />
                            </IconButton>



                        </Grid>


                        <Grid display="flex" marginTop={2}>
                            <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                                <label style={{ marginTop: -10, marginLeft: 10, }}>TOTAL AMOUNT</label>
                                <TextField
                                    id="input-with-icon-textfield"
                                    style={{
                                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                        height: 35,
                                        marginLeft: 3,
                                        marginTop: 0,
                                        width: "185px",
                                        border: "2px solid #000000",

                                        // border: "2px solid #000000",
                                        // borderRadius:8
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CurrencyRupeeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    disabled
                                    value={all_order.total_payment}
                                />




                                {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                            </Grid>
                            <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                                <label style={{ marginTop: -10, marginLeft: 3, }}> PENDING AMOUNT</label>
                                <TextField
                                    // value={100000}
                                    id="input-with-icon-textfield"
                                    style={{
                                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                        height: 35,
                                        marginLeft: 3,
                                        marginTop: 0,
                                        width: "185px",
                                        border: "2px solid #000000",

                                        // border: "2px solid #000000",
                                        // borderRadius:8
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CurrencyRupeeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    disabled
                                    value={all_order.pending_amount}
                                />



                                {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}






                            </Grid>

                        </Grid>
                        <Grid display="flex" background="#00000080">
                            {/* <div style={{ border: "1px solid rgba(0, 0, 0, 0.5)",borderColor:"#000000", marginLeft: 20, marginRight: 20 }}></div> */}

                            <div style={{ marginTop: 25, flex: 1, height: '1.5px', backgroundColor: '#00000080' }} />



                        </Grid>

                        <Grid display="flex" marginTop={1}>
                            <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                                <label style={{ marginTop: -10, marginLeft: 5, }}>PAYMENT MODE</label>



                                <Select

                                    label="payment mode"
                                    // onChange={handleChange}
                                    // height="30"
                                    // marginLeft="-55"
                                    // marginTop="0"
                                    // width="185px"
                                    // border="2px solid #000000"
                                    style={{
                                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                        height: 35,
                                        marginLeft: 5,
                                        marginTop: 0,
                                        width: "185px",
                                        border: "2px solid #000000",
                                        // borderRadius:8
                                    }}
                                    onChange={(e) => {
                                        this.setState({ payment_mode: e.target.value });
                                    }}

                                >
                                    <MenuItem value="online">ONLINE</MenuItem>
                                    <MenuItem value="cash">CASH</MenuItem>
                                    <MenuItem value="cheque">CHEQUE</MenuItem>
                                </Select>
                            </Grid>


                            {this.state.payment_mode && <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                                <label style={{ marginTop: -10, marginLeft: 3, }}>ADD AMOUNT</label>
                                {/* <input style={{
                                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                        height: 30,
                                        marginLeft: 3,
                                        marginTop: 0,
                                        width: "185px",
                                        border: "2px solid #000000",
                                        // borderRadius:8
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <CurrencyRupeeIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    // value={all_varient.speed}
                                    // onChange={(event1) => { this.props.setSpeed(event1.target.value) }}
                                    ></input> */}

                                {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}

                                <TextField
                                    id="input-with-icon-textfield"
                                    style={{
                                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                        height: 35,
                                        marginLeft: 3,
                                        marginTop: 0,
                                        width: "185px",
                                        border: "2px solid #000000",

                                        // border: "2px solid #000000",
                                        // borderRadius:8
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CurrencyRupeeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    onChange={(e) => {
                                        this.setState({ amount: e.target.value });
                                    }}
                                />






                            </Grid>}



                        </Grid>




                        <Grid style={{ backgroundColor: "#fff", height: "80px", minWidth: "600px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>



                        </Grid>

                        <Button className='btns'
                            onClick={() => {
                                this.props.AddPayment(this.state.id, this.props.login.user_id, all_order.pending_amount, this.state.payment_mode, parseInt(this.state.amount));
                                this.setState({ payment: false });
                            }}
                            style={{
                                height: "33px",
                                width: "140px",
                                left: "400px",
                                top: "-50px",

                                color: "white "

                            }}>Submit</Button>



                    </Grid>

                </Dialog>











                {/* <Dialog
                    open={this.state.view}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography style={{ color: "blue", fontSize: 25 }}>
                            Orders Deatils

                        </Typography>

                        <IconButton color="primary"
                            style={{ marginLeft: "93%", marginTop: "-15%" }}
                            onClick={() => {
                                this.setState({
                                    view: false,
                                    // _id: c._id,
                                    // color_name: c.color_name,
                                    // color_code: c.color_code,
                                })
                            }}
                        >
                            <CloseIcon style={{ color: 'blue' }}
                            />
                        </IconButton>


                    </DialogTitle> */}



                {/* <Grid lg={8} style={{ display: "flex", flexDirection: "row", width: "600px", }}> */}
                {/* <Grid style={{ marginTop: "0px", marginBottom: 10, marginLeft: "25px" }}>
                            <img
                                src={this.state.profile_pic}
                                style={{ width: 140, height: 150 }} />

                        </Grid> */}
                {/* 

                        <Grid style={{ marginLeft: "25px", marginTop: "-30px" }}>
                            <Grid item style={{ display: "flex", width: 100 }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Quantity:</Typography>
                                <Typography style={{ fontSize: 12 }}>&nbsp;{this.state.quanty}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", width: 100 }} >
                                <Typography style={{ fontWeight: 600, fontSize: 15, }}>Color:</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.order_color}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}> Register Will  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.will_registration}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Applied  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.Applied}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Recived  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.received}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Height  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.height}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Width  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.width}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Length  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.length}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Insurance  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.insurance}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Registation  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.registration}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Transporation  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.transportation}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Booking Date  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.booking_date}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Estimate Date  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.estimated_delivery_date}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>  Delivery Date  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.delivery_date}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>   Status  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.order_status}</Typography>
                            </Grid>
                            <Grid item style={{ display: "flex", marginBottom: "10px" }}>
                                <Typography style={{ fontWeight: 600, fontSize: 15 }}>   Comment  :</Typography>
                                <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.comment}</Typography>
                            </Grid>
                        </Grid>

                    </Grid>

                </Dialog> */}



                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                    > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained" onClick={() => { this.props.deleteOrder(this.state.id, this.props.login.user_id); this.setState({ delete: false }) }}>

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.quotation}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid style={{ backgroundColor: "#fff", height: "500px", minWidth: "360px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Customer "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // value={this.state.category_name}
                                    onChange={(e) => { this.setState({ customer_id: e.target.value }) }}
                                >
                                    {all_customer.all_customer.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.cust_name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Model "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // value={this.state.category_name}
                                    onChange={(e) => { this.setState({ model_id: e.target.value }) }}
                                >
                                    {all_model.all_model.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.model_name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Category "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // value={this.state.category_name}
                                    onChange={(e) => { this.setState({ category_id: e.target.value }) }}
                                >
                                    {all_category.all_category.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.category_name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Variant "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // value={this.state.category_name}
                                    onChange={(e) => { this.setState({ variant_id: e.target.value }) }}
                                >
                                    {all_varient.all_varient.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.variant_name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Quantity"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ quanty: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Color"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ order_color: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Will Register"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ will_registration: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Applied"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ applied: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Received"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ received: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Length"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ length: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Width"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ width: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Height"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ height: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Insurance"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ insurance: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Registation"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ registration: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Transporation"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ transportation: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Booking Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ booking_date: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Estimate Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ estimated_delivery_date: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Delivery Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ delivery_date: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="Status"
                                    label="Status"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ order_status: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Comment"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    onChange={(e) => { this.setState({ comment: e.target.value }) }}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>

                            <Button
                                onClick={() => {
                                    this.props.createQuotation(this.state.customer_id, this.props.login.user_id, this.state.model_id, this.state.category_id, this.state.variant_id, this.state.quanty, this.state.order_color, this.state.will_registration, this.state.applied, this.state.received, this.state.length, this.state.width, this.state.height, this.state.insurance, this.state.registration, this.state.transportation, this.state.booking_date, this.state.estimated_delivery_date, this.state.delivery_date, this.state.order_status, this.state.comment);
                                    this.setState({ quotation: false });
                                }}
                                style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Add</Button>
                            <Button onClick={() => {
                                this.setState({ quotation: false });
                            }} style={{ marginLeft: "20px", backgroundColor: "ButtonFace", marginBottom: "30px", marginTop: "20px" }}>Close</Button>


                        </Grid>

                    </Grid>
                </Dialog>


                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />

            </Grid>
        )
    }
}