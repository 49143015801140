import React, { Component } from "react";
import { connect } from "react-redux";
import Addteamlead from "../component/addteamlead";
import {
  close_snack_bar
} from "../../../common/snackbar/action";

import {
    add_upload_profile,
    viewAlldepartment
} from "../action";
export class Controller extends Component {
    render() {
        return (
            <Addteamlead {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        // user: store.user,
        // one_user: store.one_user,
        // login: store.login
        snackbar: store.snackbar,
        superadmin: store.superadmin

    };
};
export const mapDispatchToProps = dispatch => {
    return {
        add_upload_profile: (department_id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address, basic_salary,designation,time,shift) => {
            dispatch(add_upload_profile(department_id,name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address, basic_salary,designation,time,shift))
        },
        viewAlldepartment: () => {
            dispatch(viewAlldepartment())
          },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
          },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);