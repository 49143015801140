import { Box, Grid, Typography, Button } from '@mui/material'
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import "../../admin/style/status.css"
const steps = [
    'order placed',
    'DOCUMENT VERIFICITION',
    'MANUFACTURING',
    'OUT FOR DELIVERY',
    'DELIVERED'

];

export default class ordersts extends Component {
    render() {
        return (
            <Grid>
                <Box className='head' ></Box>
                <center>

                    <img src="https://www.skyyriderelectric.com/public/image/logo1.png" height={150} width={170} style={{ marginTop: "4%" }} />
                </center>
                <center>
                    <Typography className='orderstatus'>
                        {/* <u>

</u> */}
                        ORDER STATUS
                    </Typography>



                </center>

                <Grid style={{ display: "flex" }}>
                    <Grid style={{ marginLeft: "22%" }}>
                        <Grid style={{ display: "flex" }}>

                            <Typography style={{ fontWeight: "600" }}>
                                Customer Name   :
                            </Typography>

                            <Typography>
                                RASMIRANJAN MOHANTY
                            </Typography>
                            <br></br>
                            <br></br>
                        </Grid>
                        <Grid style={{ display: "flex" }}>
                            <Typography style={{ fontWeight: "600" }}>
                                Order Id     :
                            </Typography>
                            <Typography>
                                NF101Y2HPB97V69P82537
                            </Typography>
                        </Grid>
                        <br></br>
                        {/* <br></br> */}
                        <Grid style={{ display: "flex" }}>
                            <Typography style={{ fontWeight: "600" }}>
                                Customer Id    :
                            </Typography>
                            <Typography>

                                NF101Y2HPB97V69P82537
                            </Typography>
                        </Grid>
                        <br></br>
                        <Grid style={{ display: "flex" }}>


                            <Typography style={{ fontWeight: "600" }}>
                                Order Date    :
                            </Typography>
                            <Typography style={{ fontWeight: "600", color: "#264387" }}>
                                10/20/2200
                            </Typography>


                        </Grid>
                    </Grid>




                    <Grid style={{ marginLeft: "18%" }}>
                        <Grid style={{ display: "flex" }}>


                            <Typography style={{ fontWeight: "600" }}>
                                Total Amount       :
                            </Typography>
                            <Typography style={{ fontWeight: "200", color: "#264387" }}>
                                4,20,380/-
                            </Typography>


                        </Grid>
                        <br></br>
                        <Grid style={{ display: "flex" }}>


                            <Typography style={{ fontWeight: "600", marginLeft: "65px" }}>
                                Paid      :
                            </Typography>
                            <Typography style={{ fontWeight: "400", color: "#264387", display: "flex", }}>
                                4,20,380/-

                                <Typography style={{ fontWeight: "10", color: "#264387", marginLeft: 15 }}>
                                    ( 10/12/1000)
                                </Typography>
                            </Typography>



                        </Grid>
                        <hr style={{ height: "1px", }}></hr>
                        <Grid style={{ display: "flex" }}>


                            <Typography style={{ fontWeight: "600", marginLeft: "20px" }}>
                                Remaining     :
                            </Typography>
                            <Typography style={{ fontWeight: "600", color: "#264387", display: "flex", }}>
                                2,10,380/-

                            </Typography>



                        </Grid>




                    </Grid>
                </Grid>

                <Grid className='rtosts'>
                    <Grid style={{ display: "flex", justifyContent: "space-evenly", marginTop: 15, }}>
                        <Grid style={{ display: "flex" }}>


                            <Typography style={{ fontWeight: "600", marginLeft: "20px" }}>
                                Vehicle Id         :
                            </Typography>
                            <Typography style={{ fontWeight: "600", color: "#264387", display: "flex", }}>
                                2,10,380/-

                            </Typography>



                        </Grid>
                        <Grid style={{ display: "flex" }}>


                            <Typography style={{ fontWeight: "600", marginLeft: "20px" }}>
                                RTO Registered          :
                            </Typography>
                            <Typography style={{ fontWeight: "600", color: "#264387", display: "flex", }}>
                                2,10,380/-

                            </Typography>



                        </Grid>




                    </Grid>
                    <Grid style={{ display: "flex", justifyContent: "space-evenly", marginTop: 15 }}>

                        <Grid style={{ display: "flex" }}>


                            <Typography style={{ fontWeight: "600", marginLeft: "20px" }}>
                                Chassis NO.           :
                            </Typography>
                            <Typography style={{ fontWeight: "600", color: "#264387", display: "flex", }}>
                                2,10,380/-

                            </Typography>



                        </Grid>
                        <Grid style={{ display: "flex" }}>


                            <Typography style={{ fontWeight: "600", marginLeft: "20px" }}>
                                Expected delivery Date    :
                            </Typography>
                            <Typography style={{ fontWeight: "600", color: "#18AD00", display: "flex", }}>
                                2,10,380/-

                            </Typography>



                        </Grid>
                    </Grid>
                    <Grid style={{ float: "right", marginTop: "-4%", marginRight: "5%" }}>
                        <img src='https://www.skyyriderelectric.com/public/image/1.jpg' height={170} width={150} />
                        <Typography style={{ fontWeight: "600" }}>SARKAR GLOF CART</Typography>
                    </Grid>



                    <Grid>
                        <Stepper activeStep={4} alternativeLabel style={{ marginTop: "4%" }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>




                    </Grid>


                </Grid>

                <Grid style={{ display: "flex", justifyContent: "center", marginTop: "11%" }}>

                    <Button variant="contained" className='submit' sx={{ borderRadius: 25, marginTop: "5%", marginBottom: "5%" }}>   Download
                        Confirmation
                    </Button>
                    <Button variant="contained" className='submit' sx={{ borderRadius: 25, marginTop: "5%", marginBottom: "5%", marginLeft: "5%" }}>CLOSE</Button>


                </Grid>



            </Grid>
        )
    }
}
