

import "../component/comming.css"
import React, { Component,PureComponent } from 'react'
import { styled } from '@mui/material/styles';
import "../component/comming.css"
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Margin } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@material-ui/core/Paper';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush,
  ReferenceLine } from 'recharts';
  import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { PieChart, Pie} from "recharts";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from "rsuite";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const rows = [
  ('Frozen yoghurt', 159, 6.0, 24, 4.0),
  ('Ice cream sandwich', 237, 9.0, 37, 4.3),
  ('Eclair', 262, 16.0, 24, 6.0),
  ('Cupcake', 305, 3.7, 67, 4.3),
  ('Gingerbread', 356, 16.0, 49, 3.9),
];
const piedata = [
  { name: "average Present 100", value: 400, fill: "#0088FE" },
  { name: "average Leave 100", value: 300, fill: "#00C49F" },
  { name: "average Over Time 100", value: 300, fill: "#FFBB28" },
 
];

  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
        {value}
      </span>
    );
  };
export default class attendancetl extends Component {
  handleBack = () => {
  };
  static demoUrl =
  "https://codesandbox.io/s/pie-chart-with-padding-angle-7ux0o";
  render() {
 
    return (

      <div>
         
        <div>
          <Box>
            <Button style={{ marginTop: "2%",fontSize:"19px" ,border:"none",background:"#fff"}}   onClick={this.handleBack}>
              <ArrowBackIosIcon /> Attendance
            </Button>
          </Box>
          <Box className='attendance1' style={{ display: "flex",flexDirection:"row" }}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" style={{ width: "100px", height: "100px", marginTop: "2%", marginLeft: "2%" }} />
            <Box style={{ marginLeft: "2%", marginTop: "2%", fontSize: "20px", width: "25%" }}>
              <Typography style={{ marginLeft: "2%", marginTop: "2%", color: "#6B6B6B", fontSize: "20px" }} >
                Name: <strong style={{ color: "black", fontSize: "16px", fontWeight: 400 }}>Aman Dey
                </strong>


              </Typography>
              <Typography style={{ marginLeft: "2%", color: "#6B6B6B", fontSize: "20px", marginTop: "2%" }} >
                Employee ID: <strong style={{ color: "black", fontSize: "16px", fontWeight: 400 }}>345678675678
                </strong>

              </Typography>
              <Typography style={{ marginLeft: "2%", color: "#6B6B6B", fontSize: "20px", marginTop: "2%" }} >
                Department:  <strong style={{ color: "black", fontSize: "16px", fontWeight: 400 }}>Finance
                </strong>

              </Typography>
               
            <div style={{marginLeft:"15em",display: "flex",flexDirection:"row",marginTop:"-13em"}}>
           
            <PieChart width={800} height={400} onMouseEnter={this.onPieEnter}>
        <Legend
          height={36}
          iconType="circle"
          layout="vertical"
          verticalAlign="middle"
          iconSize={10}
          padding={5}
          formatter={renderColorfulLegendText}
        />
        <Pie
          data={piedata}
          cx={120}
          cy={200}
          innerRadius={40}
          outerRadius={60}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {/* {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))} */}
        </Pie>
      
       
      </PieChart>
  
      </div> 
            </Box>






          </Box>





        </div>
        <div>

        </div>
        <label style={{ marginTop: 50,marginLeft:"auto" }}>  </label>
                <select style={{
                  background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                  height: 38,
                  color: "#000000",
                  marginLeft: "76%",
                  marginTop: 0,
                  width: "180px",
                  border: "1px solid grey",
                  borderRadius: 4,


                }}
                 
                  name=""
                  // value={this.state.eventName}
                  select

                >
                  <option value=''>
                    Select Month
                  </option>
                  <option value='1st Shift-9 to 14'>
                    1st Shift-9 to 14
                  </option>
                  <option value='  2nd Shift-14 to 19'>
                    2nd Shift-14 to 19
                  </option>
                  <option value=' Overtime- 19 to 21'>
                    Overtime- 19 to 21
                  </option>
                </select>


                <TableContainer component={Paper} style={{width:"90%",marginTop:"2%",backgroundColor:"linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)"}}>
      <Table sx={{ minWidth: 500 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">1</TableCell>
            <TableCell align="right">2</TableCell>
            <TableCell align="right">3</TableCell>
            <TableCell align="right">4</TableCell>
            <TableCell align="right">5</TableCell>
            <TableCell align="right">6</TableCell>
            <TableCell align="right">7</TableCell>
            <TableCell align="right">8</TableCell>
            <TableCell align="right">9</TableCell>
            <TableCell align="right">10</TableCell>
            <TableCell align="right">11</TableCell>
            <TableCell align="right">12</TableCell>
            <TableCell align="right">13</TableCell>
            <TableCell align="right">14</TableCell>
            <TableCell align="right">15</TableCell>
            <TableCell align="right">16</TableCell>
            <TableCell align="right">17</TableCell>
            <TableCell align="right">18</TableCell>
            <TableCell align="right">19</TableCell>
            <TableCell align="right">20</TableCell>
            <TableCell align="right">21</TableCell>
            <TableCell align="right">22</TableCell>
            <TableCell align="right">23</TableCell>
            <TableCell align="right">25</TableCell>
            <TableCell align="right">26</TableCell>
            <TableCell align="right">27</TableCell>
            <TableCell align="right">28</TableCell>
            <TableCell align="right">29</TableCell>
            <TableCell align="right">30</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
         <TableRow
         
           sx={{ '&:last-child td, &:last-child th': { borderLeft:1,borderColor:"rgba(209, 209, 209, 1)" } }}
         >
           <TableCell component="th" scope="row">
           Present
           </TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
           <TableCell align="right"></TableCell>
         
         </TableRow>

         
   
     </TableBody>
        <TableBody>
         
            <TableRow
            
              sx={{ '&:last-child td, &:last-child th': { borderLeft:1,borderColor:"rgba(209, 209, 209, 1)" } }}
            >
              <TableCell component="th" scope="row">
              Overtime
              </TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            
            </TableRow>

            
      
        </TableBody>
      </Table>
    </TableContainer>

    <Box style={{width:"90%",display:"flex"}}>


    <Card style={{width:"40%",marginTop:"2%"}}>
      <CardContent>
        <Typography sx={{ fontSize: "22px",color:"black",fontWeight:600 }} color="text.secondary" gutterBottom>
          Salary Days
        </Typography>
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }}>
      Total Attendance:  <strong style={{marginLeft:"50%"}}>
        21
      </strong>
        </Typography>
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }} color="text.secondary">
          Total Leave:  <strong style={{marginLeft:"62%"}}>
        21
      </strong>
        </Typography>
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }}>
      Allowed Leave:  <strong style={{marginLeft:"56%"}}>
        21
      </strong>
     
        </Typography>
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }}>
      Loss of pay day:  <strong style={{marginLeft:"54%"}}>
        21
      </strong>
     
        </Typography>
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }}>
      Total Salary days:  <strong style={{marginLeft:"51%"}}>
        21
      </strong>
     
        </Typography>
      </CardContent>
      <CardActions>
     
      </CardActions>
    </Card>

    <Card style={{width:"40%",marginTop:"2%",marginLeft:"9%"}}>
      <CardContent>
        <Typography sx={{ fontSize: "22px",color:"black",fontWeight:600 }} color="text.secondary" gutterBottom>
          Total Salary
        </Typography>
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }}>
       Basic Pay <strong style={{marginLeft:"54%"}}>
        21
      </strong>
        </Typography>
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }}>
          Overtime<strong style={{marginLeft:"57%"}}>
        21
      </strong>
        </Typography>
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }}>
      Salary Days
      <strong style={{marginLeft:"51.5%"}}>
        21
      </strong>
     
        </Typography>
      
        <Typography sx={{ fontSize: "20px",color:"black",fontWeight:400 }}>
      Total Salary days <strong style={{marginLeft:"39%"}}>
        21
      </strong>
     
        </Typography>
      </CardContent>
      <CardActions>
     
      </CardActions>
    </Card>

    </Box>





      </div>

    )
  }
}