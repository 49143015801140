import {
  SET_VARIENT,
    SET_MODEL_VARIENT,
    SET_VARIENT_DETAILS,
    SET_VARIANTID,
    SET_CATEGORYID,
    SET_MODELID,
    SET_VARIANTNAME,
    SET_BRAKING,
    SET_MODELPIC,
    // SET_SPEED,
    SET_RANGE,
    SET_MOTOR,
    SET_WARRANTY,
    SET_CAPACITY,
    SET_CHARGETIME,
    // SET_BATTERYTYPE,
    // SET_BATTERYVOLT,
    SET_SYSTEM,
    SET_LENGTH,
    SET_WIDTH,
    SET_REARAXLE,
    SET_EXSHOWROOMPRICE,
    SET_INSURANCEPRICE,
    SET_REGISTRATIONPRICE,
    SET_MODEL,
    SET_CATEGORY,
    SET_FILTERED_VALUE
  // SET_CATEGORYNAME,
  // SET_MODALNAME
} from "./constant";
const initial_state = {
  all_varient: [],
  all_model_variant: [],
  all_variant_details: [],
  // edit_variant: [],
  varient_id: "",
  category_id:"",
  model_id:"",
  variant_name:"",
  braking: "",
  model_pic: "",
  // speed: "",
  range: "",
  motor: "",
  warranty: "",
  capacity: "",
  charging_time: "",
  // battery_type: "",
  // battery_volt: "",
  system: "",
  overall_length: "",
  width: "",
  rear_axle: "",
  exshowroom_price: "",
  insurance_price: "",
  registration_price: "",
  model: "",
  category: "",
  filtered_model:[]
  // category_name:"",
  // modalname:""
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_VARIENT:
      return state = { ...state, all_varient: action.payload,filtered_model:action.payload  };
    case SET_MODEL_VARIENT:
      return state = { ...state, all_model_variant: action.payload };
    case SET_VARIENT_DETAILS:
      return state = { ...state, all_variant_details: action.payload };
    case SET_VARIANTID:
      return state = { ...state, varient_id: action.payload };
    case SET_CATEGORYID:
      return state = { ...state, category_id: action.payload };
    case SET_MODELID:
      return state = { ...state, model_id: action.payload };
    case SET_VARIANTNAME:
      return state = { ...state, variant_name: action.payload };
    case SET_BRAKING:
      return state = { ...state, braking: action.payload };
    case SET_MODELPIC:
      return state = { ...state, model_pic: action.payload };
    // case SET_SPEED:
    //   return state = { ...state, speed: action.payload };
    case SET_RANGE:
      return state = { ...state, range: action.payload };
    case SET_MOTOR:
      return state = { ...state, motor: action.payload };
    case SET_WARRANTY:
      return state = { ...state, warranty: action.payload };
    case SET_CAPACITY:
      return state = { ...state, capacity: action.payload };
    case SET_CHARGETIME:
      return state = { ...state, charging_time: action.payload };
    // case SET_BATTERYTYPE:
    //   return state = { ...state, battery_type: action.payload };
    // case SET_BATTERYVOLT:
    //   return state = { ...state, battery_volt: action.payload };
    case SET_SYSTEM:
      return state = { ...state, system: action.payload };
    case SET_LENGTH:
      return state = { ...state, overall_length: action.payload };
    case SET_WIDTH:
      return state = { ...state, width: action.payload };
    case SET_REARAXLE:
      return state = { ...state, rear_axle: action.payload };
    case SET_EXSHOWROOMPRICE:
      return state = { ...state, exshowroom_price: action.payload };
    case SET_INSURANCEPRICE:
      return state = { ...state, insurance_price: action.payload };
    case SET_REGISTRATIONPRICE:
      return state = { ...state, registration_price: action.payload };
    case SET_MODEL:
      return state = { ...state, model: action.payload ,filtered_model:action.payload };
    case SET_CATEGORY:
      return state = { ...state, category: action.payload,filtered_model:action.payload };
      case SET_FILTERED_VALUE:
      return state = { ...state, filtered_model: action.payload };
    // case SET_CATEGORYNAME:
    //   return state = { ...state, category_name: action.payload };
    //   case SET_MODALNAME:
    //     return state = { ...state, modalname: action.payload };


    default:
      return state;
  }
}
