import {
    SET_ALL_ADMIN_DETAILS,
    SET_DEPARTMENT,
    SET_TL_ID,
    SET_TL_DEPARTMENT_NAME,
    SET_TL_NAME,
    SET_TL_MOBILE,
    SET_TL_EMAIL,
    SET_TL_GENDER,
    SET_TL_DOB,
    SET_TL_PADDRESS,
    SET_TL_PRADDRESS,
    SET_TL_PROFILE_PIC,
    SET_TL_PASSWORD,
    SET_TL_BASICSALARY,
    SET_TL_DEPARTMENT,
    SET_ONETL_USER,
    SET_TL_DESIGNATION,
    SET_TL_TIME,
    SET_TL_SHIFT

} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import set_snack_bar from "../../common/snackbar/components/snackbar";
import LoaderCon from "../../common/loader/containers/loader_cont";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


// import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import 'firebase/storage';
// import firebase from 'firebase/app'


export function viewAllAdminDetails() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "all_admin_details_pie_chart", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },


        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch({ type: SET_ALL_ADMIN_DETAILS, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_ALL_ADMIN_DETAILS, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// export function setcallTaken(payload) {
//     return {
//         type: SET_CALL_TAKEN,
//         payload: payload,
//     };
// }

// export function setNewlead(payload) {
//     return {
//         type: SET_NEW_LEAD,
//         payload: payload,
//     };
// }

// export function setTotallead(payload) {
//     return {
//         type: SET_TOTAL_LEAD,
//         payload: payload,
//     };
// }
// export function settotalconversion(payload) {
//     return {
//         type: SET_TOTAL_CONVERSION,
//         payload: payload,
//     };
// }
// export function setGender(payload) {
//     return {
//         type: SET_USER_GENDER,
//         payload: payload,
//     };
// }
// export function setDob(payload) {
//     return {
//         type: SET_USER_DOB,
//         payload: payload,
//     };
// }
// export function setPer_address(payload) {
//     return {
//         type: SET_USER_PADDRESS,
//         payload: payload,
//     };
// }
// export function setPre_address(payload) {
//     return {
//         type: SET_USER_PRADDRESS,
//         payload: payload,
//     };
// }
// export function setProfile_pic(payload) {
//     return {
//         type: SET_USER_PROFILE_PIC,
//         payload: payload,
//     };
// }
// export function setPassword(payload) {
//     return {
//         type: SET_USER_PASSWORD,
//         payload: payload,
//     };
// }


////////////////////////////DEPARTMENT//////////////////////////////////

export function createdeparment(department_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "add_department", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                department_name: department_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(viewAlldepartment());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updatedepartment(department_id, department_name) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_department", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                department_id: department_id,
                department_name: department_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAlldepartment());
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deletedepartment(department_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "delete_department", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                department_id: department_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {

                    dispatch(viewAlldepartment());

                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAlldepartment() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_department", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("rsempnicd", responseJson)

                if (responseJson.status) {
                    dispatch({ type: SET_DEPARTMENT, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_DEPARTMENT, payload: [] })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
////////////////////////////DEPARTMENT//////////////////////////////////


///////////////////TL\\\\\\\\\\\\\\\\\\\\\\\\///////////////////////////////


export function add_upload_profile(department_id, name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address, basic_salary,designation,time,shift) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/Profile/" + mobile + ".png")
        // .put(image)
        // uploadBytes(storageRef, image).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //   });
        const uploadTask = uploadBytesResumable(storageRef, profile_pic);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                    // console.log(downloadURL)
                    dispatch(createTeamLeader(department_id, name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address, basic_salary,designation,time,shift));
                });
            }
        );

    }
}





export function update_profile_pic(id, department_id, name, mobile, email, password, gender, profile_pic_old,profile_pic, dob, per_address, pre_address, basic_salary,designation,time,shift) {
    return dispatch => {
        //   console.log(Emp_Id)
        dispatch(setLoader(true));
        if (profile_pic == null) {
            dispatch(updateTL(id, department_id, name, mobile, email, password, gender, profile_pic_old, dob, per_address, pre_address, basic_salary,designation,time,shift));

        } else {
            const storageRef = ref(getStorage(), "/Profile/" + id + ".png")

            // .put(image)
            // uploadBytes(storageRef, image).then((snapshot) => {
            //     console.log('Uploaded a blob or file!');
            //   });
            const uploadTask = uploadBytesResumable(storageRef, profile_pic);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic) {
                        // console.log(downloadURL)
                        dispatch(updateTL(id, department_id, name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address, basic_salary,designation,time,shift));
                    });
                }
            );
        }
    }
}


export function createTeamLeader(department_id, name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address, basic_salary,designation,time,shift) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_tl", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                department_id: department_id,
                name: name,
                mobile: mobile,
                email: email,
                password: password,
                gender: gender,
                profile_pic: profile_pic,
                dob: dob,
                per_address: per_address,
                pre_address: pre_address,
                basic_salary: basic_salary,
                designation: designation,
                time: time,
                shift: shift,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(view_all_admin_user());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateTL(id, department_id, name, mobile, email, password, gender, profile_pic, dob, per_address, pre_address, basic_salary) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_tl", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                tl_id: id,
                department_id: department_id,
                name: name,
                mobile: mobile,
                email: email,
                password: password,
                gender: gender,
                profile_pic: profile_pic,
                dob: dob,
                per_address: per_address,
                pre_address: pre_address,
                basic_salary: basic_salary
            })
        })

            .then((response) => response.json())

            .then((responseJson) => {
                // console.log(responseJson)
                if (responseJson.status) {
                    dispatch(view_all_TL());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function delete_TL(id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_TL());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function view_all_TL() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_tl", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
console.log("veiw all tl",responseJson.result.department_name)
                if (responseJson.status) {
                    dispatch(setID(responseJson.result._id));
                    dispatch(setDEPARTMENTNAME(responseJson.result.department_name));
                    dispatch(setName(responseJson.result.name));
                    dispatch(setMobile(responseJson.result.mobile));
                    dispatch(setEmail(responseJson.result.email));
                    dispatch(setPassword(responseJson.result.password));
                    dispatch(setGender(responseJson.result.gender));
                    dispatch(setProfile_pic(responseJson.result.profile_pic));
                    dispatch(setDob(responseJson.result.dob));
                    dispatch(setPer_address(responseJson.result.per_address));
                    dispatch(setbasicsalary(responseJson.result.basic_salary));
                    dispatch(setdepartment(responseJson.result.department_name));
                    dispatch(setDesignation(responseJson.result.designation));
                    dispatch(setTime(responseJson.result.time));
                    dispatch(setShift(responseJson.result.shift));
                    dispatch({ type: SET_ONETL_USER, payload: responseJson.result });
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_ADMIN_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
            
    };
}

export function setID(payload) {
   
    return {
        type: SET_TL_ID,
        payload: payload,
    };
}
export function setDEPARTMENTNAME(payload) {
    return {
        type: SET_TL_DEPARTMENT_NAME,
        payload: payload,
    };
}

export function setName(payload) {
    console.log("veiw  tl id",payload)
    return {
        type: SET_TL_NAME,
        payload: payload,
    };
}

export function setMobile(payload) {
    return {
        type: SET_TL_MOBILE,
        payload: payload,
    };
}
export function setEmail(payload) {
    return {
        type: SET_TL_EMAIL,
        payload: payload,
    };
}
export function setGender(payload) {
    return {
        type: SET_TL_GENDER,
        payload: payload,
    };
}
export function setDob(payload) {
    return {
        type: SET_TL_DOB,
        payload: payload,
    };
}
export function setPer_address(payload) {
    return {
        type: SET_TL_PADDRESS,
        payload: payload,
    };
}
export function setPre_address(payload) {
    return {
        type: SET_TL_PRADDRESS,
        payload: payload,
    };
}
export function setProfile_pic(payload) {
    return {
        type: SET_TL_PROFILE_PIC,
        payload: payload,
    };
}
export function setPassword(payload) {
    return {
        type: SET_TL_PASSWORD,
        payload: payload,
    };
}
export function setbasicsalary(payload) {
    return {
        type: SET_TL_BASICSALARY,
        payload: payload,
    };
}
export function setdepartment(payload) {
    return {
        type: SET_TL_DEPARTMENT,
        payload: payload,
    };
}
export function setDesignation(payload) {
    return {
        type: SET_TL_DESIGNATION,
        payload: payload,
    };
}
export function setTime(payload) {
    return {
        type: SET_TL_TIME,
        payload: payload,
    };
}
export function setShift(payload) {
    return {
        type: SET_TL_SHIFT,
        payload: payload,
    };
}

///////////////////TL\\\\\\\\\\\\\\\\\\\\\\\\
