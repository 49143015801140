import React, { Component } from 'react'
import {
    Grid,
    // Card,
    // CardContent,
    Typography,
    Box,
    IconButton,

    Stack, Input,
    Avatar
} from "@mui/material";
// import React from "react";

// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

// import "../../style/variant.css"

import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import VisibilityIcon from '@mui/icons-material/Visibility';

export default class addvariant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            profile_pic: "",
            _id:""

        }
    }
    componentDidMount() {
        this.props.viewAlldepartment();

    }
    render() {
        const {
            superadmin
        } = this.props;
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='makeorder'>
                        <center style={{ display: "flex" }}>
                            <Link to="/employee" style={{ textDecoration: "none" }}>
                                <IconButton
                                    style={{ marginLeft: "18px" }}

                                >
                                    <ArrowBackIcon style={{ color: "white" }} />
                                </IconButton>
                            </Link>


                            <Typography style={{ color: "white ", marginLeft: "28px", fontSize: 16, marginTop: "8px" }}>ADD A TEAM LEADER</Typography>
                        </center>
                    </Box>


                </Grid>
                <Grid style={{ marginLeft: "50px", width: "300px" }}>
                    <Typography style={{ color: "black ", fontSize: 18, marginTop: "8px", fontWeight: "500", marginLeft: "30px" }}>TEAM LEADER PICTURE</Typography>


                    <Stack
                        spacing={1}
                        style={{
                            textDecoration: "none",
                            // display: "inline-block",
                            padding: "0 30px",
                            marginTop: "10px",
                        }}
                        className="imagemedia"
                    >
                        <Avatar
                            alt="Profile"
                            src={this.state.profile_pic !== "" ? URL.createObjectURL(this.state.profile_pic) : this.state.profile_pic}
                            style={{ height: "114px", width: "114px", borderRadius: 100 }}
                            variant={"rounded"}
                        />
                        {/* {imageUrl && (
                                        <img
                                            src={imageUrl}
                                            alt={imageAlt}
                                            className="displayed-image"
                                        />
                                    )} */}
                        <Input
                            type="file"
                            // onChange={(e)=>this.handleFileChange(e)}
                            onChange={(e) => { this.setState({ profile_pic: e.target.files[0] }) }}
                        />
                    </Stack>
                </Grid>
<Grid  class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}>Department </label>
                        <select style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            color:"#5C5C5C",
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",
                            // borderRadius:8

                        }}
                            onChange={(e) => { this.setState({ _id: e.target.value }) }}
                            name=""
                            // value={this.state.eventName}
                            select

                        >
                            <option value='' style={{color:"grey"}}>
                                Select Department
                            </option>
                            {superadmin.all_department.map((c) => {
                                        return (
                            <option value={c._id}>
                            {c.department_name}
                            </option>
                              );
                            })}
                            
                        </select>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
           <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 55, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>Basic Salary</label>
                        <input required={true}
                        type={"number"} 
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            onChange={(e) => {
                                this.setState({ basic_salary: e.target.value });
                            }}
                        >

                        </input>

                    </Grid>
               </Grid>
               
             
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>Team Leader Name</label>
                        <input
                            // style={{ width: "200px", marginLeft: "30px" }}
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name=""

                            onChange={(e) => {
                                this.setState({ name: e.target.value });
                            }}
                        >

                        </input>

                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 40, marginTop: 25, }}>
                        <label style={{ marginTop: -10 }}>Mobile</label>
                       <form> <input required={true}
                        type={"text"} pattern="[0-9]{10}"  placeholder="mobile number"
                       
                            style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",
                                height: 30,
                                // marginLeft:80,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}

                            name="formName"
                                 value={this.state.empl_mobile}
                            min={0}
                            // onKeyDown="if(this.value.length==2 && event.keyCode!=8) return false;"
                            maxLength={10}
                            onChange={(e) => {
                                this.setState({ mobile: e.target.value });
                            }}
                        >
                        </input></form>

                    </Grid>

                </Grid>



                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> Email </label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ email: e.target.value }) }}


                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>

                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 40, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> Password</label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",

                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ password: e.target.value }) }}
                        ></input>



                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>


                </Grid>
                <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                    <label style={{ marginTop: -10, }}> Designation </label>
                    <input style={{
                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                        height: 30,
                        // marginLeft:-55,
                        marginTop: 0,
                        width: "300px",
                        border: "2px solid #000000",
                        // borderRadius:8
                    }}
                        onChange={(e) => { this.setState({ designation: e.target.value }) }}


                    ></input>


                    {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                </Grid>

                <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                    <label style={{ marginTop: -10, }}> time </label>
                    <select style={{
                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                        height: 30,
                        // marginLeft:-55,
                        marginTop: 0,
                        width: "300px",
                        border: "2px solid #000000",
                        // borderRadius:8

                    }}
                        onChange={(e) => { this.setState({ time: e.target.value }) }}
                        name=""
                        // value={this.state.eventName}
                        select

                    >
                        <option value=''>
                            time
                        </option>
                        <option value='10 am'>
                            10am
                        </option>
                        <option value='5 pm '>
                            5pm
                        </option>
                    </select>


                    {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                </Grid>

                <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                    <label style={{ marginTop: -10, }}> shift </label>
                    <select style={{
                        background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                        height: 30,
                        // marginLeft:-55,
                        marginTop: 0,
                        width: "300px",
                        border: "2px solid #000000",
                        // borderRadius:8

                    }}
                        onChange={(e) => { this.setState({ shift: e.target.value }) }}
                        name=""
                        // value={this.state.eventName}
                        select

                    >
                        <option value=''>
                            shift
                        </option>
                        <option value='1st'>
                            1st
                        </option>
                        <option value='2nd'>
                            2nd
                        </option>
                    </select>


                    {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> Gender </label>
                        <select style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 30,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "300px",
                            border: "2px solid #000000",
                            // borderRadius:8

                        }}
                            onChange={(e) => { this.setState({ gender: e.target.value }) }}
                            name=""
                            // value={this.state.eventName}
                            select

                        >
                            <option value=''>
                                SELECT GENDER
                            </option>
                            <option value='male'>
                                Male
                            </option>
                            <option value='female'>
                                Female
                            </option>
                        </select>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 10, marginTop: 25, }}>
                        <label style={{ marginTop: -10, marginLeft: 30, }} >D.O.B</label>
                        <input
                            type={"date"} style={{
                                background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                                height: 30,
                                marginLeft: 30,
                                marginTop: 0,
                                width: "300px",
                                border: "2px solid #000000",
                                // borderRadius:8
                            }}
                            onChange={(e) => { this.setState({ dob: e.target.value }) }}
                        ></input>



                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>

                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex', flexDirection: 'coloum' }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}
      /> */}






                </Grid>
                <Grid class="register-form-custom-select" style={{ marginTop: 5, display: 'flex', flexDirection: "column" }} >
                    {/* <Dropdown
        // options={this.state.options}
        // placeholder='Choose Language'
        search
        // selection
        fluid
        allowAdditions
        value={currentValue}
        onAddItem={this.handleAddition}
        onChange={this.handleChange}

      /> */}
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}>  Permanent Address </label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 90,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "650px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ per_address: e.target.value }) }}


                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", marginLeft: 80, marginTop: 25, }}>
                        <label style={{ marginTop: -10, }}> Present Adress </label>
                        <input style={{
                            background: "linear-gradient(91.75deg, #EBF1F2 0.93%, #FFFFFF 110.51%)",

                            height: 90,
                            // marginLeft:-55,
                            marginTop: 0,
                            width: "650px",
                            border: "2px solid #000000",
                            // borderRadius:8
                        }}
                            onChange={(e) => { this.setState({ pre_address: e.target.value }) }}


                        ></input>


                        {/* <label style={{color:"black"}}>BOOKING/INVOICE</label> */}


                    </Grid>


                </Grid>

                <Grid class="register-form-custom-select" style={{ marginTop: 25, display: 'flex', marginBottom: 25 }} >
                    
                    <Link to="/teamlead" style={{ textDecoration: "none" }}>
                        <Typography marginLeft={40} sx={{ borderRadius: "15px" }}>
                            <Button
                            //  disabled={this.state.name==="" || this.state.mobile==="" || this.state.email==="" || this.state.password==="" || this.state.gender==="" || this.state.profile_pic==="" || this.state.dob==="" || this.state.per_address==="" || this.state.pre_address===""}
                            onClick={() => {

                                this.props.add_upload_profile(this.state._id,this.state.name, this.state.mobile, this.state.email, this.state.password, this.state.gender, this.state.profile_pic, this.state.dob, this.state.per_address, this.state.pre_address, this.state.basic_salary,this.state.designation, this.state.time, this.state.shift);
                                this.setState({ name: "",mobile:"", email:"",password:"",gender:"",profile_pic:"",dob:"",per_address:"",pre_address:"",basic_salary:""});
                            }}
                                className='button' variant='contained' sx={{ borderRadius: "20px" }}>Add Team Leader</Button></Typography>

                    </Link>
                </Grid>

            </Grid>
        )
    }
}