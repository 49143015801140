import {
    SET_MODEL,
    SET_CATEGORY_MODEL,
    SET_FILTERED_VALUE
} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
import { set_snack_bar } from "../../../common/snackbar/action";

// import { set_snack_bar } from "../../common/snackbar/action";
// import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import 'firebase/storage';
// import firebase from 'firebase/app'


export function createModel(category_id, model_name, speed) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "new_model", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                category_id: category_id,
                model_name: model_name,
                speed: speed

            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewModel());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateMOdal(id, category_id, model_name, speed) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_model", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                category_id: category_id,
                model_name: model_name,
                speed: speed


            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewModel());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteModel(id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_model", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch((viewModel()));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch((viewModel()));
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true,"Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewModel() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_models", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_MODEL, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_MODEL, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function searchModel(all_model,e) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (e.target.value === "") {
            dispatch({type:SET_FILTERED_VALUE,payload:all_model});
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
          //   setFilter(model.all_model);
          }
          else {
            const newArray =all_model.filter((el) => {
              return (el.model_name.toLowerCase().includes(e.target.value.toLowerCase()));
            })
            dispatch({type:SET_FILTERED_VALUE,payload:newArray})
            // dispatch(unsetLoader());

          //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
          }
    };
}

export function filter_category(all_model,category_id) {
    return (dispatch) => {
        // dispatch(setLoader());
        if (category_id === "") {
            dispatch({type:SET_FILTERED_VALUE,payload:all_model});
            // dispatch(unsetLoader());

            // this.setState({ filterValue: this.props.all_model.all_model })
          //   setFilter(model.all_model);
          }
          else {
            const newArray =all_model.filter((el) => {
              return (el.category_id.toLowerCase().match(category_id.toLowerCase()));
            })
            dispatch({type:SET_FILTERED_VALUE,payload:newArray})
            // dispatch(unsetLoader());

          //   setFilter(newArray)
            // this.setState({ filterValue: newArray })
          }
    };
}



export function viewCategortModel(category_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_model_category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                category_id: category_id
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_CATEGORY_MODEL, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_CATEGORY_MODEL, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_filtered_value(payload){
    return{
        type: SET_FILTERED_VALUE,
        payload: payload
    }
}