import React, { Component } from "react";
import { connect } from "react-redux";
import Teamlead from "../component/teamlead";
import {
  close_snack_bar
} from "../../../common/snackbar/action"
// import {
//   onLogout
// } from "../../../../auth/actions";
import {
   
    // deleteCategory,
    // view_all_admin_user,
    delete_TL,
    view_all_TL
} from "../action";


// import{
//   deleteUser
// }from "../../empolyee/actions"
export class Controller extends Component {
  render() {
    return (
      <Teamlead {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_admin_user: store.all_admin_user,
    snackbar: store.snackbar,
    superadmin: store.superadmin,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
  
    delete_TL: (id) => {
      dispatch(delete_TL(id))
    },
    
    view_all_TL: () => {
      dispatch(view_all_TL())
    },
    
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    // onLogout: () => {
    //   dispatch(onLogout());
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);