import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../component/dashboardnew";
// import {
//   close_snack_bar
// } from "../../../../common/snackbar/action"
import {
  viewAllOrder,
  view_status_delivered,
  view_status_booking,
  view_Status_Delivered_By_Admin,
  view_Status_Booking_By_Admin,
  viewAllOrderBySA
} from "../../orders/action"

import {
  viewAllService,
  search_service,
  view_all_booking_service,
  view_all_completed_service,
  view_All_Service_Booking_By_Admin,
  view_All_Service_Completed_By_Admin,
  viewAllServiceBySA
} from "../../service/action"


export class Controller extends Component {
  render() {
    return (
      <Dashboard {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    all_order: store.all_order,
    all_service: store.all_service,
    snackbar: store.snackbar,
    login: store.login,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // close_snack_bar: () => {
    //   dispatch(close_snack_bar());
    // },
    viewAllOrder: (admin_id,status,order_status,page_number) => {
      dispatch(viewAllOrder(admin_id,status,order_status,page_number))
    }, 
    viewAllService: (admin_id, page_number, rowper_page) => {
      dispatch(viewAllService(admin_id, page_number, rowper_page))
    },   
    search_service: (all_service, e) => {
      dispatch(search_service(all_service, e))
    },
    view_all_booking_service:() => {
      dispatch(view_all_booking_service())
    },
    view_all_completed_service:() => {
      dispatch(view_all_completed_service())
    },
    view_status_booking:() => {
      dispatch(view_status_booking())
    },
    view_status_delivered: () => {
      dispatch(view_status_delivered() )
    },


    view_Status_Delivered_By_Admin: (admin_id) => {
      dispatch(view_Status_Delivered_By_Admin(admin_id) )
    },

    view_Status_Booking_By_Admin: (admin_id) => {
      dispatch(view_Status_Booking_By_Admin(admin_id) )
    },

    view_All_Service_Booking_By_Admin: (admin_id) => {
      dispatch(view_All_Service_Booking_By_Admin(admin_id) )
    },

    view_All_Service_Completed_By_Admin: (admin_id) => {
      dispatch(view_All_Service_Completed_By_Admin(admin_id) )
    },

    viewAllOrderBySA: (page_number,rowper_page) => {
      dispatch(viewAllOrderBySA(page_number,rowper_page) )
    },

    viewAllServiceBySA: (page_number,rowper_page) => {
      dispatch(viewAllServiceBySA(page_number,rowper_page) )
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);