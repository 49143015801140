import { Grid, Card, CardContent, Typography, Box, CardHeader, IconButton, Icon, TextField, Tooltip } from "@mui/material";
import React from "react";
// import { DataGrid } from '@mui/x-data-grid';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import MenuItem from '@mui/material/MenuItem';
// import LoaderCon from "../../../common/loader/containers/loader_cont";
// import Snackbar from "../../../common/snackbar/components/snackbar";
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
// import upload from "../../../images/register/upload.jpeg";
// import speakerphoto from "../../../images/navbar/govt.png";
// import UNIVERSAL from "../../../config/config";
import { styled, alpha } from "@mui/material/styles";
// import { DownloadTableExcel } from 'react-export-table-to-excel';
// import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import LoaderCon from "../../../../common/loader/containers/loader_cont";

import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import "../../style/order.css"
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Link } from "react-router-dom";
import Snackbar from "../../../../common/snackbar/components/snackbar";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import Radio from '@mui/material/Radio';
import TablePagination from "@mui/material/TablePagination";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));


export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            add: false,
            edit: false,
            view: false,
            upload_image_dialog: false,
            delete: false,
            addview: false,
            // filterValue: this.props.speakers.all_speakers,
            page: 0,
            setPage: 0,
            rowsPerPage: 10,
            setRowsPerPage: 10,
            selectedValue: ' ',
            task: false,
            cust_name: "",
            phone: "",
            model_name: "",
            email: "",
            battery_number: "",
            agent_name: "",



        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ selectedValue: event.target.value });
    }




    componentDidMount() {
        // this.props.viewAllService(this.props.login.user_id, this.state.page, this.state.rowsPerPage);
       

       if (this.props.login.type === "D") {
        this.props.viewAllService(this.props.login.user_id, this.state.page, this.state.rowsPerPage);
       } else {
        this.props.view_All_Service_By_Admin_And_Superadmin(this.props.login.user_id,this.state.page,this.state.rowsPerPage)
       }
    }

    render() {
        // console.log("user_id",this.props.login.user_id);
        const {
            all_service,
            user,
            snackbar,
            login,
            close_snack_bar
        } = this.props;

        const handleChangePage = (event, newPage) => {
            // console.log(newPage)
            // this.props.viewAlltheClients(this.props.login.user_id,newPage);
            this.props.viewAllService(this.props.login.user_id, this.state.page, this.state.rowsPerPage);

            // this.props.view_all_participants(this.props.login.user_token, newPage * this.state.rowsPerPage, this.state.rowsPerPage, this.state.status, this.state.event_id, this.state.department_id,this.state.sort);
            // this.props.view_all_participants()
            this.setState({
               
                page: newPage,
            });
        };

        const { selectedValue } = this.state;
        return (
            <Grid>
                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='ordername'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>All Service</Button>
                        </center>
                    </Box>
                    {this.props.login.type === "D" &&
                    <center style={{ borderRadius: 0 }}>
                        <Link to="/addservice" style={{ textDecoration: "none", }}>

                            <Button className='orderadd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    // add: true,
                                })
                            }}>Add</Button>
                        </Link>
                    </center>}

                </Grid>
                <Grid
                    // container
                    // direction="row"
                    alignItems="end"
                    // justifyContent="space-between"
                    mr={10}
                    style={{}}
                >
                    <TablePagination
                        rowsPerPageOptions={[20,]}
                        component="div"
                        count={this.props.all_service.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={handleChangePage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <CardContent style={{ marginLeft: "40px", marginRight: "65px" }}>
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: 600 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                            <TableHead className="orderhead">
                                <TableRow >
                                    <TableCell>Customer Name</TableCell>
                                    {this.props.login.type === "A" &&
                                    <TableCell>Dealer Name</TableCell>}
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Modal Name</TableCell>
                                    <TableCell style={{ textAlign: "center" }} >Status</TableCell>
                                    <TableCell>Agent Name</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="orderbody">

                                {Array.isArray(this.props.all_service.all_service) && this.props.all_service.all_service.map((service) => {
                                    return (
                                        <TableRow
                                            // key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{service.customer_details.cust_name}</TableCell>
                                            {this.props.login.type === "A" && service.executive_details!==undefined &&
                                            <TableCell>{service.executive_details.delar_name}</TableCell>}
                                            <TableCell>{service.phone}</TableCell>
                                            <TableCell>{service.model_name}</TableCell>
                                            <TableCell style={{ textAlign: "center", fontFamily: "Georgia", color: "white" }}> 
                                            <Button style={{
                                                    backgroundColor: service.status === 'register' ? 'orange' :
                                                    service.status === 'assigned' ? 'red' :
                                                    service.status === 'pending' ? "purple" :
                                                    service.status === 'booking' ? "blue" : 'green', borderRadius: '10px', height: "25px", textAlign: "center"
                                                }} variant="contained"

                                                >
                                                    {service.status}
                                                </Button>
                                                    {service.status === "completed" ? '' : <Button
                                                        onClick={() => {
                                                            this.props.updateServiceStatus(this.props.login.user_id, service._id, service.status, this.state.page, this.state.rowsPerPage);
                                                        }}>
                                                        <RotateRightIcon style={{ marginLeft: "2%" }} />
                                                    </Button>}
                                                </TableCell>
                                            <TableCell
                                            >{service.agent_name}</TableCell>
                                            <TableCell style={{ display: "flex", gap: "6px" }}>
                                                {/* <Link>
                                                    <RemoveRedEyeOutlined style={{ color: "blue" }} />
                                                </Link> */}
                                                {/* <Link to={"/editservice"}>
                                                    <EditOutlinedIcon style={{ color: "green" }}
                                                        onClick={() => {
                                                            this.props.setService(service)
                                                        }}
                                                    />
                                                </Link> */}
                                                <IconButton onClick={() => {
                                                    this.setState({
                                                        edit: true,
                                                        id: service._id,
                                                        cust_name: service.cust_name,
                                                        phone: service.phone,
                                                        model_name: service.model_name,
                                                        email: service.email,
                                                        battery_number: service.battery_number,
                                                        agent_name: service.agent_name,

                                                    });
                                                }}>

                                                    <EditOutlinedIcon style={{ color: "green" }} />
                                                </IconButton>
                                                <IconButton style={{ marginTop: -2 }} onClick={() => {
                                                    this.setState({
                                                        delete: true,
                                                        id: service._id,
                                                    });
                                                }}>

                                                    <DeleteOutlineOutlinedIcon style={{ color: "red" }} />
                                                </IconButton>
                                            </TableCell>

                                        </TableRow>

                                    );
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>

                </CardContent>








                {/* view dialog */}

                <Dialog
                    open={this.state.addview}
                    onClose={() => {
                        this.setState({ addview: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%", }}
                >


                    <Grid style={{ backgroundColor: "#F8F8F8", minWidth: "380px", padding: "15px" }}>


                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography style={{ fontSize: "24px" }}>
                                New Entry
                            </Typography>
                            <CloseIcon

                                onClick={() => { this.setState({ addview: false }) }}
                                style={{ cursor: "pointer" }} />
                        </div>

                        <Typography style={{ textAlign: "center", margin: "9px 0px" }}>
                            <strong style={{ fontSize: "24px" }}>Job Sheet</strong>
                        </Typography>

                        <Table style={{ minWidth: "400" }} aria-label="spanning table">
                            <TableHead >

                                <TableRow>
                                    <TableCell style={{ background: "gray", color: "white", fontSize: "20px" }}>work</TableCell>
                                    <TableCell style={{ background: "gray", color: "white", fontSize: "20px" }} align="right">price</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow>
                                    <TableCell>Engine oil, Oil Filter, lights, tyres</TableCell>
                                    <TableCell align="right">5263</TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell>Brakes, steering, fluid, Suspension</TableCell>
                                    <TableCell align="right">963</TableCell>

                                </TableRow>

                                <TableRow>

                                    <TableCell colSpan={2}>total</TableCell>
                                    <TableCell  >₹ 6226</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>

                        <div style={{ border: "1px solid gray" }}>

                            <Box sx={{ display: "flex", gap: 3, padding: "7px" }}>
                                <div style={{ display: "flex", gap: "10px" }}>

                                    <Radio
                                        checked={selectedValue === '1'}
                                        onChange={this.handleChange}
                                        value="1"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    <h6 style={{ marginTop: "18px" }}>By Cash</h6>

                                </div>

                                <div style={{ display: "flex", gap: "10px" }}>

                                    <Radio
                                        checked={selectedValue === '2'}
                                        onChange={this.handleChange}
                                        value="2"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'B' }}
                                    />
                                    <h6 style={{ marginTop: "18px" }}>By UPI</h6>
                                </div>
                            </Box>

                            {selectedValue === "1" &&
                                <div style={{ marginBottom: "2%", marginLeft: "2%" }}>
                                    <TextField id="standard-basic" label="Cash In Rupees" variant="standard" sty />
                                </div>}

                            {selectedValue === "2" &&
                                <div style={{ marginBottom: "2%", marginLeft: "2%" }}>
                                    <TextField id="standard-basic" label="Transaction ID" variant="standard" />
                                </div>}
                        </div>
                        <div style={{ marginBottom: "5%" }}></div>
                    </Grid>



                </Dialog>















                <Dialog
                    open={this.state.view}
                    // onClose={() => { this.setState({ view: false }) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ marginLeft: "0%", width: "100%" }}
                >
                    <Grid className="view" height={460}>


                        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                            <Box className='details'>
                                <center>

                                    <Button style={{ color: "white ", textAlign: 'center', marginLeft: "0px", fontSize: 16 }}>   Service Details</Button>
                                </center>

                            </Box>
                            <IconButton
                                // color=""
                                style={{ marginLeft: "0%", marginTop: "-0%" }}
                                onClick={() => {
                                    this.setState({
                                        view: false,
                                    })
                                }}
                            // size={18}
                            >
                                <CloseIcon style={{ color: 'black', fontSize: 48 }}
                                />
                            </IconButton>

                        </Grid>

                        <Grid container spacing={1} class="register-form-custom-select" style={{ width: 1200, display: 'flex' }}>
                            <Grid item sm={6}>
                                <Box textAlign="center">
                                    <br></br>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, }}>Variant Name:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.variant}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, marginLeft: 25 }}>Battery:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, marginLeft: 10 }}>{this.state.battery}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, marginLeft: 45 }}>Color:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, marginLeft: 50 }}>{this.state.color}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, marginLeft: 20 }}>Comment:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, marginLeft: 25 }}>{this.state.comment}</Typography>
                                    </Grid>

                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, marginLeft: 10, marginLeft: -30 }}>Customer Name:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, marginLeft: 20 }}>{this.state.customer}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, marginLeft: -30 }}>Mobile Number:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, marginLeft: 20 }}>{this.state.mobile}</Typography>
                                    </Grid>
                                    <Grid item style={{ display: "flex", width: 200, marginTop: "3%", marginLeft: "10%" }} >
                                        <Typography style={{ fontWeight: 600, fontSize: 15, marginLeft: 22 }}>Address:</Typography>
                                        <Typography style={{ fontWeight: 400, fontSize: 14, marginTop: 2, marginLeft: 30 }}>{this.state.address}</Typography>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>


                    </Grid>

                </Dialog>

                <Dialog
                    open={this.state.delete}
                    onClose={() => {
                        this.setState({ delete: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%" }}
                >
                    {/* <IconButton
                > */}
                    <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                    {/* </IconButton> */}
                    <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                    <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                        <Button className="delete" variant="contained"
                            style={{}}
                            onClick={() => {
                                this.setState({ delete: false });
                            }}
                        >Cancel
                        </Button>
                        <Button className="delete" variant="contained"
                            onClick={() => {
                                this.props.deleteService(this.props.login.user_id, this.state.id, this.state.page, this.state.rowsPerPage);
                                this.setState({ delete: false });
                            }}>

                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.add}

                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid style={{ backgroundColor: "#fff", height: "700px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                {/* <TextField
                                    required
                                    select
                                    id="outlined-required"
                                    label="Customer "
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // value={this.state.category_name}
                                    onChange={(e) => { this.setState({ customer_id: e.target.value }) }}
                                >
                                    {all_customer.all_customer.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.cust_name}
                                        </MenuItem>
                                    ))}
                                </TextField> */}
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search>


                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    id="outlined-name-input"
                                    countryCodeEditable={false}
                                    label="Customer Id"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ customer_id: e.target.value })}

                                >
                                    {/* {all_category.all_category.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.category_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    id="outlined-name-input"
                                    countryCodeEditable={false}
                                    label="Vechicle Id"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ vechicle_id: e.target.value })}

                                >
                                    {/* {all_model.all_category_model.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.model_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    id="outlined-date-input"
                                    countryCodeEditable={false}
                                    label="Booking Date	"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ booking_date: e.target.value })}
                                >
                                    {/* {all_varient.all_model_variant.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.variant_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-sTime-input"
                                    countryCodeEditable={false}
                                    label="Status"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ status: e.target.value })}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-number-input"
                                    countryCodeEditable={false}
                                    label="Estimate Visit Date "
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ estimated_visit_date: e.target.value })}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                >
                                    {/* {all_color.all_color.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.color_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-number-input"
                                    countryCodeEditable={false}
                                    label="Visit Date"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ visit_date: e.target.value })}
                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                >
                                    {/* {all_color.all_color.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.color_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-multiline-static"
                                    countryCodeEditable={false}
                                    label="Address"
                                    type="textarea"
                                    fullWidth={true}
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ address: e.target.value })}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                >
                                    {/* {all_color.all_color.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.color_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-sTime-input"
                                    countryCodeEditable={false}
                                    label="Components "
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ components: e.target.value })}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                >
                                    {/* {all_color.all_color.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.color_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-sTime-input"
                                    countryCodeEditable={false}
                                    label="Service Charges"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ service_charges: e.target.value })}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                >
                                    {/* {all_color.all_color.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.color_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-sTime-input"
                                    countryCodeEditable={false}
                                    label="Amount"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ amount: e.target.value })}

                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                >
                                    {/* {all_color.all_color.map((e) => (
                                        <MenuItem key={e._id} value={e._id}>
                                            {e.color_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>




                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-multiline-static"
                                    countryCodeEditable={false}
                                    label="Comments"
                                    type="textarea"
                                    fullWidth={true}
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    required={false}
                                    onChange={(e) => this.setState({ comment: e.target.value })}
                                // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                />

                            </div>

                            <Button
                                onClick={() => {
                                    this.setState({ add: false });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                onClick={() => {
                                    this.props.createService(this.state.customer_id, this.state.vechicle_id, this.state.booking_date, this.state.status, this.state.estimated_visit_date, this.state.visit_date, this.state.address, this.state.components, this.state.service_charges, this.state.amount, this.state.comment)
                                    this.setState({ add: false });
                                }}
                            >
                                Add
                            </Button>
                        </Grid>

                    </Grid>
                </Dialog>


                {/* <Dialog
                    open={this.state.edit}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid style={{ backgroundColor: "#fff", height: "500px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    countryCodeEditable={false}
                                    label="Customer Id"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.customer_id}
                                    onChange={(e) => this.setState({ customer_id: e.target.value })}
                                >

                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    id="outlined-name-input"
                                    countryCodeEditable={false}
                                    label="Vechicle Id"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    defaultValue={this.state.vechicle_id}
                                    onChange={(e) => this.setState({ vechicle_id: e.target.value })}
                                >

                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    id="outlined-date-input"
                                    countryCodeEditable={false}
                                    label="Booking Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true, required: true }}
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.booking_date}
                                    onChange={(e) => this.setState({ booking_date: e.target.value })}
                                >

                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    id="outlined-sTime-input"
                                    countryCodeEditable={false}
                                    label="Status"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.status}
                                    onChange={(e) => this.setState({ status: e.target.value })}
                                >

                                </TextField>

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-number-input"
                                    countryCodeEditable={false}
                                    label="Estimate Visit Date "
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.estimated_visit_date}
                                    onChange={(e) => this.setState({ estimated_visit_date: e.target.value })}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-number-input"
                                    countryCodeEditable={false}
                                    label="Visit Date"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.visit_date}
                                    onChange={(e) => this.setState({ visit_date: e.target.value })}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-sTime-input"
                                    countryCodeEditable={false}
                                    label="Components"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.components}
                                    onChange={(e) => this.setState({ components: e.target.value })}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-sTime-input"
                                    countryCodeEditable={false}
                                    label="Service Charges"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.service_charges}
                                    onChange={(e) => this.setState({ service_charges: e.target.value })}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-sTime-input"
                                    countryCodeEditable={false}
                                    label="Amount"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.amount}
                                    onChange={(e) => this.setState({ amount: e.target.value })}
                                />

                            </div>
                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>
                                <TextField
                                    id="outlined-multiline-static"
                                    countryCodeEditable={false}
                                    label="Comments"
                                    type="textarea"
                                    fullWidth={true}
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    required={false}
                                    value={this.state.comment}
                                    onChange={(e) => this.setState({ comment: e.target.value })}
                                />

                            </div>









                            <Button
                                onClick={() => {
                                    this.setState({ edit: false });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                onClick={() => {
                                    this.props.updateService(this.state.id, this.state.customer_id, this.state.vechicle_id, this.state.booking_date, this.state.status, this.state.estimated_visit_date, this.state.visit_date, this.state.address, this.state.components, this.state.service_charges, this.state.amount, this.state.comment);
                                    this.setState({ edit: false });
                                }}
                            >
                                Update
                            </Button>

                        </Grid>

                    </Grid>
                </Dialog> */}

                <Dialog
                    open={this.state.edit}
                    onClose={() => {
                        this.setState({ edit: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"EDIT SERVICE"}</DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TextField
                                id="outlined-name-input"
                                countryCodeEditable={false}
                                label="Phone"
                                fullWidth={true}
                                margin="normal"
                                variant="outlined"
                                required={false}
                                defaultValue={this.state.phone}
                                onChange={(e) => this.setState({ phone: e.target.value })}
                            >
                                {/* {events.all_events.map((option) => (
                                    <MenuItem key={option.event_id} value={option.event_id}>
                                        {option.event_name}
                                    </MenuItem>
                                ))} */}
                            </TextField>

                            <TextField
                                id="outlined-date-input"
                                countryCodeEditable={false}
                                label="Email ID"
                                type="text"
                                InputLabelProps={{ shrink: true, required: true }}
                                fullWidth={true}
                                margin="normal"
                                variant="outlined"
                                required={false}
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                            />
                            <TextField
                                id="outlined-sTime-input"
                                countryCodeEditable={false}
                                label="Battery Number"
                                type="text"
                                fullWidth={true}
                                margin="normal"
                                variant="outlined"
                                required={false}
                                value={this.state.battery_number}
                                onChange={(e) => this.setState({ battery_number: e.target.value })}
                            />
                            <TextField
                                id="outlined-number-input"
                                countryCodeEditable={false}
                                label="Model Name"
                                type="text"
                                fullWidth={true}
                                margin="normal"
                                variant="outlined"
                                required={false}
                                value={this.state.model_name}
                                onChange={(e) => this.setState({ model_name: e.target.value })}
                            />
                            <TextField
                                id="outlined-number-input"
                                countryCodeEditable={false}
                                label="Agent Name"
                                type="text"
                                fullWidth={true}
                                margin="normal"
                                variant="outlined"
                                required={false}
                                value={this.state.agent_name}
                                onChange={(e) => this.setState({ agent_name: e.target.value })}
                            />
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ edit: false });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            autoFocus
                            onClick={() => {
                                this.props.updateService(this.props.login.user_id, this.state.id, this.state.phone, this.state.email, this.state.battery_number, this.state.model_name, this.state.agent_name, this.state.page, this.state.rowsPerPage);
                                this.setState({ edit: false });
                            }}
                        >
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>



                <Dialog
                    open={this.state.task}
                    // onClose={() => {
                    //     this.setState({ add: false });
                    // }}
                    style={
                        {
                            // top: "50%",
                            // left: "10%",
                            // right: "auto",
                            // bottom: "auto",
                            // marginRight: "-50%",
                            // transform: "translate(-50%, -50%)",
                            // width: "1000px",
                            // marginLeft: "500px",
                            // paddingTop: "40px",
                            // paddingLeft: "25px",
                            // paddingRight: "25px",
                            // borderRadius: "0px",
                            // height: "700px",
                            // zIndex: "200",
                            // backgroundColor: "#fff"
                        }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid style={{ backgroundColor: "#fff", height: "300px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                        <Grid>
                            <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                <TextField
                                    select
                                    id="outlined-name-input"
                                    countryCodeEditable={false}
                                    label="EMPLOYEE"
                                    type="text"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    value={this.state.Emp_Id}
                                    onChange={(e) => this.setState({ Emp_Id: e.target.value })}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                >
                                    {Array.isArray(this.props.user.all_users) && this.props.user.all_users.map((option) => {

                                        <MenuItem key={option.Emp_Id} value={option.Emp_Id}>
                                            {option.name}
                                        </MenuItem>
                                    })}
                                </TextField>

                            </div>

                            <div class="input-group mb-3" style={{ marginLeft: "20px" }}>

                                <TextField
                                    id="outlined-name-input"
                                    countryCodeEditable={false}
                                    label="ESTIMATE VISIT DATE"
                                    type="date"
                                    fullWidth={true}
                                    margin="normal"
                                    variant="outlined"
                                    required={false}
                                    // InputLabelProps={{ shrink: true, required: true }}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    style={{ height: "50px", width: "280px" }}
                                    // value={this.state.estimated_visit_date}
                                    onChange={(e) => this.setState({ estimated_visit_date: e.target.value })}

                                // defaultValue={this.state.vechicle_id}
                                // onChange={(e) => this.setState({ vechicle_id: e.target.value })}
                                >
                                    {/* {events.all_events.map((option) => (
                                        <MenuItem key={option.event_id} value={option.event_id}>
                                            {option.event_name}
                                        </MenuItem>
                                    ))} */}
                                </TextField>

                            </div>
                            <Button
                                className='btns'
                                autoFocus
                                onClick={() => {
                                    this.props.updateService(this.state.Emp_Id, this.state.estimated_visit_date,);
                                    this.setState({ task: false });
                                }}
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}
                            >
                                Add
                            </Button>




                            <Button
                                className='btns'
                                style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}
                                onClick={() => {
                                    this.setState({ task: false });
                                }}
                            >
                                Close
                            </Button>

                        </Grid>

                    </Grid>
                </Dialog>


                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />

            </Grid>





            // <Grid>
            //     <br /><br />
            //     <Grid justifyContent={"center"}>
            //         <Box>
            //             <Card variant="elevation" style={{ width: '90%', borderRadius: 10, marginLeft: "60px" }}>
            //                 <Grid container justifyContent="space-between" direction="row">
            //                     {/* <CardHeader title="All Speakers" /> */}
            //                     <Typography style={{ fontSize: 20, color: "blue", marginLeft: "20px", fontWeight: "bold", width: "20%", marginTop: "10px" }}>ALL SERVICE</Typography>



            //                     <Grid>
            //                         <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "" }} >
            //                             <Button style={{ backgroundColor: "ButtonFace   ", marginRight: "10px", marginTop: "10px" }} onClick={() => {
            //                                 this.setState({
            //                                     add: true,
            //                                     // id: allusers._id,
            //                                 })
            //                             }}>ADD

            //                                 <AddIcon style={{ marginTop: 0, color: "blue" }} />
            //                             </Button>
            //                         </Grid>
            //                         <IconButton style={{ paddingRight: 14 }} onClick={() => {
            //                             this.setState({ add_event: true });
            //                         }}>
            //                             {/* <Typography style={{ fontWeight: "600", color: "black", fontSize: 13 }}>Add Speakers</Typography>
            //                             <Icon sx={{ color: green[500] }} >add_circle</Icon> */}
            //                         </IconButton>
            //                         {/* <DownloadTableExcel

            //                             filename="speaker table"
            //                             sheet="speaker"
            //                             currentTableRef={this.tableRef.current}
            //                         > */}

            //                         <IconButton
            //                         //  onClick={()=>export_all_speakar()}
            //                         >
            //                             {/* <Typography
            //                                     style={{ fontWeight: "600", color: "black", fontSize: 13 }}

            //                                 >
            //                                     Export Speaker
            //                                 </Typography>
            //                                 <Icon sx={{ color: green[500] }}>download</Icon> */}
            //                         </IconButton>


            //                         {/* </DownloadTableExcel> */}
            //                     </Grid>
            //                 </Grid>
            //                 <Grid container direction="row" justifyContent="space-between"
            //                     style={{ paddingLeft: 9, paddingRight: 14 }}>
            //                     <Grid item lg={4}>
            //                         <Search
            //                             style={{
            //                                 backgroundColor: "#d9d9d9",
            //                                 height: 32,
            //                                 marginRight: 19,
            //                                 marginTop: 12,
            //                             }}
            //                             direction="row"
            //                         >
            //                             <SearchIconWrapper>
            //                                 <SearchIcon style={{ color: "black" }} />
            //                             </SearchIconWrapper>
            //                             <StyledInputBase
            //                                 placeholder="Search by name ..."
            //                                 color="black"
            //                                 inputProps={{ "aria-label": "search" }}
            //                                 style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
            //                             // onChange={handleChange}
            //                             />
            //                         </Search>
            //                     </Grid>

            //                     <Grid class="register-form-custom-select" style={{ marginTop: 19, }} >
            //                         <select
            //                             style={{ width: "200px", marginLeft: "30px" }}
            //                             name=""
            //                             // value={this.state.eventName}
            //                             select
            //                         // onChange={handleChangeDepartment}
            //                         >

            //                             <option value="">DEMO</option>
            //                             {/* {departments.all_departments.map((row) => (

            //                                 <>

            //                                     <option value={row.department_id}>{row.department_name}</option>
            //                                 </>

            //                             ))} */}
            //                         </select>
            //                         <select
            //                             style={{ width: "200px", marginLeft: "10px" }}
            //                             name="participant type"
            //                         // value={this.state.eventName}
            //                         // onChange={handleChangeEvent}
            //                         >

            //                             <option value="">DEMO</option>
            //                             {/* {events.all_events.map((row) => (

            //                                 <>
            //                                     <option value={row.event_id}>{row.event_name}</option>
            //                                 </>

            //                             ))} */}
            //                         </select>

            //                     </Grid>
            //                 </Grid>


            //                 <CardContent>
            //                     <TableContainer component={Paper}>
            //                         <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" ref={this.tableRef}>
            //                             <TableHead>
            //                                 <TableRow>
            //                                     <TableCell>Customer Id</TableCell>
            //                                     <TableCell>Vechicle Id</TableCell>
            //                                     <TableCell>Booking Date</TableCell>
            //                                     <TableCell>Status</TableCell>
            //                                     <TableCell >Action</TableCell>
            //                                 </TableRow>
            //                             </TableHead>
            //                             <TableBody>
            //                                 {all_service.all_service.map((allservice) => {
            //                                     return (
            //                                         <TableRow
            //                                             // key={row.name}
            //                                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            //                                         >

            //                                             <TableCell >{allservice.customer_id}</TableCell>
            //                                             <TableCell >{allservice.vechicle_id}</TableCell>
            //                                             <TableCell >{allservice.booking_date}</TableCell>
            //                                             <TableCell >{allservice.status}</TableCell>
            //                                             <TableCell >
            //                                                 <IconButton
            //                                                     onClick={() => {
            //                                                         this.setState({
            //                                                             view: true,
            //                                                             estimated_visit_date: allservice.estimated_visit_date,
            //                                                             visit_date: allservice.visit_date,
            //                                                             address: allservice.address,
            //                                                             components: allservice.components,
            //                                                             service_charges: allservice.service_charges,
            //                                                             amount: allservice.amount,
            //                                                             comment: allservice.comment


            //                                                         });
            //                                                     }}
            //                                                 >
            //                                                     <Icon style={{ color: 'blue' }}>
            //                                                         visibility
            //                                                     </Icon>
            //                                                 </IconButton>
            //                                                 {/* <IconButton color="primary" aria-label="upload picture" component="label"
            //                                                     onClick={() => {
            //                                                         this.setState({
            //                                                             upload_image_dialog: true,
            //                                                             // speaker_id: row.speaker_id, speaker_photo: row.speaker_photo
            //                                                         })
            //                                                     }}
            //                                                 >

            //                                                 </IconButton> */}
            //                                                 <IconButton
            //                                                     onClick={() => {
            //                                                         this.setState({
            //                                                             edit: true,
            //                                                             id: allservice._id,
            //                                                             customer_id: allservice.customer_id,
            //                                                             vechicle_id: allservice.vechicle_id,
            //                                                             booking_date: allservice.booking_date,
            //                                                             status: allservice.status,
            //                                                             estimated_visit_date: allservice.estimated_visit_date,
            //                                                             visit_date: allservice.visit_date,
            //                                                             address: allservice.address,
            //                                                             components: allservice.components,
            //                                                             service_charges: allservice.service_charges,
            //                                                             amount: allservice.amount,
            //                                                             comment: allservice.comment
            //                                                         });

            //                                                     }}
            //                                                 >
            //                                                     <Icon style={{ color: 'green' }}>
            //                                                         edit
            //                                                     </Icon>
            //                                                 </IconButton>
            //                                                 <IconButton
            //                                                     onClick={() => {
            //                                                         this.setState({
            //                                                             delete: true,
            //                                                             id: allservice._id
            //                                                         })

            //                                                     }}
            //                                                 >
            //                                                     <Icon style={{ color: 'red' }}>
            //                                                         delete
            //                                                     </Icon>
            //                                                 </IconButton>
            //                                                 <IconButton
            //                                                     onClick={() => {
            //                                                         this.setState({
            //                                                             task: true

            //                                                         })

            //                                                     }}
            //                                                 >
            //                                                     {/* <Icon style={{ color: 'black' }}>
            //                                                     AssignmentLateIcon

            //                                                     </Icon> */}
            //                                                     <AssignmentLateIcon style={{ color: "purple" }} />
            //                                                 </IconButton>


            //                                             </TableCell>
            //                                         </TableRow>

            //                                     );
            //                                 })}
            //                             </TableBody>
            //                         </Table>
            //                     </TableContainer>
            //                     <TablePagination
            //                         rowsPerPageOptions={[10, 25, 100]}
            //                         component="div"
            //                     // count={speakers.filterValue.length}
            //                     // rowsPerPage={this.state.rowsPerPage}
            //                     // page={this.state.page}
            //                     // onPageChange={handleChangePage}
            //                     // onRowsPerPageChange={handleChangeRowsPerPage}
            //                     />
            //                 </CardContent>
            //             </Card>
            //         </Box>
            //     </Grid>

            //     <Dialog
            //         open={this.state.view}
            //         // onClose={() => { this.setState({ view: false }) }}
            //         aria-labelledby="alert-dialog-title"
            //         aria-describedby="alert-dialog-description"
            //     >
            //         <DialogTitle id="alert-dialog-title">
            //             Service Details
            //         </DialogTitle>
            //         <Grid container justifyContent="center" lg={12} style={{ paddingLeft: 20 }}>


            //             <Grid item lg={6}>
            //                 <Grid item style={{ display: "flex", width: 1000 }} >
            //                     <Typography style={{ fontWeight: 600, fontSize: 15, }}>Estimate Visit Date :</Typography>
            //                     <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.estimated_visit_date}</Typography>
            //                 </Grid>
            //                 <Grid item style={{ display: "flex", width: 1000 }} >
            //                     <Typography style={{ fontWeight: 600, fontSize: 15, }}>Visit Date :</Typography>
            //                     <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.visit_date}</Typography>
            //                 </Grid>
            //                 <Grid item style={{ display: "flex" }}>
            //                     <Typography style={{ fontWeight: 600, fontSize: 15 }}> Address :</Typography>
            //                     <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.address}</Typography>
            //                 </Grid>
            //                 <Grid item style={{ display: "flex" }}>
            //                     <Typography style={{ fontWeight: 600, fontSize: 15 }}> Components  :</Typography>
            //                     <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.components}</Typography>
            //                 </Grid>
            //                 <Grid item style={{ display: "flex" }}>
            //                     <Typography style={{ fontWeight: 600, fontSize: 15 }}>Service Charges  :</Typography>
            //                     <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.service_charges}</Typography>
            //                 </Grid>
            //                 <Grid item style={{ display: "flex" }}>
            //                     <Typography style={{ fontWeight: 600, fontSize: 15 }}>Amount :</Typography>
            //                     <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.amount}</Typography>
            //                 </Grid>
            //                 <Grid item style={{ display: "flex" }}>
            //                     <Typography style={{ fontWeight: 600, fontSize: 15 }}> Comments:</Typography>
            //                     <Typography style={{ fontSize: 15, }}>&nbsp;{this.state.comment}</Typography>
            //                 </Grid>



            //             </Grid>
            //             <Grid item lg={6}>
            //                 {/* <img src={UNIVERSAL.BASEURL + this.state.speaker_photo} style={{ width: 140, height: 150 }} /> */}
            //             </Grid>

            //         </Grid>
            //         <DialogActions>
            //             <Button autoFocus onClick={() => { this.setState({ view: false }) }}>
            //                 Close
            //             </Button>
            //         </DialogActions>
            //     </Dialog>



            //     <Dialog
            //         open={this.state.delete}
            //         onClose={() => {
            //             this.setState({ delete: false });
            //         }}
            //         aria-labelledby="alert-dialog-title"
            //         aria-describedby="alert-dialog-description"
            //     >
            //         <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
            //         <DialogContent>
            //             <DialogContentText id="alert-dialog-description">
            //                 Are you sure want to Delete   ?
            //             </DialogContentText>
            //         </DialogContent>
            //         <DialogActions>
            //             <Button

            //                 onClick={() => {
            //                     this.setState({ delete: false });
            //                 }}
            //             >
            //                 Cancel
            //             </Button>
            //             <Button
            //                 onClick={() => {
            //                     this.props.deleteService(this.state.id);
            //                     this.setState({ delete: false });
            //                 }}
            //             >Confirm</Button>
            //         </DialogActions>
            //     </Dialog>


            //     <Dialog
            //         open={this.state.add}
            //         // onClose={() => {
            //         //     this.setState({ add: false });
            //         // }}
            //         aria-labelledby="alert-dialog-title"
            //         aria-describedby="alert-dialog-description"
            //     >
            //         <DialogTitle id="alert-dialog-title">{"ADD SERVICE"}</DialogTitle>

            //         <DialogContent>
            //             <DialogContentText id="alert-dialog-description">
            //                 <TextField
            //                     id="outlined-name-input"
            //                     countryCodeEditable={false}
            //                     label="Customer Id"
            //                     type="text"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ customer_id: e.target.value })}
            //                 >
            //                 </TextField>

            //                 <TextField
            //                     id="outlined-name-input"
            //                     countryCodeEditable={false}
            //                     label="Vechicle Id"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ vechicle_id: e.target.value })}
            //                 >
            //                 </TextField>

            //                 <TextField
            //                     id="outlined-date-input"
            //                     countryCodeEditable={false}
            //                     label="Booking Date	"
            //                     type="date"
            //                     InputLabelProps={{ shrink: true, required: true }}
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ booking_date: e.target.value })}
            //                 />



            //                 <TextField
            //                     id="outlined-sTime-input"
            //                     countryCodeEditable={false}
            //                     label="Status"
            //                     type="text"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ status: e.target.value })}
            //                 />
            //                 <TextField
            //                     id="outlined-number-input"
            //                     countryCodeEditable={false}
            //                     label="Estimate Visit Date "
            //                     type="text"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ estimated_visit_date: e.target.value })}
            //                 />
            //                 <TextField
            //                     id="outlined-number-input"
            //                     countryCodeEditable={false}
            //                     label="Visit Date"
            //                     type="text"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ visit_date: e.target.value })}
            //                 />
            //                 <TextField
            //                     id="outlined-multiline-static"
            //                     countryCodeEditable={false}
            //                     label="Address"
            //                     type="textarea"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     multiline
            //                     rows={4}
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ address: e.target.value })}
            //                 />
            //                 <TextField
            //                     id="outlined-sTime-input"
            //                     countryCodeEditable={false}
            //                     label="Components "
            //                     type="text"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ components: e.target.value })}
            //                 />

            //                 <TextField
            //                     id="outlined-sTime-input"
            //                     countryCodeEditable={false}
            //                     label="Service Charges"
            //                     type="text"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ service_charges: e.target.value })}
            //                 />
            //                 <TextField
            //                     id="outlined-sTime-input"
            //                     countryCodeEditable={false}
            //                     label="Amount"
            //                     type="text"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ amount: e.target.value })}
            //                 />

            //                 <TextField
            //                     id="outlined-multiline-static"
            //                     countryCodeEditable={false}
            //                     label="Comments"
            //                     type="textarea"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     multiline
            //                     rows={4}
            //                     variant="outlined"
            //                     required={false}
            //                     onChange={(e) => this.setState({ comment: e.target.value })}
            //                 />
            //             </DialogContentText>
            //         </DialogContent>
            //         <DialogActions>
            //             <Button
            //                 onClick={() => {
            //                     this.setState({ add: false });
            //                 }}
            //             >
            //                 Cancel
            //             </Button>
            //             <Button
            //                 autoFocus
            //                 onClick={() => {
            //                     this.props.createService(this.state.customer_id, this.state.vechicle_id, this.state.booking_date, this.state.status, this.state.estimated_visit_date, this.state.visit_date, this.state.address, this.state.components, this.state.service_charges, this.state.amount, this.state.comment)
            //                     this.setState({ add: false });
            //                 }}
            //             >
            //                 Add
            //             </Button>
            //         </DialogActions>
            //     </Dialog>






            //     <Dialog
            //         open={this.state.task}
            //         onClose={() => {
            //             this.setState({ task: false });
            //         }}
            //         aria-labelledby="alert-dialog-title"
            //         aria-describedby="alert-dialog-description"
            //     >
            //         <DialogTitle id="alert-dialog-title">{"TASK ASSIGNED"}</DialogTitle>

            //         <DialogContent>
            //             <DialogContentText id="alert-dialog-description">
            //                 <TextField
            //                     select
            //                     id="outlined-name-input"
            //                     countryCodeEditable={false}
            //                     label="EMPLOYEE"
            //                     type="text"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     value={this.state.Emp_Id}
            //                     onChange={(e) => this.setState({ Emp_Id: e.target.value })}
            //                 >
            //                     {user.all_users.map((option) => (
            //                         <MenuItem key={option.Emp_Id} value={option.Emp_Id}>
            //                             {option.name}
            //                         </MenuItem>
            //                     ))}
            //                 </TextField>

            //                 <TextField

            //                     id="outlined-name-input"
            //                     countryCodeEditable={false}
            //                     label="ESTIMATE VISIT DATE"
            //                     type="date"
            //                     fullWidth={true}
            //                     margin="normal"
            //                     variant="outlined"
            //                     required={false}
            //                     InputLabelProps={{ shrink: true, required: true }}
            //                     // value={this.state.estimated_visit_date}
            //                     onChange={(e) => this.setState({ estimated_visit_date: e.target.value })}

            //                 // defaultValue={this.state.vechicle_id}
            //                 // onChange={(e) => this.setState({ vechicle_id: e.target.value })}
            //                 >
            //                     {/* {events.all_events.map((option) => (
            //                         <MenuItem key={option.event_id} value={option.event_id}>
            //                             {option.event_name}
            //                         </MenuItem>
            //                     ))} */}
            //                 </TextField>

            //             </DialogContentText>
            //         </DialogContent>

            //         <DialogActions>
            //             <Button
            //                 onClick={() => {
            //                     this.setState({ task: false });
            //                 }}
            //             >
            //                 Cancel
            //             </Button>
            //             <Button
            //                 autoFocus
            //                 onClick={() => {
            //                     // this.props.updateService(this.state.employee, this.state.estimated ,);
            //                     this.setState({ task: false });
            //                 }}
            //             >
            //                 Add
            //             </Button>
            //         </DialogActions>
            //     </Dialog>


            //     <LoaderCon />
            // </Grid>

        )
    }
}