import {
  SET_USER,
  SET_ONE_USER,
  SET_USER_ID,
  SET_USER_NAME,
  SET_USER_MOBILE,
  SET_USER_EMAIL,
  SET_USER_GENDER,
  SET_USER_DOB,
  SET_USER_PADDRESS,
  SET_USER_PRADDRESS,
  SET_USER_PROFILE_PIC,
  SET_USER_PASSWORD,
  SET_USER_TL,
  SET_USER_DEPARTMENT,
  SET_USER_BASICSALARY,
  SET_USER_DESIGNATION,
  SET_USER_TIME,
  SET_USER_SHIFT
} from "./constant";
const initial_state = {
  all_users: [],
  one_user: [],
  emp_id: "",
  name: "",
  mobile: "",
  email: "",
  password:"",
  gender: "",
  profile_pic: "",
  dob: "",
  tl_name: "",
  department: "",
  basic_salary: "",
  designation: "",
  time: "",
  shift: "",
  
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_USER:
      return state = { ...state, all_users: action.payload };
    case SET_ONE_USER:
      return state = { ...state, one_user: action.payload };

    case SET_USER_ID:
      return state = { ...state, Emp_id: action.payload };
    case SET_USER_NAME:
      return state = { ...state, name: action.payload };
    case SET_USER_MOBILE:
      return state = { ...state, mobile: action.payload };
    case SET_USER_EMAIL:
      return state = { ...state, email: action.payload };
    case  SET_USER_PASSWORD:
      return state = { ...state, password: action.payload };
    case SET_USER_GENDER:
      return state = { ...state, gender: action.payload };
    case SET_USER_PROFILE_PIC:
      return state = { ...state, profile_pic: action.payload };
    case SET_USER_DOB:
      return state = { ...state, dob: action.payload };
    case SET_USER_PADDRESS:
      return state = { ...state, per_address: action.payload };
    case SET_USER_PRADDRESS:
      return state = { ...state, pre_address: action.payload };
    case SET_USER_TL:
      return state = { ...state, tl_name: action.payload };
    case SET_USER_DEPARTMENT:
      return state = { ...state, department: action.payload };
    case SET_USER_BASICSALARY:
      return state = { ...state, basic_salary: action.payload };
    case SET_USER_DESIGNATION:
      return state = { ...state, designation: action.payload };
    case SET_USER_TIME:
      return state = { ...state, time: action.payload };
    case SET_USER_SHIFT:
      return state = { ...state, shift: action.payload };
    default:
      return state;
  }
}
