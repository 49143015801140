export const SET_USER="SET_USER";
export const SET_ONE_USER="SET_ONE_USER";
export const SET_USER_ID="SET_USER_ID";
export const SET_USER_NAME="SET_USER_NAME";
export const SET_USER_MOBILE="SET_USER_MOBILE";
export const SET_USER_EMAIL="SET_USER_EMAIL";
export const SET_USER_GENDER="SET_USER_GENDER";
export const SET_USER_DOB="SET_USER_DOB";
export const SET_USER_PADDRESS="SET_USER_PADDRESS"
export const SET_USER_PRADDRESS="SET_USER_PRADDRESS"
export const SET_USER_PROFILE_PIC="SET_USER_PROFILE_PIC"
export const SET_USER_PASSWORD=" SET_USER_PASSWORD"
