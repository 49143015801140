import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import "react-datepicker/dist/react-datepicker.css";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid } from "rsuite";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { IconButton, Select, TablePagination } from "@mui/material";
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import "../style/style.css";
import MenuItem from '@mui/material/MenuItem';


const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  height: 130,
  bgcolor: "#fff",
  border: "2px solid #fff",
  borderRadius: "3px",
  boxShadow: 24,
  p: 4,
};
export default class superadmintask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
      Dialog: false,
      open: false,
      visibilitymodule: false,
      value: '1',
      page: 0,
      page2: 0,
      setPage: 0,
      rowsPerPage: 10,
      setRowsPerPage: 10,
      Dialog2: false,

      // taskdata: [
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "completed",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "pending",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "hold",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "ongoing",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "completed",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "pending",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "hold",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "ongoing",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "completed",
      //   },
      //   {
      //     givenby: "Albert Flores",
      //     date: "1/15/12",
      //     time: "11.00-12.00",
      //     status: "pending",
      //   },

      // ],
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  handleShow = () => {
    this.setState({
      value: 2,
    });
  };
  componentDidMount() {
    this.props.viewAlltaskGivenBy(this.props.login.user_id, this.state.page, this.state.rowsPerPage,)
    this.props.viewAlltaskGivenTo(this.props.login.user_id, this.state.page, this.state.rowsPerPage,)
    this.props.viewAllUser();
    console.log("given", this.props.login.user_id)
    // this.props.viewAlltask();
    // this.props.viewAllUser();
    // console.log("vvvvvvvvvvvv", this.props.taskmanagement.assign_time);
  }
  createData(name, Date, Time, Deadline, Progress, Status) {
    return { name, Date, Time, Deadline, Progress, Status };
  }

  render() {
    const { value } = this.state;
    const { taskmanagement } = this.props;
    const { open } = this.state;
    const { showButton } = this.state;
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: " #E5E5E5",
        color: theme.palette.common.black,
        fontSize: 22,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));

    const rows = [
      this.createData("Super Admin", "13/05/2023", "20/05/2023", "11am-12am"),
      this.createData("Team Leader", "13/05/2023", "20/05/2023", "11am-12am"),
      this.createData("Admin", "13/05/2023", "20/05/2023", "11am-12am"),
      this.createData("Team Leader", "13/05/2023", "20/05/2023", "11am-12am"),
    ];
    const handleChangePage = (event, newPage) => {
      console.log(event, newPage)
      this.props.viewAlltaskGivenBy(this.props.login.user_id, newPage, this.state.rowsPerPage);
      this.props.viewAlltaskGivenTo(this.props.login.user_id, newPage, this.state.rowsPerPage)
      this.setState({
        page: newPage,
      });
    };
    const handleChangePage2 = (event, newPage) => {
      console.log(event, newPage)
      this.props.viewAlltaskGivenTo(this.props.login.user_id, newPage, this.state.rowsPerPage)
      this.setState({
        page2: newPage,
      });
    };
    return (
      <div>
        <Grid

        >
          <TabContext value={value}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                <Tab label="My Task" value="1" />
                <Tab label="Assign Task" value="2" />
                <Tab label="Create Task" value="3" />

              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid
                // container
                // direction="row"
                alignItems="end"
                // justifyContent="space-between"
                // mr={10}
                style={{}}
              >
                <TablePagination
                  rowsPerPageOptions={[20]}
                  component="div"
                  count={Math.ceil(this.props.taskmanagement.given_to_length)} // Assuming you want 20 items per page
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page2}
                  onPageChange={handleChangePage2}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                />

              </Grid>
              <Grid style={{ marginTop: "3em", marginRight: "4rem" }}>
                <TableContainer component={Paper} >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Sl No </StyledTableCell>
                        <StyledTableCell>Task</StyledTableCell>
                        <StyledTableCell>Owner </StyledTableCell>
                        {/* <StyledTableCell align="right">Date</StyledTableCell>
                        <StyledTableCell align="right">Time</StyledTableCell> */}
                        <StyledTableCell align="right">Status</StyledTableCell>
                        <StyledTableCell align="right">Response</StyledTableCell>
                        {/* <StyledTableCell align="right">Status</StyledTableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {Array.isArray(this.props.taskmanagement.given_to) && this.props.taskmanagement.given_to.sort((a, b) => new Date(b.date) - new Date(a.date)).map((tm, index) => {
                        console.log("adsasddvvvv", tm);
                        return (
                          <TableRow>
                            <StyledTableCell component="th" scope="row">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {tm.desc}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {tm.givenBy_details.name}
                            </StyledTableCell>
                            {/* <StyledTableCell align="right">
                              {tm.date}
                            </StyledTableCell>
                            <StyledTableCell align="right">{tm.time}</StyledTableCell> */}
                            <StyledTableCell align="right">
                              <Select
                                defaultValue={tm.status}
                              >
                                <MenuItem key={1} value={"Progerss"}
                                  onClick={() => {
                                    this.setState({ status: "Progerss", task_id: tm._id, Dialog: true })
                                  }}
                                >
                                  Progerss
                                </MenuItem>
                                <MenuItem key={2} value={"Completed"}
                                  onClick={() => {
                                    this.setState({ status: "Completed", task_id: tm._id, Dialog: true })
                                  }}>
                                  Completed
                                </MenuItem>
                                <MenuItem key={3} value={"Not Started"}
                                  onClick={() => {
                                    this.setState({ status: "Not Started", task_id: tm._id, Dialog: true })
                                  }}
                                >
                                  Not Started
                                </MenuItem>
                              </Select>
                              {/* {tm.status === "completed" && (
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #03A9F4",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  Completed
                                </button>
                              )}
                              {tm.status === "pending" && (
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #FFC107",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  Pending
                                </button>
                              )}
                              {tm.status === "On-going" && (
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #4CAF50",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  On-going
                                </button>
                              )}
                              {tm.status === "hold" && (
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #FF9800",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  Hold
                                </button>
                              )} */}
                              {/* {row.Status} */}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {tm.response === "completed" ?
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #03A9F4",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  Completed
                                </button> :
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #03A9F4",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  {tm.response}
                                </button>
                              }
                              {/* <Select
                                value={"completed"}
                                onChange={(e) => {
                                  this.setState({ mail_transporter: e.target.value });
                                }}
                              >
                                <MenuItem key={1} value={"completed"}>
                                  Completed
                                </MenuItem>
                                <MenuItem key={2} value={"completed"}>
                                  Completed
                                </MenuItem>
                                <MenuItem key={3} value={"completed"}>
                                  Completed
                                </MenuItem>
                              </Select> */}
                              {/* {" "}
                              <div>
                                <Link>
                                  <VisibilityOutlinedIcon
                                    style={{ color: "black" }}
                                    onClick={() => {
                                      this.setState({
                                        visibilitymodule: true,
                                        id: tm._id,
                                        givenby: tm.givenBy_details.name,
                                        givento: tm.givenTo_details.name,
                                        time: tm.time,
                                        date: tm.date,
                                        desc: tm.desc

                                      });
                                    }}
                                  />
                                </Link>
                              </div> */}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid
                // container
                // direction="row"
                alignItems="end"
                // justifyContent="space-between"
                // mr={10}
                style={{}}
              >
                <TablePagination
                  rowsPerPageOptions={[20,]}
                  component="div"
                  count={this.props.taskmanagement.given_by_length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>

              <Grid style={{ marginTop: "3em", marginRight: "5rem" }}>
                <TableContainer component={Paper} >
                  <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Sl No </StyledTableCell>
                        <StyledTableCell>Task</StyledTableCell>
                        <StyledTableCell>Assign Leader </StyledTableCell>
                        {/* <StyledTableCell align="right">Date</StyledTableCell>
                        <StyledTableCell align="right">Time</StyledTableCell> */}
                        <StyledTableCell align="right">Status</StyledTableCell>
                        <StyledTableCell align="right">Response</StyledTableCell>
                        {/* <StyledTableCell align="right">Status</StyledTableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {Array.isArray(this.props.taskmanagement.given_by) && this.props.taskmanagement.given_by.sort((a, b) => new Date(b.date) - new Date(a.date)).map((tm, index) => {
                        console.log("given byyyyyyy", tm);
                        return (
                          <TableRow>
                            <StyledTableCell component="th" scope="row">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {tm.desc}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {tm.givenTo_details.name}
                            </StyledTableCell>
                            {/* <StyledTableCell align="right">
                              {tm.date}
                            </StyledTableCell>
                            <StyledTableCell align="right">{tm.time}</StyledTableCell> */}
                            <StyledTableCell align="right">
                              {tm.status === "completed" ?
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #03A9F4",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  Completed
                                </button> :
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #03A9F4",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  {tm.status}
                                </button>
                              }
                              {/* {tm.status === "completed" && (
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #03A9F4",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  Completed
                                </button>
                              )}
                              {tm.status === "pending" && (
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #FFC107",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  Pending
                                </button>
                              )}
                              {tm.status === "On-going" && (
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #4CAF50",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  On-going
                                </button>
                              )}
                              {tm.status === "hold" && (
                                <button
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "7px",
                                    width: "6.5em",
                                    height: "2em",
                                    background: " #FF9800",
                                  }}
                                // onClick={() =>
                                //   console.log(`Button clicked for ${row.name}`)
                                // }
                                >
                                  Hold
                                </button>
                              )} */}
                              {/* {row.Status} */}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Select
                                defaultValue={tm.response}
                              >
                                <MenuItem key={1}
                                  value={"No Response"}
                                  onClick={() => {
                                    this.setState({ response: "No Response", task_id: tm._id, Dialog2: true })
                                  }}
                                >
                                  No Response
                                </MenuItem>
                                <MenuItem key={2}
                                  value={"Completed"}
                                  onClick={() => {
                                    this.setState({ response: "Completed", task_id: tm._id, Dialog2: true })
                                  }}
                                >
                                  Completed
                                </MenuItem>
                                <MenuItem key={3}
                                  value={"Re Assign"}
                                  onClick={() => {
                                    this.setState({ response: "Re Assign", task_id: tm._id, Dialog2: true })
                                  }}
                                >
                                  Re Assign
                                </MenuItem>
                              </Select>
                              {/* {" "}
                              <div>
                                <Link>
                                  <VisibilityOutlinedIcon
                                    style={{ color: "black" }}
                                    onClick={() => {
                                      this.setState({
                                        visibilitymodule: true,
                                        id: tm._id,
                                        givenby: tm.givenBy_details.name,
                                        givento: tm.givenTo_details.name,
                                        time: tm.time,
                                        date: tm.date,
                                        desc: tm.desc
                                      });
                                    }}
                                  />
                                </Link>
                              </div> */}
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <div style={{ marginTop: "3em" }}>
                <Grid style={{ display: "flex" }}>
                  <Grid
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >

                      Given By
                    </label>
                    <TextField
                      style={{

                        color: "#000000",

                        marginTop: 10,
                        width: "450px",
                        border: "2px solid #E5E5E5",
                        borderRadius: 4,
                      }}
                      value={this.props.login.name}
                      id="outlined-basic" variant="outlined" />

                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "2em",
                    }}
                  >
                    <label
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >

                      Given To{" "}
                    </label>
                    <TextField
                      // size=''
                      style={{
                        color: "#000000",

                        marginTop: 10,
                        width: "450px",
                        border: "2px solid #E5E5E5",
                        borderRadius: 4,
                      }}
                      id="outlined-select-currency-native"
                      select
                      // value={this.state.project_priority}
                      onChange={(e) => {
                        this.setState({ givenTo: e.target.value })
                      }}
                    >
                      <MenuItem value={"N"}>{"----------"}</MenuItem>
                      {Array.isArray(this.props.taskmanagement.users) && this.props.taskmanagement.users.map((e) => (
                        <MenuItem value={e._id}>{e.name}</MenuItem>
                      ))}

                    </TextField>
                    {/* <select
                      style={{
                        height: 57,
                        color: "#000000",

                        marginTop: 10,
                        width: "544px",
                        border: "2px solid #E5E5E5",
                        borderRadius: 4,
                      }}
                      onChange={(e) => {
                        this.setState({ shift: e.target.value });
                      }}
                      name=""
                      // value={this.state.eventName}
                      select
                    >
                      <option value=""></option>
                      <option value="1st Shift-9 to 14">xyz</option>
                      <option value="  2nd Shift-14 to 19">xyz</option>
                      <option value=" Overtime- 19 to 21">xyz</option>
                    </select> */}
                  </Grid>
                </Grid>

                {/* Date */}
                {/* <Grid style={{ display: "flex" }}> */}
                {/* <Grid
                    style={{ display: "flex", flexDirection: "column", }}
                  >
                    <label
                      style={{ fontWeight: "bold", marginTop: 10, fontSize: "20px" }}

                    >
                      Date{" "}
                    </label>
                    <TextField
                      // size=''
                      style={{
                        color: "#000000",

                        marginTop: 10,
                        width: "450px",
                        border: "2px solid #E5E5E5",
                        borderRadius: 4,
                      }}
                      id="outlined-select-currency-native"
                      type="date"
                      // value={this.state.project_priority}
                      onChange={(e) => {
                        this.setState({ givenDate: e.target.value })
                      }}
                    />
                  

                  </Grid> */}

                {/* Time */}
                {/* <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "2em",
                    }}
                  >
                    <label
                      style={{ fontWeight: "bold", marginTop: 10, fontSize: "20px" }}
                    >
                      Time{" "}
                    </label>
                    <TextField
                      // size=''
                      style={{
                        color: "#000000",

                        marginTop: 10,
                        width: "450px",
                        border: "2px solid #E5E5E5",
                        borderRadius: 4,
                      }}
                      id="outlined-select-currency-native"
                      type="time"
                      // value={this.state.project_priority}
                      onChange={(e) => {
                        this.setState({ givenTime: e.target.value })
                      }}
                    />

                  </Grid> */}
                {/* </Grid> */}

                <Grid style={{ display: "flex" }}>

                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: 10,
                      }}
                    >
                      Description
                    </label>
                    <form>
                      {" "}
                      <textarea
                        type="textarea"
                        placeholder="Write description about task"
                        // style={{ width: "200px", marginLeft: "30px" }}
                        style={{
                          height: 200,
                          // marginLeft:80,
                          marginTop: 15,
                          width: "930px",
                          border: "2px solid #E5E5E5",
                          placeholder: "Write description about task",
                          // borderRadius:8
                        }}
                        name=""
                        onChange={(e) => {
                          this.setState({ todo: e.target.value });
                        }}
                      ></textarea>
                    </form>
                  </Grid>
                </Grid>

                <Grid
                  style={{ display: "flex", flexDirection: "column", marginTop: 25 }}
                >

                </Grid>
                <div>

                  <Link to={"/superadmintask"}>
                    <center>

                      <Button
                        className="addtask"
                        onClick={(e) => {
                          console.log("")
                          this.setState({ createtask: false, givenTo: "", givenDate: "", givenTime: "", todo: "", value: "2" })
                          this.props.createtask(this.props.login.user_id, this.state.givenTo, new Date(), this.state.todo, this.state.page, this.state.rowsPerPage)
                          // this.handleChange()

                        }}

                      >
                        Add Task
                      </Button>
                    </center>
                  </Link>
                </div>
              </div>

            </TabPanel>
          </TabContext>






        </Grid>





        {/* <Dialog
          style={{ backgroundColor: "#D1D1D1", opacity: 0.5 }}
          open={this.state.Dialog}

        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontWeight: "bold", fontSize: "18px" }}
            >
              Are you sure to complete the task or not ?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <div>
              <Button
                onClick={()=>{
                  this.setState({Dialog: false})
                }}
                style={{
                  fontWeight: "bold",
                  border: "2px solid  #2F2FB8",
                  background: " #fff",
                  color: "#2F2FB8",
                  borderRadius: "1px",
                  width: "4em",
                  height: "2em",
                  marginLeft: "2em",
                }}
              >
                No
              </Button>
            </div>
            <Button
              onClick={this.handleClose}
              autoFocus
              style={{
                fontWeight: "bold",
                border: "1px solid  #2F2FB8",
                background: " #2F2FB8",
                color: "white",
                borderRadius: "1px",
                width: "4em",
                height: "2em",
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog> */}
        <Dialog
          onClose={() => {
            this.setState({ Dialog: false, status: this.state.status2 })
          }}
          sx={{
            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          open={this.state.Dialog}

        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontWeight: "bold", fontSize: "18px" }}
            >
              Are you sure..? You want to Change The Status ?
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ justifyContent: "space-evenly" }}>
            <div >
              <Button
                onClick={() => {
                  this.setState({ Dialog: false })
                }}
                style={{
                  fontWeight: "bold",
                  border: "2px solid  #2F2FB8",
                  background: " #fff",
                  color: "#2F2FB8",
                  borderRadius: "1px",
                  width: "4em",
                  height: "2em",
                  marginLeft: "2em",
                }}
              >
                No
              </Button>
            </div>
            <Button
              onClick={() => {
                this.setState({ Dialog: false })
                this.props.update_task_status(this.state.task_id, this.state.status, this.props.login.user_id, this.state.page_number, this.state.rowsPerPage)

              }}
              autoFocus
              style={{
                fontWeight: "bold",
                border: "1px solid  #2F2FB8",
                background: " #2F2FB8",
                color: "white",
                borderRadius: "1px",
                width: "4em",
                height: "2em",
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => {
            this.setState({ Dialog2: false, response: this.state.response2 })
          }}
          sx={{
            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          open={this.state.Dialog2}

        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontWeight: "bold", fontSize: "18px" }}
            >
              Are you sure..? You want to Change The Response ?
            </DialogContentText>
          </DialogContent>

          <DialogActions style={{ justifyContent: "space-evenly" }}>
            <div >
              <Button
                onClick={() => {
                  this.setState({ Dialog2: false })
                }}
                style={{
                  fontWeight: "bold",
                  border: "2px solid  #2F2FB8",
                  background: " #fff",
                  color: "#2F2FB8",
                  borderRadius: "1px",
                  width: "4em",
                  height: "2em",
                  marginLeft: "2em",
                }}
              >
                No
              </Button>
            </div>
            <Button
              onClick={() => {
                this.props.update_task_response(this.state.task_id, this.state.response, this.props.login.user_id, this.state.page_number, this.state.rowsPerPage)
                this.setState({Dialog2:false})
              }

              }
              autoFocus
              style={{
                fontWeight: "bold",
                border: "1px solid  #2F2FB8",
                background: " #2F2FB8",
                color: "white",
                borderRadius: "1px",
                width: "4em",
                height: "2em",
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.visibilitymodule} style={{}}>
          <Grid style={{ height: "520px", width: "450px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginLeft: "5% ",
                  marginTop: "20px",
                }}
              >
                View Task
              </Typography>
              <CloseOutlinedIcon
                onClick={() => {
                  this.setState({ visibilitymodule: false });
                }}
                style={{
                  // marginLeft: "400px",
                  marginTop: "20px",
                  cursor: "pointer",
                  marginRight: "5%"
                }}
              />
            </div>
            <Divider style={{ color: "#000", borderWidth: 1, borderColor: "#000", marginTop: "4%" }} />

            <Box style={{ margin: "7% 7% 7% 7% ", gap: "3rem" }}>
              <Typography >Given by- {this.state.givenby}</Typography>
              <br></br>
              <Typography>Given to- {this.state.givento}</Typography>
              <br></br>
              <Typography>Time- {this.state.time}</Typography>
              <br></br>
              <Typography>Date- {this.state.date}</Typography>
              <br></br>
              <Typography>
                Description-<br></br><br></br>
                {this.state.desc}
              </Typography>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6%",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({ visibilitymodule: false });
                  }}
                  style={{
                    marginLeft: 10,
                    width: "23%",
                    backgroundColor: "grey",
                    borderRadius: "5px",
                    height: "40px"
                  }}
                >
                  Okay
                </Button>
              </Box>
            </Box>
          </Grid>
        </Dialog>
      </div>
    );
  }
}
