import React, { Component } from 'react'
import {
    Grid,
    CardContent,
    Box,
    IconButton,
    Button,
    Typography,
    Icon, DialogActions,
    DialogTitle,
    Tooltip

} from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import {

    Navigate,
} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import "../../style/color.css"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Snackbar from "../../../common/snackbar/components/snackbar";
import LoaderCon from "../../../common/loader/containers/loader_cont";

export default class color extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            delete: false,
            add: false,
            view: false,
            imageUrl: null,
            imageAlt: null,
            // color_code: "",
            department_name: ""

        }
    }
    componentDidMount() {
        this.props.viewAlldepartment();

    }


    render() {
        const {
            superadmin,
            snackbar,
            close_snack_bar
        } = this.props;
        const { imageUrl, imageAlt } = this.state;
        if (this.state.redirect === true) {
            return <Navigate to="/" />;
        }
        return (
            <Grid>

                <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box className='colorname'>
                        <center>

                            <Button style={{ color: "white ", textAlign: 'center', }}>Department</Button>
                        </center>
                    </Box>

                    <Box style={{ marginRight: "23%" }}>
                        <center style={{ borderRadius: 0 }}>

                            <Button className='coloradd' style={{ color: "white ", textAlign: 'center', }} onClick={() => {
                                this.setState({
                                    add: true,
                                })
                            }}>Add Department</Button>
                        </center>
                    </Box>

                </Grid>
                <Box
                    marginLeft={'2.5%'}
                    // marginTop={"%"}
                    marginRight={"5%"}
                >
                    <CardContent style={{ marginRight: "25%" }}>
                        <TableContainer component={Paper}>
                            <Table style={{ minWidth: 300 }} size="small" aria-label="a dense table" ref={this.tableRef}>
                                <TableHead className="colorhead">
                                    <TableRow >
                                        {/* <TableCell>Color Code</TableCell> */}
                                        <TableCell>Department Name</TableCell>

                                        <TableCell style={{ marginRight: "2px" }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="colorbody">
                                    {superadmin.all_department.map((c) => {
                                        return (
                                            <TableRow
                                                // key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >

                                                <TableCell >{c.department_name}</TableCell>

                                                <TableCell  >
                                                    {/* <IconButton color="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                quotation: true,
                                                            })
                                                        }}
                                                        style={{ marginLeft: "-35px" }}
                                                    >
                                                        <Icon style={{ color: 'black' }} >
                                                            assignmentIcon
                                                        </Icon>

                                                    </IconButton> */}
                                                    {/* <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                view: true,
                                                               


                                                            });
                                                        }}
                                                    >
                                                        <Icon style={{ color: 'blue' }}>
                                                            visibility
                                                        </Icon>
                                                    </IconButton> */}
                                                    <Tooltip title="Edit">
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    edit: true,
                                                                    _id: c._id,
                                                                    department_name: c.department_name,
                                                                    // color_code: c.color_code,
                                                                });

                                                            }}
                                                            style={{ marginLeft: "-5px" }}
                                                        >
                                                            <Icon style={{ color: 'green' }}>
                                                            edit
                                                        </Icon>
                                                            {/* <EditOutlinedIcon style={{ color: 'green' }} /> */}
                                                        </IconButton>
                                                    </Tooltip>
                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({
                                                                delete: true,
                                                                _id: c._id,
                                                            })

                                                        }}
                                                    >
                                                        {/* <Icon style={{ color: 'red' }}> */}
                                                        <DeleteOutlineOutlinedIcon style={{ color: 'red' }} />
                                                        {/* </Icon> */}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>

                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </CardContent>











                    <Dialog
                        open={this.state.edit}
                        // onClose={() => {
                        //     this.setState({ add: false });
                        // }}
                        // style={{wi}}
                        style={
                            {
                                // top: "50%",
                                // left: "10%",
                                // right: "auto",
                                // bottom: "auto",
                                // marginRight: "-50%",
                                // transform: "translate(-50%, -50%)",
                                // width: "1000px",
                                // marginLeft: "500px",
                                // paddingTop: "40px",
                                // paddingLeft: "25px",
                                // paddingRight: "25px",
                                // borderRadius: "0px",
                                height: "700px",
                                // zIndex: "200",
                                // backgroundColor: "#fff"
                            }}
                    >
                        <Grid style={{ backgroundColor: "#fff", height: "220px", minWidth: "320px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                {/* <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>
                                    
                                    <TextField
                                        required={true}
                                        id="outlined-required"
                                        label="Color Id"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "280px" }}
                                        value={this.state.color_code}
                                        onChange={(e) => { this.setState({ color_code: e.target.value }) }}

                                    />

                                </div> */}

                                <div class="input-group mb-3" style={{ marginLeft: "20px" ,marginTop:"20px"}}>
                                    <TextField
                                        required={true}
                                        id="outlined-required"
                                        label="Department Name"

                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "280px" }}
                                        value={this.state.department_name}
                                        onChange={(e) => { this.setState({ department_name: e.target.value }) }}

                                    />

                                </div>


                                <Button className='btns'
                                    // disabled={this.state.color_name === "" || this.state.color_code === ""}
                                    onClick={() => {
                                        this.props.updatedepartment(this.state._id, this.state.department_name);
                                        this.setState({ edit: false });
                                    }}
                                    // disabled = {this.state.color_name==="" || this.state.color_code===""}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Update</Button>
                                <Button className='btns' onClick={() => {
                                    this.setState({ edit: false });
                                }} style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Close</Button>


                            </Grid>

                        </Grid>

                    </Dialog>
                    <Dialog
                        open={this.state.delete}
                        onClose={() => {
                            this.setState({ delete: false });
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100%", height: "100%" }}
                    >
                        {/* <IconButton
                    > */}
                        <HighlightOffOutlinedIcon style={{ fontSize: "60", color: "red", fontWeight: "100", marginLeft: "80", marginTop: "20" }} />
                        {/* </IconButton> */}
                        <DialogTitle style={{ textAlign: "center", fontSize: "1.5rem" }}>Are You Sure ?</DialogTitle>

                        <DialogActions style={{ marginTop: "15px", marginRight: "0px" }}>
                            <Button className="delete" variant="contained"
                                style={{}}
                                onClick={() => {
                                    this.setState({ delete: false });
                                }}
                            >Cancel
                            </Button>
                            <Button className="delete" variant="contained" onClick={() => { this.props.deletedepartment(this.state._id); this.setState({ delete: false }) }}>

                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.add}
                        // onClose={() => {
                        //     this.setState({ add: false });
                        // }}
                        // style={{wi}}
                        style={
                            {
                                // top: "50%",
                                // left: "10%",
                                // right: "auto",
                                // bottom: "auto",
                                // marginRight: "-50%",
                                // transform: "translate(-50%, -50%)",
                                // width: "1000px",
                                // marginLeft: "500px",
                                // paddingTop: "40px",
                                // paddingLeft: "25px",
                                // paddingRight: "25px",
                                // borderRadius: "0px",
                                height: "700px",
                                // zIndex: "200",
                                // backgroundColor: "#fff"
                            }}
                    >
                        <Grid style={{ backgroundColor: "#fff", height: "220px", minWidth: "330px", scrollBehavior: "smooth", overflow: "revert-layer", alignSelf: "center", overflowX: "hidden", flexDirection: "row", display: "flex", overflowY: "hidden" }}>
                            <Grid>
                                {/* <div class="input-group mb-3" style={{ marginLeft: "20px", marginTop: "20px" }}>
                                    
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Color Code"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "280px" }}
                                        onChange={(e) => { this.setState({ color_code: e.target.value }) }}
                                    />

                                </div> */}

                                <div class="input-group mb-3" style={{ marginLeft: "20px",marginTop:"30px" }}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Department Name"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        style={{ height: "50px", width: "280px" }}
                                        onChange={(e) => { this.setState({ department_name: e.target.value }) }}

                                    // onChange={(e) => { this.setState({ name: e.target.value }) }}
                                    />

                                </div>



                                <Button
                                    className='btns'

                                    // disabled={this.state.department_name === "" && this.state.department_name === ""}
                                    onClick={() => {
                                        this.props.createdeparment(this.state.department_name);
                                        this.setState({ add: false });
                                    }}
                                    style={{ marginLeft: "20px", marginBottom: "30px", marginTop: "20px", color: "white" }}>Add</Button>
                                <Button className='btns' onClick={() => {
                                    this.setState({ add: false });
                                }} style={{ marginLeft: "20px", color: "white", marginBottom: "30px", marginTop: "20px" }}>Close</Button>


                            </Grid>

                        </Grid>

                    </Dialog>


                </Box>
                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />

            </Grid>
        )
    }
}