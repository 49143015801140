import React, { Component } from "react";
import { connect } from "react-redux";
import Client from "../Components/Leads2";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";

import {
  viewAlltheClients,
  viewAllWebSiteClient,
  viewAllWebSiteDealer,
  viewAllWebSiteDealerDate,
  viewAllWebSiteClientDate
} from "../../leads/action";
import {
  viewModel,
} from "../../model/action";
import {
  createCustomer,
} from "../../customer/action";

export class Controller extends Component {
  render() {
    return (
      <Client {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    lead: store.lead,
    login: store.login,
    snackbar: store.snackbar,
    all_category: store.all_category,
    all_model: store.all_model,
  };
};
export const mapDispatchToProps = dispatch => {
  return {

    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAlltheClients: (admin_id,name,type,status,pagenumber) => {
      dispatch(viewAlltheClients(admin_id,name,type,status,pagenumber))
    },
    viewAllWebSiteDealer: (pagenumber) => {
      dispatch(viewAllWebSiteDealer(pagenumber))
    },
    viewAllWebSiteClient: (pagenumber) => {
      dispatch(viewAllWebSiteClient(pagenumber))
    },
    viewAllWebSiteDealerDate: (date,pagenumber) => {
      dispatch(viewAllWebSiteDealerDate(date, pagenumber))
    },
    viewAllWebSiteClientDate: (date,pagenumber) => {
      dispatch(viewAllWebSiteClientDate(date, pagenumber) )
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);