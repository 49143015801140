import React, { Component } from "react";
import { connect } from "react-redux";
import Client from "../component/client";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";

import {
  viewAlltheClients,
  addLeadClient,
  viewClientsFollowup,
  updateNewFollowup,
  updateClient,
  setClientId,
  searchNAME,
  filter_status,
  filter_type
} from "../action";
import {
  viewModel,
} from "../../model/action";
import {
  createCustomer,
} from "../../customer/action";

export class Controller extends Component {
  render() {
    return (
      <Client {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    lead: store.lead,
    login: store.login,
    snackbar: store.snackbar,
    all_category: store.all_category,
    all_model: store.all_model,
  };
};
export const mapDispatchToProps = dispatch => {
  return {

    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewAlltheClients: (admin_id,name,type,status,pagenumber) => {
      dispatch(viewAlltheClients(admin_id,name,type,status,pagenumber))
    },
    viewClientsFollowup: (client_id) => {
      dispatch(viewClientsFollowup(client_id))
    },
    searchNAME: (all_client_details,e) => {
      dispatch(searchNAME(all_client_details,e))
    },
    filter_status: (all_client_details,status) => {
      dispatch(filter_status(all_client_details,status))
    },
    filter_type: (all_client_details,type) => {
      dispatch(filter_type(all_client_details,type))
    },
    updateClient: (client_id,model_id,name, company, phone, email, location, source,admin_id,alternate_phone) => {
      dispatch(updateClient(client_id,model_id,name, company, phone, email, location, source,admin_id,alternate_phone))
    },
    setClientId: (client_id) => {
      dispatch(setClientId(client_id))
    },
    addLeadClient: (admin_id, name, company, phone, email, location, source, type, status, message,model_id,follow_up_date,alternate_phone) => {
      dispatch(addLeadClient(admin_id, name, company, phone, email, location, source, type, status, message,model_id,follow_up_date,alternate_phone))
    },
    updateNewFollowup: (client_id, follow_up_date, type, status, message,admin_id) => {
      dispatch(updateNewFollowup(client_id, follow_up_date, type, status, message,admin_id))
    },
    viewModel: () => {
      dispatch(viewModel())
    },
    createCustomer: (admin_id, name, mobile, email, address, aadhar, pan, gst, profile_pic, cust_gst_no, other_doc1, other_doc2) => {
      dispatch(createCustomer(admin_id, name, mobile, email, address, aadhar, pan, gst, profile_pic, cust_gst_no, other_doc1, other_doc2))
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);