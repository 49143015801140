import {
  SET_CLIENTS,
  SET_CLIENTS_FOLLOWUP,
  SET_CLIENT_ID,
  SET_ALL_CLIENT_DETAILS,
  SET_FILTERED_ALL_CLIENT_DETAILS,
  SET_CLIENT_NAME
} from "./constant";
const initial_state = {
  clients_details: [],
  clients_followup: [],
  client_id:"",
  all_client_details:[],
  client_name:"",
  filtered_lead:[],
  length:0,
  today_length:0,
  
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case SET_CLIENTS:
      return state = { ...state, clients_details: action.payload.data, today_length: action.payload.count };
    case SET_CLIENTS_FOLLOWUP:
      return state = { ...state, clients_followup: action.payload };
    case SET_CLIENT_ID:
      return state = { ...state, client_id: action.payload };
    case SET_ALL_CLIENT_DETAILS:
      return state = { ...state, all_client_details: action.payload.data,length: action.payload.count};
    case SET_CLIENT_NAME:
      return state = { ...state, client_name: action.payload };
    case SET_FILTERED_ALL_CLIENT_DETAILS:
      return state = { ...state, filtered_lead: action.payload.data };

    default:
      return state;
  }
}
