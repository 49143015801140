import React, { Component } from "react";
import { connect } from "react-redux";
import Order from "../component/orderdash";
// import {
//   close_snack_bar
// } from "../../common/snackbar/action";



import{
  viewAllOrder
}from "../../orders/action"//done





export class Controller extends Component {
  render() {
    return (
      <Order {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {

    viewAllOrder:store. viewAllOrder
   
  };
};
export const mapDispatchToProps = dispatch => {
  return {
  
    
    viewAllOrder: (admin_id) => {
      dispatch(viewAllOrder(admin_id))//done
    },
   
   
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);