import {
    SET_SERVICE,
    SET_DELIVERED_VECHICLE,
    SET_SERVICE_ALLOTTED,
    SET_EDIT_SERVICE,
    SEARCH_SERVICE,
    BOOKING_SERVICE,
    COMPLETED_SERVICE
} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
// import { set_snack_bar } from "../../common/snackbar/action";
// import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import 'firebase/storage';
// import firebase from 'firebase/app'
import { set_snack_bar } from "../../../common/snackbar/action";


export function createService(admin_id, customer_id, phone, email, battery_number, model_name, agent_name, page_number, rowper_page) {
    console.log(admin_id, customer_id, phone, email, battery_number, model_name, agent_name)
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_service_skyRide", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                executive_id: admin_id,
                customer_id: customer_id,
                phone: phone,
                email: email,
                battery_number: battery_number,
                model_name: model_name,
                agent_name: agent_name,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllService(admin_id, page_number, rowper_page));
                    dispatch(view_All_Service_By_Admin_And_Superadmin(admin_id, page_number, rowper_page));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function createServiceOutside(admin_id, pan_card, adhar_number, name, email, phone, model_name, battery_number, page_number, rowper_page) {
    // console.log(admin_id, customer_id,phone, email, battery_number,  model_name, agent_name)
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_service_outside", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                executive_id: admin_id,
                pan_card: pan_card,
                adhar_number: adhar_number,
                name: name,
                email: email,
                phone: phone,
                model_name: model_name,
                battery_number: battery_number
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllService(admin_id, page_number, rowper_page));
                    dispatch(view_All_Service_By_Admin_And_Superadmin(admin_id, page_number, rowper_page));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateService(admin_id, id, phone, email, battery_number, model_name, agent_name, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                service_id: id,
                phone: phone,
                email: email,
                battery_number: battery_number,
                model_name: model_name,
                agent_name: agent_name
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asdcxv", responseJson)
                if (responseJson.status) {
                    dispatch(viewAllService(admin_id, page_number, rowper_page));
                    dispatch(view_All_Service_By_Admin_And_Superadmin(admin_id, page_number, rowper_page));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
// this.props.updateBook(this.props.login.user_id, this.props.login.org._id, e._id);

export function updateServiceStatus(admin_id, id, status, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_service_status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                service_id: id,
                status: status
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asdcxv", responseJson)
                if (responseJson.status) {
                    dispatch(viewAllService(admin_id, page_number, rowper_page));
                    dispatch(view_All_Service_By_Admin_And_Superadmin(admin_id, page_number, rowper_page));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteService(admin_id, id, page_number, rowper_page) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                service_id: id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {
                    console.log(admin_id, id, page_number, rowper_page)
                    dispatch(viewAllService(admin_id, page_number, rowper_page));
                    dispatch(view_All_Service_By_Admin_And_Superadmin(admin_id, page_number, rowper_page));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAllService(admin_id, page_number, rowper_page) {
    console.log(admin_id, page_number, rowper_page)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_service_by_admin", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_SERVICE, payload: { data: responseJson.result, count: responseJson.length } });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_SERVICE, payload: { data: [], count: 0 } })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}



export function viewAllServiceBySA(page_number,rowper_page) {
    return (dispatch) => {
        console.log("viewSA")
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                page_number: page_number,
                rowper_page: rowper_page
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    console.log("viewSA", responseJson)
                    dispatch({ type: SET_SERVICE, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_SERVICE, payload: { data: [], count: 0 } })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function view_All_Service_By_Admin_And_Superadmin(admin_id, page_number, rowper_page) {
    console.log(admin_id, page_number, rowper_page)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_service", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
                page_number: page_number,
                rowper_page: rowper_page
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    console.log(responseJson)
                    dispatch({ type: SET_SERVICE, payload: { data: responseJson.result, count: responseJson.length } });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_SERVICE, payload: { data: [], count: 0 } })
                   
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewDeliveredVechicle() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_vechicle_service", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_DELIVERED_VECHICLE, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_DELIVERED_VECHICLE, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewAllServiceAllotted(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_service_allotted", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
            })

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_SERVICE_ALLOTTED, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_SERVICE_ALLOTTED, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function serviceCompleted(id, admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "service_completed", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                service_id: id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAllService(admin_id));
                    dispatch(viewDeliveredVechicle());
                    dispatch(viewAllServiceAllotted(admin_id));

                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_booking_service() {
    console.log("book")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_booking_service", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resbook", responseJson)
                if (responseJson.status) {
                    dispatch({ type: BOOKING_SERVICE, payload: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: BOOKING_SERVICE, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function view_All_Service_Booking_By_Admin(admin_id) {
    console.log("book")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_service_booking_by_admin", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resbook", responseJson)
                if (responseJson.status) {
                    dispatch({ type: BOOKING_SERVICE, payload: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: BOOKING_SERVICE, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_All_Service_Completed_By_Admin(admin_id) {
    console.log("book")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_service_completed_by_admin", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                admin_id: admin_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("resbook", responseJson)
                if (responseJson.status) {
                    dispatch({ type: COMPLETED_SERVICE, payload: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: COMPLETED_SERVICE, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_completed_service() {
    console.log("com")
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_completed_service", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("rescom", responseJson)
                if (responseJson.status) {
                    dispatch({ type: COMPLETED_SERVICE, payload: responseJson.length });
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: COMPLETED_SERVICE, payload: 0 })
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function search_service(all_service, e) {
    return (dispatch) => {
        console.log(e)
        if (e === "") {
            dispatch({ type: SEARCH_SERVICE, payload: all_service });
        }
        else {
            const newArray = all_service.filter((el) => {
                console.log(el.customer_details.cust_name)
                return (el.customer_details.cust_name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_SERVICE, payload: newArray })
        }
    };
}

export function setService(payload) {
    return {
        type: SET_EDIT_SERVICE,
        payload: payload,
    };
}