import React, { Component } from "react";
import { connect } from "react-redux";
import Editvariant from "../component/editvariant";
import {
  close_snack_bar
} from "../../../../common/snackbar/action";
import {
  //   createVarient,
  update_model_picture,
  //   deleteVariant,
  viewVarient,
  viewVarientByid,
  setvariantid,
  setCategoryid,
  setModelid,
  setVariantname,
  setBraking,
  // setSpeed,
  setModelpic,
  setRange,
  setMotor,
  setWarranty,
  setCapacity,
  setChargeTime,
  // setBatteryType,
  // setBatteryVolt,
  setSystem,
  setLength,
  setWidth,
  setRearaxle,
  setExshowroomprice,
  setInsuranceprice,
  setRegistrationprice,
  setModel,
  setCategory
} from "../action";
import {
  viewAllCategory
} from "../../category/action";
import {
  viewModel,
  viewCategortModel
} from "../../model/action";
export class Controller extends Component {
  render() {
    return (
      <Editvariant {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    all_varient: store.all_varient,
    all_category: store.all_category,
    all_model: store.all_model,
    snackbar: store.snackbar,
    login: store.login,

  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // createVarient: (category_id, model_id, braking, speed, range, motor, warranty, capacity, charging_time, battery_type, battery_voltage, system, overall_length, width, rear_axle) => {
    //   dispatch(createVarient(category_id, model_id, braking, speed, range, motor, warranty, capacity, charging_time, battery_type, battery_voltage, system, overall_length, width, rear_axle))
    // },
    update_model_picture: (id, category_id, model_id, variant_name, model_pic_old, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price) => {
      dispatch(update_model_picture(id, category_id, model_id, variant_name, model_pic_old, model_pic, braking, range, motor, warranty, capacity, charging_time, system, overall_length, width, rear_axle, exshowroom_price, insurance_price, registration_price))
    },
    // deleteVariant: (id) => {
    //   dispatch(deleteVariant(id))
    // },
    viewVarient: () => {
      dispatch(viewVarient())
    },
    viewVarientByid: (variant_id) => {
      dispatch(viewVarientByid(variant_id))
    },
    viewAllCategory: () => {
      dispatch(viewAllCategory())
    },
    viewModel: () => {
      dispatch(viewModel())
    },
    viewCategortModel: (category_id) => {
      dispatch(viewCategortModel(category_id))
    },
    setvariantid: (_id) => {
      dispatch(setvariantid(_id))
    },
    setCategoryid: (category_id) => {
      dispatch(setCategoryid(category_id))
    },
    setModelid: (model_id) => {
      dispatch(setModelid(model_id))
    },
    setVariantname: (variant_name) => {
      dispatch(setVariantname(variant_name))
    },

    setBraking: (braking) => {
      dispatch(setBraking(braking))
    },
    setModelpic: (model_pic) => {
      dispatch(setModelpic(model_pic))
    },
    // setSpeed: (speed) => {
    //   dispatch(setSpeed(speed))
    // },
    setRange: (range) => {
      dispatch(setRange(range))
    },
    setMotor: (motor) => {
      dispatch(setMotor(motor))
    },
    setWarranty: (warranty) => {
      dispatch(setWarranty(warranty))
    },
    setCapacity: (capacity) => {
      dispatch(setCapacity(capacity))
    },
    setChargeTime: (charging_time) => {
      dispatch(setChargeTime(charging_time))
    },
    // setBatteryType: (battery_type) => {
    //   dispatch(setBatteryType(battery_type))
    // },
    // setBatteryVolt: (battery_voltage) => {
    //   dispatch(setBatteryVolt(battery_voltage))
    // },
    setSystem: (system) => {
      dispatch(setSystem(system))
    },

    setLength: (overall_length) => {
      dispatch(setLength(overall_length))
    },
    setWidth: (width) => {
      dispatch(setWidth(width))
    },

    setRearaxle: (rear_axle) => {
      dispatch(setRearaxle(rear_axle))
    },
    setExshowroomprice: (exshowroom_price) => {
      dispatch(setExshowroomprice(exshowroom_price))
    },
    setInsuranceprice: (insurance_price) => {
      dispatch(setInsuranceprice(insurance_price))
    },
    setRegistrationprice: (registration_price) => {
      dispatch(setRegistrationprice(registration_price))
    },
    setModel: (model) => {
      dispatch(setModel(model))
    },
    setCategory: (category) => {
      dispatch(setCategory(category))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    // setcategoryname: (rear_axle) => {
    //   dispatch(setcategoryname(rear_axle))
    // },
    // setmodalname: (rear_axle) => {
    //   dispatch(setmodalname(rear_axle))
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);