import {
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    IconButton,
    Icon,
    TextField,
    DialogContent,
    DialogActions,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import OutlinedInput from "@mui/material/OutlinedInput";

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { Close } from "@material-ui/icons";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Navigate, redirect } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
// import AddIcon from "@mui/icons-material/Add";
import LoaderCon from "../../../../common/loader/containers/loader_cont";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../style/order.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { Dropdown } from "semantic-ui-react";
import { Textarea } from "@mui/joy";
import Snackbar from "../../../../common/snackbar/components/snackbar";
// import { colourOptions } from '../data';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Radio from '@mui/material/Radio';

const options = [
    { key: "English", text: "English", value: "English" },
    { key: "French", text: "French", value: "French" },
    { key: "Spanish", text: "Spanish", value: "Spanish" },
    { key: "German", text: "German", value: "German" },
    { key: "Chinese", text: "Chinese", value: "Chinese" },
];

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "20ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));

export default class Dashboard extends React.Component {
    // state = { options }
    constructor(props) {
        super(props);
        this.state = {
            // edit: false,
            // delete: false,
            // add: false,
            // view: false,
            // quotation: false,

            addnewCustomer: false,
            addCustomer: false,
            addview: false,

            options: "",
            // imageUrl: null,
            // imageAlt: null,
            customer_id: "",
            vechicle_id: "",
            address: "",
            comment: "",
            phone: "",
            email: "",
            battery: "",
            model: "",
            agent: "",
            selectedValue: 'a',
        };
        this.handleChange = this.handleChange.bind(this);
    }
    // handleAddition = (e, { value }) => {
    //     this.setState((prevState) => ({
    //         options: [{ text: value, value }, ...prevState.options],
    //     }))
    // }

    handleChange(event) {
        this.setState({ selectedValue: event.target.value });
    }
    // handleChange = (e, { value }) => this.setState({ currentValue: value })

    componentDidMount() {
        this.props.viewAllService(this.props.login.user_id);
        this.props.viewAllCustomerByAdmin(this.props.login.user_id);
        this.props.viewDeliveredVechicle();
        // this.props.viewModel();
    }
    render() {
        const { all_service, all_customer, snackbar, login, close_snack_bar } =
            this.props;
        // const { currentValue } = this.state
        // console.log(all_model.all_category_model)
        if (this.state.redirect === true) {
            return <Navigate to="/" />;
        }
        const { selectedValue } = this.state;

        return (
            <>
                <Grid>
                    <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box className='makeorder'>
                            <center style={{ display: "flex" }}>
                                <Link to="/service" style={{ textDecoration: "none" }}>
                                    <IconButton
                                        style={{ marginLeft: "18px" }}

                                    >
                                        <ArrowBackIcon style={{ color: "white" }} />
                                    </IconButton>
                                </Link>


                                <Typography style={{ color: "white ", marginLeft: "40px", fontSize: 16, marginTop: "8px" }}>Add Service</Typography>
                            </center>
                        </Box>


                    </Grid>
                    <Box sx={{ display: "flex", gap: 7, marginTop: 7 }}>
                        <div style={{ display: "flex", gap: "14px" }}>

                            <Radio
                                checked={selectedValue === 'a'}
                                onChange={this.handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                            /> <h5>Sky Rider Electrics</h5>

                        </div>

                        <div style={{ display: "flex", gap: "14px" }}>

                            <Radio
                                checked={selectedValue === 'b'}
                                onChange={this.handleChange}
                                value="b"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'B' }}
                            /> <h5>Outside</h5>

                        </div>
                    </Box>
                    {selectedValue === "a" && <div>

                        <h4 style={{ marginTop: "3%" }}>Customer Details</h4>

                        <Grid style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                            <Box>
                                <div>
                                    <TextField
                                        id="standard-basic"
                                        label="Customer Name"
                                        required
                                        variant="standard"
                                        select
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        value={this.state.cust_name}
                                        onChange={(e) => {
                                            this.setState({ customer_id: e.target.value });
                                        }}

                                    >
                                        <MenuItem value={"SELECT CUSTOMER"}>{"SELECT CUSTOMER"}</MenuItem>
                                        {Array.isArray(all_customer.customer) && all_customer.customer.map((e) => (
                                            <MenuItem value={e._id}>{e.cust_name}</MenuItem>
                                        ))}
                                    </TextField>


                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Phone Number"
                                        variant="standard"
                                        required
                                        type="number"
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ phone: e.target.value })
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Email-ID"
                                        variant="standard"
                                        type="email"
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Battery Number"
                                        variant="standard"
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ battery: e.target.value })
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Model Number"
                                        variant="standard"
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ model: e.target.value })
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Agent Name"
                                        variant="standard"
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ agent: e.target.value })
                                        }}
                                    />
                                </div>
                            </Box>
                            <div>
                                <Link to="/service" style={{ textDecoration: "none" }}>

                                    <Button variant="contained" style={{ background: "blue", color: "white", marginTop: "5%" }}
                                        onClick={(e) => {
                                            this.props.createService(this.props.login.user_id, this.state.customer_id, this.state.phone, this.state.email, this.state.battery, this.state.model, this.state.agent, this.state.page, this.state.rowsPerPage)

                                        }}
                                    >Submit</Button>
                                </Link>
                            </div>
                        </Grid>
                    </div>}
                    {selectedValue === "b" && <div>

                        <h4 style={{ marginTop: "3%" }}>Customer Details</h4>

                        <Grid style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                            <Box>
                                <div>
                                    <TextField
                                        id="standard-basic"
                                        label="Pan Card"
                                        required
                                        variant="standard"
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ pan_card: e.target.value });
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Aadhar card"
                                        variant="standard"
                                        required
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ adhar_number: e.target.value });
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Customer Name"
                                        variant="standard"
                                        required
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Phone Number"
                                        variant="standard"
                                        required
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ phone: e.target.value });
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Model Number"
                                        variant="standard"
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ model_name: e.target.value });
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Battery Number"
                                        variant="standard"
                                        style={{ width: "500px", marginBottom: "2%" }}
                                        onChange={(e) => {
                                            this.setState({ battery_number: e.target.value });
                                        }}
                                    />
                                </div>

                            </Box>
                            <div>
                            <Link to="/service" style={{ textDecoration: "none" }}>

                                <Button variant="contained" style={{ background: "gray", color: "white", marginTop: "5%" }}
                                onClick={(e) => {
                                    this.props.createServiceOutside(this.props.login.user_id, this.state.pan_card, this.state.adhar_number, this.state.name, this.state.email, this.state.phone, this.state.model_name, this.state.battery_number, this.state.page, this.state.rowsPerPage)

                                }}
                                >Submit</Button>
                                </Link>
                            </div>
                        </Grid>
                    </div>}
                </Grid>




                {/* dialog box 1 */}


                <Dialog
                    open={this.state.addnewCustomer}
                    onClose={() => {
                        this.setState({ addnewCustomer: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%", }}
                >


                    <Grid style={{ backgroundColor: "#F8F8F8", minWidth: "380px", padding: "10px" }}>


                        <CloseIcon

                            onClick={() => { this.setState({ addnewCustomer: false }) }}
                            style={{ marginLeft: "90%", cursor: "pointer" }}

                        />
                        <Box>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography> Esther Howard</Typography>

                                <Checkbox />

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography> Guy Hawkins</Typography>

                                <Checkbox />

                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography> Ronald Richards</Typography>

                                <Checkbox />

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography> Jane Kaooper</Typography>

                                <Checkbox />

                            </div>

                        </Box>

                        <DialogActions style={{ marginTop: "3%", marginBottom: "2%", marginRight: "15%", alignItems: "center" }}>


                            <Button variant="contained"
                                style={{ backgroundColor: "blue", fontSize: "15px", }}
                                onClick={() => { this.setState({ addCustomer: true }) }}>
                                <AddIcon />
                                Add New Customer
                            </Button>


                        </DialogActions>
                    </Grid>






                </Dialog>

                {/* dialog box 2 */}

                <Dialog
                    open={this.state.addCustomer}
                    onClose={() => {
                        this.setState({ addCustomer: false });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ width: "100%", height: "100%", }}
                >


                    <Grid style={{ backgroundColor: "#F8F8F8", minWidth: "380px", padding: "10px" }}>


                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography>
                                Add Customer
                            </Typography>
                            <CloseIcon

                                onClick={() => { this.setState({ addCustomer: false }) }}
                                style={{ cursor: "pointer" }} />
                        </div>

                        <hr></hr>
                        <Box style={{ marginLeft: "9%" }}>
                            <div style={{ marginBottom: "6%", lineHeight: "9px" }}>
                                <Typography >Name</Typography>
                                <TextField id="outlined-basic" label="Name" variant="outlined" style={{ width: "300px" }} />
                            </div>
                            <div style={{ marginBottom: "6%", lineHeight: "9px" }}>
                                <Typography>Phone Number</Typography>
                                <TextField id="outlined-basic" label="Phone number" variant="outlined" style={{ width: "300px" }} />
                            </div>
                            <div style={{ marginBottom: "6%", lineHeight: "9px" }}>
                                <Typography>Email-ID</Typography>
                                <TextField id="outlined-basic" label="Email Id" variant="outlined" style={{ width: "300px" }} />
                            </div>
                            <div style={{ marginBottom: "2%" }}>
                                <Typography>Address</Typography>
                                <Textarea minRows={3} style={{ width: "300px" }} />
                                {/* <TextField id="outlined-basic"  label="Disabled" variant="outlined"  /> */}
                            </div>

                        </Box>

                        <DialogActions style={{ marginTop: "3%", marginRight: "15%", alignItems: "center" }}>


                            <Button variant="contained"
                                style={{ backgroundColor: "gray", color: "white", fontSize: "15px", marginRight: "25%", marginBottom: "2%" }}
                                onClick={() => { this.setState({ addCustomer: false }) }}>
                                <AddIcon />
                                ADD
                            </Button>


                        </DialogActions>
                        <div style={{ marginBottom: "5%" }}></div>
                    </Grid>






                </Dialog>










            </>


        );
    }
}
