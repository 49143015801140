import React, { Component } from "react";
// import skyyrider from "./img/skyyriderlogo.png";
// import Background from "./img/background.jpg"
import { Grid, Typography, Card, Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import "./profile.css";
import { Link } from "react-router-dom";
// import BG from "./img/backgroun"
import {
  // Link,
  Navigate,
} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from "@mui/system";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }
  componentDidMount() {
    this.props.view_user_profile(this.props.login.user_id);
  }
  render() {
    const {
      one_user
    } = this.props;
    if (this.state.redirect === true) {
      return <Navigate to="/" />;
    }
    return (
      <>
       
        <Grid className="bg">
        <Link to="/" style={{marginLeft:100,marginTop:100}}> <ArrowBackIcon style={{marginTop:60}}/></Link>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              alt="Cindy Baker"
              src={one_user.one_user.profile_pic}
              style={{ width: "100px", height: "100px", marginTop: "-4vh" }}
            />
          </Grid>
         
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              //   paddingTop: "20px",
            }}
          >
            {one_user.one_user.name}
          </h1>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              fontSize: "20px",
            }}
          >
            Email:
            <Typography
              style={{
                paddingLeft: "5px",
                color: "white",
                fontSize: "20px",
              }}
            >
              {one_user.one_user.email}
            </Typography>
          </Typography>


          <Grid
            container
            spacing={2}
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  paddingRight: "5px",
                  color: "white",
                }}
              >
                Employee Id:
              </Typography>
              <Card
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  width: "22vw",
                  height: "43px",
                }}
              >
                {one_user.one_user.Emp_Id}
              </Card>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  paddingRight: "5px",
                  color: "white",
                }}
              >
                Mobile:
              </Typography>
              <Card
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  width: "22vw",
                  height: "43px",
                }}
              >
                {one_user.one_user.mobile}
              </Card>
            </Grid>
            {/* <Grid item md={4} lg={4} xs={4} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography style={{fontSize:'15px',fontWeight:'500',paddingRight:"5px"}}>Email:</Typography>
            <Card style={{display:"flex",justifyContent:'center',alignItems:'center',width:'22vw',height:'43px'}}>xyz@gmail.com</Card>
          </Grid> */}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              padding: "10px 20px",
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "10px",
            }}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  paddingRight: "5px",
                  color: "white",
                  marginLeft: "13px",
                }}
              >
                Gender:
              </Typography>
              <Card
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  width: "22vw",
                  height: "43px",
                }}
              >
                {one_user.one_user.gender}
              </Card>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  paddingRight: "5px",
                  color: "white",
                  marginLeft: "33px",
                }}
              >
                D.O.B:
              </Typography>
              <Card
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  width: "22vw",
                  height: "43px",
                }}
              >
               {one_user.one_user.dob}
              </Card>
            </Grid>
            {/* <Grid item md={4} lg={4} xs={4} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography style={{fontSize:'18px',fontWeight:'500',paddingRight:"5px"}}>Email:</Typography>
            <Card style={{display:"flex",justifyContent:'center',alignItems:'center',width:'22vw',height:'43px'}}>xyz@gmail.com</Card>
          </Grid> */}
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "10px",
            }}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  paddingRight: "5px",
                  color: "white",
                  marginLeft: "-30px",
                }}
              >
                Present Address:
              </Typography>
              <Card
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  width: "22vw",
                  height: "43px",
                  borderRadius: "5px",
                  paddingLeft: '5px',
                  // paddingTop:'2px'
                }}
              >
                {one_user.one_user.pr_address}
              </Card>
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  paddingRight: "5px",
                  color: "white",
                  marginLeft: "-84px",
                }}
              >
                Permanent Address:
              </Typography>
              <Card
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  width: "22vw",
                  height: "43px",
                  borderRadius: "5px",
                  // paddingLeft:'5px',
                  // paddingTop:'2px'
                }}
              >
                {one_user.one_user.p_address}
              </Card>
            </Grid>
            {/* <Grid item md={4} lg={4} xs={4} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Typography style={{fontSize:'18px',fontWeight:'500',paddingRight:"5px"}}>Email:</Typography>
            <Card style={{display:"flex",justifyContent:'center',alignItems:'center',width:'22vw',height:'43px'}}>xyz@gmail.com</Card>
          </Grid> */}
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Link to="/editProfile" style={{ textDecoration: "none" }}>
              <Button
                style={{
                  background: "orangered",
                  color: "white",
                  marginTop: "20px",
                }}
              >
                Edit Profile
              </Button>
            </Link><Link to="/" style={{ textDecoration: "none",marginLeft:"15px" }}>
              <Button
                style={{
                  background: "orangered",
                  color: "white",
                  marginTop: "20px",
                }}
                onClick={() => {
                  // localStorage.removeItem("user_id");
                  this.setState({ redirect: true });
                  this.props.onLogout();
                }}
              >
                {" "}
                 LogOut
              </Button>
            </Link>
          </Grid>
        </Grid>
      </>
    );
  }
}
