import {
    SET_USER,
    SET_ONE_USER,
    SET_USER_ID,
    SET_USER_NAME,
    SET_USER_MOBILE,
    SET_USER_EMAIL,
    SET_USER_GENDER,
    SET_USER_DOB,
    SET_USER_PADDRESS,
    SET_USER_PRADDRESS,
    SET_USER_PROFILE_PIC,
    SET_USER_PASSWORD
} from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
// import { set_snack_bar } from "../../common/snackbar/action";
import { getStorage,ref,uploadBytesResumable,getDownloadURL   } from "firebase/storage";
import 'firebase/storage';
// import firebase from 'firebase/app'

export function add_upload_profile(id,name,mobile,email,dob,gender,profile_pic,p_address,pr_address,password) {
    return dispatch => {
      console.log(id)
      dispatch(setLoader(true));
      const storageRef = ref(getStorage(),"/Profile/" + id + ".png")
        // .put(image)
        // uploadBytes(storageRef, image).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //   });
          const uploadTask = uploadBytesResumable(storageRef, profile_pic);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
        },
        function (error) {
            console.log(error)
        //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
          dispatch(setLoader(false));
        },
        function () {
          getDownloadURL(uploadTask.snapshot.ref).then(function (downloadURL) {
            console.log(downloadURL)
            dispatch(createUser(id,name,mobile,email,dob,gender,downloadURL,p_address,pr_address,password));
          });
        }
      );
  
    }
  }


export function createUser(Emp_Id,name,mobile,email,password,gender,dob,p_address,pr_address,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Emp_Id: Emp_Id,
                name: name,
                mobile: mobile,
                email: email,
                password:password,
                gender: gender,
                dob:dob,
                p_address:p_address,
                pr_address:pr_address,
                type: "E"
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_user(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function view_all_user(admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_all_users", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_USER, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function upload_profile(id,name,mobile,email,dob,gender,profile_pic,p_address,pr_address,password) {
    return dispatch => {
      console.log(id)
      dispatch(setLoader(true));
      const storageRef = ref(getStorage(),"/Profile/" + id + ".png")
        // .put(image)
        // uploadBytes(storageRef, image).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        //   });
          const uploadTask = uploadBytesResumable(storageRef, profile_pic);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
        },
        function (error) {
            console.log(error)
        //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
          dispatch(setLoader(false));
        },
        function () {
          getDownloadURL(uploadTask.snapshot.ref).then(function (downloadURL) {
            console.log(downloadURL)
            dispatch(updateUser(id,name,mobile,email,dob,gender,downloadURL,p_address,pr_address,password));
          });
        }
      );
  
    }
  }

export function updateUser(id,name,mobile,email,dob,gender,profile_pic,p_address,pr_address,password,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                name: name,
                mobile: mobile,
                email: email,
                dob : dob,
                gender: gender,
                profile_pic : profile_pic,
                p_address : p_address,
                pr_address : pr_address,
                password : password,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_user(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function deleteUser(Emp_Id,admin_id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Emp_Id: Emp_Id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_user(admin_id));
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function view_user_profile(id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(setID(responseJson.result.Emp_Id));
                    dispatch(setName(responseJson.result.name));
                    dispatch(setMobile(responseJson.result.mobile));
                    dispatch(setEmail(responseJson.result.email));
                    dispatch(setGender(responseJson.result.gender));
                    dispatch(setDob(responseJson.result.dob));
                    dispatch(setP_address(responseJson.result.p_address));
                    dispatch(setPr_address(responseJson.result.pr_address));
                    dispatch(setProfile_pic(responseJson.result.profile_pic));
                    dispatch(setPassword(responseJson.result.password));
                    dispatch({ type: SET_ONE_USER, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ONE_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function setID(payload) {
    return {
        type: SET_USER_ID,
        payload: payload,
    };
}

export function setName(payload) {
    return {
        type: SET_USER_NAME,
        payload: payload,
    };
}

export function setMobile(payload) {
    return {
        type: SET_USER_MOBILE,
        payload: payload,
    };
}
export function setEmail(payload) {
    return {
        type: SET_USER_EMAIL,
        payload: payload,
    };
}
export function setGender(payload) {
    return {
        type: SET_USER_GENDER,
        payload: payload,
    };
}
export function setDob(payload) {
    return {
        type: SET_USER_DOB,
        payload: payload,
    };
}
export function setP_address(payload) {
    return {
        type: SET_USER_PADDRESS,
        payload: payload,
    };
}
export function setPr_address(payload) {
    return {
        type: SET_USER_PRADDRESS,
        payload: payload,
    };
}
export function setProfile_pic(payload) {
    return {
        type: SET_USER_PROFILE_PIC,
        payload: payload,
    };
}
export function setPassword(payload) {
    return {
        type:  SET_USER_PASSWORD,
        payload: payload,
    };
}

