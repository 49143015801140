import { Typography, Card, Grid, CardContent, TablePagination } from '@mui/material'
import React, { Component } from 'react'
import "../style/style.css"
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import moment from "moment";


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "30ch",
      },
    },
  },
}));

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '1',
      page: 0,
      setPage: 0,
      rowsPerPage: 20,
      setRowsPerPage: "",
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  componentDidMount() {

    if (this.props.login.type === "SA") {
      this.props.view_all_booking_service();
      this.props.view_all_completed_service();
      this.props.view_status_booking();
      this.props.view_status_delivered();
      this.props.viewAllOrderBySA(this.state.page, this.state.rowsPerPage);
      this.props.viewAllServiceBySA(this.state.page, this.state.rowsPerPage);

    } else if (this.props.login.type === "D") {
      this.props.view_Status_Delivered_By_Admin(this.props.login.user_id)
      this.props.view_Status_Booking_By_Admin(this.props.login.user_id)
      this.props.view_All_Service_Booking_By_Admin(this.props.login.user_id)
      this.props.view_All_Service_Completed_By_Admin(this.props.login.user_id)
      this.props.viewAllOrder(this.props.login.user_id, 'booking', "true", this.state.page);
      this.props.viewAllService(this.props.login.user_id, this.state.page, this.state.rowsPerPage);
    }

  }

  render() {
    const { value } = this.state;
    const { all_order, all_service } = this.props;
    const handleChangePage = (event, newPage) => {
      this.props.viewAllOrder(this.props.login.user_id, this.state.status, this.state.order_status, newPage);
      this.setState({
        page: newPage,
      });
    };
    const handleChangePage2 = (event, newPage) => {
      this.props.viewAllService(this.props.login.user_id, newPage, this.state.rowsPerPage);
      this.setState({
        page: newPage,
      });
    };
    return (
      <div>
        <Grid style={{ display: "flex", flexDirection: "row", gap: 10 }}>

          <Card className='samll-container'>

            <Typography>Vehicle Ordered - {this.props.all_order.status_booking}</Typography>

            <Typography>Vehicle Delivered - {this.props.all_order.status_delivered}</Typography>


          </Card>
          <Card className='samll-container'>

            <Typography>Service Booked - {this.props.all_service.booking_service}</Typography>

            <Typography>Service Completed - {this.props.all_service.completed_service}</Typography>
          </Card>
        </Grid>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                <Tab label="Vehicle Order" value="1" />
                <Tab label="Service Issue" value="2" />

              </TabList>
            </Box>
            <TabPanel value="1" style={{ marginLeft: "-2%" }}>

              <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}  >
                {/* <Search
                  style={{

                    height: 32,


                    width: "300px",
                    border: "1px solid #000000",
                    borderRadius: 4
                  }}
                  direction="row"
                >
                  <SearchIconWrapper>
                    <SearchIcon style={{ color: "black" }} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search ....."
                    color="black"
                    inputProps={{ "aria-label": "search" }}
                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                  // onChange={handleChange}
                  />
                </Search> */}
                <TablePagination
                  rowsPerPageOptions={[20,]}
                  component="div"
                  count={Array.isArray(this.props.all_order.all_order) && this.props.all_order.all_order.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>


              <Box
                style={{ marginLeft: "-0.8%" }}
              >
                <CardContent style={{}}>
                  <TableContainer >
                    <Table style={{ minWidth: 300 }} >
                      <TableHead className="colorhead">
                        <TableRow >
                          <TableCell>Customer’s Name</TableCell>
                          <TableCell>Vehicle</TableCell>
                          <TableCell>Booking Date </TableCell>
                          <TableCell>Delivery Date</TableCell>



                        </TableRow>
                      </TableHead>
                      <TableBody className="colorbody">
                        {Array.isArray(this.props.all_order.all_order) && this.props.all_order.all_order.map((order) => {
                          return (
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >


                              <TableCell>{order.customer !== null ? order.customer : "- - - - - "}</TableCell>
                              <TableCell>{order.model !== null ? order.model : "- - - - - "}</TableCell>
                              <TableCell>{order.booking_date !== null ? moment(order.booking_date).format('LLL') : "- - - - - "}</TableCell>
                              <TableCell>{order.estimated_delivery_date !== null ? order.estimated_delivery_date : "- - - - - "}</TableCell>


                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </CardContent>
              </Box>


            </TabPanel>
            <TabPanel value="2" style={{ marginLeft: "-2%" }}>


              <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
                {/* <Search
                  style={{

                    height: 32,

                    width: "300px",
                    border: "1px solid #000000",
                    borderRadius: 4
                  }}
                  direction="row"
                >
                  <SearchIconWrapper>
                    <SearchIcon style={{ color: "black" }} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search ....."
                    color="black"
                    inputProps={{ "aria-label": "search" }}
                    style={{ textAlign: "center", justifyItems: "center", marginTop: -4 }}
                  // onChange={(e) => this.props.search_service(all_service.all_service, e.target.value, "search")}
                  />
                </Search> */}
                <TablePagination
                  rowsPerPageOptions={[20,]}
                  component="div"
                  count={Array.isArray(this.props.all_service.all_service) && this.props.all_service.all_service.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={handleChangePage2}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>


              <Box
                style={{ marginLeft: "-0.8%" }}
              >
                <CardContent style={{}}>
                  <TableContainer >
                    <Table style={{ minWidth: 300 }} >
                      <TableHead className="colorhead">
                        <TableRow >
                          <TableCell style={{ textAlign: "center" }}>Customer Name</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Phone Number</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Modal Name</TableCell>
                          <TableCell style={{ textAlign: "center" }} >Status</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Agent Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="colorbody">
                        {Array.isArray(this.props.all_service.all_service) && this.props.all_service.all_service.map((service) => {
                          return (
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >


                              <TableCell style={{ textAlign: "center" }}>{service.customer_details.cust_name}</TableCell>
                              <TableCell style={{ textAlign: "center" }}>{service.customer_details.cust_mobile}</TableCell>
                              <TableCell style={{ textAlign: "center" }}>{service.model_name}</TableCell>
                              <TableCell style={{ textAlign: "center", fontFamily: "Georgia", color: "white" }}>
                                <Button style={{
                                  backgroundColor: service.status === 'register' ? 'orange' :
                                    service.status === 'assigned' ? 'red' :
                                      service.status === 'pending' ? "purple" :
                                        service.status === 'booking' ? "blue" : 'green', borderRadius: '10px', height: "25px", textAlign: "center"
                                }} variant="contained"

                                >
                                  {service.status}
                                </Button>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}
                              >{service.agent_name}</TableCell>


                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </CardContent>
              </Box>




            </TabPanel>

          </TabContext>
        </Box>



      </div>
    )
  }
}
