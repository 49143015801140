import {
    SET_ADMIN_USER
} from "./constant";
import UNIVERSAL from "../../../config/config";
import { setLoader, unsetLoader } from "../../../common/loader/action";
import { set_snack_bar } from "../../../common/snackbar/action";
import 'firebase/storage';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


export function add_upload_profile(admin_username, admin_useremail, admin_mobile, admin_userpassword, admin_profile) {
    return dispatch => {
        dispatch(setLoader(true));
        const storageRef = ref(getStorage(), "/Profile/" + admin_mobile + ".png")
        const uploadTask = uploadBytesResumable(storageRef, admin_profile);
        uploadTask.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                dispatch(setLoader(false));
            },
            function () {
                getDownloadURL(uploadTask.snapshot.ref).then(function (admin_profile) {
                    console.log(admin_profile)
                    dispatch(createadmin_user(admin_username, admin_useremail, admin_mobile, admin_userpassword, admin_profile));
                });
            }
        );

    }
}

export function update_upload_admin_user(id, admin_username, admin_useremail, admin_mobile, admin_userpassword, profile_pic, profile_pic2) {
    return dispatch => {
        if (profile_pic2 === "") {
            dispatch(updateadmin_user(id, admin_username, admin_useremail, admin_mobile, admin_userpassword, profile_pic));
        }
        else {

            dispatch(setLoader(true));
            const storageRef = ref(getStorage(), "/Profile/" + admin_mobile + ".png")
            const uploadTask = uploadBytesResumable(storageRef, profile_pic2);
            uploadTask.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    dispatch(setLoader(false));
                },
                function () {
                    getDownloadURL(uploadTask.snapshot.ref).then(function (profile_pic2) {
                        console.log(profile_pic2)
                        dispatch(updateadmin_user(id, admin_username, admin_useremail, admin_mobile, admin_userpassword, profile_pic2));
                    });
                }
            );
        }

    }
}

export function createadmin_user(admin_username, admin_useremail, admin_mobile, admin_userpassword, admin_profile) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "add_admin_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({

                name: admin_username,
                email: admin_useremail,
                mobile: admin_mobile,
                password: admin_userpassword,
                profile_pic: admin_profile,

                // type: "AU"
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_admin_user());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateadmin_user(id, admin_username, admin_useremail, admin_mobile, admin_userpassword, profile_pic) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "update_admin_users", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                profile_pic:profile_pic,
                id: id,
                name: admin_username,
                email: admin_useremail,
                mobile: admin_mobile,
                password: admin_userpassword


            })
        })

            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.status) {
                    dispatch(view_all_admin_user());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteUser(id) {
    return (dispatch) => {
        dispatch(setLoader());
        // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
        return fetch(UNIVERSAL.BASEURL + "delete_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(view_all_admin_user());
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    // dispatch({ type: SET_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

// export function deleteCategory(category_id) {
//     return (dispatch) => {
//         dispatch(setLoader());
//         // var otp= Math.floor(Math.random() * (999999 - 100000)) + 100000;
//         return fetch(UNIVERSAL.BASEURL + "delete_category", {
//             method: "POST",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//                 category_id: category_id,
//             })
//         })
//             .then((response) => response.json())
//             .then((responseJson) => {

//                 if (responseJson.status) {
//                     dispatch(viewAllCategory());
//                     // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
//                     dispatch(unsetLoader());
//                 } else {
//                     // alert("No Registration Found! Please Register First");
//                     // dispatch({ type: SET_USER, payload: [] })
//                     // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
//                 }
//                 dispatch(unsetLoader());
//             })
//             .catch((error) => {
//                 console.error(error);
//             });
//     };
// }

export function view_all_admin_user() {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_admin_users", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_ADMIN_USER, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    // alert("No Registration Found! Please Register First");
                    dispatch({ type: SET_ADMIN_USER, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}